const resourceElementNames = {
  "ActivityDefinition": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "status", "experimental", "date", "publisher", "contact", "description", "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "subtitle", "subjectCodeableConcept", "subjectReference", "subjectCanonical", "library", "kind", "profile",
    "code", "intent", "priority", "doNotPerform", "timingTiming", "timingAge", "timingRange", "timingDuration",
    "asNeededBoolean", "asNeededCodeableConcept", "location", "participant", "productReference", "productCodeableConcept",
    "quantity", "dosage", "bodySite", "specimenRequirement", "observationRequirement", "observationResultRequirement",
    "transform", "dynamicValue"],
  "Bundle": ["id", "meta", "implicitRules", "language",
    "identifier", "type", "timestamp", "total", "link", "entry", "signature", "issues"],
  "CodeSystem": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "status", "experimental",
    "date", "publisher", "contact", "description", "useContext", "jurisdiction", "purpose", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "caseSensitive", "valueSet", "hierarchyMeaning", "compositional", "versionNeeded", "content", "supplements", "count", "filter",
    "property", "concept"],
  "Composition": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "status", "type", "category", "subject", "encounter",
    "date", "useContext", "author", "name", "title", "note", "attester", "custodian", "relatesTo",
    "event", "section"],
  "Evidence": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title",
    "citeAsReference", "citeAsMarkdown", "status", "experimental", "date", "publisher", "contact", "description",
    "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "assertion", "note", "variableDefinition", "synthesisType", "studyDesign", "statistic", "certainty"],
  "EvidenceVariable": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "shortTitle",
    "status", "experimental", "date", "publisher", "contact", "description", "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "actual", "definition", "characteristic", "characteristicDefinition", "handling", "category"],
  "Group": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title",
    "status", "experimental", "date", "publisher", "contact", "description", "useContext", "purpose", "copyright", "copyrightLabel",
    "type", "membership", "code", "quantity", "managingEntity",
    "combinationMethod", "combinationThreshold", "characteristic", "member"],
  "Library": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title",
    "subtitle", "status", "experimental", "type", "subjectCodeableConcept", "subjectReference",
    "date", "publisher", "contact", "description", "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser",
    "relatedArtifact", "parameter", "dataRequirement", "content"],
  "List": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "identifier", "status", "mode", "title", "code", "subject", "encounter", "date", "source", "orderedBy",
    "note", "entry", "emptyReason"],
  "Measure": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "subtitle", "status", "experimental",
    "subjectCodeableConcept", "subjectReference", "basis", "date", "publisher", "contact", "description", "useContext", "jurisdiction",
    "purpose", "usage", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "library", "disclaimer", "scoring", "scoringUnit",
    "compositeScoring", "type", "riskAdjustment", "rateAggregation", "rationale", "clinicalRecommendationStatement",
    "improvementNotation", "term", "guidance", "group", "supplementalData"],
  "PlanDefinition": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding",
    "name", "title", "subtitle", "type", "status", "experimental",
    "subjectCodeableConcept", "subjectReference", "subjectCanonical",
    "date", "publisher", "contact", "description", "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "library", "goal", "actor", "action", "asNeededBoolean", "asNeededCodeableConcept"],
  "ResearchStudy": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "name", "title", "label", "protocol", "partOf", "relatedArtifact",
    "date", "status", "primaryPurposeType", "phase", "studyDesign", "focus", "condition", "keyword", "region",
    "descriptionSummary", "description", "period", "site", "note", "classifier",
    "associatedParty", "progressStatus", "whyStopped", "recruitment", "comparisonGroup", "objective",
    "outcomeMeasure", "result"],
  "ValueSet": ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "status", "experimental",
    "date", "publisher", "contact", "description", "useContext", "jurisdiction", "immutable", "purpose", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "compose", "expansion", "scope"]
}

export default resourceElementNames;