import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useHistory, Redirect, Switch, Route } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import { Modal, Button, Dimmer, Loader } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import firebase from 'firebase/compat/app';
import { getAuth, signInWithEmailAndPassword, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import './App.css';
import FevirContext from './FevirContext';
import FevirNavigationButtons from './FevirNavigationButtons';
import HomePage from './HomePage';
import TermsOfAgreementPage from './TermsOfAgreementPage';
import MyBallotPage from './MyBallotPage';
import ToolsPage from './ToolsPage';
import ProjectsPage from './ProjectsPage';
import EditUserProfilePage from './EditUserProfilePage';
import { FavoriteResourcesPage } from './FavoriteResourcesPage';
import RecordRepoPage from './RecordRepoPage';
import AdminPage from './AdminPage';
import UserRemovalPage from './UserRemovalPage';
import JsonEntryPage from './JsonEntryPage';
import CreateResourcePage from './CreateResourcePage';
import JsonBulkEntryPage from './JsonBulkEntryPage';
import {ConfirmModal} from './ConfirmModal';
import CreateNewProjectPage from './CreateNewProjectPage';
import CreateNewCitationPage from './CreateNewCitationPage';
import CreateNewRiskOfBiasAssessmentPage from './CreateNewRiskOfBiasAssessmentPage';
import CreateCodeableConceptJsonPage from './CreateCodeableConceptJsonPage';
import SRDRProjectImporterPage from './SRDRProjectImporterPage';
import CEDARSearchImporterPage from './CEDARSearchImporterPage';
import MedlineConvertPage from './MedlineConvertPage';
import MedlineSearchImportPage from './MedlineSearchImportPage';
import RIStoFEvIRConverterPage from './RIStoFEvIRConverterPage';
import DocxToJsonSectionsConverterPage from './DocxToJsonSectionsConverterPage';
import GradeProConverterPage from './GradeProConverterPage';
import MAGICtoFEvIRConverterPage from './MAGICtoFEvIRConverterPage';
import CTgovConvertPage from './CTgovConvertPage';
import ConvertedCTgovPage from './ConvertedCTgovPage';
import KnowledgePortalDemoPage from './KnowledgePortalDemoPage';
import ContentResourcesPage from './ContentResourcesPage';
import ContentNewResourcesPage from './ContentNewResourcesPage';
import NetEffectCalculatorPage from './NetEffectCalculatorPage';
import RecommendationTableViewerPage from './RecommendationTableViewerPage';
import ApiDocumentationPage from './ApiDocumentationPage';
import SearchQueryPage from './SearchQueryPage';
import ResultsPage from './ResultsPage';
import FliToFoiPage from './FliToFoiPage';
import TermsOfUsePage from './TermsOfUsePage';
import PersonalDataAgreementPage from './PersonalDataAgreementPage';
import InvitedToResourcePage from './InvitedToResourcePage';
import ResourceDisplayPage from './ResourceDisplayPage';
import MeasuringTheRatePage from './MeasuringTheRatePage';
import MeasuringTheRateCreationPage from './MeasuringTheRateCreationPage';
import FeedbackButton from './FeedbackButton';
import Footer from './Footer';
import TestPage from './TestPage';
import PageNotFoundPage from './PageNotFoundPage';
//import MaintenanceInProgressPage from './MaintenanceInProgressPage';
import ScrollToTop from './ScrollToTop';
import { googleSignInFirebase, emailPasswordSignInFirebase, continuedSessionLogin, getResource } from "./UserAccountFunctions";
import { changeSimpleStateWithCheck } from './ResourceFunctions';
import JsonVersionComparison from './JsonVersionComparison';
import ProfilesByResourceType from './ProfilesByResourceType';

const startingUserState = {
  loading: true,
  displaythetermsofagreement: false,
  id: null,
  firebaseuid: null,
  name: null,
  displayname: null,
  email: null,
  additionalemail: null,
  shortbio: null,
  termsofuseaccept: [],
  datapersonalagreementaccept: [],
  type: [],
  affiliations: [""],
  datecreated: null,
  lastmodified: null,
  usercreated: false
};

const emptyState = {
  "currentPage": 1,
  "pageTitle": ""
};
let startingState = emptyState;

const useTitle = (title) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    }
  })
};

const AlertModal = ({ alertModalState, setAlertModalState }) => {
  const modalClose = () => {
    setAlertModalState(prevState => { return { ...prevState, "open": false }; });
  }

  useEffect(() => {
    window.addEventListener('keyup', (e) => { if (e.key === "Enter") { modalClose(); } }, false);
  }, []);

  useEffect(async () => {
    if (alertModalState.open) {
      setAlertModalState(prevState => { return { ...prevState, alertHistory: [...prevState.alertHistory, { header: prevState.header, content: prevState.content }] }; });
    }
  }, [alertModalState.open]);


  return <Modal
    style={{ width: "60%", margin: "16px" }}
    dimmer={<Modal.Dimmer blurring style={{ backgroundColor: "#00000077" }} />}
    open={alertModalState.open}
    centered={false}
    content={<div style={{ whiteSpace: "pre-wrap" }} onKeyUp={(e) => { if (e.key === "Enter") { modalClose(); } }}>
      <Button style={{ padding: "6px", position: "absolute", top: "6px", right: "6px" }} className="formButton negative" content="✖"
        onClick={modalClose} />
      <Modal.Header style={{ padding: "6px 16px 0px 16px", color: "#AC3B61" }}><h2>{alertModalState.header}</h2></Modal.Header>
      <br />
      <div style={{ padding: "0px 16px 0px 16px" }}>
        {alertModalState.content && <><h3>{alertModalState.content}</h3><br /></>}
        <br />
      </div>
    </div>}
    closeOnEscape
    closeOnDimmerClick
    onClose={modalClose}
  />
}

const PromptModal = ({ promptModalState, setPromptModalState }) => {
  const modalClose = () => {
    setPromptModalState(prevState => { return { ...prevState, "open": false, "defaultValue": "", "inputLabel": "", "buttonLabel": "", "runFunction": null }; });
  }

  useEffect(async () => {
    if (promptModalState.open) {
      setPromptModalState(prevState => { return { ...prevState, "value": prevState.defaultValue || "" }; });
    }
  }, [promptModalState.open]);

  const modalSubmit = () => {
    if (promptModalState.runFunction) {
      promptModalState.runFunction(promptModalState.value);
    }
    modalClose();
  }

  return (
    <Modal
      style={{ width: "40%", margin: "16px" }}
      dimmer={<Modal.Dimmer blurring style={{ backgroundColor: "#00000077" }} />}
      open={promptModalState.open}
      centered={false}
      content={<div style={{ whiteSpace: "pre-wrap" }}>
        <Button style={{ padding: "6px", position: "absolute", top: "6px", right: "6px" }} className="formButton negative" content="✖"
          onClick={modalClose} />
        <Modal.Header style={{ padding: "6px 16px 0px 16px", color: "#AC3B61" }}><h2>{promptModalState.header}</h2></Modal.Header>
        <br />
        <div style={{ padding: "0px 16px 0px 16px" }}>
          {promptModalState.content && <><h3>{promptModalState.content}</h3><br /></>}
          <div style={{ width: "100%" }}>
            <TextField style={{ width: "calc(100% - 150px)" }}
              className="inputField" size="small" variant='outlined'
              label={promptModalState.inputLabel || ''}
              value={promptModalState.value || ""}
              onChange={(e) => { setPromptModalState(prevState => { return { ...prevState, value: e.target.value } }); }}
              onKeyUp={(e) => { if (e.key === "Enter") { modalSubmit(); } }}
            />
            <Button className="formButton" color={'green'} style={{ float: "right", padding: "10px 24px", fontSize: "20px" }}
              content={promptModalState.buttonLabel || "Submit"}
              onClick={modalSubmit}
            />
          </div>
          <br />
          <br />
        </div>
      </div>}
    />
  )
}

const SignInFireBaseModal = ({ globalContext }) => {
  const modalClose = () => {
    globalContext.setFirebaseModalState(prevState => { return { ...prevState, "open": false, "signInWithEmailAndPasswordPage": false, "email": "", "password": "" }; });
  }

  useEffect(async () => {
    if (globalContext.signInStatusState) {
      modalClose();
    }
  }, [globalContext.signInStatusState]);

  return <Modal
    style={{ width: "450px", margin: "16px" }}
    dimmer={<Modal.Dimmer blurring style={{ backgroundColor: "#00000077" }} />}
    open={globalContext.firebaseModalState.open}
    centered={false}
    content={<div style={{ whiteSpace: "pre-wrap" }} onKeyUp={(e) => { if (e.key === "Enter") { modalClose(); } }}>
      <Button style={{ padding: "6px", position: "absolute", top: "6px", right: "6px" }} className="formButton negative" content="✖"
        onClick={modalClose} />
      <Modal.Header style={{ padding: "6px 16px 0px 16px", color: "#AC3B61" }}><h2>Please sign in or create an account</h2></Modal.Header>
      <br />
      {!globalContext.firebaseModalState.signInWithEmailAndPasswordPage ?
        <div style={{ padding: "0px 16px 0px 16px" }}>
          <br />
          <button className="gsi-material-button" style={{width: "222px"}}
            onClick={() => {
              googleSignInFirebase(globalContext);
            }}>
            <div className="gsi-material-button-state" />
            <div className="gsi-material-button-content-wrapper">
              <div className="gsi-material-button-icon">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ display: "block" }}>
                  <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
                  <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
                  <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
                  <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
                  <path fill="none" d="M0 0h48v48H0z" />
                </svg>
              </div>
              <span className="gsi-material-button-contents">Sign in with Google</span>
              <span style={{ display: "none" }}>Sign in with Google</span>
            </div>
          </button><span style={{color: "#CC4444"}}> (recommended)</span>
          <br />
          <br />
          <button className="gsi-material-button"
            onClick={() => {
              globalContext.setFirebaseModalState(prevState => { return { ...prevState, "signInWithEmailAndPasswordPage": true }; });
            }}>
            <div className="gsi-material-button-state" />
            <div className="gsi-material-button-content-wrapper">
              <span className="gsi-material-button-contents">Sign in with Email & Password</span>
              <span style={{ display: "none" }}>Sign in with Email & Password</span>
            </div>
          </button>
          {/*
          <br />
          <br />
          <button className="gsi-material-button"
            onClick={() => {
              globalContext.setFirebaseModalState(prevState => { return { ...prevState, "signInWithEmailPage": true }; });
            }}>
            <div className="gsi-material-button-state" />
            <div className="gsi-material-button-content-wrapper">
              <span className="gsi-material-button-contents">Sign in with Email</span>
              <span style={{ display: "none" }}>Sign in with Email</span>
            </div>
          </button>
          */}
          <br />
          <br />
        </div>
        :
        <div>
          <br/>
          <TextField required style={{margin: "8px", width: "90%"}} className="inputField" type='text' label={'Email'} size="small" variant='outlined' value={globalContext.firebaseModalState.email}
            onChange={(e) => {
              globalContext.setFirebaseModalState(prevState => { return { ...prevState, email: e.target.value } });
            } }
          />
          <br/>
          <br/>
          <TextField required style={{margin: "8px", width: "90%"}} className="inputField" type='password' label={'FEvIR Password'} size="small" variant='outlined' value={globalContext.firebaseModalState.password}
            onChange={(e) => {
              globalContext.setFirebaseModalState(prevState => { return { ...prevState, password: e.target.value } });
            } }
          />
          <br/>
          <br/>
        <Button className="formButton" style={{ color: "#000000", marginLeft: "8px" }}
          content={`Sign In / Create Account`}
          disabled={!globalContext.firebaseModalState.email || !globalContext.firebaseModalState.password || !globalContext.firebaseModalState.email.includes("@")}
          onClick={async () => {
            //let email = "testUser@test.com";
            let email = "kshahintest8@computablepublishing.com";
            //let password = "testPassword";
            let password = "testPasswordETRBN%NNRTYTRNEgrdytr";
            emailPasswordSignInFirebase(globalContext);
          }} />
          {/* createUserWithEmailAndPassword 
              emailPasswordSignInFirebase(globalContext);
              globalContext.firebaseEmailAuthProvider
          */}
          <br/>
          <br/>
        </div>
      }
    </div>}
    closeOnEscape
    closeOnDimmerClick
    onClose={modalClose}
  />
}

const App = ({ firebaseGoogleProvider, firebaseEmailAuthProvider, firebaseConfig, createUserWithEmailAndPassword, ga4react }) => {

  const [globalLoadingState, setGlobalLoadingState] = useState(false);
  const [alertModalState, setAlertModalState] = useState({ open: false, alertHistory: [], header: "", content: "" });
  const [confirmModalState, setConfirmModalState] = useState({ open: false, header: "", content: "", runFunction: null, saveFunction: null, buttonTextOkay: "Okay", buttonTextCancel: "Cancel", buttonTextSave: "Save", buttonColorSave: "green", buttonColorOkay: "green", buttonColorCancel: "red" });
  const [promptModalState, setPromptModalState] = useState({ open: false, header: "", content: "", value: "", defaultValue: "", inputLabel: "", buttonLabel: "", runFunction: null });
  const [firebaseModalState, setFirebaseModalState] = useState({ open: false, email: "", password: "" });
  const [signInStatusState, setSignInStatusState] = useState(undefined);
  const [unsavedChangesInReferencedResourceState, setUnsavedChangesInReferencedResourceState] = useState({"unsavedChanges": false, "modalHeader": "", "message": "", "runFunction": null});

  const [userState, setUserState] = useState(startingUserState);
  const [favoriteFOIsState, setFavoriteFOIsState] = useState([]);
  const [favoritedResourcesDictState, setFavoritedResourcesDictState] = useState({});

  const [formState, setFormState] = useState(startingState);

  const [resourcesListState, setResourcesListState] = useState({ resourcesList: [], resourceTypes: [], allResourceTypes: [], visibleResourceTypes: [], resourcesTypeOptions: [], resourceDictionary: {}, projectsList: [], projectResourcesList: [""], projectResourcesDict: {}, resourceTypeList: [], filterByResourceType: [], filterByProject: [], resourceTypeMenuIsOpen: false, viewing: "all", expandAll: true, citationexpanded: false, loading: true, resourceTypesLoading: true });

  const [pageEditModeState, setPageEditModeState] = useState("");

  const changeFormState = (newValue, field) => {
    setFormState(prevState => {
      return {
        ...prevState,
        //fhirEntry: newValue,
        [field]: newValue
      };
    });
  };

  const openModal = (setter, data) => {
    setter(prevState => { return { ...prevState, "open": true, ...data }; });
  }

  const openAlert = (data) => openModal(setAlertModalState, data);
  const openConfirmation = (data) => openModal(setConfirmModalState, data);
  const openPrompt = (data) => openModal(setPromptModalState, data);

  let fevirContextObject = {
    globalLoadingState, setGlobalLoadingState,
    userState, setUserState,
    favoriteFOIsState, setFavoriteFOIsState,
    favoritedResourcesDictState, setFavoritedResourcesDictState,
    alertModalState, openAlert, openConfirmation, openPrompt,
    firebaseModalState, setFirebaseModalState,
    firebaseGoogleProvider, firebaseEmailAuthProvider, firebaseConfig, continuedSessionLogin,
    createUserWithEmailAndPassword,
    signInStatusState, setSignInStatusState,
    unsavedChangesInReferencedResourceState, setUnsavedChangesInReferencedResourceState
  };

  const GlobalPageDimmer = () => {
    return (<>{globalLoadingState
      &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }</>);
  }

  const PageDimmer = () => {
    return (<>{userState.loading
      &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }</>);
  }

  useEffect(async () => {
    /*
    await fetch('/meta.json', {credentials: 'same-origin', headers: { "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept" }})
      .then((response) => response.json() )
      .then(async (meta) => {
        if (meta.version !== global.appVersion) {
          console.log(metaJson);
          console.log(meta.version);
          console.log( global.appVersion);
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            console.log(caches);
            await caches.keys().then(async names => {
              console.log(names);
              await Promise.all(names.map(name => caches.delete(name)));
            });
          }
          console.log("Reloading page");
          window.location.reload(true);
          console.log("end of fetch")
        }
      });
    console.log("After fetch")
    */
    //("FEvIR Platform Loaded");
    //continuedSessionLogin(fetchUserData);
    //let user = firebase.auth().currentUser;
    //changeUserState(userState, setUserState, true, "loading");
    changeSimpleStateWithCheck(userState, setUserState, true, "loading");
    await continuedSessionLogin(fevirContextObject, undefined, setSignInStatusState);
    //load from tool_versions.txt
    //changeUserState(setUserState, false, "loading");
    //updateUserAccount(userState, setUserState);
  }, []);

  let devEnvironment = false;
  if (window.location.href.includes("dev.fevir.net") || window.location.href.includes("localhost")) {
    devEnvironment = true;
  } else {
    try {
      //(window.location.pathname);
      //ga4react.pageview(window.location.pathname);
      //ga4react.gtag('event','pageviewevent', window.location.pathname);
      //ga4react.gtag('event','pageview', { 'value': window.location.pathname });
      //ga4react.gtag('event','page_view', { 'value': window.location.pathname });
      //ga4react.gtag('config', 'MEASUREMENT_ID', { page_location:  window.location.href /* The full URL is required. */ });
      //ga4react.gtag('config', 'GA_MEASUREMENT_ID', { page_path:  window.location.pathname /* The full URL is required. */ });
      //ga4react.gtag('event','page_view', window.location.pathname );
      //ga4react.gtag('config', 'MEASUREMENT_ID', { page_location:  window.location.href /* The full URL is required. */ });
      //ga4react.gtag('event','page_view', { 'value': window.location.pathname });
      ga4react.pageview(window.location.pathname);
    } catch (e) { console.log("Google Analytics: BLOCKED by browser or by unknown error."); }
  }

  /* for react-router v6
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams.get("nctid"));
  console.log(searchParams);
  */

  const history = useHistory();

  let htmlPageTitle = <>{formState.pageTitle}</>;

  if (formState.pageTitle.substring(0, 6) === "FEvIR®") {
    htmlPageTitle = <>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>{formState.pageTitle.substring(6)}</>;
  } else if (formState.pageTitle.substring(0, 22) === "Computable Publishing®") {
    htmlPageTitle = <>Computable Publishing<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>{formState.pageTitle.substring(23)}</>;
  }

  return (
    <FevirContext.Provider value={fevirContextObject}>
      <ToastProvider autoDismiss autoDismissTimeout={5000} placement="bottom-left" >
        <GlobalPageDimmer />
        <AlertModal alertModalState={alertModalState} setAlertModalState={setAlertModalState} />
        <ConfirmModal confirmModalState={confirmModalState} setConfirmModalState={setConfirmModalState} />
        <PromptModal promptModalState={promptModalState} setPromptModalState={setPromptModalState} />
        <SignInFireBaseModal globalContext={fevirContextObject} />
        <Router>
          <ScrollToTop />
          {userState.displaythetermsofagreement ?
            <Switch>
              <Route path={["/termsofuse"]} exact>
              </Route>
              <Route path={["/personaldataagreement"]} exact>
              </Route>
              <Route>
                <Redirect from="/termsofuse" to="/termsofagreement" />
              </Route>
            </Switch> : <></>}
          <main className="App">
            <header className="App-header">
              <table className="headerTable">
                <tbody>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <h2 className={"computablePublishingTopHeader"}>
                        <a href="/" className={"unselectable"}>
                          {devEnvironment
                            ?
                            <span style={{ color: "red" }}>Development FEvIR</span>
                            :
                            <span>FEvIR Platform</span>
                          }</a>
                        {/*&nbsp;&nbsp;<span style={{display: "none", cursor: "pointer", color: "#000099", border: "3px solid", paddingLeft: "3px", paddingRight: "3px"}}>&#9776; Menu</span>*/}
                      </h2>
                    </td>
                    <td style={{ width: "100%", textAlign: "center" }}></td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "right" }}><FevirNavigationButtons /></td>
                  </tr>
                </tbody>
              </table>
            </header>
            <div className={"appBody"}>
              <Switch>
                {/*<Route>
            <MaintenanceInProgressPage useTitle={useTitle} />
          </Route>*/}
                <Route path={[""]} exact>
                  <PageDimmer />
                  <HomePage useTitle={useTitle} setResourcesListState={setResourcesListState} />
                  <Footer path={""} />
                </Route>
                <Route path={["/editprofile"]} exact>
                  {/*<PageDimmer/>*/}
                  <EditUserProfilePage useTitle={useTitle} />
                  <Footer path={"/editprofile"} />
                </Route>
                <Route path={["/recordrepo"]} exact>
                  {/*<PageDimmer/>*/}
                  <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>User Repository Management</h2>
                  <RecordRepoPage useTitle={useTitle} />
                  <Footer path={"/recordrepo"} />
                </Route>
                {(userState.loading || userState.admin) &&
                  <Route path={["/admin"]} exact>
                    <PageDimmer />
                    <AdminPage useTitle={useTitle} />
                    <Footer path={"/admin"} />
                  </Route>
                }
                <Route path={["/jsondifftest",]} exact>
                  <JsonVersionComparison />
                </Route>
                <Route path={["/content/resources", "/resources"]} exact>
                  <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup> Resources</h2>
                  <ContentResourcesPage useTitle={useTitle} loadMyResources={false} signInStatusState={signInStatusState} resourcesListState={resourcesListState} setResourcesListState={setResourcesListState} />
                  <Footer path={"/content/resources"} />
                </Route>
                <Route path={["/newlookresources"]} exact>
                  <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup> Resources</h2>
                  <ContentNewResourcesPage useTitle={useTitle} loadMyResources={false} signInStatusState={signInStatusState} resourcesListState={resourcesListState} setResourcesListState={setResourcesListState} />
                  <Footer path={"/content/resources"} />
                </Route>
                <Route path={["/content/myresources"]} exact>
                  <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>My FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup> Resources</h2>
                  <ContentResourcesPage useTitle={useTitle} loadMyResources={true} signInStatusState={signInStatusState} resourcesListState={resourcesListState} setResourcesListState={setResourcesListState} />
                  <Footer path={"/content/myresources"} />
                </Route>
                <Route path={["/favorites", "favorite", "bookmarks", "bookmark"]} exact>
                  <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>My Favorited Resources</h2>
                  <FavoriteResourcesPage useTitle={useTitle} loadMyResources={true} signInStatusState={signInStatusState} favoritedResourcesDictState={favoritedResourcesDictState} setFavoritedResourcesDictState={setFavoritedResourcesDictState} />
                  <Footer path={"/favorites"} />
                </Route>
                <Route path={["/radar"]}
                  render={(props) => {
                    return <>
                      <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: RADAR Tool</h2>
                      <MeasuringTheRateCreationPage useTitle={useTitle} />
                      <Footer path={"/radar"} />
                    </>
                  }} exact>
                </Route>
                <Route path={["/radar/:frcpdbid?",]}
                  render={(props) => {
                    let investigationId = props.match.params.frcpdbid;
                    let modalLoad = props.location.hash.slice(1);
                    return <>
                      <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: RADAR Tool</h2>
                      <MeasuringTheRatePage useTitle={useTitle} investigationId={investigationId} modalLoad={modalLoad} />
                      <Footer path={"/radar"} />
                    </>
                  }} exact>
                </Route>
                <Route path={["/create", "/create/:resource_type?/:frcpdbid?", "/create/:resource_type?", "/jsonentry", "/fhirentry"]}
                  render={(props) => {
                    let resourceType = props.match.params.resource_type;
                    let resourceId = props.match.params.frcpdbid;
                    let path = "/create";
                    let jsonView = false;
                    if (resourceType && resourceId) {
                      path = "/resources/" + resourceType + "/" + resourceId;
                      jsonView = true;
                    }

                    if (props.location.hash === "#json") {
                      jsonView = true;
                      if (history) {
                        history.push(`/create`);
                      }
                    }
                    return <>
                      <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: Create New Resource</h2>
                      <CreateResourcePage useTitle={useTitle} resourceType={resourceType} resourceId={resourceId} getResource={getResource} jsonView={jsonView} />
                      <Footer path={path} />
                    </>
                  }} exact>
                </Route>
                <Route path={["/jsonentryold", "/jsonentryold/:resource_type?/:frcpdbid?", "/fhirentryold", "/fhirentryold/:resource_type?/:frcpdbid?"]}
                  render={(props) => {
                    let resourceType = props.match.params.resource_type;
                    let resourceId = props.match.params.frcpdbid;
                    let path = "/create";
                    if (resourceType && resourceId) {
                      path = "/resources/" + resourceType + "/" + resourceId;
                    }
                    return <>
                      <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: Resource JSON Entry</h2>
                      <JsonEntryPage useTitle={useTitle} resourceType={resourceType} resourceId={resourceId} getResource={getResource} />
                      <Footer path={path} />
                    </>
                  }} exact>
                </Route>
                <Route path={["/createrecommendation"]} render={(props) => {
                  let path = "/create";
                  let resourceStarter = {
                    "resourceType": "Composition",
                    "meta": { "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation"] },
                    "title": "",
                    "type": {
                      coding: [{
                        system: "https://fevir.net/resources/CodeSystem/179423",
                        code: "Recommendation",
                        display: "Recommendation"
                      }]
                    },
                    "section": []
                  };
                  resourceStarter.section = ProfilesByResourceType.Composition.Recommendation.sectionStarter;
                  return <>
                    <h2 style={{
                      zIndex: "-1", margin: "0px", width: "100%", textAlign: "center",
                      top: "8px", position: "fixed", color: "#AC3B61"
                    }}>Computable Publishing<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: Create Recommendation</h2>
                    <JsonEntryPage useTitle={useTitle} resourceType={"Composition"} resourceId={""}
                      getResource={getResource}
                      resourceStarter={resourceStarter} />
                    <Footer path={path} />
                  </>
                }} exact>
                </Route>
                <Route path={["/createguideline"]} render={(props) => {
                  let path = "/create";
                  let resourceStarter = {
                    "resourceType": "Composition",
                    "meta": { "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/guideline"] },
                    "title": "",
                    "type": {
                      coding: [{
                        system: "https://fevir.net/resources/CodeSystem/179423",
                        code: "Guideline",
                        display: "Guideline"
                      }]
                    },
                    "section": []
                  };
                  resourceStarter.section = ProfilesByResourceType.Composition.Guideline.sectionStarter;
                  return <>
                    <h2 style={{
                      zIndex: "-1", margin: "0px", width: "100%", textAlign: "center",
                      top: "8px", position: "fixed", color: "#AC3B61"
                    }}>Computable Publishing<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: Create Guideline</h2>
                    <JsonEntryPage useTitle={useTitle} resourceType={"Composition"} resourceId={""}
                      getResource={getResource}
                      resourceStarter={resourceStarter} />
                    <Footer path={path} />
                  </>
                }} exact>
                </Route>
                <Route path={["/createcohortdefinition"]} render={(props) => {
                  let path = "/create";
                  return <>
                    <h2 style={{
                      zIndex: "-1", margin: "0px", width: "100%", textAlign: "center",
                      top: "8px", position: "fixed", color: "#AC3B61"
                    }}>Computable Publishing<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: Create Cohort Definition</h2>
                    <JsonEntryPage useTitle={useTitle} resourceType={"Group"} resourceId={""}
                      getResource={getResource}
                      resourceStarter={{
                        "resourceType": "Group",
                        "membership": "conceptual",
                        "title": "",
                        "name": "",
                        "characteristic": []
                      }} />
                    <Footer path={path} />
                  </>
                }} exact>
                </Route>
                <Route path={["/jsonbulkentry"]} render={(props) => {
                  return <>
                    <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: Resource JSON Entry</h2>
                    <JsonBulkEntryPage useTitle={useTitle} />
                    <Footer path={"/jsonbulkentry"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/createproject"]} render={(props) => {
                  return <>
                    <CreateNewProjectPage useTitle={useTitle} />
                    <Footer path={"/createproject"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/createcitation"]} render={(props) => {
                  return <>
                    <CreateNewCitationPage useTitle={useTitle} />
                    <Footer path={"/createcitation"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/createcodeableconcept"]} render={(props) => {
                  return <>
                    <CreateCodeableConceptJsonPage useTitle={useTitle} />
                    <Footer path={"/createcodeableconcept"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/createriskofbiasassessment/", "/createriskofbiasassessment/:resource_type?/:frcpdbid?", "/createriskofbiasassessment/:resource_type?/:frcpdbid?/:associatedprojectfoi?"]} render={(props) => {
                  let ratedResourceType = props.match.params.resource_type;
                  let ratedResourceId = props.match.params.frcpdbid;
                  let associatedProjectFoi = props.match.params.associatedprojectfoi;
                  return <>
                    <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>{htmlPageTitle}</h2>
                    <CreateNewRiskOfBiasAssessmentPage useTitle={useTitle} changeFormState={changeFormState} ratedResourceType={ratedResourceType} ratedResourceId={ratedResourceId} associatedProjectFoi={associatedProjectFoi} getResource={getResource} />
                    <Footer path={"/createriskofbiasassessment"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/recommendationviewer", "/recommendationtableviewer"]} render={(props) => {
                  return <>
                    <RecommendationTableViewerPage useTitle={useTitle} userState={userState} getResource={getResource} />
                    <Footer path={"/recommendationtableviewer"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/apidoc"]} render={(props) => {
                  return <>
                    <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup> API Documentation</h2>
                    <ApiDocumentationPage useTitle={useTitle} />
                    <Footer path={"/apidoc"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/search"]} render={(props) => {
                  return <>
                    <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup> Search</h2>
                    <SearchQueryPage useTitle={useTitle} />
                    <Footer path={"/search"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/srdr"]} render={(props) => {
                  return <>
                    <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: SRDR+ Project Importer</h2>
                    <SRDRProjectImporterPage useTitle={useTitle} />
                    <Footer path={"/srdr"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/cedar"]} render={(props) => {
                  return <>
                    <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: CEDAR Search Importer</h2>
                    <CEDARSearchImporterPage useTitle={useTitle} />
                    <Footer path={"/cedar"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/medlineconvert"]} render={(props) => {
                  return <>
                    <MedlineConvertPage useTitle={useTitle} />
                    <Footer path={"/medlineconvert"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/medlinesearchimport"]} render={(props) => {
                  return <>
                    <MedlineSearchImportPage useTitle={useTitle} />
                    <Footer path={"/medlinesearchimport"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/ris"]} render={(props) => {
                  return <>
                    <RIStoFEvIRConverterPage useTitle={useTitle} />
                    <Footer path={"/ris"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/docxtojsonsections"]} render={(props) => {
                  return <>
                    <DocxToJsonSectionsConverterPage useTitle={useTitle} changeFormState={changeFormState} />
                    <Footer path={"/docxtojsonsections"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/GRADEpro"]} render={(props) => {
                  return <>
                    <GradeProConverterPage useTitle={useTitle} />
                    <Footer path={"/GRADEpro"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/MAGIC"]} render={(props) => {
                  return <>
                    <MAGICtoFEvIRConverterPage useTitle={useTitle} />
                    <Footer path={"/MAGIC"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/ctgovconvert"]} render={(props) => {
                  let urlNCTid;
                  if (window.location.search?.toLowerCase().includes("nctid=")) {
                    urlNCTid = window.location.search.slice(1).toLowerCase().replace("nctid=", "").split("&")[0];
                  }

                  return <>
                    <CTgovConvertPage useTitle={useTitle} urlNCTid={urlNCTid} />
                    <Footer path={"/ctgovconvert"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/fhirtoctgovjson"]} render={(props) => {
                  return <>
                    <ConvertedCTgovPage useTitle={useTitle} userState={userState} formState={formState} />
                    <Footer path={"/fhirtoctgovjson"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/hevka"]} render={(props) => {
                  return <Redirect from="/resources/Project/29272" to="/resources/Project/29272" />
                }}>
                </Route>
                <Route path={["/portalview"]} render={(props) => {
                  return <Redirect from="/knowledgeportaldemo" to="/knowledgeportaldemo" />
                }}>
                </Route>
                <Route path={["/knowledgeportaldemo"]} render={(props) => {
                  return <>
                    <h1 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>{htmlPageTitle}</h1>
                    <KnowledgePortalDemoPage useTitle={useTitle} changeFormState={changeFormState} />
                    <Footer path={"/knowledgeportaldemo"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/myballot"]} render={(props) => {
                  return <>
                    <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>{htmlPageTitle}</h2>
                    <MyBallotPage useTitle={useTitle} changeFormState={changeFormState} />
                    <Footer path={"/myballot"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/tools"]} render={(props) => {
                  return <>
                    <ToolsPage useTitle={useTitle} setResourcesListState={setResourcesListState} />
                    <Footer path={"/tools"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/projects"]} render={(props) => {
                  return <>
                    <ProjectsPage useTitle={useTitle} signInStatusState={signInStatusState}
                      resourcesListState={resourcesListState} setResourcesListState={setResourcesListState} />
                    <Footer path={"/projects"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/effect-calculator", "/net-effect-calculator"]} render={(props) => {
                  return <>
                    <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>FEvIR<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup> Net Effect Calculator</h2>
                    <NetEffectCalculatorPage useTitle={useTitle} />
                    <Footer path={"/effect-calculator"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/termsofuse"]} render={(props) => {
                  return <>
                    <TermsOfUsePage useTitle={useTitle} />
                    <Footer path={"/termsofuse"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/personaldataagreement"]} render={(props) => {
                  return <>
                    <PersonalDataAgreementPage useTitle={useTitle} />
                    <Footer path={"/personaldataagreement"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/termsofagreement"]} render={(props) => {
                  return <>
                    <TermsOfAgreementPage useTitle={useTitle} />
                    <Footer path={"/termsofagreement"} />
                  </>
                }} exact>
                </Route>
                {userState.userremoval &&
                  <Route path={["/userremoval"]} exact>
                    <UserRemovalPage useTitle={useTitle} setSignInStatusState={setSignInStatusState} />
                    <Footer path={"/userremoval"} />
                  </Route>
                }
                {devEnvironment &&
                  <Route path={["/test"]} exact>
                    <TestPage useTitle={useTitle} />
                    <Footer path={"/test"} />
                  </Route>}
                <Route path={["/pagenotfound"]} exact>
                  <PageNotFoundPage useTitle={useTitle} />
                  <Footer path={"/pagenotfound"} />
                </Route>
                <Route path={["/invite/:invitecode?"]} render={(props) => {
                  let inviteCode = props.match.params.invitecode || "";
                  if (inviteCode?.length === 90) {
                    return <>
                      <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>{htmlPageTitle}</h2>
                      <InvitedToResourcePage useTitle={useTitle} changeFormState={changeFormState} inviteCode={inviteCode} />
                      <Footer path={"/invite"} />
                    </>
                  } else {
                    return <Redirect from="/pagenotfound" to="/pagenotfound" /> //This will catch all pages that are invalid and redirect to /pagenotfound 
                  }
                }} exact>
                </Route>
                <Route path={["/results"]} render={(props) => {
                  const params = new URLSearchParams(props.location.search);
                  let redirect = params.get('redirect') || undefined;
                  let urls = params.get('urls') || undefined;
                  let titles = params.get('titles') || undefined;
                  let searchstring = params.get('searchstring') || undefined;
                  let resourcetype = params.get('resourcetype') || undefined;
                  let searchparameters = params.get('searchparameters') || undefined
                  try {
                    if (urls) {
                      urls = JSON.parse(decodeURIComponent(urls));
                    }
                    if (titles) {
                      titles = JSON.parse(decodeURIComponent(titles));
                    }
                    if (searchstring) {
                      searchstring = decodeURIComponent(searchstring);
                    }
                    if (resourcetype) {
                      resourcetype = decodeURIComponent(resourcetype);
                    }
                    if (searchparameters) {
                      searchparameters = decodeURIComponent(searchparameters);
                      searchparameters = JSON.parse(searchparameters);
                    }
                  } catch (e) { }

                  return <>
                    <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>Search Results</h2>
                    <ResultsPage useTitle={useTitle} setFormState={setFormState} changeFormState={changeFormState} urls={urls} titles={titles} redirect={redirect} searchstring={searchstring} resourcetype={resourcetype} searchparameters={searchparameters} />
                    <Footer path={"/results"} pageTitle={formState.pageTitle} />
                  </>
                }} exact>
                </Route>
                <Route path={["/flitofoi/:fli?", "/FLI", "/fli", "/flitofoi"]} render={(props) => {
                  let urlFli = props.match.params.fli || "";
                  return <>
                    {urlFli && <PageDimmer />}
                    <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>{htmlPageTitle}</h2>
                    <FliToFoiPage useTitle={useTitle} changeFormState={changeFormState} urlFli={urlFli} />
                    <Footer path={"/FLI"} />
                  </>
                }} exact>
                </Route>
                <Route path={["/resources/:frcpdbid?", "/resources/:resource_type?/:frcpdbid?", "/resources/:resource_type?/:frcpdbid?/:editmode?", "/Profile/:partialfli?", "/FLI/Profile/:partialfli?", "/FLI/:fli?", "/:frcpdbid?", "/:frcpdbid?/:editmode?"]} render={(props) => {
                  let urlFli = props.match.params.fli || undefined;
                  if (props.match.params.partialfli) {
                    urlFli = "Profile/" + props.match.params.partialfli;
                  }
                  let resourceType = props.match.params.resource_type;
                  let resourceId = props.match.params.frcpdbid;
                  let startEditMode = false;
                  let indexPath = [];
                  let convertedIndexPath = [];
                  let jsonView = false;
                  let viewMyWorkModalOpen = false;
                  if (props.location.hash === "#json") {
                    jsonView = true;
                  } else if (props.location.hash === "#viewmywork") {
                    viewMyWorkModalOpen = true;
                  } else {
                    if (resourceType === "CodeSystem" || resourceType === "ArtifactAssessment" || resourceType === "EvidenceVariable" || resourceType === "Composition") {
                      indexPath = props.location.hash.slice(1).split(",");
                      if (indexPath && indexPath[0] === "") {
                        indexPath = [];
                      }

                      for (let indexPathIndex in indexPath) {
                        let indexEntry = indexPath[indexPathIndex];
                        let indexEntryAsInteger = Number(indexEntry);
                        if (Number.isInteger(indexEntryAsInteger)) {
                          convertedIndexPath.push(indexEntryAsInteger);
                        } else {
                          convertedIndexPath.push(indexEntry);
                        }
                      }
                    }
                  }

                  if (props.match.params.editmode === "edit") {
                    startEditMode = true;
                  }
                  if (!isNaN(resourceType) && resourceId === "edit") {
                    resourceId = resourceType;
                    resourceType = "";
                    startEditMode = true;
                  }

                  if (startEditMode) {
                    setPageEditModeState(resourceId);
                  }
                  let unsureFli = false;
                  if (isNaN(resourceId) && urlFli === undefined && !props.match.params.editmode) {
                    //TEST FLI ELSE redirect to page not found
                    urlFli = resourceId;
                    unsureFli = true;
                    resourceId = undefined;
                  } else if (isNaN(resourceId) && urlFli === undefined) {
                    return <Redirect from="/pagenotfound" to="/pagenotfound" /> //This will catch all pages that are invalid and redirect to /pagenotfound 
                  }
                  return <>
                    <h2 style={{ zIndex: "-1", margin: "0px", width: "100%", textAlign: "center", top: "8px", position: "fixed", color: "#AC3B61" }}>{htmlPageTitle}</h2>
                    {formState.pageTitle && <FeedbackButton formState={formState} />}
                    <ResourceDisplayPage pageEditModeState={pageEditModeState} setPageEditModeState={setPageEditModeState} useTitle={useTitle} resourceType={resourceType} startEditMode={startEditMode} resourceId={resourceId} firebase={firebase} formState={formState} setFormState={setFormState} changeFormState={changeFormState} indexPath={convertedIndexPath} urlFli={urlFli} unsureFli={unsureFli} jsonView={jsonView} viewMyWorkModalOpen={viewMyWorkModalOpen} />
                    <Footer path={"/resources/" + props.match.params.resource_type + "/" + props.match.params.frcpdbid} pageTitle={formState.pageTitle} />
                  </>
                }} exact>
                </Route>
                <Route>
                  <PageNotFoundPage useTitle={useTitle} />
                  <Footer path={"/pagenotfound"} />
                </Route>
              </Switch>
            </div>
          </main>
        </Router>
      </ToastProvider>
    </FevirContext.Provider>
  );
};


export default App;