import React, { memo, useState, useEffect, useContext, useImperativeHandle } from 'react';
import { emptyReasonValueSet, emptyReasonCodeableConceptSet } from './CodeSystemLookup';
import { clearDivWrapper } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import {
  EditIntroduction, EditDiscussion, EditMethods, EditReferences, EditCompetingInterests,
  EditAcknowledgements, EditAppendices
} from './EvidenceReportPackageAuthoringTool';
import { generateGroupSummary, generateSubgroupsSummary, generateResearchStudySummary, generateEvidenceSummary,
  generateCalculatedInterventionRateSummary
 } from './GenerateNarrativeSummaryFunctions';
import { getFoiFromReference, getFoisFromReference } from './ResourceDictionaryFunctions';
import { autoEditEmptyReason, DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import { ButtonWithConfirmModal } from './ConfirmModal';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { Button } from 'semantic-ui-react';
import FevirContext from './FevirContext';

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } })
}

let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];

/*
const PicosSectionsDataEntry = memo(({ startingValue, setSourceJsonState, setResourceState }) => {
  let startingPicosSections = [];
  if (startingValue) {
    startingPicosSections = startingValue.filter(section => section?.code?.coding?.[0]?.code === "summary-of-findings-entry-for-a-single-outcome") || [];
  }

  const [picosSectionsState, setPicosSectionsState] = useState({ "section": startingPicosSections });

  useEffect(() => {
    if (picosSectionsState.section?.length && JSON.stringify(picosSectionsState.section) !== JSON.stringify(startingPicosSections)) {
      let picosSections = JSON.parse(JSON.stringify(picosSectionsState.section));
      setResourceState(prevState => {
        let starterSection = prevState.section.filter(section => section?.code?.coding?.[0]?.code !== "summary-of-findings-entry-for-a-single-outcome");
        let newSection = starterSection.concat(picosSections);
        return { ...prevState, section: newSection };
      })
    }
  }, [picosSectionsState]);

  return <DataEntry asArray={true} datatype="PicoSection" elementName="section"
    setSourceJsonState={setSourceJsonState}
    startingValue={picosSectionsState.section} setResourceState={setPicosSectionsState} />
});

      <p>Ignore what is below here.</p>
      <PicosSectionsDataEntry
        startingValue={resourceState.section || null}
        resourceState={resourceState} setResourceState={setResourceState} />
*/

const NarrativeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  generateSummaryFunction, generateSummaryFromArrayFunction,
  sectionEntry, sectionFocus, resourceType, resourceDictionary, globalContext }) => {
  let startingNarrative = { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" };
  if (startingValue) {
    if (startingValue.extension) { startingNarrative.extension = startingValue.extension; }
    if (startingValue.status) { startingNarrative.status = startingValue.status; }
    if (startingValue.div) { startingNarrative.div = startingValue.div; }
  }
  let startingDivTextValue = startingNarrative.div;
  if (startingDivTextValue.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
    startingDivTextValue = startingDivTextValue.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
    let sDTVLength = startingDivTextValue.length;
    if (startingDivTextValue.substring(sDTVLength - 6, sDTVLength) === "</div>") {
      startingDivTextValue = startingDivTextValue.substring(0, sDTVLength - 6);
    }
  }
  let startingDivValue = { "divText": startingDivTextValue };
  const [narrativeState, setNarrativeState] = useState(startingNarrative);
  const [narrativeDivState, setNarrativeDivState] = useState(startingDivValue);
  const [narrativeIsGeneratedState, setNarrativeIsGeneratedState] = useState(startingNarrative.status === "generated");
  const checkIfStatusAdditional = () => {
    return narrativeState.status === "additional";
  };
  const updateNarrativeStates = async () => {
    if (generateSummaryFunction) {
      let entryFoi = getFoiFromReference(sectionEntry || sectionFocus, resourceType, resourceDictionary);
      if (entryFoi) {
        let narrativeDivValue = await generateSummaryFunction(entryFoi, resourceDictionary, globalContext);
        if (narrativeDivValue.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
          narrativeDivValue = narrativeDivValue.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
          let sDTVLength = narrativeDivValue.length;
          if (narrativeDivValue.substring(sDTVLength - 6, sDTVLength) === "</div>") {
            narrativeDivValue = narrativeDivValue.substring(0, sDTVLength - 6);
          }
        }
        setNarrativeIsGeneratedState(true);
        setNarrativeDivState({ "divText": narrativeDivValue, "generated": true });
        setNarrativeState(prevState => { return { ...prevState, "status": "generated" }; });
      }
    } else if (generateSummaryFromArrayFunction) {
      let entryFoiList = getFoisFromReference(sectionEntry, resourceType, resourceDictionary);
      if (entryFoiList?.length) {
        let narrativeDivValue = await generateSummaryFromArrayFunction(entryFoiList, resourceDictionary, globalContext);
        if (narrativeDivValue.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
          narrativeDivValue = narrativeDivValue.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
          let sDTVLength = narrativeDivValue.length;
          if (narrativeDivValue.substring(sDTVLength - 6, sDTVLength) === "</div>") {
            narrativeDivValue = narrativeDivValue.substring(0, sDTVLength - 6);
          }
        }
        setNarrativeIsGeneratedState(true);
        setNarrativeDivState({ "divText": narrativeDivValue, "generated": true });
        setNarrativeState(prevState => { return { ...prevState, "status": "generated" }; });
      }
    }
  }

  useEffect((() => {
    if (Object.keys(narrativeState).length) {
      let newNarrative = {};
      if (narrativeState.extension) { newNarrative.extension = narrativeState.extension; }
      if (narrativeState.status) { newNarrative.status = narrativeState.status; }
      if (narrativeState.div) { newNarrative.div = narrativeState.div; }
      if (Object.keys(newNarrative).length === 0 ||
        (newNarrative.status === "empty" && !clearDivWrapper(newNarrative.div) && !newNarrative.extension)) {
        newNarrative = null;
      }
      handleChange(elementName, newNarrative, setResourceState);
    }
  }), [narrativeState]);

  useEffect((() => {
    if (startingDivValue.divText !== '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>") {
      if ((narrativeDivState.divText || narrativeDivState.divText === "0") &&
        narrativeDivState.divText !== '[No data]' && narrativeDivState.divText !== '[No data.]') {
        if (narrativeIsGeneratedState) {
          setNarrativeState(prevState => { return { ...prevState, "status": "generated", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
          setNarrativeIsGeneratedState(false);
        } else {
          setNarrativeState(prevState => { return { ...prevState, "status": "additional", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
        }
      } else {
        setNarrativeState(prevState => { return { ...prevState, "status": "empty", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
      }
    }
  }), [narrativeDivState]);

  return <div>
    <ButtonWithConfirmModal buttonContent="Generate Natural Language Summary"
      message="This will overwrite the manually edited summary with a generated summary."
      conditionForConfirmModal={checkIfStatusAdditional}
      functionIfConfirmed={updateNarrativeStates}
      disabled={((!sectionEntry || !sectionEntry[0] || (!sectionEntry[0].reference && !sectionEntry[0].identifier)) && !sectionFocus)} />
    <p style={{ marginBottom: "0px" }}><b>Natural language summary: </b>
      (status: {(narrativeState.status === "additional") ? "manually edited" : narrativeState.status})
    </p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype="xhtml" elementName='divText' fieldLabel={fieldLabel}
        startingValue={narrativeDivState.divText} setResourceState={setNarrativeDivState} />
    </div>
  </div>
})

const TopSectionDataEntry = ({ sectionCode, startingValue, resourceState, setResourceState, globalContext,
  sourceJsonState, setSourceJsonState }) => {
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let startingTopSection = {
    "title": sectionCode + " Section",
    "code": { "text": sectionCode },
    "author": [],
    "focus": {},
    "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": []
  };
  let startingPopulationEntry = null;
  let populationSubgroup = false;
  if (startingValue) {
    if (startingValue.title) { startingTopSection.title = startingValue.title; }
    if (startingValue.code) {
      startingTopSection.code = startingValue.code;
    } else if (sectionCode === "Population") {
      startingTopSection.code = {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": "population",
            "display": "Population"
          }
        ]
      }
    } else if (sectionCode === "Intervention") {
      startingTopSection.code = {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": "intervention-description",
            "display": "Intervention Description"
          }
        ]
      }
    } else if (sectionCode === "Comparator") {
      startingTopSection.code = {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": "comparator-description",
            "display": "Comparator Description"
          }
        ]
      }
    } else if (sectionCode === "Research Study") {
      startingTopSection.code = {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": "research-study",
            "display": "Research Study"
          }
        ]
      }
    } else if (sectionCode === "Results") {
      startingTopSection.code = {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": "summary-of-findings",
            "display": "Summary of Findings"
          }
        ]
      }
    }
    if (startingValue.author) { startingTopSection.author = startingValue.author; }
    if (startingValue.focus) { startingTopSection.focus = startingValue.focus; }
    if (startingValue.text) { startingTopSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingTopSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) {
      startingTopSection.entry = startingValue.entry;
      if (sectionCode === "Population") {
        if (startingValue.entry.length > 1) {
          alert("The Population section has more than one entry value. Only the first entry value will be kept if the content is edited.");
        }
        startingPopulationEntry = startingValue.entry[0];
      }
    }
    if (startingValue.emptyReason) { startingTopSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) {
      startingTopSection.section = startingValue.section;
      populationSubgroup = true;
    }
  } else {
    startingTopSection = {
      "title": sectionCode + " Section",
      "code": { "text": sectionCode }
    };
  }

  const [topSectionState, setTopSectionState] = useState(startingTopSection);
  const [populationEntryState, setPopulationEntryState] = useState({ "referenceInstance": startingPopulationEntry });
  const [populationSubgroupState, setPopulationSubgroupState] = useState(populationSubgroup);

  useEffect(() => {
    if (sectionCode === "Population" && populationEntryState.referenceInstance) {
      setTopSectionState(prevState => {
        return { ...prevState, entry: [JSON.parse(JSON.stringify(populationEntryState.referenceInstance))] };
      });
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState, setResourceState);
    }
  }, [populationEntryState])

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(resourceState));
    let newSection = JSON.parse(JSON.stringify(topSectionState));
    newSection = autoEditEmptyReason(newSection);
    if (sectionCode === "Population") {
      newResource.section[1] = newSection;
      if (newSection.section?.[0]?.entry?.length) {
        let newSubpopulationFoiList = newSection.section[0].entry.map(item => { return item.reference?.split("/")[1] });
        if (newSubpopulationFoiList?.length) {
          setSubpopulationFoiListState(newSubpopulationFoiList);
        }
      }
    } else if (sectionCode === "Intervention") {
      newResource.section[2] = newSection;
    } else if (sectionCode === "Comparator") {
      newResource.section[3] = newSection;
    } else if (sectionCode === "Research Study") {
      newResource.section[4] = newSection;
    } else if (sectionCode === "Results") {
      newResource.section[6] = newSection;
    }
    setResourceState(newResource);
  }, [topSectionState]);

  const addNewOutcomeSections = () => {
    setTopSectionState(prevState => {
      let entryList = prevState.entry;
      let sectionsToAdd = [];
      for (const entry of entryList) {
        if (entry.reference || entry.display) {
          sectionsToAdd.push({
            "title": entry.display || "Outcome at " + entry.reference,
            "code": {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "summary-of-findings-entry-for-a-single-outcome",
                "display": "Summary of findings entry for a single outcome"
              }]
            },
            "focus": entry,
            "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
            "section": [
              {
                "title": "Population",
                "code": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/17942",
                    "code": "population", "display": "Population"
                  }]
                },
                "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                "emptyReason": {
                  "coding": [{
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }]
                }
              },
              {
                "title": "Intervention Group",
                "code": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/17942",
                    "code": "intervention-group", "display": "Intervention Group"
                  }]
                },
                "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                "emptyReason": {
                  "coding": [{
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }]
                }
              },
              {
                "title": "Comparator Group",
                "code": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/17942",
                    "code": "comparator-group", "display": "Comparator Group"
                  }]
                },
                "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                "emptyReason": {
                  "coding": [{
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }]
                }
              },
              {
                "title": "Result with comparator alone",
                "code": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/17942",
                    "code": "result-with-comparator-alone", "display": "Result with comparator alone"
                  }]
                },
                "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                "emptyReason": {
                  "coding": [{
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }]
                }
              }, {
                "title": "Result with intervention alone",
                "code": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/17942",
                    "code": "result-with-intervention-alone", "display": "Result with intervention alone"
                  }]
                },
                "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                "emptyReason": {
                  "coding": [{
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }]
                }
              }, {
                "title": "Result with intervention alone (calculated)",
                "code": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/17942",
                    "code": "result-with-intervention-alone-calculated", "display": "Result with intervention alone (calculated)"
                  }]
                },
                "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                "emptyReason": {
                  "coding": [{
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }]
                }
              }, {
                "title": "Result with intervention vs. comparator",
                "code": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/17942",
                    "code": "result-with-intervention-vs-comparator", "display": "Result with intervention vs. comparator"
                  }]
                },
                "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                "emptyReason": {
                  "coding": [{
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }]
                }
              }

            ]
          });
        }
      }
      let prevSection = prevState.section || [];
      let newSection = prevSection.concat(sectionsToAdd);
      return { ...prevState, section: newSection };
    })
  };

  const [expandAuthorEntryState, setExpandAuthorEntryState] = useState(false);
  const [subpopulationFoiListState, setSubpopulationFoiListState] = useState(null);

  if (sectionCode === "Population") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
          generateSummaryFunction={generateGroupSummary}
          sectionEntry={topSectionState.entry} globalContext={globalContext}
          resourceType="Group" resourceDictionary={resourceDictionary}
          startingValue={topSectionState.text} setResourceState={setTopSectionState} />
        <br />
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
          {expandAuthorEntryState ? <>
            <b>Collapse Section Author Data Entry ▼</b>
          </> : <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </>}
        </span>
        <br />
        {expandAuthorEntryState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
            startCollapsed startEmptyArrayClosed enableCreation={true}
            startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            setResourceState={setTopSectionState} />
        </>}
        <h3>{sectionCode} Section Content: Create or identify the Group Resource (StudyGroup Profile) for the Population.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='Reference' elementName='referenceInstance' fieldLabel='Group Resource (StudyGroup Profile)'
            startingValue={populationEntryState.referenceInstance} referencedResourceTypes={['Group']}
            startingResourceType="Group" setProfile="StudyGroup"
            startCollapsed enableCreation={true}
            setResourceState={setPopulationEntryState} setSourceJsonState={setSourceJsonState}
            globalContext={globalContext}
            fullResourceState={resourceState} />
        </div>
        <br />
        {((!topSectionState.section || topSectionState.section.length === 0) &&
          (!topSectionState.entry || topSectionState.entry.length === 0) &&
          (!topSectionState.text || topSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setTopSectionState} />
            </div>
          </>}
        <br />
        {!populationSubgroupState && <div>
          <span onClick={() => { setPopulationSubgroupState(true); }} >
            <p><b>Click to add Population Subgroups.</b></p>
          </span>
        </div>}
        {populationSubgroupState && <div>
          <DocumentSectionEntry sectionArrayIndex={0}
            fieldLabel="Population Subgroup"
            startingValue={topSectionState.section[0] || {
              "title": "Population Subgroups",
              "code": {
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "subgroup",
                    "display": "Subgroup"
                  }
                ]
              },
              "entry": []
            }}
            fixedTitle="Population Subgroups" fixedCode={{
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "subgroup",
                  "display": "Subgroup"
                }
              ]
            }} editTextDiv={true}
            generateNarrativeFromEntryArrayFunction={generateSubgroupsSummary}
            entryFoiList={subpopulationFoiListState}
            editOrderedBy={true} editOrderedByStartCollapsed={true}
            entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
            entryStartCollapsed={true} entryReferencedResourceTypes={["Group"]}
            entryStartingResourceType="Group" editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonStartCollapsed={true}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            setResourceState={setTopSectionState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            resourceDictionary={resourceDictionary}
            setSourceJsonState={setSourceJsonState} globalContext={globalContext}
            resourceState={resourceState}
          />
        </div>}
      </div>
    </>
  } else if (sectionCode === "Intervention" || sectionCode === "Comparator") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
          generateSummaryFunction={generateGroupSummary}
          sectionEntry={topSectionState.entry} globalContext={globalContext}
          resourceType="Group" resourceDictionary={resourceDictionary}
          startingValue={topSectionState.text} setResourceState={setTopSectionState} />
        <h3>{sectionCode} Section Content: Create or identify the Group Resource (ExposureDefinition or ComparatorDefinition Profile) for the {sectionCode} .</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel='Group Resource (ExposureDefinition or ComparatorDefinition Profile)'
            startingValue={topSectionState.entry} referencedResourceTypes={['Group']}
            startingResourceType="Group" selectProfile={['ExposureDefinition', 'ComparatorDefinition']}
            startCollapsed enableCreation={true}
            setResourceState={setTopSectionState} setSourceJsonState={setSourceJsonState}
            globalContext={globalContext}
            fullResourceState={resourceState} />
        </div>
        <br />
        {((!topSectionState.section || topSectionState.section.length === 0) &&
          (!topSectionState.entry || topSectionState.entry.length === 0) &&
          (!topSectionState.text || topSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setTopSectionState} />
            </div>
          </>}
      </div>
    </>
  } else if (sectionCode === "Research Study") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
          generateSummaryFunction={generateResearchStudySummary}
          sectionEntry={topSectionState.entry} globalContext={globalContext}
          resourceType="ResearchStudy" resourceDictionary={resourceDictionary}
          startingValue={topSectionState.text} setResourceState={setTopSectionState} />
        <br />
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
          {expandAuthorEntryState ? <>
            <b>Collapse Section Author Data Entry ▼</b>
          </> : <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </>}
        </span>
        <br />
        {expandAuthorEntryState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
            startCollapsed startEmptyArrayClosed enableCreation={true}
            startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            setResourceState={setTopSectionState} />
        </>}
        <h3>{sectionCode} Section Content: Create or identify the ResearchStudy Resource containing the Study Design information.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='ResearchStudy Resource'
            startingValue={topSectionState.entry} referencedResourceTypes={['ResearchStudy']}
            startingResourceType="ResearchStudy"
            startCollapsed startEmptyArrayClosed enableCreation={true}
            setResourceState={setTopSectionState} />
        </div>
        <br />
        {((!topSectionState.section || topSectionState.section.length === 0) &&
          (!topSectionState.entry || topSectionState.entry.length === 0) &&
          (!topSectionState.text || topSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setTopSectionState} />
            </div>
          </>}
      </div>
    </>
  } else if (sectionCode === "Results") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
          sectionEntry={topSectionState.entry}
          startingValue={topSectionState.text} setResourceState={setTopSectionState} />
        <br />
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
          {expandAuthorEntryState ? <>
            <b>Collapse Section Author Data Entry ▼</b>
          </> : <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </>}
        </span>
        <br />
        {expandAuthorEntryState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
            startCollapsed startEmptyArrayClosed enableCreation={true}
            startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            setResourceState={setTopSectionState} />
        </>}
        <h3>{sectionCode} Section Content: Create or identify the EvidenceVariable Resources containing the selected Outcomes to generate section content.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='EvidenceVariable Resource'
            startingValue={topSectionState.entry} referencedResourceTypes={['EvidenceVariable']}
            startingResourceType="EvidenceVariable"
            startCollapsed startEmptyArrayClosed enableCreation={true}
            setResourceState={setTopSectionState} />
        </div>
        <br />
        {topSectionState.section?.length ? <div>
          <p>Current section content includes sections for:</p>
          {topSectionState.section.map((sect, sectIndex) => {
            let sectionDisplay = sect.title || sect.focus?.display || sect.focus?.reference || "[Missing variable identifier.]";
            return <div key={sectIndex} style={{ marginLeft: "24px" }}>
              <p>{sectionDisplay}</p>
            </div>
          })}
        </div> : <></>}
        {topSectionState.entry?.length ? <div>
          <br />
          <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
            content="Add new Outcome (Result) sections" positive
            onClick={() => {
              addNewOutcomeSections();
            }}
          />
        </div> : <></>}
        {((!topSectionState.section || topSectionState.section.length === 0) &&
          (!topSectionState.entry || topSectionState.entry.length === 0) &&
          (!topSectionState.text || topSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setTopSectionState} />
            </div>
          </>}
        <br />
      </div>
    </>
  } else {
    return <>
      <p>Unrecognized section detected with sectionCode:</p>
      <p>{sectionCode}</p>
    </>
  }
}

const reorderSections = (sectionArray) => {
  let emptyIntroduction = {
    "title": "Introduction Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "introduction",
        display: "Introduction"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyPopulation = {
    "title": "Population",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "population",
        display: "Population"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyIntervention = {
    "title": "Intervention",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "intervention-description",
        display: "Intervention Description"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyComparator = {
    "title": "Comparator",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "comparator-description",
        display: "Comparator Description"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyResearchStudy = {
    "title": "Research Study",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "research-study",
        display: "Research Study"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyMethods = {
    "title": "Methods Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "methods",
        display: "Methods"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyResults = {
    "title": "Results",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "summary-of-findings",
        display: "Summary of Findings"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyDiscussion = {
    "title": "Discussion Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "discussion",
        display: "Discussion"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyReferences = {
    "title": "References Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "references",
        display: "References"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyCompetingInterests = {
    "title": "Competing Interests Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "competing-interests",
        display: "Competing Interests"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAcknowledgements = {
    "title": "Acknowledgements Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "acknowledgements",
        display: "Acknowledgements"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAppendices = {
    "title": "Appendices Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "appendices",
        display: "Appendices"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let sectionDictionary = {
    introduction: emptyIntroduction,
    population: emptyPopulation,
    intervention: emptyIntervention,
    comparator: emptyComparator,
    researchStudy: emptyResearchStudy,
    methods: emptyMethods,
    results: emptyResults,
    discussion: emptyDiscussion,
    references: emptyReferences,
    competingInterests: emptyCompetingInterests,
    acknowledgements: emptyAcknowledgements,
    appendices: emptyAppendices,
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
    if (sectionCode === "introduction" || sectionCode === "Introduction") {
      sectionDictionary.introduction = section;
    } else if (sectionCode === "population" || sectionCode === "Population") {
      sectionDictionary.population = section;
    } else if (sectionCode === "intervention-description" || sectionCode === "Intervention Description" ||
      sectionCode === "intervention" || sectionCode === "Intervention") {
      sectionDictionary.intervention = section;
    } else if (sectionCode === "comparator-description" || sectionCode === "Comparator Description" ||
      sectionCode === "comparator" || sectionCode === "Comparator") {
      sectionDictionary.comparator = section;
    } else if (sectionCode === "research-study" || sectionCode === "Research Study") {
      sectionDictionary.researchStudy = section;
    } else if (sectionCode === "methods" || sectionCode === "Methods") {
      sectionDictionary.methods = section;
    } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
      sectionDictionary.discussion = section;
    } else if (sectionCode === "references" || sectionCode === "References") {
      sectionDictionary.references = section;
    } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
      sectionDictionary.competingInterests = section;
    } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
      sectionDictionary.acknowledgements = section;
    } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
      sectionDictionary.appendices = section;
    } else if (sectionCode === "summary-of-findings" || sectionCode === "Summary of findings" ||
      sectionCode === "results" || sectionCode === "Results") {
      sectionDictionary.results = section;
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  let reorderedSectionArray = [
    sectionDictionary.introduction,
    sectionDictionary.population,
    sectionDictionary.intervention,
    sectionDictionary.comparator,
    sectionDictionary.researchStudy,
    sectionDictionary.methods,
    sectionDictionary.results,
    sectionDictionary.discussion,
    sectionDictionary.references,
    sectionDictionary.competingInterests,
    sectionDictionary.acknowledgements,
    sectionDictionary.appendices
  ].concat(sectionDictionary.additionalSections);
  return reorderedSectionArray;
};

const ComparativeEvidenceSynthesisReportAuthor = ({ resourceState, setResourceState, globalContext, sourceJsonState,
  setSourceJsonState, previousVersionLoaded }) => {

  let defaultType = {
    coding: [{
      system: "https://fevir.net/resources/CodeSystem/179423",
      code: "ComparativeEvidenceSynthesisReport",
      display: "ComparativeEvidenceSynthesisReport"
    }],
    text: 'Comparative Evidence Synthesis Report'
  }

  useEffect(() => {
    if (resourceState) {
      setResourceState(prevState => {
        return {
          ...prevState,
          type: prevState.type || defaultType,
          section: reorderSections(prevState.section)
        };
      });
    }
  }, []);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded || !sourceJsonState.loaded2) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState, setResourceState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  useEffect(() => {
    setSourceJsonState(prevState => { return { ...prevState, loaded2: false } });
  }, [resourceState.section]);

  return <>{sourceJsonState.loaded ?
    <div style={{ marginTop: "12px" }}>
      <div>
        {resourceState.section ? <>
          <EditIntroduction sectionIndex={0} resourceState={resourceState} setResourceState={setResourceState} />
          <h3 id="population">Population</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Population"
              startingValue={resourceState.section[1] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState} />
          </div>
          <br />
          <h3 id="intervention">Intervention</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Intervention"
              startingValue={resourceState.section[2] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            />
          </div>
          <h3 id="comparator">Comparator</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Comparator"
              startingValue={resourceState.section[3] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState} />
          </div>
          <br />
          <h3 id="research-study">Research Study</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Research Study"
              startingValue={resourceState.section[4] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} />
          </div>
          <EditMethods sectionIndex={5} resourceState={resourceState} setResourceState={setResourceState} />
          <br />
          <h3 id="summary-of-findings">Summary of Findings</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Results"
              startingValue={resourceState.section[6] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} />
          </div>
          <br />
          <EditDiscussion sectionIndex={7} resourceState={resourceState} setResourceState={setResourceState} />
          <br />
          <EditReferences sectionIndex={8} previousVersionLoaded={previousVersionLoaded}
            resourceState={resourceState} setResourceState={setResourceState} />
          <br />
          <EditCompetingInterests sectionIndex={9} resourceState={resourceState} setResourceState={setResourceState} />
          <br />
          <EditAcknowledgements sectionIndex={10} resourceState={resourceState} setResourceState={setResourceState} />
          <br />
          <EditAppendices sectionIndex={11} resourceState={resourceState} setResourceState={setResourceState} />
        </>
          :
          <>Section element is missing in the JSON.</>
        }
      </div>
    </div>
    :
    <div><p>Loading ...</p></div>}</>
};

const SummaryOfFindingsEdit = ({ section, formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState,
  resourceState, setResourceState, sourceJsonState, setSourceJsonState }) => {
  const globalContext = useContext(FevirContext);
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let sectionPathIndexes = fhirEntryState.sectionPathIndexes;
  let sectionName = section.title || section.code?.text || section.code?.coding?.[0]?.display || section.code?.coding?.[0]?.code;

  let startingSection = {
    title: "", code: {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/179423",
          "code": "summary-of-findings-entry-for-a-single-outcome",
          "display": "Summary of findings entry for a single outcome"
        }
      ]
    }, author: [], focus: "", text: "", orderedBy: "",
    entry: [], section: [], sectionPathIndexes: sectionPathIndexes
  }
  if (!section) {
    section = "";
  } else {
    if (section.extension) { startingSection.extension = section.extension; }
    if (section.title) { startingSection.title = section.title; }
    if (section.code) { startingSection.code = section.code; }
    if (section.author) { startingSection.author = section.author; }
    if (section.focus) { startingSection.focus = section.focus; }
    if (section.text) { startingSection.text = section.text; }
    if (section.orderedBy) { startingSection.orderedBy = section.orderedBy; }
    if (section.entry) { startingSection.entry = section.entry; }
    if (section.section) { startingSection.section = section.section; }
  }

  let startingPopulationSection = {
    "title": "Population",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "population",
          "display": "Population"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "entry": [],
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingInterventionGroupSection = {
    "title": "Intervention Group",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "intervention-group",
          "display": "Intervention Group"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "entry": [],
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingComparatorGroupSection = {
    "title": "Comparator Group",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "comparator-group",
          "display": "Comparator Group"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "entry": [],
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingComparatorResultSection = {
    "title": "Result with comparator alone",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "result-with-comparator-alone",
          "display": "Result with comparator alone"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "entry": [],
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingInterventionResultSection = {
    "title": "Result with intervention alone",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "result-with-intervention-alone",
          "display": "Result with intervention alone"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "entry": [],
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingInterventionCalculatedSection = {
    "title": "Result with intervention alone (calculated)",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "result-with-intervention-alone-calculated",
          "display": "Result with intervention alone (calculated)"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "entry": [],
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingEffectEstimateSection = {
    "title": "Result with intervention vs. comparator",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "result-with-intervention-vs-comparator",
          "display": "Result with intervention vs. comparator"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "entry": [],
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };

  if (startingSection.section) {
    for (let index in startingSection.section) {
      let subsection = startingSection.section[index];
      if (subsection.code?.coding?.[0]?.code) {
        let subsectionCode = subsection.code.coding[0].code;
        if (subsectionCode === "population") {
          startingPopulationSection = subsection;
        } else if (subsectionCode === "intervention-group") {
          startingInterventionGroupSection = subsection;
        } else if (subsectionCode === "comparator-group") {
          startingComparatorGroupSection = subsection;
        } else if (subsectionCode === "result-with-comparator-alone") {
          startingComparatorResultSection = subsection;
        } else if (subsectionCode === "result-with-intervention-alone") {
          startingInterventionResultSection = subsection;
        } else if (subsectionCode === "result-with-intervention-alone-calculated") {
          startingInterventionCalculatedSection = subsection;
        } else if (subsectionCode === "result-with-intervention-vs-comparator") {
          startingEffectEstimateSection = subsection;
        }
      }
    }
  }

  let startingSubsections = {
    "population": startingPopulationSection,
    "interventionGroup": startingInterventionGroupSection,
    "comparatorGroup": startingComparatorGroupSection,
    "comparatorResult": startingComparatorResultSection,
    "interventionResult": startingInterventionResultSection,
    "interventionCalculated": startingInterventionCalculatedSection,
    "effectEstimate": startingEffectEstimateSection
  }

  const [sectionState, setSectionState] = useState(startingSection);
  const [subsectionState, setSubsectionState] = useState(startingSubsections);
  const [populationState, setPopulationState] = useState(startingPopulationSection);
  const [interventionGroupState, setInterventionGroupState] = useState(startingInterventionGroupSection);
  const [comparatorGroupState, setComparatorGroupState] = useState(startingComparatorGroupSection);
  const [comparatorResultState, setComparatorResultState] = useState(startingComparatorResultSection);
  const [interventionResultState, setInterventionResultState] = useState(startingInterventionResultSection);
  const [interventionCalculatedState, setInterventionCalculatedState] = useState(startingInterventionCalculatedSection);
  const [effectEstimateState, setEffectEstimateState] = useState(startingEffectEstimateSection);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(populationState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "population": newSection };
    });
  }, [populationState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(interventionGroupState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "interventionGroup": newSection };
    });
  }, [interventionGroupState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(comparatorGroupState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "comparatorGroup": newSection };
    });
  }, [comparatorGroupState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(comparatorResultState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "comparatorResult": newSection };
    });
  }, [comparatorResultState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(interventionResultState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "interventionResult": newSection };
    });
  }, [interventionResultState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(interventionCalculatedState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "effectEstimate": newSection };
    });
  }, [interventionCalculatedState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(effectEstimateState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "interventionCalculated": newSection };
    });
  }, [effectEstimateState]);

  useEffect(() => {
    setSectionState(prevState => {
      let newSection = [subsectionState.population, subsectionState.interventionGroup, subsectionState.comparatorGroup,
      subsectionState.comparatorResult, subsectionState.interventionResult, subsectionState.interventionCalculated,
      subsectionState.effectEstimate
      ];
      return { ...prevState, "section": newSection };
    });
  }, [subsectionState]);

  useEffect((() => {
    if (Object.keys(sectionState).length > 0) {
      if (JSON.stringify(sectionState.text) !== JSON.stringify(startingSection.text) ||
        sectionState.title !== startingSection.title ||
        JSON.stringify(sectionState.section) !== JSON.stringify(startingSection.section) ||
        JSON.stringify(sectionState.code) !== JSON.stringify(startingSection.code)) {
        setFhirEntryState(prevState => {
          return { ...prevState, sectionChanged: true }
        });
      }
    }
  }), [sectionState]);

  useImperativeHandle(formInputsStateRef, () => ({
    sectionState,
  }), [sectionState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 style={{ margin: "0px" }}>{sectionName || fhirEntryState.sectionName}</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Section Title'
          startingValue={sectionState.title} setResourceState={setSectionState} />
        <NarrativeEntry elementName='text' fieldLabel='Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={sectionState.text} setResourceState={setSectionState} />
        <h3>Create or identify the EvidenceVariable Resource defining the focus for this section.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='Reference' elementName='focus'
            fieldLabel={'EvidenceVariable Resource'}
            startingValue={sectionState.focus} referencedResourceTypes={["EvidenceVariable"]}
            startingResourceType={"EvidenceVariable"}
            startCollapsed={true} enableCreation={true}
            setResourceState={setSectionState} addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
      </div>
      <h3 style={{ margin: "0px" }}>Population Observed for {sectionName}</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Population Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          generateSummaryFunction={generateGroupSummary}
          sectionEntry={populationState.entry}
          resourceType="Group"
          resourceDictionary={resourceDictionary} globalContext={globalContext}
          startingValue={populationState.text} setResourceState={setPopulationState}
        />
        <h3>Create or identify the Group Resource for the Population.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'Group Resource'}
            startingValue={populationState.entry} referencedResourceTypes={['Group']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            startingResourceType={"Group"}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            setResourceState={setPopulationState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!populationState.section || populationState.section.length === 0) &&
          (!populationState.entry || populationState.entry.length === 0) &&
          (!populationState.text || populationState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={populationState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setPopulationState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Intervention Group Observed for {sectionName}</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Intervention Group Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          generateSummaryFunction={generateGroupSummary}
          sectionEntry={interventionGroupState.entry}
          resourceType="Group"
          resourceDictionary={resourceDictionary} globalContext={globalContext}
          startingValue={interventionGroupState.text} setResourceState={setInterventionGroupState}
        />
        <h3>Create or identify the Group Resource for the Intervention Group.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'Group Resource'}
            startingValue={interventionGroupState.entry} referencedResourceTypes={['Group']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            startingResourceType={"Group"}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            setResourceState={setInterventionGroupState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!interventionGroupState.section || interventionGroupState.section.length === 0) &&
          (!interventionGroupState.entry || interventionGroupState.entry.length === 0) &&
          (!interventionGroupState.text || interventionGroupState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={interventionGroupState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setInterventionGroupState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Comparator Group Observed for {sectionName}</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Comparator Group Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          generateSummaryFunction={generateGroupSummary}
          sectionEntry={comparatorGroupState.entry}
          resourceType="Group"
          resourceDictionary={resourceDictionary} globalContext={globalContext}
          startingValue={comparatorGroupState.text} setResourceState={setComparatorGroupState}
        />
        <h3>Create or identify the Group Resource for the Comparator Group.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'Group Resource'}
            startingValue={comparatorGroupState.entry} referencedResourceTypes={['Group']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            startingResourceType={"Group"}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            setResourceState={setComparatorGroupState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!comparatorGroupState.section || comparatorGroupState.section.length === 0) &&
          (!comparatorGroupState.entry || comparatorGroupState.entry.length === 0) &&
          (!comparatorGroupState.text || comparatorGroupState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={comparatorGroupState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setComparatorGroupState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Result with Comparator for {sectionName}</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Result with Comparator Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          generateSummaryFunction={generateEvidenceSummary}
          sectionEntry={comparatorResultState.entry}
          resourceType="Evidence"
          resourceDictionary={resourceDictionary} globalContext={globalContext}
          startingValue={comparatorResultState.text} setResourceState={setComparatorResultState}
        />
        <h3>Create or identify the Evidence Resource for the Result with Comparator.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'Evidence Resource'}
            startingValue={comparatorResultState.entry} referencedResourceTypes={['Evidence']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            startingResourceType={"Evidence"}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ],
              variableDefinition: [
                {
                  "description": (comparatorGroupState.text && comparatorGroupState.text.status !== "empty") ? comparatorGroupState.text.div : "",
                  "variableRole": "population",
                  "observed": comparatorGroupState.entry?.[0] || ""
                },
                {
                  "variableRole": "outcome",
                  "observed": sectionState.focus || ""
                }
              ]
            }}
            setResourceState={setComparatorResultState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!comparatorResultState.section || comparatorResultState.section.length === 0) &&
          (!comparatorResultState.entry || comparatorResultState.entry.length === 0) &&
          (!comparatorResultState.text || comparatorResultState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={comparatorResultState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setComparatorResultState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Result with Intervention for {sectionName}</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Result with Intervention Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          generateSummaryFunction={generateEvidenceSummary}
          sectionEntry={interventionResultState.entry}
          resourceType="Evidence"
          resourceDictionary={resourceDictionary} globalContext={globalContext}
          startingValue={interventionResultState.text} setResourceState={setInterventionResultState}
        />
        <h3>Create or identify the Evidence Resource for the Result with Intervention.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'Evidence Resource'}
            startingValue={interventionResultState.entry} referencedResourceTypes={['Evidence']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            startingResourceType={"Evidence"}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ],
              variableDefinition: [
                {
                  "description": (interventionGroupState.text && interventionGroupState.text.status !== "empty") ? interventionGroupState.text.div : "",
                  "variableRole": "population",
                  "observed": interventionGroupState.entry?.[0] || ""
                },
                {
                  "variableRole": "outcome",
                  "observed": sectionState.focus || ""
                }
              ]
            }}
            setResourceState={setInterventionResultState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!interventionResultState.section || interventionResultState.section.length === 0) &&
          (!interventionResultState.entry || interventionResultState.entry.length === 0) &&
          (!interventionResultState.text || interventionResultState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={interventionResultState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setInterventionResultState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Result with Intervention (Calculated) for {sectionName}</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Result with Intervention (Calculated) Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          generateSummaryFromArrayFunction={generateCalculatedInterventionRateSummary}
          sectionEntry={interventionCalculatedState.entry}
          resourceType="Evidence"
          resourceDictionary={resourceDictionary} globalContext={globalContext}
          startingValue={interventionCalculatedState.text} setResourceState={setInterventionCalculatedState}
        />
        <h3>Create or identify the Evidence Resource for the Result with Intervention (Calculated).</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'Evidence Resource'}
            startingValue={interventionCalculatedState.entry} referencedResourceTypes={['Evidence']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={false}
            startingResourceType={"Evidence"}
            setResourceState={setInterventionCalculatedState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!interventionCalculatedState.section || interventionCalculatedState.section.length === 0) &&
          (!interventionCalculatedState.entry || interventionCalculatedState.entry.length === 0) &&
          (!interventionCalculatedState.text || interventionCalculatedState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={interventionCalculatedState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setInterventionCalculatedState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Effect Estimate for {sectionName}</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Effect Estimate Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          generateSummaryFunction={generateEvidenceSummary}
          sectionEntry={effectEstimateState.entry}
          resourceType="Evidence"
          resourceDictionary={resourceDictionary} globalContext={globalContext}
          startingValue={effectEstimateState.text} setResourceState={setEffectEstimateState}
        />
        <h3>Create or identify the Evidence Resource for the Effect Estimate.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'Evidence Resource'}
            startingValue={effectEstimateState.entry} referencedResourceTypes={['Evidence']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            startingResourceType={"Evidence"}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ],
              variableDefinition: [
                {
                  "description": (populationState.text && populationState.text.status !== "empty") ? populationState.text.div : "",
                  "variableRole": "population",
                  "observed": populationState.entry?.[0] || ""
                },
                {
                  "description": "Group Assignment: " +
                    (interventionGroupState.entry?.[0]?.display ?
                      interventionGroupState.entry[0].display
                      :
                      (interventionGroupState.text && interventionGroupState.text.status !== "empty") ?
                        interventionGroupState.text.div
                        :
                        interventionGroupState.title || "Intervention Group") +
                    " vs. " +
                    (comparatorGroupState.entry?.[0]?.display ?
                      comparatorGroupState.entry[0].display
                      :
                      (comparatorGroupState.text && comparatorGroupState.text.status !== "empty") ?
                        comparatorGroupState.text.div
                        :
                        comparatorGroupState.title || "Comparator Group"),
                  "variableRole": "exposure",
                  "comparatorCategory": comparatorGroupState.entry?.[0]?.display ?
                    comparatorGroupState.entry[0].display
                    :
                    (comparatorGroupState.text && comparatorGroupState.text.status !== "empty") ?
                      comparatorGroupState.text.div
                      :
                      comparatorGroupState.title || "Comparator Group"
                },
                {
                  "variableRole": "outcome",
                  "observed": sectionState.focus || ""
                }
              ]
            }}
            setResourceState={setEffectEstimateState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!effectEstimateState.section || effectEstimateState.section.length === 0) &&
          (!effectEstimateState.entry || effectEstimateState.entry.length === 0) &&
          (!effectEstimateState.text || effectEstimateState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={effectEstimateState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setEffectEstimateState} />
            </div>
          </>}
      </div>
    </div>
  </div>
}

const changeSectionDetailEditSettingsForComparativeEvidenceSynthesisReport = (sectionDetailEditSettings, section, resourceDictionary) => {

  if (section.code?.coding?.[0]?.code === "population" || section.code?.text === "Population") {
    sectionDetailEditSettings.fixedTitle = "Population";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource for the Population.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
  }
  if (section.code?.coding?.[0]?.code === "subgroup" || section.code?.text === "Subgroups" || section.code?.text === "Population Subgroups") {
    sectionDetailEditSettings.fixedTitle = "Population Subgroups";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction = generateSubgroupsSummary;
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource(s) for the Population Subgroups.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "intervention-description" || section.code?.text === "Intervention Description") {
    sectionDetailEditSettings.fixedTitle = "Intervention Description";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource (ExposureDefinition Profile) for the Intervention Description.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySetProfile = "ExposureDefinition";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "intervention-group" || section.code?.text === "Intervention Group") {
    sectionDetailEditSettings.fixedTitle = "Intervention Group";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource for the Intervention Group.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "comparator-description" || section.code?.text === "Comparator Description") {
    sectionDetailEditSettings.fixedTitle = "Comparator Description";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource (ExposureDefinition or ComparatorDefinition Profile) for the Comparator Description.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySelectProfile = ["ExposureDefinition", "ComparatorDefinition"];
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "comparator-group" || section.code?.text === "Comparator Group") {
    sectionDetailEditSettings.fixedTitle = "Comparator Group";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource for the Comparator Group.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "research-study" || section.code?.text === "Research Study") {
    sectionDetailEditSettings.fixedTitle = "Research Study";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateResearchStudySummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the ResearchStudy Resource containing the Study Design information.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ResearchStudy"];
    sectionDetailEditSettings.entryStartingResourceType = "ResearchStudy";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
  }
  if (section.code?.coding?.[0]?.code === "summary-of-findings" || section.code?.text === "Summary of Findings") {
    sectionDetailEditSettings.fixedTitle = "Results";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the EvidenceVariable Resources containing the selected Outcomes to generate section content.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["EvidenceVariable"];
    sectionDetailEditSettings.entryStartingResourceType = "EvidenceVariable";
    sectionDetailEditSettings.addNewOutcomeSectionsFunction = (setSectionState) => {
      setSectionState(prevState => {
        let entryList = prevState.entry;
        let sectionsToAdd = [];
        for (const entry of entryList) {
          if (entry.reference || entry.display) {
            sectionsToAdd.push({
              "title": entry.display || "Outcome at " + entry.reference,
              "code": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "summary-of-findings-entry-for-a-single-outcome",
                  "display": "Summary of findings entry for a single outcome"
                }]
              },
              "focus": entry,
              "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
              "section": [
                {
                  "title": "Population",
                  "code": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/17942",
                      "code": "population", "display": "Population"
                    }]
                  },
                  "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                  "emptyReason": {
                    "coding": [{
                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                      "code": "notstarted",
                      "display": "Not Started"
                    }]
                  }
                },
                {
                  "title": "Intervention Group",
                  "code": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/17942",
                      "code": "intervention-group", "display": "Intervention Group"
                    }]
                  },
                  "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                  "emptyReason": {
                    "coding": [{
                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                      "code": "notstarted",
                      "display": "Not Started"
                    }]
                  }
                },
                {
                  "title": "Comparator Group",
                  "code": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/17942",
                      "code": "comparator-group", "display": "Comparator Group"
                    }]
                  },
                  "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                  "emptyReason": {
                    "coding": [{
                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                      "code": "notstarted",
                      "display": "Not Started"
                    }]
                  }
                },
                {
                  "title": "Result with comparator alone",
                  "code": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/17942",
                      "code": "result-with-comparator-alone", "display": "Result with comparator alone"
                    }]
                  },
                  "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                  "emptyReason": {
                    "coding": [{
                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                      "code": "notstarted",
                      "display": "Not Started"
                    }]
                  }
                }, {
                  "title": "Result with intervention alone",
                  "code": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/17942",
                      "code": "result-with-intervention-alone", "display": "Result with intervention alone"
                    }]
                  },
                  "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                  "emptyReason": {
                    "coding": [{
                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                      "code": "notstarted",
                      "display": "Not Started"
                    }]
                  }
                }, {
                  "title": "Result with intervention alone (calculated)",
                  "code": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/17942",
                      "code": "result-with-intervention-alone-calculated", "display": "Result with intervention alone (calculated)"
                    }]
                  },
                  "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                  "emptyReason": {
                    "coding": [{
                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                      "code": "notstarted",
                      "display": "Not Started"
                    }]
                  }
                }, {
                  "title": "Result with intervention vs. comparator",
                  "code": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/17942",
                      "code": "result-with-intervention-vs-comparator", "display": "Result with intervention vs. comparator"
                    }]
                  },
                  "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
                  "emptyReason": {
                    "coding": [{
                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                      "code": "notstarted",
                      "display": "Not Started"
                    }]
                  }
                }
  
              ]
            });
          }
        }
        let prevSection = prevState.section || [];
        let newSection = prevSection.concat(sectionsToAdd);
        return { ...prevState, section: newSection };
      })
    };
  }
  if (section.code?.coding?.[0]?.code === "summary-of-findings-entry-for-a-single-outcome" || section.code?.text === "Summary of findings entry for a single outcome") {
    sectionDetailEditSettings.SummaryOfFindingsEdit = SummaryOfFindingsEdit;
  }
  if (section.code?.coding?.[0]?.code === "result-with-comparator-alone" || section.code?.text === "Result with comparator alone") {
    sectionDetailEditSettings.fixedTitle = "Result with comparator alone";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateEvidenceSummary;
    sectionDetailEditSettings.entryFoi = getFoiFromReference(section.entry);
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Evidence Resource for the Result with Comparator.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Evidence"];
    sectionDetailEditSettings.entryStartingResourceType = "Evidence";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "result-with-intervention-alone" || section.code?.text === "Result with intervention alone") {
    sectionDetailEditSettings.fixedTitle = "Result with intervention alone";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateEvidenceSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Evidence Resource for the Result with Intervention.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Evidence"];
    sectionDetailEditSettings.entryStartingResourceType = "Evidence";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
    sectionDetailEditSettings.entryFoi = getFoiFromReference(section.entry);
  }
  if (section.code?.coding?.[0]?.code === "result-with-intervention-alone-calculated" || section.code?.text === "Result with intervention alone (calculated)") {
    sectionDetailEditSettings.fixedTitle = "Result with intervention alone (calculated)";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.entryFoiList = getFoisFromReference(section.entry, "Evidence", resourceDictionary);
    sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction = generateCalculatedInterventionRateSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Evidence Resource for the Result with Intervention (Calculated).";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Evidence"];
    sectionDetailEditSettings.entryStartingResourceType = "Evidence";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
    sectionDetailEditSettings.usesLoadSourceJson = true;
  }
  if (section.code?.coding?.[0]?.code === "result-with-intervention-vs-comparator" || section.code?.text === "Result with intervention vs. comparator") {
    sectionDetailEditSettings.fixedTitle = "Result with intervention vs. comparator";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateEvidenceSummary;
    sectionDetailEditSettings.entryFoi = getFoiFromReference(section.entry);
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Evidence Resource for the Effect Estimate.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Evidence"];
    sectionDetailEditSettings.entryStartingResourceType = "Evidence";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }

  return sectionDetailEditSettings;
}

export { ComparativeEvidenceSynthesisReportAuthor, changeSectionDetailEditSettingsForComparativeEvidenceSynthesisReport };