import React, { useEffect } from 'react';
import { emptyReasonValueSet, emptyReasonCodeableConceptSet } from './CodeSystemLookup';
import { DisplayFromFHIR } from './ResourceFunctions';
import { DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import {
  EditIntroduction, EditDiscussion, EditMethods, EditReferences, EditCompetingInterests,
  EditAcknowledgements, EditAppendices
} from './EvidenceReportPackageAuthoringTool';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';

const RecommendationsDisplay = ({ recommendationDisplayValuesDictionary }) => {

  let recommendationKeys = [];
  if (recommendationDisplayValuesDictionary && typeof recommendationDisplayValuesDictionary === 'object' &&
    Object.keys(recommendationDisplayValuesDictionary).length > 0) {
    recommendationKeys = Object.keys(recommendationDisplayValuesDictionary);
  }
  let numberOfRecommendations = recommendationKeys.length || 0;

  if (numberOfRecommendations === 0) {
    return <div>
      <p>There are no recommendations yet. Create a Recommendation above to add one, or <a
        href='/createrecommendation' target='_blank'
        rel='noopener noreferrer'>Create Recommendation</a> then Add Content Entry above to associate it with this guideline.</p>
    </div>
  }

  return <div>
    <div style={{ marginLeft: "24px" }}>
      {numberOfRecommendations > 0 && recommendationKeys.map((key, keyIndex) => {
        const rec = recommendationDisplayValuesDictionary[key];
        return <div key={keyIndex} >
          <p><b>Recommendation {keyIndex + 1} of {numberOfRecommendations}</b></p>
          <div style={{ marginLeft: "24px" }}>
            {rec.title && <p><b>Title: </b>{rec.title}</p>}
            {rec.statement && <span><b>Recommendation Statement: </b><DisplayFromFHIR xhtml={rec.statement} /><br /></span>}
            {rec.ratings && <span><b>Strength/Level of Recommendation: </b><DisplayFromFHIR xhtml={rec.ratings} /><br /></span>}
            {rec.population && <span><b>Population: </b><DisplayFromFHIR xhtml={rec.population} /><br /></span>}
            {rec.action && <span><b>Action: </b><DisplayFromFHIR xhtml={rec.action} /><br /></span>}
            {rec.resourceReference && <span><b>Recommendation Resource: </b>
              <DisplayFromFHIR reference={rec.resourceReference} /></span>}
          </div>
        </div>
      })}
    </div>
  </div>
}

const reorderSections = (sectionArray) => {
  let emptySummary = {
    "title": "Summary Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "summary",
        display: "Summary"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyIntroduction = {
    "title": "Introduction Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "introduction",
        display: "Introduction"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyDiscussion = {
    "title": "Discussion Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "discussion",
        display: "Discussion"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyMethods = {
    "title": "Methods Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "methods",
        display: "Methods"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyReferences = {
    "title": "References Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "references",
        display: "References"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyCompetingInterests = {
    "title": "Competing Interests Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "competing-interests",
        display: "Competing Interests"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAcknowledgements = {
    "title": "Acknowledgements Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "acknowledgements",
        display: "Acknowledgements"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAppendices = {
    "title": "Appendices Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "appendices",
        display: "Appendices"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyRecommendations = {
    "title": "Recommendations Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "recommendations",
        display: "Recommendations"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let sectionDictionary = {
    summary: emptySummary,
    introduction: emptyIntroduction,
    discussion: emptyDiscussion,
    methods: emptyMethods,
    references: emptyReferences,
    competingInterests: emptyCompetingInterests,
    acknowledgements: emptyAcknowledgements,
    appendices: emptyAppendices,
    recommendations: emptyRecommendations,
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
    if (sectionCode === "introduction" || sectionCode === "Introduction") {
      sectionDictionary.introduction = section;
    } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
      sectionDictionary.discussion = section;
    } else if (sectionCode === "methods" || sectionCode === "Methods") {
      sectionDictionary.methods = section;
    } else if (sectionCode === "summary" || sectionCode === "Summary") {
      sectionDictionary.summary = section;
    } else if (sectionCode === "references" || sectionCode === "References") {
      sectionDictionary.references = section;
    } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
      sectionDictionary.competingInterests = section;
    } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
      sectionDictionary.acknowledgements = section;
    } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
      sectionDictionary.appendices = section;
    } else if (sectionCode.includes("recommendations") || sectionCode === "Recommendations") {
      sectionDictionary.recommendations = section;
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  let reorderedSectionArray = [
    sectionDictionary.summary,
    sectionDictionary.introduction,
    sectionDictionary.methods,
    sectionDictionary.recommendations,
    sectionDictionary.discussion,
    sectionDictionary.references,
    sectionDictionary.competingInterests,
    sectionDictionary.acknowledgements,
    sectionDictionary.appendices
  ].concat(sectionDictionary.additionalSections);
  return reorderedSectionArray;
};

const GuidelineAuthor = ({ resourceState, setResourceState, globalContext, sourceJsonState,
  setSourceJsonState, previousVersionLoaded }) => {

  let defaultType = {
    coding: [{
      system: "https://fevir.net/resources/CodeSystem/179423",
      code: "Guideline",
      display: "Guideline"
    }]
  }

  useEffect(() => {
    if (resourceState) {
      setResourceState(prevState => {
        return {
          ...prevState,
          type: prevState.type || defaultType,
          section: reorderSections(prevState.section)
        };
      });
    }
  }, []);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  return <>{sourceJsonState.loaded ?
    <div style={{ marginTop: "12px" }}>
      <div>
        {resourceState.section ? <>
          <h3 id="summary">Summary</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={0}
              fieldLabel="Summary" sectionCode="section:https://fevir.net/resources/CodeSystem/179423#summary"
              startingValue={resourceState.section[0] || null}
              fixedTitle={"Summary"} fixedCode={{
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "summary",
                    "display": "Summary"
                  }
                ]
              }} startCollapsed editTextDiv={true} textDeletable={true}
              emptyTextDivValue="[No data.]"
              editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
              noEntry={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonStartCollapsed={true}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              editSection={true} 
              editableSectionCodes={true} addSectionAllowed={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title} />
          </div>
          <div>
            <EditIntroduction sectionIndex={1} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditMethods sectionIndex={2} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <h3 id="recommendations">Recommendations</h3>
            <div style={{ marginLeft: "24px" }}>
              <DocumentSectionEntry sectionArrayIndex={3}
                startingValue={resourceState.section[3] || null}
                fieldLabel="Recommendations"
                entryReferencedResourceTypes={["Composition"]} 
                editTitle={true} fixedCode={{
                  "coding": [
                    {
                      "system": "https://fevir.net/resources/CodeSystem/179423",
                      "code": "recommendations",
                      "display": "Recommendations"
                    }
                  ]
                }} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
                entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
                entryStartCollapsed={true} entryStartEmptyArrayClosed={true}
                entryStartingResourceType={"Composition"} entrySetProfile={"Recommendation"}
                editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
                emptyReasonStartCollapsed={true}
                emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                editSection={true} 
                editableSectionCodes={true} addSectionAllowed={true} sectionEntryRecommendationOnly={true}
                setResourceState={setResourceState}
                compositionId={resourceState.id} compositionTitle={resourceState.title} />
              <h3>To edit the Recommendations, click through to edit directly.</h3>
              <RecommendationsDisplay recommendationDisplayValuesDictionary={sourceJsonState.recommendationDisplayValuesDictionary} />
            </div>
            <br />
            <EditDiscussion sectionIndex={4} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditReferences sectionIndex={5} previousVersionLoaded={previousVersionLoaded}
              resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditCompetingInterests sectionIndex={6} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditAcknowledgements sectionIndex={7} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditAppendices sectionIndex={8} resourceState={resourceState} setResourceState={setResourceState} />
          </div>
          <br />
        </>
          :
          <>Section element is missing in the JSON.</>
        }
      </div>
    </div>
    :
    <div><p>Loading ...</p></div>}</>
};

const changeSectionDetailEditSettingsForGuideline = (sectionDetailEditSettings, section) => {
  if (section.code?.coding?.[0]?.code === "recommendations" || section.code?.text?.includes("recommendations")) {
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add Composition Resource (Recommendation Profile)";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
    sectionDetailEditSettings.entryStartingResourceType = "Composition";
    sectionDetailEditSettings.entrySetProfile = "Recommendation";
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
    sectionDetailEditSettings.createRecommendation = true;
    sectionDetailEditSettings.editCode = true;
    if (section.code?.coding?.[0]?.code === "recommendations") {
      sectionDetailEditSettings.editCode = false;
    }
  }

  return sectionDetailEditSettings;
}

export { GuidelineAuthor, changeSectionDetailEditSettingsForGuideline };