import { useState, useContext } from "react";
import { Button, Dropdown, Modal, Table } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import "firebase/compat/auth";
import axios from 'axios';
import FevirContext from './FevirContext';
import submitToFevirServer from "./SubmitToFevirServer";
import server_config from './ServerConfiguration';
import addOption from './AddOptionFunction';
import { monthLookup, groupCharacteristicKindLookup } from './CodeSystemLookup';
import { getProjectResourcesList, builderUpdateProjectJson } from './ProjectFunctions';
import { builderUpdateValueSetJson } from './ValueSetFunctions';
import { builderUpdateCitationJson } from './CitationFunctions';
import { builderUpdateGroupJson } from './GroupFunctions';
import { builderUpdateCommentRatingJson } from './CommentRatingFunctions';
import { builderUpdateRobatJson } from './CodeSystemFunctions';
import { builderUpdateCodeSystemJson, builderUpdateCodeSystemTermDetailJson } from "./CodeSystemEditFunctions";
import { builderUpdateRecommendationJustificationJson } from './RecommendationJustificationFunctions';
//import { builderUpdateRecommendationJson } from "./RecommendationFunctions";
import { builderUpdateCompositionDocumentJson } from "./CompositionDocumentBundleFunctions";
import { builderUpdateSchemaElementJson } from './SchemaElementFunctions';
import { builderUpdateActivityDefinitionJson } from "./ActivityDefinitionFunctions";
import { builderUpdateLibraryJson } from "./LibraryFunctions";
import { builderUpdateListJson } from "./ListFunctions";
import { builderUpdateBundleJson } from "./BundleFunctions";
import { builderUpdateMeasureJson } from "./MeasureFunctions";
import { builderUpdatePlanDefinitionJson } from "./PlanDefinitionFunctions";
import { builderUpdateResearchStudyJson } from "./ResearchStudyFunctions";
import { builderUpdateEvidenceJson } from "./EvidenceFunctions";
import { builderUpdateEvidenceVariableJson, builderUpdateEvidenceVariableCharacteristicDetailJson } from "./EvidenceVariableFunctions";
import { builderUpdateSoftwareScriptJson } from "./SoftwareScriptFunctions";
import { builderUpdateCharacteristicResourceJson } from "./CharacteristicResourceFunctions";
import { builderUpdateClassificationJson } from "./ClassificationFunctions";
import { builderUpdateCommentJson } from "./CommentFunctions";
import { builderUpdateCompositionJson } from "./CompositionFunctions";
import { builderUpdateAdaptationJson, builderUpdateAdaptationContentJson, builderUpdateAdaptationDetailJson } from "./AdaptationFunctions";
import { builderUpdateCompositionSectionDetailJson } from "./CompositionDocumentBundleFunctions";
import { getStringFromFHIR, DisplayFromFHIR } from './GetDatatypeDisplayFunctions';

const node_server_address = server_config["node_server_address"];

const clearDivWrapper = (divText) => {
  if (divText && typeof (divText) === "string") {
    if (divText.substring(0, 42) === '<div xmlns="http://www.w3.org/1999/xhtml">' && divText.substring(divText.length - 6) === '</div>') {
      divText = divText.substring(42, divText.length - 6);
    }
  }
  return divText;
}

const clearState = (setter, blankState) => {
  setter(prevState => {
    return blankState;
  });
};

const addResourceElementState = (setter, parentField, parentIndex, field, emptyDefault) => {
  if (parentField) {
    setter(prevState => {
      let prevField = prevState[parentField];
      prevField[parentIndex][field].push(emptyDefault);
      return {
        ...prevState,
        [parentField]: prevField
      };
    });
  } else {
    setter(prevState => {
      let prevField = prevState[field];
      prevField.push(emptyDefault);
      return {
        ...prevState,
        [field]: prevField
      };
    });
  }
};

const retrieveIterativeElement = (element, parentField, field, indexTrail, fieldIndex, subField) => {
  let fieldValue = "";
  if (indexTrail?.length > 0 && element[parentField]) {
    fieldValue = retrieveIterativeElement(element[parentField][indexTrail[0]], parentField, field, indexTrail.slice(1), fieldIndex, subField);
  } else {
    if (fieldIndex !== undefined) {
      if (subField === undefined) {
        fieldValue = element[field][fieldIndex];
      }
    } else {
      fieldValue = element[field];
    }
  }
  return fieldValue;
}

const updateIterativeJson = (state, json, parentField, field) => {
  for (let index in state[parentField]) {
    let newValue = state[parentField][index][field];
    if (json[parentField] === undefined) {
      json[parentField] = [];
    }
    if (json[parentField].length - 1 < index) {
      json[parentField].push({});
    }
    if (!(json[parentField][index][field] === undefined && (newValue === "" || newValue === undefined))) {
      json[parentField][index][field] = state[parentField][index][field];
    }
    if (parentField in state) {
      json[parentField][index] = updateIterativeJson(state[parentField][index], json[parentField][index], parentField, field);
    }
  }
  return json;
}

const editIterativeState = (state, stateSetter, newValue, parentField, field, indexTrail) => {
  stateSetter(prevState => {
    if (indexTrail.length > 0) {
      let element = state;
      for (let indexPosition in indexTrail) {
        let index = indexTrail[indexPosition];
        element = element[parentField][index];
      }
      element[field] = newValue;
      return {
        ...prevState,
        [parentField]: state[parentField]
      };
    } else {
      return {
        ...prevState,
        [field]: newValue,
      };
    }
  });
}

const loadReferencedResource = async (getResource, userState, setter, setGroupCharacteristicKindOptions, referencedGroupUrl, referencedGroupKey, referencedGroupStringKey, groupCharacteristicsKey, groupExclusionCharacteristicsKey, groupInclusionCharacteristicsKey) => {
  let returningdata = false;
  let referencedGroupJson;
  if (referencedGroupUrl) {
    let referencedGroupUrlParts = referencedGroupUrl.split("/");
    let referencedGroup = await getResource(referencedGroupUrlParts[referencedGroupUrlParts.length - 1], referencedGroupUrlParts[referencedGroupUrlParts.length - 2], userState.idToken);
    if (referencedGroup.found && referencedGroup.readpermission) {
      returningdata = true;
      referencedGroupJson = JSON.parse(referencedGroup["fhirResource"]);
      setter(prevState => {
        return {
          ...prevState,
          [referencedGroupStringKey]: referencedGroup["fhirResource"],
          [referencedGroupKey]: referencedGroupJson
        };
      });
    }
    await loadGroupCharacteristics(referencedGroupJson, groupCharacteristicsKey, groupExclusionCharacteristicsKey, groupInclusionCharacteristicsKey, setGroupCharacteristicKindOptions, setter);
  }
  return returningdata;
};

const loadGroupCharacteristics = async (groupJson, groupCharacteristicsKey, groupExclusionCharacteristicsKey, groupInclusionCharacteristicsKey, setGroupCharacteristicKindOptions, setter) => {
  if (groupJson) {
    let groupInclusionCharacteristics = [];
    let groupCharacteristics = [];
    let groupExclusionCharacteristics = [];
    for (let characteristicIndex in groupJson.characteristic) {

      let characteristicValueType = "";
      let characteristicValueCoding = [];
      let characteristicValueSystem = "";
      let characteristicValueCode = "";
      let characteristicValueDisplay = "";

      let characteristicValue = "";
      let characteristicValues = [];
      let characteristicPeriodText = "";
      let characteristicValueText = "";

      let characteristicValueBoolean = "";

      let characteristicValueInternalReference = "";
      let characteristicValueReferenceDisplay = "";

      let characteristicValueComparator = "";
      let characteristicValueQuantity = "";
      let characteristicValueUnit = "";

      let characteristicValueLow = "";
      let characteristicValueLowUnit = "";
      let characteristicValueLowSystem = "";
      let characteristicValueLowCode = "";
      let characteristicValueHigh = "";
      let characteristicValueHighUnit = "";
      let characteristicValueHighSystem = "";
      let characteristicValueHighCode = "";

      let valueExpressionDescription = "";
      let valueExpressionLanguage = "";
      let valueExpressionExpression = "";
      let valueExpressionReference = "";

      let characteristicType = "";
      let characteristicTypeCode = "";
      let characteristicTypeCodeDisplay = "";

      let characteristic = groupJson.characteristic[characteristicIndex];
      if (characteristic) {

        if (characteristic.code) {
          if (characteristic.code.coding) {
            for (let characteristicCodingIndex in characteristic.code.coding) {
              let coding = characteristic.code.coding[characteristicCodingIndex];
              let display = coding.display;
              if (coding.code) {
                if (groupCharacteristicKindLookup[coding.code]) {
                  display = groupCharacteristicKindLookup[coding.code].display;
                } else if (coding.display) {
                  display = coding.display;
                }
              }
              let code = coding.code;
              let comma = "";
              if (display) {
                characteristicTypeCodeDisplay = display;
                if (code === undefined || code === "") {
                  code = display;
                }
                if (characteristicType.length > 0) {
                  comma = ", ";
                }
                characteristicType += comma + display;
              }
              if (code) {
                comma = "";
                if (characteristicTypeCode.length > 0) {
                  comma = ", ";
                }
                characteristicTypeCode += comma + code;
              }
            }
          }
          if (characteristic.code.text && characteristicType !== characteristic.code.text) {
            let comma = "";
            if (characteristicType.length > 0) {
              comma = ", ";
            }
            characteristicType += comma + characteristic.code.text;
            comma = "";
            if (characteristicTypeCode.length > 0) {
              comma = ", ";
            }
            characteristicTypeCode += comma + characteristic.code.text;
          }
        }
        if (characteristicTypeCodeDisplay === "" || characteristicTypeCodeDisplay === undefined) {
          characteristicTypeCodeDisplay = characteristicTypeCode;
        }

        if (characteristic.valueCodeableConcept) {
          characteristicValueType = "codeableConcept";
          characteristicValueText = characteristic.valueCodeableConcept.text;
          if (characteristic.valueCodeableConcept.coding) {
            for (let characteristicCodingIndex in characteristic.valueCodeableConcept.coding) {
              let characteristicValueCodingEntry = {};
              let coding = characteristic.valueCodeableConcept.coding[characteristicCodingIndex];
              let comma = "";
              if (characteristicValue.length > 0) {
                comma = ", ";
              }
              if (coding.system) {
                characteristicValueSystem = coding.system;
                characteristicValueCodingEntry["valueSystem"] = coding.system;
              }
              if (coding.code) {
                characteristicValueCode = coding.code;
                characteristicValueCodingEntry["valueCode"] = coding.code;
              }
              if (coding.display) {
                characteristicValueDisplay = coding.display;
                characteristicValueCodingEntry["valueDisplay"] = coding.display;
                characteristicValue += comma + coding.display;
                characteristicValues.push(coding.display);
              } else if (coding.code) {
                characteristicValue += comma + coding.code;
                characteristicValues.push(coding.code);
              }
              characteristicValueCoding.push(characteristicValueCodingEntry);
            }
          }
          if (characteristicValueText && characteristicValue !== characteristicValueText) {
            let comma = "";
            if (characteristicValue.length > 0) {
              comma = ", ";
            }
            characteristicValue += comma + characteristicValueText;
          }
        } else if (characteristic.valueQuantity) {
          characteristicValueType = "quantity";
          if (characteristic.valueQuantity.comparator) {
            characteristicValue = characteristic.valueQuantity.comparator + " ";
            characteristicValueComparator = characteristic.valueQuantity.comparator;
          }
          if (characteristic.valueQuantity.value) {
            characteristicValue += " " + characteristic.valueQuantity.value + " ";
            characteristicValueQuantity = characteristic.valueQuantity.value;
          }
          if (characteristic.valueQuantity.unit) {
            characteristicValue += " " + characteristic.valueQuantity.unit + " ";
            characteristicValueUnit = characteristic.valueQuantity.unit;
          }
          if (characteristic.valueQuantity.system) {
            characteristicValueSystem = characteristic.valueQuantity.system;
          }
          if (characteristic.valueQuantity.code) {
            characteristicValueCode = characteristic.valueQuantity.code;
          }
        } else if (characteristic.valueBoolean !== undefined && characteristic.valueBoolean !== "") {
          characteristicValueType = "boolean";
          //characteristicValue = characteristic.valueBoolean.toString();
          characteristicValue = characteristic.valueBoolean;
          characteristicValueBoolean = characteristic.valueBoolean;
        } else if (characteristic.valueRange) {
          characteristicValueType = "range";
          let lowValue = "No Lower Bound";
          let highValue = "No Upper Bound";
          if (characteristic.valueRange.low) {
            if (characteristic.valueRange.low.value) {
              //lowValue = characteristic.valueRange.low.value.toString();
              lowValue = characteristic.valueRange.low.value;
              characteristicValueLow = characteristic.valueRange.low.value;
              if (characteristic.valueRange.low.unit) {
                lowValue += " " + characteristic.valueRange.low.unit;
                characteristicValueLowUnit = characteristic.valueRange.low.unit;
              }
            }
            if (characteristic.valueRange.low.system) {
              characteristicValueLowSystem = characteristic.valueRange.low.system;
            }
            if (characteristic.valueRange.low.code) {
              characteristicValueLowCode = characteristic.valueRange.low.code;
            }
          }
          if (characteristic.valueRange.high) {
            if (characteristic.valueRange.high.value) {
              //highValue = characteristic.valueRange.high.value.toString();
              highValue = characteristic.valueRange.high.value;
              characteristicValueHigh = characteristic.valueRange.high.value;
              if (characteristic.valueRange.high.unit) {
                highValue += " " + characteristic.valueRange.high.unit;
                characteristicValueHighUnit = characteristic.valueRange.high.unit;
              }
              if (characteristic.valueRange.high.system) {
                characteristicValueHighSystem = characteristic.valueRange.high.system;
              }
              if (characteristic.valueRange.high.code) {
                characteristicValueHighCode = characteristic.valueRange.high.code;
              }
            }
          }
          characteristicValue = lowValue + " to " + highValue;
        } else if (characteristic.valueReference) {
          characteristicValueType = "reference";
          if (characteristic.valueReference.display) {
            characteristicValue = characteristic.valueReference.display;
          }
          if (characteristic.valueReference.reference) {
            if (characteristicValue === "") {
              characteristicValue = characteristic.valueReference.reference;
            }
            characteristicValueInternalReference = characteristic.valueReference.reference;
            characteristicValueReferenceDisplay = characteristic.valueReference.display;
          }
        } else if (characteristic.valueExpression) {
          characteristicValueType = "expression";
          valueExpressionDescription = characteristic.valueExpression.description;
          valueExpressionLanguage = characteristic.valueExpression.language;
          valueExpressionExpression = characteristic.valueExpression.expression;
          valueExpressionReference = characteristic.valueExpression.reference;
          if (characteristicValue === "") {
            characteristicValue = valueExpressionDescription + " ... " + valueExpressionLanguage + " ... " + valueExpressionExpression + " " + valueExpressionReference;
          }
        }
        if (characteristic.period) {
          let periodStart = "No start stated";
          let periodEnd = "No end stated";
          if (characteristic.period.start) {
            periodStart = characteristic.period.start;
          }
          if (characteristic.period.end) {
            periodEnd = characteristic.period.end;
          }
          characteristicPeriodText = periodStart + " to " + periodEnd;
        }
      }
      let characteristicsEntry = { "type": characteristicType, "typeCode": characteristicTypeCode, "typeCodeDisplay": characteristicTypeCodeDisplay, "valueType": characteristicValueType, "coding": characteristicValueCoding, "valueCode": characteristicValueCode, "valueDisplay": characteristicValueDisplay, "valueText": characteristicValueText, "valueSystem": characteristicValueSystem, "value": characteristicValue, "values": characteristicValues, "valueInternalReference": characteristicValueInternalReference, "valueReferenceDisplay": characteristicValueReferenceDisplay, "valueBoolean": characteristicValueBoolean, "valueComparator": characteristicValueComparator, "valueQuantity": characteristicValueQuantity, "valueUnit": characteristicValueUnit, "valueLow": characteristicValueLow, "valueLowUnit": characteristicValueLowUnit, valueLowSystem: characteristicValueLowSystem, valueLowCode: characteristicValueLowCode, "valueHigh": characteristicValueHigh, "valueHighUnit": characteristicValueHighUnit, valueHighSystem: characteristicValueHighSystem, valueHighCode: characteristicValueHighCode, characteristicValueSystem: characteristicValueSystem, characteristicValueCode: characteristicValueCode, "period": characteristicPeriodText, "valueExpressionDescription": valueExpressionDescription, "valueExpressionLanguage": valueExpressionLanguage, "valueExpressionExpression": valueExpressionExpression, "valueExpressionReference": valueExpressionReference };
      groupCharacteristics.push({ "type": characteristicTypeCode, "typeCodeDisplay": characteristicTypeCodeDisplay });
      if (setGroupCharacteristicKindOptions) {
        addOption(setGroupCharacteristicKindOptions, characteristicTypeCodeDisplay, characteristicTypeCode);
      }

      if (characteristic.exclude && characteristic.exclude === true) {
        groupExclusionCharacteristics.push(characteristicsEntry);
      } else {
        groupInclusionCharacteristics.push(characteristicsEntry);
      }
    }

    setter(prevState => {
      return {
        ...prevState,
        [groupCharacteristicsKey]: groupCharacteristics,
        [groupExclusionCharacteristicsKey]: groupExclusionCharacteristics,
        [groupInclusionCharacteristicsKey]: groupInclusionCharacteristics
      };
    });

  }
};

const getCodeDisplayOrTextForElement = (element, lookup) => {
  let text = "";
  let textCoding = "";
  if (Array.isArray(element.coding)) {
    for (let codingIndex in element.coding) {
      if (element.coding[codingIndex].display) {
        textCoding += element.coding[codingIndex].display + ", ";
      } else if (element.coding[codingIndex].code) {
        if (lookup && lookup[element.coding[codingIndex].code]?.display) {
          textCoding += lookup[element.coding[codingIndex].code].display + ", ";
        } else {
          textCoding += element.coding[codingIndex].code + ", ";
        }
      }
    }
    if (textCoding) {
      text += textCoding;
    }
  }
  if (element.text) {
    text += element.text + ", ";
  }
  if (text.length > 1) {
    text = text.substring(0, text.length - 2);
  }
  return text;
}

const getCodeDisplayOrText = (element, lookup) => {
  let text = "";
  if (element && typeof element === "object") { //Both arrays and objects have the same type, "object"
    if (Array.isArray(element)) {
      for (let index in element) {
        text += getCodeDisplayOrTextForElement(element[index], lookup) + ", ";
      }
    } else {
      text += getCodeDisplayOrTextForElement(element, lookup) + ", ";
    }
    if (text.length > 1) {
      text = text.substring(0, text.length - 2);
    }
  } else {
    //Else, it's probably a string already
    text = element;
  }
  return text;
}

const checkCharacteristicCombination = (element) => {
  let howCombined = "";
  if (element?.characteristicCombination) {
    let characteristicCombination;
    if (element.characteristicCombination.code) {
      characteristicCombination = element.characteristicCombination.code;
    } else {
      characteristicCombination = element.characteristicCombination;
    }
    let threshold = "#";
    if (element.characteristicCombination.threshold) {
      threshold = element.characteristicCombination.threshold;
    }
    if (characteristicCombination === "union" || characteristicCombination === "any-of") {
      howCombined = "Any of";
    } else if (characteristicCombination === "intersection" || characteristicCombination === "all-of") {
      howCombined = "All of";
    } else if (characteristicCombination === "net-effect") {
      howCombined = "Net effect";
    } else if (characteristicCombination === "at-least") {
      howCombined = "At least " + threshold;
    } else if (characteristicCombination === "at-most") {
      howCombined = "At most " + threshold;
    }
  }
  return howCombined
}

const findAllReferences = (parentElement, resourceDictionary) => {
  for (const key in parentElement) {
    let foiFound = false;
    let fliFound = false;
    let element = parentElement[key];
    if (Array.isArray(element)) {
      for (let entry of element) {
        if (typeof (entry) != "object" || Array.isArray(entry)) {
          break;
        } else {
          findAllReferences({ [key]: entry }, resourceDictionary);
        }
      }
    } else if (element && (typeof (element.reference) === "string" || element.identifier?.system === "https://fevir.net/FLI" || (element.identifier?.type?.text && JSON.stringify(element.identifier.type.text).includes("FEvIR Linking Identifier")))) {
      let resourceType = element.type;
      if (typeof (element.reference) === "string" && element.reference.split("/").length === 2 && !isNaN(element.reference.split("/")[1])) {
        foiFound = element.reference.split("/")[1];
        if (!resourceType) {
          resourceType = element.reference.split("/")[0];
        }
      } else if (element.identifier?.system === "https://fevir.net/FLI" ||
        (element.identifier?.type?.text && JSON.stringify(element.identifier.type.text).includes("FEvIR Linking Identifier"))) {
        fliFound = element.identifier.value;
      }
      if (foiFound || fliFound) {
        let title = element.display;
        if (!title) {
          title = "[Title not captured] " + (foiFound || fliFound);
        }
        let url;
        if (foiFound) {
          url = "/resources/" + resourceType + "/" + foiFound;
        } else {
          url = "/FLI/" + fliFound;
        }
        if (!resourceType) {
          resourceType = "UNKNOWN";
        }
        if (!resourceDictionary[resourceType]) {
          resourceDictionary[resourceType] = [];
        }
        resourceDictionary[resourceType].push({ "title": title, "url": url });
      }
    } else if (typeof (element) === "object") {
      findAllReferences(element, resourceDictionary);
    }
  }
  return resourceDictionary;
}

const AssociatedResourcesDisplay = ({ fhirJson }) => {
  //for each element in the fhirJson, check if Reference datatype
  //if Reference datatype, check if FOI or FLI
  //if FOI or FLI, extract title (from display) and url and add to resourceDictionaryByType
  //display resourceDictionaryByType
  if (typeof (fhirJson) === "object") {
    let resourceDictionary = {};
    findAllReferences(fhirJson, resourceDictionary);
    for (const resourceType in resourceDictionary) {
      let entries = resourceDictionary[resourceType];
      entries.sort((a, b) => a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1);
    }
    let resourceTypes = Object.keys(resourceDictionary).sort();

    return <div>
      {resourceTypes?.length > 0
        ?
        resourceTypes.map((resourceType, index) => {
          return <div key={index}>
            <div className="resourceListHeaderTextForProject"
              style={{ width: "100%", marginTop: "12px", marginBottom: "8px" }}>
              <span className="resourceListHeader" style={{ fontWeight: "bold", paddingLeft: "30%", cursor: "auto" }}>
                <span>{resourceType} <b style={{ padding: "none !important", fontWeight: "normal" }}>Resources</b></span>
              </span><br />
            </div>
            {resourceDictionary[resourceType].map((resource, resourceIndex) => {
              return <div key={resourceIndex}>
                <>{(resourceIndex + 1)}. </> <a href={resource.url}>{resource.title}</a>
              </div>
            })}
          </div>
        })
        :
        <>None</>
      }
    </div>
  } else {
    return <></>
  }

}

const SimpleResourceFieldViewer = ({ resource, parentElement, parentParentElement, startExpanded }) => {

  const [expandedState, setExpandedState] = useState(startExpanded || false);

  return <>
    {resource && <span className={"unselectable"} style={{ cursor: "pointer" }}
      onClick={() => { setExpandedState(!expandedState) }}>
      {!parentElement && <>{expandedState ? <>Collapse </> : <>Expand </>}</>}
      {expandedState ? <>▼</> : <>►</>}
    </span>}
    {resource && expandedState && Object.keys(resource).map((field, index) => {
      let reference = "";
      if (field === "reference") {
        let slashesFound = resource[field].toString().match(/\//g);
        if (slashesFound?.length === 1) {
          reference = resource[field];
        }
      }
      let fli;
      if ((parentParentElement === "identifier" || parentElement === "identifier") && field === "value") { //&& field === "value"
        //Then it's an identifier value
        if (resource.system?.toLowerCase() === "https://fevir.net/fli" || resource.type?.text === "FEvIR Linking Identifier") {
          //Then it's an FLI
          fli = resource[field];
        }
      }
      return <div key={index} style={{ paddingLeft: "40px", wordBreak: "break-word" }}>
        {resource[field] !== "" &&
          <>
            {typeof resource[field] !== "object" ?
              <>
                {reference ?
                  <span name={field}><b>{field}: </b><a href={"/resources/" + reference} target="_blank" rel="noopener noreferrer">{reference}</a><br /></span>
                  :
                  <>{fli ?
                    <span name={field}><b>{field}: </b><a href={"/FLI/" + fli} target="_blank" rel="noopener noreferrer">{resource[field].toString()}</a><br /></span>
                    :
                    <>{(field === "@id" || field === "@type") && resource[field].toLowerCase().substring(0, 4) === "http" ?
                      <span name={field}><b>{field}: </b><a href={resource[field]} target="_blank" rel="noopener noreferrer">{resource[field]}</a><br /></span>
                      :
                      <span name={field}>{resource[field] !== undefined && <><b>{field}: </b><span>{resource[field].toString()}</span><br /></>}</span>
                    }</>
                  }</>
                }
              </>
              :
              <span>
                <b>{isNaN(field) ? field : parentElement + " " + (parseInt(field)).toString()}: </b>
                <SimpleResourceFieldViewer resource={resource[field]} parentElement={field} parentParentElement={parentElement} startExpanded={true} />
              </span>
            }
          </>
        }
        {/*parentElement === "" && <br />*/}
      </div>
    }
    )}
  </>
}

const codesToCodeableConcept = (entry, codeArray, lookup, system) => {
  let codeableConceptJson = JSON.parse(JSON.stringify(entry));
  codeableConceptJson.text = "";
  let previousText = codeableConceptJson.text;
  let textCount = 0;
  let codeableConceptJsonTextEdited = false;
  if (codeArray?.length > 0) {
    //let codeableConceptJson = {"coding": [], "text": ""};
    //codeableConceptJson.coding = [];
    for (let index in codeArray) {
      if (codeArray[index] !== "[Undeclared]") {
        if (lookup[codeArray[index]]) {
          if (codeableConceptJson.coding && index < codeableConceptJson.coding.length) {
            codeableConceptJson.coding[index].code = codeArray[index];
            codeableConceptJson.coding[index].display = lookup[codeArray[index]].display;
            if (codeableConceptJson.coding[index].system === undefined || codeableConceptJson.coding[index].system === "") {
              codeableConceptJson.coding[index].system = lookup[codeArray[index]].system;
            }
          } else {
            if (codeableConceptJson.coding === undefined) {
              codeableConceptJson.coding = [];
            }
            let systemFromTable = lookup[codeArray[index]].system;
            if (systemFromTable === undefined) {
              systemFromTable = system;
            }
            codeableConceptJson.coding.push({ "system": systemFromTable, "code": codeArray[index], "display": lookup[codeArray[index]].display });
          }
        } else if (codeArray[index] !== undefined && codeArray[index]) {
          if (codeableConceptJsonTextEdited === false) {
            codeableConceptJson.text = "";
            codeableConceptJsonTextEdited = true;
          }
          codeableConceptJson.text += codeArray[index] + ", ";
          textCount += 1;
        } else if (codeArray.length === 1) {
          //codeableConceptJson = {};
        }
      } else if (codeArray.length === 1) {
        //codeableConceptJson = {};
      }
    }
    if (codeableConceptJson.coding) {
      if (codeableConceptJson.coding.length > codeArray.length) {
        codeableConceptJson.coding = codeableConceptJson.coding.slice(0, codeArray.length);
      }
    }
    if (codeableConceptJsonTextEdited) {
      codeableConceptJson.text = codeableConceptJson.text.slice(0, -2);
      if (previousText !== codeableConceptJson.text && (codeArray.length === 1 || textCount === codeArray.length)) {
        delete codeableConceptJson.coding;
      }
    }
  } else {
    codeableConceptJson.coding = [];
    //return undefined;
  }
  return codeableConceptJson;
}

const NoteDisplay = ({ notes }) => {
  return <>{notes.map((noteText, noteIndex) => {
    return <span key={noteIndex}><b>Note: </b> {noteText}<br /></span>
  })}<br /></>
}

const getNotes = (note) => {
  let notes = [];
  for (let noteIndex in note) {
    if (note[noteIndex].text) {
      notes.push(note[noteIndex].text);
    }
  }
  return notes;
}

const ElementNote = ({ element }) => {
  let notes = [];
  if (element?.note) {
    notes = getNotes(element.note);
  }
  return <NoteDisplay notes={notes} />;
}

const changeSimpleState = (setter, newValue, field) => {
  setter(prevState => {
    return {
      ...prevState,
      [field]: newValue,
    };
  });
}

const changeSimpleStateWithCheck = (state, setter, newValue, field) => {
  if (state[field] !== newValue) {
    setter(prevState => {
      return {
        ...prevState,
        [field]: newValue,
      };
    });
  }
}

const changeResourceElementState = (setter, newValue, parentField, parentIndex, field, index, subfield) => {
  if (parentField) {
    setter(prevState => {
      let prevField = prevState[parentField];
      if (parentIndex !== undefined && index !== undefined) {
        if (subfield !== undefined) {
          prevField[parentIndex][field][index][subfield] = newValue;
        } else {
          if (prevField[parentIndex][field][index] === undefined) {
            prevField[parentIndex][field].push("");
          }
          prevField[parentIndex][field][index] = newValue;
        }
      } else if (parentIndex !== undefined && index === undefined) {
        prevField[parentIndex][field][subfield] = newValue;
      } else if (parentIndex === undefined && index !== undefined) {
        prevField[field][index][subfield] = newValue;
      }
      else if (field && subfield) {
        prevField[field][subfield] = newValue;
      }
      return {
        ...prevState,
        [parentField]: prevField
      };
    });
  } else if (index !== undefined && subfield !== undefined) {
    setter(prevState => {
      let prevField = prevState[field];
      prevField[index][subfield] = newValue;
      return {
        ...prevState,
        [field]: prevField
      };
    });
  } else if (field && subfield) {
    setter(prevState => {
      let prevField = prevState[field];
      prevField[subfield] = newValue;
      return {
        ...prevState,
        [field]: prevField
      };
    });
  } else if (field && index !== undefined) {
    setter(prevState => {
      let prevField = prevState[field];
      prevField[index] = newValue;
      return {
        ...prevState,
        [field]: prevField
      };
    });
  } else {
    setter(prevState => {
      return {
        ...prevState,
        [field]: newValue
      };
    });
  }
}

const getIdentifier = (identifierSet, system) => {
  let value;
  if (identifierSet) {
    identifierSet.some(identifier => {
      if (identifier.system === system) {
        value = identifier.value;
        return true;
      }
    })
  }
  return value;
}

const identifierTextViewChangesToJson = (identifiers, identifierJson, systemLookup, displayLookup) => {
  let identifiersArray;
  if (Array.isArray(identifiers)) {
    identifiersArray = true;
  } else {
    identifiersArray = false;
    identifiers = [identifiers];
    identifierJson = [identifierJson];
  }
  for (let x in identifiers) {
    let identifier = identifiers[x];
    if (identifierJson === undefined) {
      identifierJson = [];
    }
    if (x >= identifierJson.length) {
      identifierJson.push({});
    }
    if (identifierJson[x].type === undefined) {
      identifierJson[x].type = {};
    }
    if (identifierJson[x].assigner === undefined) {
      identifierJson[x].assigner = {};
    }
    if (identifier.typeText !== undefined) {
      identifierJson[x].type.text = identifier.typeText;
    } else if (systemLookup[identifier.system]) {
      identifierJson[x].type.text = systemLookup[identifier.system].display;
    }
    if (identifier.system !== undefined) {
      identifierJson[x].system = identifier.system;
    } else if (displayLookup[identifier.typeText] !== undefined) {
      identifierJson[x].system = displayLookup[identifier.typeText].system;
    }
    if (identifier.assignerDisplay !== undefined) {
      identifierJson[x].assigner.display = identifier.assignerDisplay;
    } else if (displayLookup[identifier.typeText]?.assignerDisplay !== undefined) {
      identifierJson[x].assigner.display = displayLookup[identifier.typeText].assignerDisplay;
    }
    identifierJson[x].value = identifier.value;
  }
  if (identifiersArray) {
    return identifierJson;
  } else {
    return identifierJson[0];
  }
}

const generateIdentifierSystemCell = (identifier, index, parentElement, parentIndex, stateElement, editMode, systemLookup, displayLookup, identifierTypeOptions, changeResourceElementState, setter, disabledSystems) => {
  if (disabledSystems === undefined) {
    disabledSystems = [];
  }
  return <Table.Cell style={{ paddingRight: "0px", paddingTop: "8px", paddingBottom: "8px", verticalAlign: "top" }}>
    {editMode ?
      <>
        <Dropdown
          name="identifierdisplay"
          placeholder="Preset"
          closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
          style={{ minWidth: "15%", width: "15%" }}
          options={identifierTypeOptions.options}
          value={systemLookup[identifier.system]?.display || ''}
          disabled={disabledSystems?.includes(identifier.system)}
          onChange={(e, data) => {
            //changeResourceElementState(setter, data.value, stateElement, index, "display");
            if (data.value && displayLookup[data.value]) {
              if (!disabledSystems.includes(displayLookup[data.value].system)) {
                if (changeResourceElementState) {
                  changeResourceElementState(setter, displayLookup[data.value].system, parentElement, parentIndex, stateElement, index, "system");
                  if (displayLookup[data.value].typeText !== undefined) {
                    changeResourceElementState(setter, displayLookup[data.value].typeText, parentElement, parentIndex, stateElement, index, "typeText");
                  }
                  if (displayLookup[data.value].assignerDisplay !== undefined) {
                    changeResourceElementState(setter, displayLookup[data.value].assignerDisplay, parentElement, parentIndex, stateElement, index, "assignerDisplay");
                  }
                } else {
                  setter(prevState => {
                    let newArray = prevState[parentIndex][stateElement];
                    newArray[index]["system"] = displayLookup[data.value].system;
                    if (displayLookup[data.value].typeText !== undefined) {
                      newArray[index]["typeText"] = displayLookup[data.value].typeText;
                    }
                    if (displayLookup[data.value].assignerDisplay !== undefined) {
                      newArray[index]["assignerDisplay"] = displayLookup[data.value].assignerDisplay;
                    }
                    return { ...prevState, [parentIndex]: { ...prevState[parentIndex], [stateElement]: newArray } }
                  });
                }
              }
            } else {
              if (changeResourceElementState) {
                changeResourceElementState(setter, "", parentElement, parentIndex, stateElement, index, "system");
                changeResourceElementState(setter, "", parentElement, parentIndex, stateElement, index, "typeText");
                changeResourceElementState(setter, "", parentElement, parentIndex, stateElement, index, "assignerDisplay");
              } else {
                setter(prevState => { let newArray = prevState[parentIndex][stateElement]; newArray[index]["system"] = ""; newArray[index]["typeText"] = ""; newArray[index]["assignerDisplay"] = ""; return { ...prevState, [parentIndex]: { ...prevState[parentIndex], [stateElement]: newArray } } });
              }
            }
            //changeResourceElementState(setter, displayLookup[data.value].system, stateElement, index, "system");
            //if (displayLookup[data.value].assignerDisplay) { changeResourceElementState(setter, displayLookup[data.value].assignerDisplay, stateElement, index, "assignerDisplay") };
          }}
        />
        &nbsp;&nbsp;&nbsp;
        <TextField style={{ width: "37%" }} multiline className="inputField" type='text' label={identifier.system ? "System URL" : "System URL"} size="small" value={identifier.system || ''} onChange={(e) => {
          if (changeResourceElementState) {
            changeResourceElementState(setter, e.target.value, parentElement, parentIndex, stateElement, index, "system");
          } else {
            setter(prevState => { let newArray = prevState[parentIndex][stateElement]; newArray[index]["system"] = e.target.value; return { ...prevState, [parentIndex]: { ...prevState[parentIndex], [stateElement]: newArray } } });
          }
        }}
          variant={systemLookup[identifier.system]?.systemDisabled ? 'filled' : 'outlined'
          } disabled={systemLookup[identifier.system] ? systemLookup[identifier.system].systemDisabled : false} />
        &nbsp;&nbsp;&nbsp;
        <TextField style={{ width: "15%" }} multiline className="inputField" type='text' label={identifier.typeText !== undefined && identifier.typeText !== "" ? "Name/Type" : systemLookup[identifier.system]?.typeDisabled ? "Name/Type N/A" : "Name/Type"} size="small" value={systemLookup[identifier.system]?.typeText !== undefined ? systemLookup[identifier.system].typeText : identifier.typeText ? identifier.typeText : ''}
          onChange={(e) => {
            if (changeResourceElementState) {
              changeResourceElementState(setter, e.target.value, parentElement, parentIndex, stateElement, index, "typeText");
            } else {
              setter(prevState => { let newArray = prevState[parentIndex][stateElement]; newArray[index]["typeText"] = e.target.value; return { ...prevState, [parentIndex]: { ...prevState[parentIndex], [stateElement]: newArray } } });
            }

          }} variant={systemLookup[identifier.system]?.typeDisabled ? 'filled' : 'outlined'} disabled={systemLookup[identifier.system] ? systemLookup[identifier.system].typeDisabled : false} />
        &nbsp;&nbsp;&nbsp;
        <TextField style={{ width: "26%" }} multiline className="inputField" type='text' label={identifier.assignerDisplay ? "Assigning Organization" : "Assigning Organization"} size="small" value={systemLookup[identifier.system]?.assignerDisplay !== undefined ? systemLookup[identifier.system].assignerDisplay : identifier.assignerDisplay ? identifier.assignerDisplay : ''}
          onChange={(e) => {
            if (changeResourceElementState) {
              changeResourceElementState(setter, e.target.value, parentElement, parentIndex, stateElement, index, "assignerDisplay");
            } else {
              setter(prevState => { let newArray = prevState[parentIndex][stateElement]; newArray[index]["assignerDisplay"] = e.target.value; return { ...prevState, [parentIndex]: { ...prevState[parentIndex], [stateElement]: newArray } } });
            }
          }} variant={systemLookup[identifier.system]?.assignerDisabled ? 'filled' : 'outlined'} disabled={systemLookup[identifier.system] ? systemLookup[identifier.system].assignerDisabled : false} />
      </>
      :
      <>{identifier.system && systemLookup[identifier.system] ? systemLookup[identifier.system].display : identifier.typeText ? identifier.typeText : identifier.system ? identifier.system : identifier.assignerDisplay}</>
    }
  </Table.Cell>
}

const generateIdentifierValueCell = (identifier, index, parentElement, parentIndex, stateElement, editMode, changeResourceElementState, setter, disabledSystems) => {
  return <Table.Cell style={{ paddingRight: "0px", paddingTop: "8px", paddingBottom: "8px", verticalAlign: "top" }}>
    {editMode ?
      <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={"Identifier Value"} size="small" variant='outlined' value={identifier.value || ''} disabled={disabledSystems?.includes(identifier.system)} onChange={(e) => {
        if (changeResourceElementState) {
          changeResourceElementState(setter, e.target.value, parentElement, parentIndex, stateElement, index, "value");
        } else {
          setter(prevState => { let newArray = prevState[parentIndex][stateElement]; newArray[index]["value"] = e.target.value; return { ...prevState, [parentIndex]: { ...prevState[parentIndex], [stateElement]: newArray } } });
        }

      }} />
      :
      <>{identifier.value}</>
    }
  </Table.Cell>
}

const generateIdentifierTable = (identifiers, parentElement, parentIndex, stateElement, editMode, systemLookup, displayLookup, identifierTypeOptions, changeResourceElementState, setter, disabledSystems) => {
  let buttonText = "+ Add Identifier";
  if (stateElement === "relatedIdentifiers") {
    buttonText = "+ Add Related Identifier"
  }
  return <><Table>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell style={editMode ? { width: "75%" } : { width: "30%" }}>Identifier System</Table.HeaderCell>
        <Table.HeaderCell style={editMode ? { width: "25%" } : { width: "70%" }}>Identifier Value</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>{identifiers.map((identifier, identifierKey) => {
      return <Table.Row key={identifierKey}>
        {generateIdentifierSystemCell(identifier, identifierKey, parentElement, parentIndex, stateElement, editMode, systemLookup, displayLookup, identifierTypeOptions, changeResourceElementState, setter, disabledSystems)}
        {generateIdentifierValueCell(identifier, identifierKey, parentElement, parentIndex, stateElement, editMode, changeResourceElementState, setter, disabledSystems)}
      </Table.Row>;
    })
    }
    </Table.Body>
  </Table>
    <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content={buttonText} onClick={() => {
      addResourceElementState(setter, parentElement, parentIndex, stateElement, {});
    }} />}</span>
  </>
}

const DeleteRowButton = ({ state, stateSetter, stateEntryKey, index, stateSubEntryKey, subIndex }) => {
  let characteristicString = JSON.stringify(Object.fromEntries(Object.entries(state[stateEntryKey][index][stateSubEntryKey][subIndex]).filter(([_, v]) => v != "")), null, 2);
  return (
    <Modal
      trigger={<span className={"unselectable"} style={{ cursor: "pointer", fontWeight: "normal", fontSize: "42px" }} title="Remove this entire row">🗑</span>}
      header='Are you sure you want to delete the Characteristic Row?'
      centered={false}
      content={<div style={{ paddingLeft: "20px" }}>
        {characteristicString && <span><b>Delete: </b><span style={{ whiteSpace: "pre-wrap" }}>{characteristicString}</span></span>}
      </div>}
      actions={[
        {
          key: 'delete',
          positive: false,
          content: 'Delete',
          onClick: () => {
            stateSetter(prevState => {
              let newEntry = prevState[stateEntryKey];
              newEntry[index][stateSubEntryKey].splice(subIndex, 1);
              return {
                ...prevState,
                [stateEntryKey]: newEntry,
              };
            });
          }
        },
        {
          key: 'done',
          content: 'Close',
          negative: true
        }
      ]}
    />
  )
}

const getResourceComments = async (globalContext, fhirEntryState, resourceId) => {
  let comments = [];
  let votes = [];
  let myvotes = [];
  let body = {
    'functionid': 'getresourcecommentsjson',
    'idToken': '',
    'foi': resourceId,
    'commentids': []
  };
  let makeServerCall = false;
  if (fhirEntryState.commentsdict) {
    body['commentids'] = fhirEntryState.commentsdict[fhirEntryState.conceptCode];
    if (body?.commentids?.length > 0) {
      makeServerCall = true;
    }
  }

  if (makeServerCall) {
    let response = { "success": false };
    response = await submitToFevirServer(globalContext, 150000, body, true, false);
    if (response?.success) {
      comments = response.comments;
    }
  }
  myvotes = fhirEntryState.myvotesdict[fhirEntryState.conceptCode];

  let returningdata = { comments: comments, myvotes: myvotes };
  if (fhirEntryState.votesdict) {
    votes = fhirEntryState.votesdict[fhirEntryState.conceptCode];
    returningdata["votes"] = votes;
  }
  return returningdata;
}

const loadUsageView = async (resourceType, resourceId, globalContext, fhirEntryState, setFhirEntryState) => {
  let editors = [];
  let inviteNotCreatedYet;
  let inviteCode;

  if (fhirEntryState.adminpermission) {
    let multipleFunctionBody = {
      'functionid': 'multiple',
      'idToken': "",
      'functions': []
    };

    let resourceEditorsBody = {
      'functionid': 'getresourceeditors',
      'idToken': "",
      'resourceid': resourceId
    };
    multipleFunctionBody.functions.push(resourceEditorsBody);

    let inviteCodeBody = {
      'functionid': 'getinvitecodeforresource',
      'idToken': "",
      'resourceid': resourceId
    };
    multipleFunctionBody.functions.push(inviteCodeBody);

    let multipleResponse = await submitToFevirServer(globalContext, 10000, multipleFunctionBody, true, false);
    if (multipleResponse?.success && multipleResponse.multiplereturns) {
      let editorsResponse = multipleResponse.multiplereturns[0].returningdata;
      let inviteCodeResponse = multipleResponse.multiplereturns[1].returningdata;
      if (editorsResponse?.success) {
        editors = editorsResponse.users;
      }

      if (inviteCodeResponse?.success) {
        if (inviteCodeResponse.invitenotcreatedyet) {
          inviteNotCreatedYet = true;
        } else if (inviteCodeResponse.invitecode) {
          inviteCode = inviteCodeResponse.invitecode;
        }
      }
    }
  }

  let projectresources = [];
  let responseProjects = { "success": false };
  let projectsChecked = {};
  if (resourceType === "Project") {
    projectresources = await getProjectResourcesList(resourceId, false, globalContext);
  } else {
    let bodyProjects = {
      'functionid': 'getresourceprojects',
      'idToken': "",
      'resourceid': resourceId
    };
    responseProjects = await submitToFevirServer(globalContext, 5000, bodyProjects, true, false);
    if (responseProjects?.success === false) {
    } else {
      for (let projectIndex in responseProjects?.projectids) {
        projectsChecked[responseProjects.projectids[projectIndex]] = true;
      }
    }
  }

  let body = {
    'functionid': 'getresourceusage',
    'idToken': "",
    'resourceid': resourceId
  };

  let response = await submitToFevirServer(globalContext, 5000, body, true, false);

  if (!response?.success) {
  } else {
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        totalviews: response.totalviews,
        totalusersviewed: response.totalusersviewed,
        totaledits: response.totaledits,
        totalusersedited: response.totalusersedited,
        editors: editors,
        inviteNotCreatedYet: inviteNotCreatedYet,
        inviteCode: inviteCode,
        projectsContain: responseProjects.projectsinfo,
        projectsContainIds: responseProjects.projectids,
        projectsChecked: projectsChecked,
        projectResources: projectresources,
        projectResourcesLoaded: true,
        submittingToServer: false
      };
    });
  }
}

const createInviteLink = async (resourceId, globalContext, setFhirEntryState) => {
  setFhirEntryState(prevState => {
    return {
      ...prevState,
      creatingInvite: true
    };
  });
  let body = {
    'functionid': 'getinvitecodeforresource',
    'createinvitelink': true,
    'idToken': "",
    'resourceid': resourceId
  };
  let inviteCodeResponse = await submitToFevirServer(globalContext, 20000, body, true, false);
  if (inviteCodeResponse?.success && inviteCodeResponse.invitecode) {
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        inviteNotCreatedYet: false,
        inviteCode: inviteCodeResponse.invitecode,
        creatingInvite: false
      };
    });
  }
  /* Tells server to create entry in invite table (AFTER VALIDATING USER AND MAKING SURE AN ENTRY DOESN'T EXIST) and then write to the FHIR ENTRY STATE the inviteCode */
}

const revokeInviteLink = async (resourceId, inviteCode, globalContext, setFhirEntryState) => {
  let body = {
    'functionid': 'revokeinvitecodeforresource',
    'createinvitelink': true,
    'idToken': "",
    'resourceid': resourceId,
    'invitecode': inviteCode,
  };
  let revokeResponse = await submitToFevirServer(globalContext, 20000, body, true, false);
  if (revokeResponse?.success) {
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        inviteNotCreatedYet: true,
        inviteCode: undefined
      };
    });
  } else {
    alert("Trouble revoking invite code");
  }
}

const recursiveChange = (prevElement, elementKeyEntry, elementKeySubEntry, indexPath, value, elementKeySubSubEntry, subIndex) => {
  let element = prevElement;
  for (let index of indexPath) {
    element = element[elementKeyEntry][index];
  }
  if (elementKeySubSubEntry) {
    if (subIndex !== undefined) {
      element[elementKeySubSubEntry][subIndex][elementKeySubEntry] = value;
    } else {
      element[elementKeySubSubEntry][elementKeySubEntry] = value;
    }
  } else {
    element[elementKeySubEntry] = value;
  }
  return prevElement;
}
//NOTE: jsonErrorCheck2 will replace jsonErrorCheck as all the Resource Builders shift to the new platform model
//The difference is the handling of Resource.url changes -- shifting from "not applied" to 'handle as seeting the canonical URL'
const jsonErrorCheck = (resourceType, resourceId, startingVersionId, startingJson, fhirJsonNotUsed, citationURL) => {
  let fhirJson;
  try {
    if (typeof startingJson === 'string') {
      fhirJson = JSON.parse(startingJson);
    } else if (typeof startingJson === 'object') {
      fhirJson = startingJson;
    } else {
      alert("Invalid JSON");
      return false;
    }
  } catch {
    alert("Invalid JSON");
    return false;
  }
  //Warnings if the JSON is missing a resourceType
  if (!("resourceType" in fhirJson) || fhirJson.resourceType.trim() === "") {
    alert("resourceType field is missing from JSON (jsonErrorCheck");
    return false;
  }
  if (("id" in fhirJson && fhirJson.id !== resourceId) ||
    ((resourceType !== "CodeSystem" || resourceType !== "StructureDefinition") &&
      "url" in fhirJson &&
      fhirJson.url !== "https://fevir.net/resources/" + resourceType + "/" + resourceId) ||
    (citationURL && citationURL !== "https://fevir.net/resources/" + resourceType + "/" + resourceId) ||
    (startingVersionId != 0 && startingVersionId != "0" && fhirJson.meta?.versionId &&
      fhirJson.meta.versionId.toString() !== startingVersionId.toString() &&
      fhirJson.meta.versionId.toString() !== (parseInt(startingVersionId) + 1).toString()) ||
    (fhirJson.resourceType.trim() !== resourceType)
  ) {
    let alertMessage = "You edited the id, url, meta.versionId, or resourceType element in the JSON. These elements are resource-specific data for automated management. You can continue to update this resource however these elements will be reverted to the current values. Do you wish to submit the resource?";
    if (resourceType === "CodeSystem" || resourceType === "StructureDefinition") {
      alertMessage = "You edited the id, meta.versionId, or resourceType element in the JSON. These elements are resource-specific data for automated management. You can continue to update this resource however these elements will be reverted to the current values. Do you wish to submit the resource?";
    }
    if (window.confirm(alertMessage)) {
    } else {
      return false;
    }
  }
  return fhirJson;
}

const jsonErrorCheck2 = (resourceType, resourceId, startingVersionId, startingJson) => {
  let fhirJson;
  try {
    if (typeof startingJson === 'string') {
      fhirJson = JSON.parse(startingJson);
    } else if (typeof startingJson === 'object') {
      fhirJson = startingJson;
    } else {
      alert("Invalid JSON");
      return false;
    }
  } catch {
    alert("Invalid JSON");
    return false;
  }
  //Warnings if the JSON is missing a resourceType
  if (!("resourceType" in fhirJson) || fhirJson.resourceType.trim() === "") {
    alert("resourceType field is missing from JSON (jsonErrorCheck2)");
    return false;
  }
  if (("id" in fhirJson && fhirJson.id !== resourceId) ||
    (startingVersionId != 0 && startingVersionId != "0" && fhirJson.meta?.versionId &&
      fhirJson.meta.versionId.toString() !== startingVersionId.toString() &&
      fhirJson.meta.versionId.toString() !== (parseInt(startingVersionId) + 1).toString()) ||
    (fhirJson.resourceType.trim() !== resourceType)
  ) {
    let alertMessage = "You edited the id, meta.versionId, or resourceType element in the JSON. These elements are resource-specific data for automated management. You can continue to update this resource however these elements will be reverted to the current values. Do you wish to submit the resource?";
    if (window.confirm(alertMessage)) { } else { return false; }
  }
  if ("url" in fhirJson && fhirJson.url !== "https://fevir.net/gradeontology" && fhirJson.url !== "https://fevir.net/sevco" &&
    fhirJson.url !== "https://fevir.net/resources/" + resourceType + "/" + resourceId) {
    let alertMessage = "You edited the url. This should only be done for the purpose of setting a canonical URL for this Resource. Do you want to proceed with this change?";
    if (window.confirm(alertMessage)) { } else { return false; }
  }
  return fhirJson;
}

const convertBooleanToString = (bool) => {
  let stringValue = "";
  if (bool !== undefined) {
    if (bool === true) {
      stringValue = "True";
    } else if (bool === false) {
      stringValue = "False";
    }
  }
  return stringValue
}

const sendFli = async (fli, globalContext, history, hideAlert) => {

  const body = {
    'functionid': "getfoibyfli",
    'idToken': "",
    'fli': fli,
  };

  let response = await submitToFevirServer(globalContext, 5000, body, true, false);

  if (response.success) {
    if (history) {
      history.push(`/resources/${response.resourcetype}/${response.formstateid}`);
    }
    return [response.formstateid, response.resourcetype];
  } else {
    body.fli = body.fli.trim();
    let newresponse = await submitToFevirServer(globalContext, 5000, body, true, false);
    if (newresponse.success) {
      if (history) {
        history.push(`/resources/${newresponse.resourcetype}/${newresponse.formstateid}`);
      }
      return [newresponse.formstateid, newresponse.resourcetype];
    } else {
      if (!hideAlert) {
        alert("A resource by that FEvIR Linking Identifier does not exist, or you're not allowed to view it.\n\n" + fli);
      }
      return false;
    }
  }
};

const votePermissionCheck = async (firebase, globalContext, resourceId, fhirEntryState, setFhirEntryState) => {
  let idToken;
  if (fhirEntryState.votePermission === undefined) {
    try {
      if (globalContext.userState?.idToken) {
        idToken = globalContext.userState.idToken;
      } else {
        let user = firebase.auth().currentUser;
        await user.getIdToken(true).then(async (storedIdToken) => { idToken = storedIdToken });
      }
    } catch (e) { }

    if (idToken !== undefined) {
      const body = {
        'functionid': 'votepermissioncheck',
        'idToken': '',
        'aboutformstateid': resourceId
      };
      let response;
      try {
        response = await submitToFevirServer(globalContext, 50000, body, true, false);
      } catch (e) { }
      if (response?.votepermission === true) {
        setFhirEntryState(prevState => {
          return {
            ...prevState,
            votePermission: true
          };
        });
        return true;
      }
    }
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        votePermission: false
      };
    });
    return false
  }
}

const getCodeableConceptsDisplay = (codeableConceptsJson) => {
  if (!Array.isArray(codeableConceptsJson)) {
    codeableConceptsJson = [codeableConceptsJson];
  }
  let displayStringArray = [];
  let displayArrayArray = [];
  for (let codeableConceptIndex in codeableConceptsJson) {
    let codeableConcept = codeableConceptsJson[codeableConceptIndex];
    let displayEntry = [];
    for (let codingIndex in codeableConcept.coding) {
      let coding = codeableConcept.coding[codingIndex];
      if (coding.display) {
        displayEntry.push(coding.display);
      } else if (coding.code) {
        displayEntry.push(coding.code);
      }
    }
    if (codeableConcept.text) {
      displayEntry.push(codeableConcept.text);
    }
    displayArrayArray.push(displayEntry);
    displayStringArray.push(displayEntry.join(", "));
  }
  //displayArrayArray
  return displayStringArray.join("; "); //returns a string
}

const getContainedResources = (fhirJson) => {
  let containedResources = {};
  for (let containedIndex in fhirJson.contained) {
    let containedResource = fhirJson.contained[containedIndex];
    if (containedResource.id) {
      containedResources[containedResource.id] = {
        "index": containedIndex,
        "json": containedResource
      }
    }
  }
  return containedResources;
}

const extractResourcesFromBundleEntry = (entry, resourcesArray) => {
  if (!resourcesArray) {
    resourcesArray = [];
  }
  let resource = entry.resource;
  if (resource && resource.resourceType) {
    if (resource.resourceType === "Bundle") {
      if (resource.entry) {
        for (const entry of resource.entry) {
          resourcesArray = extractResourcesFromBundleEntry(entry, resourcesArray);
        }
      }
    } else {
      resourcesArray.push(resource)
    }
  }
  return resourcesArray
}

const submitBulkResources = async (globalContext, history, fhirEntryState, silent) => {
  let fhirJson;
  let submit = true;
  let resourceSetToSubmit = [];
  try {
    if (typeof (fhirEntryState.fhirBulkEntry) === "string") {
      fhirJson = JSON.parse(fhirEntryState.fhirBulkEntry);
    } else {
      fhirJson = fhirEntryState.fhirBulkEntry;
    }
    try {
      if (!Array.isArray(fhirJson) && fhirJson["resourceType"] && typeof fhirJson["resourceType"] === 'string') {
        if (fhirJson["resourceType"] === "Bundle") {
          if (Array.isArray(fhirJson.entry) && fhirJson.entry.length > 0) {
            let resourcesArray = [];
            for (const entry of fhirJson.entry) {
              resourcesArray = extractResourcesFromBundleEntry(entry, resourcesArray);
            }
            fhirJson = resourcesArray;
          } else {
            alert("This is the JSON Bulk Entry page. It does not work with an improperly structured Bundle.");
            submit = false;
          }
        } else {
          alert("This is the JSON Bulk Entry page. It's to be used only for multiple entries.");
          submit = false;
        }
      }
    } catch (e) { }
    if (globalContext.userState.id !== 15 && globalContext.userState.id !== 16 && globalContext.userState.id !== 31 && globalContext.userState.id !== 77 && Array.isArray(fhirJson) && fhirJson.length > 1000) {
      alert("You're not allowed to submit more than 1,000 resources at a time in the JSON Bulk Entry.");
      submit = false;
    } else if (globalContext.userState.id !== 15 && globalContext.userState.id !== 16 && globalContext.userState.id !== 31 && globalContext.userState.id !== 77 && Object.keys(fhirJson).length > 1000) {
      alert("You're not allowed to submit more than 1,000 resources at a time in the JSON Bulk Entry.");
      submit = false;
    } else {
      for (let x in fhirJson) {
        let resource = fhirJson[x];
        if (resource.resourceType) {

        } else {
          alert("Resource type is missing. Please fix before submitting.");
          submit = false;
          break;
        }
        let title = "";
        if (resource.title && typeof resource.title === "string" && resource.title.trim()) {
          title = resource.title;
        } else if (resource.name) {
          if (typeof resource.name === "string" && resource.name.trim()) {
            title = resource.name;
          } else if (Array.isArray(resource.name) && resource.name.length > 0 && resource.name[0].text && resource.name[0].text.trim() !== "") {
            title = resource.name[0].text;
          }
        }
        if (title === "") {
          title = resource.resourceType + " submitted as Resource " + (parseInt(x) + 1) + " of " + fhirJson.length;
        }
        let resourceEntry = { "fhirJson": resource, "title": title };
        resourceSetToSubmit.push(resourceEntry);
      }
    }
  } catch {
    submit = false;
    alert("Improper JSON format");
  }
  //Warnings if the JSON is missing a resourceType or title field

  if (submit) {
    let projectFoi = fhirEntryState.projectFoi;
    if (projectFoi) {
      projectFoi.replace("e", "");
      projectFoi.replace(" ", "");
    }
    const body = {
      'functionid': 'bulksubmitfhirresource',
      'idToken': '',
      'resourceSet': JSON.stringify(resourceSetToSubmit, null, 2),
      'tool': fhirEntryState.tool,
      'associatedProjectFoi': projectFoi,
      'title': "",
      'status': fhirEntryState.status,
    };

    let response = await submitToFevirServer(globalContext, 900000, body, true, false); //15 minutes timeout
    if (response?.success) {
      if (response.warnings?.length > 0) {
        console.log(response.warnings.join(", "));
      }
      if (projectFoi && !silent) {
        alert("All resources successfully submitted.");
      } else if (!silent) {
        alert("All resources successfully submitted.");
      }
      if (response.redirecttoproject && history) {
        history.push(`/resources/Project/${response.redirecttoproject}`);
      } else if (projectFoi) {
        alert("Not a valid Project or you don't have permission.");
        return false;
      }
      return response.fois;
    } else {
      alert("Problem submitting resources.");
      return false;
    }
  } else {
    //console.log("There's a problem with the JSON format. It needs to be an array of resources.")
  }
}

const submitResource = async (globalContext, history, fhirEntryState, fromNewResourceEntry) => {
  const submittingResource = async (title) => {
    if (title !== null && title !== undefined) {
      globalContext.setGlobalLoadingState(true);
      const body = {
        'functionid': 'submitfhirresource',
        'idToken': '',
        'fhirEntry': fhirEntryState.fhirEntry,
        'title': title,
        'status': fhirEntryState.status,
      };

      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response?.success) {
        if (fromNewResourceEntry) {
          history.push(`/resources/${response.resourcetype}/${response.formstateid}/edit`);
        } else {
          history.push(`/resources/${response.resourcetype}/${response.formstateid}`);
        }
      }
      globalContext.setGlobalLoadingState(false);
    }
  }
  /*
  let idToken;
  if (userState?.idToken) {
    idToken = userState.idToken;
  } else {
    let user = firebase.auth().currentUser;
    await user.getIdToken(true).then( async (storedIdToken) => { idToken = storedIdToken });
  }*/

  let fhirJson;
  try {
    if (fhirEntryState.fhirEntry) {
      if (typeof fhirEntryState.fhirEntry === "string") {
        fhirJson = JSON.parse(fhirEntryState.fhirEntry);
      } else {
        fhirJson = fhirEntryState.fhirEntry;
      }
    } else {  //else if fhirEntryState.fhirEntryString
      alert("Invalid JSON");
    }
  } catch {
    alert("Invalid JSON");
    return;
  }
  let title = "";
  //Warnings if the JSON is missing a resourceType or title field
  if (!("resourceType" in fhirJson) || fhirJson.resourceType.trim() === "") {
    alert("resourceType field is missing from JSON.");
    return;
  } else {
    if (fhirJson.title && typeof fhirJson.title === "string" && fhirJson.title.trim()) {
      title = fhirJson.title;
    } else if (fhirJson.name) {
      if (typeof fhirJson.name === "string" && fhirJson.name.trim()) {
        title = fhirJson.name;
      } else if (Array.isArray(fhirJson.name) && fhirJson.name.length > 0 && fhirJson.name[0].text && fhirJson.name[0].text.trim() !== "") {
        title = fhirJson.name[0].text;
      }
    }
    if (title === "") {
      //alert("title or name field is missing from JSON");
      //title = prompt("title or name field is missing from JSON. Do you want to enter a title for the FEvIR system? (Won't be put into the JSON)\nPlease enter in a title below:", "");
      globalContext.openPrompt({ "header": "title or name field is missing from JSON", "content": "Do you want to enter a title for the FEvIR system? (Won't be put into the JSON)\nPlease enter in a title below:", "runFunction": submittingResource, "inputLabel": "Title" });
      //submittingResource(title);
      return;
    }
  }
  submittingResource(title);
};

const FHIRtoCTgovConverter = async (changeFormState, history, globalContext, resourceId) => {
  let body = {
    'functionid': 'convertresearchstudytoctgov',
    'idToken': '',
    'researchStudyId': resourceId
  };
  let response = await submitToFevirServer(globalContext, 290000, body, false, false);
  if (response?.success) {
    changeFormState(response.warningMessages, "warningMessages");
    changeFormState(response.CTgovJSON, "CTgovJSONString");
    //changeFormState(response.ResearchStudyIdCTgov, "ResearchStudyIdCTgov");
    //alert(response);
    history.push(`/fhirtoctgovjson`);
  } else {
    alert("Error converting. Please let us know the ResearchStudy that it failed on.")
  }
}

const getRobatValueSetResourcesData = async (globalContext) => {
  let success = false;
  let codeSystems = [];
  let codeSystemsJson = {};
  let valueSetDictionary = {};
  let profileList = ["Base"];
  let profileDictionary = {};
  let profileOptionsList = [];

  const body = {
    'functionid': 'getvaluesetresources',
    'idToken': ''
  };

  await axios({
    method: 'POST',
    url: node_server_address,
    timeout: 10000,
    data: body
  }).then(async (resp) => {
    if (resp?.data?.success) {
      for (let resourceIndex in resp.data.resourceslist) {
        let resourceEntry = resp.data.resourceslist[resourceIndex];
        let resourceJson = JSON.parse(resourceEntry.toolstatestring);
        if (resourceJson.useContext) {
          let valueSetKey;
          let valueSetSystemAndCode;
          let valueSetProfile = "Base";
          let valueSetTermUseContext;
          let valueSetContent;
          let valueSetCodeSystem;
          let robatTool = false;
          for (let useContextIndex in resourceJson.useContext) {
            let useContext = resourceJson.useContext[useContextIndex];
            if (useContext.code && useContext.valueCodeableConcept) {
              if (useContext.code.display === "when this codeable concept is selected" && useContext.valueCodeableConcept.coding?.length > 0 && useContext.valueCodeableConcept.coding[0].code) {
                if (valueSetContent === undefined) {
                  //valueSetKey = useContext.valueCodeableConcept.coding[0].system+"#"+useContext.valueCodeableConcept.coding[0].code;
                  valueSetSystemAndCode = useContext.valueCodeableConcept.coding[0].system + "#" + useContext.valueCodeableConcept.coding[0].code;
                  if (valueSetTermUseContext === undefined) {
                    valueSetTermUseContext = useContext;

                  }
                }
              } else if (useContext.code.display === "when this profile is selected" && useContext.valueCodeableConcept.text) {
                valueSetProfile = useContext.valueCodeableConcept.text;
              } else if (useContext.code.display === "to be viewed by this tool" && useContext.valueCodeableConcept.text === "Computable Publishing: Risk of Bias Assessment Tool") {
                robatTool = true;
              }
            }
          }
          if (robatTool) {
            valueSetKey = valueSetSystemAndCode;
            if (valueSetProfile) {
              valueSetKey += "&" + valueSetProfile;
              if (!profileList.includes(valueSetProfile)) {
                profileList.push(valueSetProfile);
                profileDictionary[valueSetProfile] = resourceEntry.id;
              }
            }
            if (valueSetDictionary[valueSetKey] === undefined && valueSetTermUseContext !== undefined) {
              valueSetCodeSystem = valueSetTermUseContext.valueCodeableConcept.coding[0].system;
              valueSetContent = {
                "system": valueSetCodeSystem,
                "code": valueSetTermUseContext.valueCodeableConcept.coding[0].code,
                "display": valueSetTermUseContext.valueCodeableConcept.coding[0].display,
                "profile": valueSetProfile,
                "resource": resourceJson
              };
            } else {
              //valueSetKey = undefined;
              valueSetSystemAndCode = undefined;
            }
            if (valueSetKey && valueSetContent && valueSetTermUseContext) {
              if (valueSetCodeSystem && !codeSystems.includes(valueSetCodeSystem)) {
                codeSystems.push(valueSetCodeSystem);
              }
              valueSetDictionary[valueSetKey] = valueSetContent;
            }
          }
        }
      }
      for (let codeSystemIndex in codeSystems) {
        let codeSystemUri = codeSystems[codeSystemIndex];
        if (codeSystemUri.includes("https://fevir.net")) {
          let codeSystemId = codeSystemUri.substring(codeSystemUri.lastIndexOf("/") + 1);
          const codeSystemBody = {
            'functionid': 'getfhirresource',
            'resourceid': codeSystemId,
            'idToken': ""
          };

          let response = await submitToFevirServer(globalContext, 90000, codeSystemBody, true, false);
          if (response?.success && response.fhirjsonstring) {
            if (codeSystemsJson[codeSystemUri] === undefined) {
              codeSystemsJson[codeSystemUri] = JSON.parse(response.fhirjsonstring);
            }
          }
        }
      }
      /*
      for (let key in valueSetDictionary) {
        let system = valueSetDictionary[key].system;
        if (codeSystemsJson[system]) {
          //valueSetDictionary[key].definition
        }
      }*/
      profileList.sort();
      for (let profileIndex in profileList) {
        let profile = profileList[profileIndex];
        let profileText = profile;
        if (profileText === "Base") {
          profileText = "No profile selected";
        }
        profileOptionsList.push({ key: profile, text: profileText, value: profile });
      }
      success = true;
    }
  });
  return { success: success, codeSystems: codeSystems, codeSystemsJson: codeSystemsJson, valueSetDictionary: valueSetDictionary, profileDictionary: profileDictionary, profileList: profileList, profileOptionsList: profileOptionsList }
}

let needToRefreshSearchIndex;

const builderUpdateJson = async (resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext) => {
  if (fhirEntryState.editMode) {
    let fhirJson;
    let newClassificationJson;
    if (resourceType === "Project" && formInputsStateRef?.current?.projectState) {
      [fhirJson, newClassificationJson] = builderUpdateProjectJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "Citation" && formInputsStateRef?.current?.citationState) {
      fhirJson = await builderUpdateCitationJson(resourceId, fhirEntryState, formInputsStateRef, globalContext);
    } else if (resourceType === "ArtifactComment" && formInputsStateRef?.current?.commentRatingState) {
      fhirJson = builderUpdateCommentRatingJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "Group" && formInputsStateRef?.current?.groupState) {
      [fhirJson, newClassificationJson] = builderUpdateGroupJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "ValueSet" && formInputsStateRef?.current?.valueSetState) {
      [fhirJson, newClassificationJson] = builderUpdateValueSetJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "CodeSystem" && formInputsStateRef?.current?.codeSystemState) {
      [fhirJson, newClassificationJson] = builderUpdateCodeSystemJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "CodeSystem" && formInputsStateRef?.current?.codeSystemTermState) {
      fhirJson = builderUpdateCodeSystemTermDetailJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "EvidenceVariable" && formInputsStateRef?.current?.evidenceVariableState) {
      [fhirJson, newClassificationJson] = builderUpdateEvidenceVariableJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "EvidenceVariable" && formInputsStateRef?.current?.characteristicState) {
      fhirJson = builderUpdateEvidenceVariableCharacteristicDetailJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "SchemaElement" && formInputsStateRef?.current?.schemaElementState) {
      fhirJson = builderUpdateSchemaElementJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "ActivityDefinition" && formInputsStateRef?.current?.activityDefinitionState) {
      [fhirJson, newClassificationJson] = builderUpdateActivityDefinitionJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "Library" && formInputsStateRef?.current?.libraryState) {
      [fhirJson, newClassificationJson] = builderUpdateLibraryJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "List" && formInputsStateRef?.current?.listState) {
      [fhirJson, newClassificationJson] = builderUpdateListJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "Bundle" && formInputsStateRef?.current?.bundleState) {
      [fhirJson, newClassificationJson] = builderUpdateBundleJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "Measure" && formInputsStateRef?.current?.measureState) {
      [fhirJson, newClassificationJson] = builderUpdateMeasureJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "PlanDefinition" && formInputsStateRef?.current?.planDefinitionState) {
      [fhirJson, newClassificationJson] = builderUpdatePlanDefinitionJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "ResearchStudy" && formInputsStateRef?.current?.researchStudyState) {
      [fhirJson, newClassificationJson] = builderUpdateResearchStudyJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "Evidence" && formInputsStateRef?.current?.evidenceState) {
      [fhirJson, newClassificationJson] = builderUpdateEvidenceJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "SoftwareScript" && formInputsStateRef?.current?.softwareScriptState) {
      [fhirJson, newClassificationJson] = builderUpdateSoftwareScriptJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "Composition" && formInputsStateRef?.current?.compositionState) {
      [fhirJson, newClassificationJson] = builderUpdateCompositionJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "Composition" && formInputsStateRef?.current?.compositionDocumentState) {
      [fhirJson, newClassificationJson] = await builderUpdateCompositionDocumentJson(resourceId, fhirEntryState, formInputsStateRef, globalContext);
    } else if (resourceType === "Composition" && formInputsStateRef?.current?.sectionState) {
      fhirJson = builderUpdateCompositionSectionDetailJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "Characteristic" && formInputsStateRef?.current?.characteristicResourceState) {
      [fhirJson, newClassificationJson] = builderUpdateCharacteristicResourceJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "ArtifactAssessment" && formInputsStateRef?.current?.classificationState) {
      [fhirJson, newClassificationJson] = builderUpdateClassificationJson(resourceId, fhirEntryState, formInputsStateRef);
      needToRefreshSearchIndex = true;
    } else if (resourceType === "ArtifactAssessment" && formInputsStateRef?.current?.commentState) {
      [fhirJson, newClassificationJson] = builderUpdateCommentJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "ArtifactAssessment" && (formInputsStateRef?.current?.adaptationState || formInputsStateRef?.current?.comparisonState)) {
      [fhirJson, newClassificationJson] = builderUpdateAdaptationJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "ArtifactAssessment" && formInputsStateRef?.current?.adaptationContentState) {
      fhirJson = builderUpdateAdaptationContentJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "ArtifactAssessment" && formInputsStateRef?.current?.adaptationDetailState) {
      fhirJson = builderUpdateAdaptationDetailJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "ArtifactAssessment" && formInputsStateRef?.current?.recommendationJustificationState) {
      [fhirJson, newClassificationJson] = builderUpdateRecommendationJustificationJson(resourceId, fhirEntryState, formInputsStateRef);
    } else if (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource) {
      setFhirEntryState(prevState => { return { ...prevState, loading: true }; });
      fhirJson = await builderUpdateRobatJson(resourceId, fhirEntryState, formInputsStateRef, globalContext);
      if (fhirJson === false) {
        setFhirEntryState(prevState => { return { ...prevState, loading: false }; });
      }
    } else {
      fhirJson = jsonErrorCheck(resourceType, resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString, fhirEntryState.fhirJson, "");
    }
    if (newClassificationJson) {
      needToRefreshSearchIndex = true;
      //submitClassification to formstatetable
      let targetFoi = "";
      let title = getStringFromFHIR.CodeableConcept(newClassificationJson.content[0].type) + " Classifier of " + getStringFromFHIR.Reference(newClassificationJson.artifactReference);
      const classificationBody = {
        'functionid': 'submitfhirresource',
        'idToken': '',
        'fhirEntry': JSON.stringify(newClassificationJson),
        'title': title,
        'status': 'active',
        'aboutformstateid': fhirJson.id,
        'targettitle': fhirJson.title || fhirJson.name || "Untitled " + fhirJson.resourceType
      };

      let response = await submitToFevirServer(globalContext, 5000, classificationBody, true, false);
      if (response?.success) {
        targetFoi = response.formstateid;
      }
    };
    if (fhirJson === false) {
      setFhirEntryState(prevState => {
        return {
          ...prevState,
          submittingToServer: false,
          needToRefreshSearchIndex: needToRefreshSearchIndex
        };
      });
      return;
    } else {
      let fhirEntryString = JSON.stringify(fhirJson, null, 2);
      if (fhirEntryString !== fhirEntryState.fhirEntryString) {
        //changeFhirEntryState(fhirEntryString, "fhirEntryString");
        //changeFhirEntryState(true, "fhirEntryStringChange");

        setFhirEntryState(prevState => {
          return {
            ...prevState,
            fhirEntryString: fhirEntryString,
            fhirEntryStringChange: true,
            submittingToServer: false,
            needToRefreshSearchIndex: needToRefreshSearchIndex
          };
        });
      }
      return fhirEntryString;
    }
  } else {
    //} else if (fhirEntryState.fhirEntryString) {
    //return JSON.stringify(JSON.parse(fhirEntryState.fhirEntryString));
    return "";
  }
}

const submitUpdatedFhirResource = async (statusSelect, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState,
  globalContext, history, changeFormState, removeHash, addToast) => {

  let title = fhirEntryState.title;
  let status = fhirEntryState.status;
  if (statusSelect) {
    status = statusSelect;
  }
  let workingBlob;
  try {
    workingBlob = (await builderUpdateJson(resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext)) || JSON.stringify(JSON.parse(fhirEntryState.fhirEntryString));
  } catch { }
  if (workingBlob) {
    let workingBlobJson = JSON.parse(workingBlob);
    if (workingBlobJson.title && typeof workingBlobJson.title === "string" && workingBlobJson.title.trim()) {
      title = workingBlobJson.title;
    } else if (workingBlobJson.name) {
      if (typeof workingBlobJson.name === "string" && workingBlobJson.name.trim()) {
        title = workingBlobJson.name;
      } else if (Array.isArray(workingBlobJson.name) && workingBlobJson.name.length > 0 && workingBlobJson.name[0].text && workingBlobJson.name[0].text.trim() !== "") {
        title = workingBlobJson.name[0].text;
      }
    }
    let body = {
      'functionid': 'updatefhirresource',
      'idToken': '',
      'fhirEntry': workingBlob,
      'resourcetype': resourceType,
      'resourceid': resourceId,
      'title': title,
      'status': workingBlobJson.status || status,
      'bypasswarnings': false
    };
    if (workingBlobJson.artifactReference) {
      try {
        let aboutformstateid = workingBlobJson.artifactReference.reference.split('/')[1];
        body.aboutformstateid = aboutformstateid;
        let targettitle = "Untitled Resource";
        if (workingBlobJson.artifactReference.type) {
          targettitle = "Untitled " + workingBlobJson.artifactReference.type;
        }
        if (workingBlobJson.artifactReference.display) {
          targettitle = workingBlobJson.artifactReference.display;
        }
        body.targettitle = targettitle;
      } catch {
        alert("The Classification.artifactReference.reference is not suitable for the Classification Index.");
      }
    }
    let response = await submitToFevirServer(globalContext, 120000, body, false, false);
    //To refresh the page (without reloading the website)
    if (response?.success) {
      if (addToast) {
        addToast('The resource has been updated.', { appearance: 'success' });
      }
      /* TODO - check why this is not working
      if (fhirEntryState.needToRefreshSearchIndex) {
        submitToFevirServer(globalContext, 60000, { functionid: 'refreshsearchindex' }, true, false);
        setFhirEntryState(prevState => {return {...prevState, needToRefreshSearchIndex: false}});
      }
      */
      history.push("/");
      if ((resourceType === "EvidenceVariable" || resourceType === "CodeSystem" || (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource)) && fhirEntryState.activeIndex === 1) {
        changeFormState(false, "hashLoaded");
        let hash;
        if (fhirEntryState.conceptCode) {
          hash = fhirEntryState.conceptCode;
        } else if (fhirEntryState.conceptPathIndexes?.length > 0) {
          hash = fhirEntryState.conceptPathIndexes.toString();
        }
        if (fhirEntryState.termToNavigateTo) {
          hash = fhirEntryState.termToNavigateTo;
        }
        if (hash && removeHash !== true) {
          history.push(`/resources/${resourceType}/${resourceId}#${hash}`);
        } else {
          history.push(`/resources/${resourceType}/${resourceId}`);
        }
      } else {
        history.push(`/resources/${resourceType}/${resourceId}`);
      }
      return true;
    } else {
      if (response?.warningMessage) {
        if (window.confirm(response.warningMessage)) {
          body["bypasswarnings"] = true;
          let response = await submitToFevirServer(globalContext, 120000, body, false, false);
          if (response?.success) {
            history.push("/");
            if ((resourceType === "EvidenceVariable" || resourceType === "CodeSystem" || (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource)) && fhirEntryState.activeIndex === 1) {
              changeFormState(false, "hashLoaded");
              let conceptCode = fhirEntryState.conceptCode;
              if (fhirEntryState.termToNavigateTo) {
                conceptCode = fhirEntryState.termToNavigateTo;
              }
              if (conceptCode) {
                history.push(`/resources/${resourceType}/${resourceId}#${conceptCode}`);
              } else {
                history.push(`/resources/${resourceType}/${resourceId}`);
              }
            } else {
              history.push(`/resources/${resourceType}/${resourceId}`);
            }
          } else {
            alert("Changes weren't saved.");
          }
        }
      }
    }
  } else {
    return false;
  }
}

const joinGroup = async (resourceId, globalContext) => {
  if (globalContext.userState.id) {
    const body = {
      'functionid': 'joinvotingpermissiongroup',
      'idToken': '',
      'aboutformstateid': resourceId
    };
    let response;
    try {
      response = await submitToFevirServer(globalContext, 100000, body, true, false);
    } catch (e) { }
    if (response === undefined || response === false || response.success === false) {
      if (response.error) {
        alert(response.error);
        return { "success": false };
      } else {
        alert("Joining the group was unsuccessful.");
        return { "success": false };
      }
    } else {
      let emailMessage = "We don't have a notification email on file for you. Please visit your user profile by clicking your name in the upper right corner and add one. Otherwise, you won't receive a notification when terms are ready for vote.";
      if (globalContext.userState.notificationemail) {
        emailMessage = `Your email on record is: ${globalContext.userState.notificationemail} which we will use to send you notices when terms are ready for vote. If you want to change your email address used for notification, please edit your user profile by clicking your name in the upper right corner.`;
      }
      alert(`Welcome to the ${response.groupname}! ${emailMessage}`);
      return { "success": true };
    }
  } else {
    alert("Please login to join the group.");
    return { "success": false };
  }
}

const addToProject = async (globalContext, resourceId, projectsChecked) => {
  let projects = [];
  for (let projectId in projectsChecked) {
    //if (project canedit)
    projects.push({ 'projectid': projectId, 'projectchecked': projectsChecked[projectId] }); //[{projectid: 4, projectchecked: false}]
  }
  let body = {
    'functionid': 'updateresourceprojects',
    'idToken': '',
    'formstateid': resourceId,
    'projects': projects,  //All the projects viewable to the user, and whether they're checked or unchecked
  };
  let response = await submitToFevirServer(globalContext, 5000, body, false, false);
  return (response.success);
}
const getValueSetCodesAsOptions = async (valueSetId, globalContext) => {
  let valueSetOptions = [];
  let valueSetOptionsLookup = {};
  const body = {
    'functionid': 'getfhirresource',
    'resourceid': valueSetId,
    'resourcetype': "ValueSet",
    'idToken': ""
  };
  let response = await submitToFevirServer(globalContext, 5000, body, true, false);
  if (response?.success && response.fhirjsonstring) {
    let valueSetJson = JSON.parse(response.fhirjsonstring);
    if (valueSetJson.compose?.include?.length > 0) {
      for (let conceptIndex in valueSetJson.compose.include[0].concept) {
        let concept = valueSetJson.compose.include[0].concept[conceptIndex];
        concept["system"] = valueSetJson.compose.include[0].system;
        valueSetOptionsLookup[concept.code] = concept;
        valueSetOptions.push({
          key: concept.code,
          text: concept.display,
          value: concept.code,
        });
      }
    }
  }
  return [valueSetOptions, valueSetOptionsLookup];
}

const loadResourceVersionList = async (resourceid, globalContext) => {
  let body = {
    'functionid': 'getresourceversionlist',
    'idToken': "",
    'resourceid': resourceid
  };
  let response = await submitToFevirServer(globalContext, 60000, body, true, false);
  if (response?.success) {
    return response["resourceversionlist"];
  }
  return [];
}

const getVersionDifference = async (resourceid, newVersionNumber, oldVersionNumber, newHistoryId, oldHistoryId, justTheDifferences, globalContext) => {
  let body = {
    'functionid': 'getversiondifference',
    'idToken': "",
    'resourceid': resourceid,
    'versionnew': newVersionNumber,
    'versionold': oldVersionNumber,
    'versionnewid': newHistoryId,
    'versionoldid': oldHistoryId,
    'onlydifferences': justTheDifferences ?? true
  };
  let response = await submitToFevirServer(globalContext, 60000, body, true, false);
  if (response?.success) {
    //console.log(response["jsonDifferences"]);
    return response["jsonDifferences"];
  }
  return [];
}

const getHistoryVersionOfResource = async (resourceid, version, historyid, globalContext) => {
  let body = {
    'functionid': 'gethistoryversionofresource',
    'idToken': "",
    'resourceid': resourceid,
    'version': version,
    'historyid': historyid
  };
  let response = await submitToFevirServer(globalContext, 60000, body, true, false);
  if (response?.success) {
    return response;
  }
  return {};
}

const formatDate = (date) => {
  try {
    let dateSplit = date.replace(/\//g, '-').split("-");
    if (dateSplit[0].length === 4) {
      if (dateSplit.length >= 2 && monthLookup[dateSplit[1]]) {
        if (dateSplit.length === 3 && date.length <= 10 && date.length >= 8) {
          return monthLookup[dateSplit[1]] + " " + dateSplit[2] + ", " + dateSplit[0];
        } else if (dateSplit.length === 2) {
          return monthLookup[dateSplit[1]] + " " + dateSplit[0];
        }
      }
    }
  } catch (e) { }
  return date;
}


const getSectionLabel = (section, defaultLabel = "[Section With No Label]") => {
  let label = section.title || section.display;   //section.title or if section is actually a CodeSystem concept and it's concept.display
  if (!label) {
    if (section.code?.text) {
      return section.code.text;
    }
    if (section.code?.coding) {
      for (let codingEntry of section.code.coding) {
        label = codingEntry.display || codingEntry.code;
        if (label) {
          return label;
        }
      }
    }
  }
  return label || defaultLabel;
}

const walkingTheSections = (globalContext, prevState, path, runFunction, above = null, elementName = "section", reorganizeSectionsModalState = null, newValue = null) => {
  let sectionWalk = prevState;
  for (let index in path) {
    let pathIndex = path[index];
    if (parseInt(index) + 1 === path.length) {  //Meaning it's the last index in the path array
      if (!sectionWalk[elementName]) {
        sectionWalk[elementName] = [];
      }
      runFunction(globalContext, pathIndex, sectionWalk, reorganizeSectionsModalState, above, elementName, newValue);
    } else {
      sectionWalk = sectionWalk[elementName][pathIndex];
    }
  }
}

const markTheOldSectionLocation = (globalContext, pathIndex, sectionWalk, reorganizeSectionsModalState, above, elementName, newValue) => {
  sectionWalk[elementName][pathIndex]["DELETETHISAFTERMOVE"] = true;
}

const placeSection = (globalContext, pathIndex, sectionWalk, reorganizeSectionsModalState, above, elementName, newValue) => {
  let position = pathIndex + (above ? 0 : 1);
  sectionWalk[elementName] = sectionWalk[elementName].slice(0, position).concat(reorganizeSectionsModalState.movingSection, sectionWalk[elementName].slice(position));
}

const recursiveWalkToDelete = (sectionWalk, elementName = "section") => {
  for (let index in sectionWalk[elementName]) {
    let section = sectionWalk[elementName][index];
    if (section["DELETETHISAFTERMOVE"]) {
      sectionWalk[elementName].splice(index, 1);
      break;
    } else if (section[elementName]) {
      recursiveWalkToDelete(section, elementName);
    }
  }
}

const moveSectionToNewSection = (globalContext, moveToSectionPath, setResourceState, reorganizeSectionsModalState, above, elementName = "section") => {
  //reorganizeSectionsModalState.movingSection
  //set reorganizeSectionsModalState.newlyMovedPath  lastPathIndex + (above ? 0 : 1)
  //let position = lastPathIndex + (above ? 0 : 1);
  //let b = a.slice(0, position).concat("NEW", a.slice(position));
  setResourceState(prevState => {
    //reorganizeSectionsModalState.movingPath
    let newSection = prevState[elementName];
    walkingTheSections(globalContext, prevState, reorganizeSectionsModalState.movingPath, markTheOldSectionLocation, above, elementName, reorganizeSectionsModalState, null);
    walkingTheSections(globalContext, prevState, moveToSectionPath, placeSection, above, elementName, reorganizeSectionsModalState, null);
    recursiveWalkToDelete(prevState, elementName);
    return { ...prevState, [elementName]: newSection };
  });
}

const deleteSection = (globalContext, setResourceState, reorganizeSectionsModalState, setReorganizeSectionsModalState, currentSectionPath, elementName = "section") => {
  setResourceState(prevState => {
    let newSection = prevState[elementName];
    walkingTheSections(globalContext, prevState, currentSectionPath, deleteActionWithConfirm, null, elementName, reorganizeSectionsModalState, null);
    recursiveWalkToDelete(prevState, elementName);
    return { ...prevState, [elementName]: newSection, changesMade: true };
  });
  setReorganizeSectionsModalState(prevState => { return { ...prevState, changesMade: true } });
}

const relabelSection = (globalContext, setResourceState, reorganizeSectionsModalState, setReorganizeSectionsModalState, currentSectionPath, newValue, elementName = "section") => {
  setResourceState(prevState => {
    let newSection = prevState[elementName];
    walkingTheSections(globalContext, prevState, currentSectionPath, changeLabel, null, elementName, reorganizeSectionsModalState, newValue);
    return { ...prevState, [elementName]: newSection, changesMade: true };
  });
  setReorganizeSectionsModalState(prevState => { return { ...prevState, changesMade: true } });
}

const changeLabel = (globalContext, pathIndex, sectionWalk, reorganizeSectionsModalState, above, elementName, newValue) => {
  sectionWalk[elementName][pathIndex]["title"] = newValue;
}

const deleteAction = (values) => {
  let pathIndex = values[0];
  let sectionWalk = values[1];
  let elementName = values[2];
  sectionWalk[elementName].splice(pathIndex, 1);
}

const deleteActionWithConfirm = (globalContext, pathIndex, sectionWalk, reorganizeSectionsModalState, above, elementName, newValue = null) => {
  let confirmContent = "Do you wish to delete this section?";
  let label = getSectionLabel(sectionWalk[elementName][pathIndex], null);
  if (label) {
    confirmContent = "Do you wish to delete the section: \"" + label + "\"?";
  }
  if (sectionWalk[elementName][pathIndex][elementName]) {
    if (label) {
      confirmContent = "Do you wish to delete the section: \"" + label + "\" and all subsections of it?";
    } else {
      confirmContent = "Do you wish to delete this section and all subsections of it?";
    }
  }
  globalContext.openConfirmation({ "header": "Delete section", "content": confirmContent, "runFunction": deleteAction, "functionParameters": [pathIndex, sectionWalk, elementName], "buttonTextOkay": "Yes", "buttonTextCancel": "No" });
  //deleteAction([pathIndex, sectionWalk, elementName]);
}

const MoveSectionButton = ({ globalContext, moveToSectionPath, resourceState, setResourceState, reorganizeSectionsModalState, setReorganizeSectionsModalState, above, elementName }) => {
  return <Button style={{ color: "#000000", padding: "4px" }} className="formButton sectionReorganizeButton"
    content="Move Here" onClick={() => {
      moveSectionToNewSection(globalContext, moveToSectionPath, setResourceState, reorganizeSectionsModalState, above, elementName);
      if (!above) {
        if (!(reorganizeSectionsModalState.movingPath.slice(0, reorganizeSectionsModalState.movingPath.length - 1).toString() === moveToSectionPath.slice(0, moveToSectionPath.length - 1).toString() && reorganizeSectionsModalState.movingPath[reorganizeSectionsModalState.movingPath.length - 1] < moveToSectionPath[moveToSectionPath.length - 1])) {
          moveToSectionPath = JSON.parse(JSON.stringify(moveToSectionPath));
          moveToSectionPath[moveToSectionPath.length - 1] += 1;
        }
      }
      setReorganizeSectionsModalState(prevState => { return { ...prevState, moveMode: false, movingPath: undefined, newlyMovedPath: moveToSectionPath, newlyMovedLabel: getSectionLabel(prevState.movingSection), changesMade: true } });
    }} />
}

const LabelOrChangeLabel = ({ globalContext, currentSectionPath, elementName, label, sectionTitle, editSectionLabelMode, reorganizeSectionsModalState, setReorganizeSectionsModalState, setResourceState }) => {
  return <>
    {/*We use section.title instead of label for editing of it*/}
    {(editSectionLabelMode && sectionTitle && !reorganizeSectionsModalState.moveMode) ?
      <TextField style={{ width: "80%" }} multiline className="inputField" type='text' //label={"Title"}
        size="small" variant='outlined' value={sectionTitle}
        onChange={(e) => {
          relabelSection(globalContext, setResourceState, reorganizeSectionsModalState, setReorganizeSectionsModalState, currentSectionPath, e.target.value, elementName);
        }} />
      :
      <b>{label}</b>
    }
  </>
}

const RecursiveSectionView = ({ globalContext, json, resourceState, setResourceState, reorganizeSectionsModalState, setReorganizeSectionsModalState, sectionPath, elementName, editSectionLabelMode, deleteSectionsMode, parentBeingMoved }) => {
  return <div style={{ marginBottom: "12px" }}>
    {json[elementName].map((section, index) => {
      let currentSectionPath = sectionPath.concat(index);
      let label = getSectionLabel(section);
      let beingMoved = reorganizeSectionsModalState.movingPath?.toString() === currentSectionPath.toString();
      let justMoved = reorganizeSectionsModalState.newlyMovedPath?.toString() === currentSectionPath.toString() && reorganizeSectionsModalState.newlyMovedLabel === label;
      let allowDropHere = !beingMoved && reorganizeSectionsModalState.moveMode && currentSectionPath.length > 1 && !parentBeingMoved;

      return <div key={index}>
        {(index === 0 && allowDropHere) && <div><MoveSectionButton globalContext={globalContext} moveToSectionPath={currentSectionPath} resourceState={resourceState} setResourceState={setResourceState} reorganizeSectionsModalState={reorganizeSectionsModalState} setReorganizeSectionsModalState={setReorganizeSectionsModalState} above={true} elementName={elementName} /></div>}
        <div style={{ outline: "1px solid #CCCCCC", margin: "4px", padding: "2px", backgroundColor: beingMoved ? "#F8EC8B" : (justMoved && !reorganizeSectionsModalState.moveMode) && "#D5F6D5" }}>
          {(!reorganizeSectionsModalState.moveMode && !reorganizeSectionsModalState.deleteSectionsMode) &&
            <>{currentSectionPath.length > 1 && <Button positive style={{ color: "#FFFFFF", marginLeft: "6px", padding: "4px" }} className="formButton sectionReorganizeButton"
              content="Move" onClick={() => { setReorganizeSectionsModalState(prevState => { return { ...prevState, moveMode: true, movingPath: currentSectionPath, movingSection: JSON.parse(JSON.stringify(section)) } }); }} />}</>
          }
          <span>
            <LabelOrChangeLabel globalContext={globalContext} elementName={elementName} currentSectionPath={currentSectionPath} label={label} sectionTitle={section.title} editSectionLabelMode={editSectionLabelMode} reorganizeSectionsModalState={reorganizeSectionsModalState} setReorganizeSectionsModalState={setReorganizeSectionsModalState} setResourceState={setResourceState} />
          </span>
          {(reorganizeSectionsModalState.deleteSectionsMode && !reorganizeSectionsModalState.moveMode && currentSectionPath.length > 1) && <>
            &nbsp;&nbsp;&nbsp;<span className={"unselectable"} style={{ cursor: "pointer", fontWeight: "normal", fontSize: "32px", color: "#CC4444" }} title="Delete This Section" onClick={() => { deleteSection(globalContext, setResourceState, reorganizeSectionsModalState, setReorganizeSectionsModalState, currentSectionPath, elementName); }}><b>🗑</b></span>
          </>}
          <>{(reorganizeSectionsModalState.moveMode && beingMoved) &&
            <> MOVING... <Button negative style={{ color: "#FFFFFF", marginLeft: "6px", padding: "4px" }} className="formButton sectionReorganizeButton"
              content="Cancel Move" onClick={() => { setReorganizeSectionsModalState(prevState => { return { ...prevState, moveMode: false, movingPath: undefined } }); }} />
            </>}</>
          {(section?.[elementName]?.length > 0)
            ?
            <div style={{ marginLeft: "36px" }}><RecursiveSectionView globalContext={globalContext} json={section} resourceState={resourceState} setResourceState={setResourceState} reorganizeSectionsModalState={reorganizeSectionsModalState} setReorganizeSectionsModalState={setReorganizeSectionsModalState} sectionPath={currentSectionPath} elementName={elementName} editSectionLabelMode={editSectionLabelMode} deleteSectionsMode={deleteSectionsMode} parentBeingMoved={parentBeingMoved || beingMoved} /></div>
            :
            <>{(reorganizeSectionsModalState.moveMode && !beingMoved && !parentBeingMoved) && <div style={{ marginLeft: "36px" }}><MoveSectionButton globalContext={globalContext} moveToSectionPath={currentSectionPath.concat(0)} resourceState={resourceState} setResourceState={setResourceState} reorganizeSectionsModalState={reorganizeSectionsModalState} setReorganizeSectionsModalState={setReorganizeSectionsModalState} above={true} elementName={elementName} /></div>}</>
          }
        </div>
        {allowDropHere && <div><MoveSectionButton globalContext={globalContext} moveToSectionPath={currentSectionPath} resourceState={resourceState} setResourceState={setResourceState} reorganizeSectionsModalState={reorganizeSectionsModalState} setReorganizeSectionsModalState={setReorganizeSectionsModalState} above={false} elementName={elementName} /></div>}
      </div>
    })}
  </div>;
}

const ReorganizeSectionsModal = ({ resourceState, setResourceState, reorganizeSectionsModalState, setReorganizeSectionsModalState, update, elementName }) => {
  const globalContext = useContext(FevirContext);

  const modalClose = () => {
    setReorganizeSectionsModalState({ modalOpen: false });
  }

  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <div style={{ float: "right" }}>
      {reorganizeSectionsModalState.changesMade ?
        <div style={{ position: "absolute", right: "14px", zIndex: "10" }}>(Refresh the browser to cancel these changes)&nbsp;&nbsp;&nbsp;<Button className="formButton positive"
          style={{ padding: "6px 40px" }} content="Save and Update"
          onClick={() => { update(); }}
        /></div>
        :
        <>
          {(elementName === "section" && !reorganizeSectionsModalState.moveMode && !reorganizeSectionsModalState.editSectionLabelMode) && <>
            {!reorganizeSectionsModalState.deleteSectionsMode && <><Button style={{ color: "#000000", padding: "4px", marginRight: "40px" }} className="formButton sectionReorganizeButton"
              content="Choose a Section To Delete" onClick={() => {
                setReorganizeSectionsModalState(prevState => { return { ...prevState, deleteSectionsMode: true } });
              }} /></>}
            {(!reorganizeSectionsModalState.editSectionLabelMode && !reorganizeSectionsModalState.deleteSectionsMode) && <><Button style={{ color: "#000000", padding: "4px", marginRight: "40px" }} className="formButton sectionReorganizeButton"
              content="Edit Section Titles" onClick={() => {
                setReorganizeSectionsModalState(prevState => { return { ...prevState, editSectionLabelMode: true } });
              }} />
              &nbsp;&nbsp;&nbsp;</>}
          </>}
          <Button className="formButton negative"
            style={{ padding: "6px", position: "absolute", right: "14px", zIndex: "10" }} content="✖" title="Close"
            onClick={() => { modalClose(); }}
          />
        </>
      }
    </div>
    <br />
    {resourceState[elementName] && <div style={{ margin: "12px 0px" }}><RecursiveSectionView globalContext={globalContext} json={resourceState} resourceState={resourceState} setResourceState={setResourceState} reorganizeSectionsModalState={reorganizeSectionsModalState} setReorganizeSectionsModalState={setReorganizeSectionsModalState} sectionPath={[]} elementName={elementName} editSectionLabelMode={reorganizeSectionsModalState.editSectionLabelMode} deleteSectionsMode={reorganizeSectionsModalState.deleteSectionsMode} /></div>}
    <br />
  </div>;

  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={true}
      centered={false}
      content={modalContent}
    />
  )
}

//The deleteEmptyElementsInObjectRecursive() can be used in many other functions, so we can start replacing lines that have ` === "DELETEME" ` with this function call, but we have to test for unintended consequences
const deleteEmptyElementsInObjectRecursive = (jsonObject) => {
  //Near perfect recursion (if not perfect) for FHIR JSON scenarios
  try {
    for (const key in jsonObject) {
      let element = jsonObject[key];
      if (element === "DELETEME" || element === "" || element === undefined || element === null ||
        (typeof element === "object" && !Array.isArray(element) && Object.keys(element).length === 0) ||
        (Array.isArray(element) &&
          (element.length === 0 ||
            (element.length === 1 &&
              ((typeof element[0] === "object" && Object.keys(element[0]).length === 0) ||
                (typeof element[0] === "string" && element[0] === "")))))) {
        delete jsonObject[key];
      } else if (typeof element === "object" && !Array.isArray(element)) {
        deleteEmptyElementsInObjectRecursive(element);
        if (Object.keys(element).length === 0) {
          delete jsonObject[key];
        }
      } else if (Array.isArray(element) && typeof element[0] === "object") {
        for (let subIndex = element.length - 1; subIndex >= 0; subIndex--) {
          deleteEmptyElementsInObjectRecursive(element[subIndex]);
          if (Object.keys(element[subIndex]).length === 0) {
            element.splice(subIndex, 1);
          }
        }
        if (element.length === 0) {
          delete jsonObject[key];
        }
      } else if (Array.isArray(element) &&
        (typeof element[0] === "string" || element[0] === null || element[0] === undefined)) {
        for (let subIndex = element.length - 1; subIndex >= 0; subIndex--) {
          if (element[subIndex] === "" || element[0] === null || element[0] === undefined) {
            element.splice(subIndex, 1);
          }
        }
        if (element.length === 0) {
          delete jsonObject[key];
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
  return jsonObject; //Not necessary since we're passing the function an address of memory and the function is modifying the object directly
}

const deleteEmptyElementsInObjectNonRecursive = (jsonObject) => {
  for (const key in jsonObject) {
    let element = jsonObject[key];
    if (element === "DELETEME" || element === "" || element === undefined || element === null ||
      (typeof element === "object" && !Array.isArray(element) && Object.keys(element).length === 0) ||
      (Array.isArray(element) && element.length === 0)) {
      delete jsonObject[key];
    }
  }
  return jsonObject; //Not necessary since we're passing the function an address of memory and the function is modifying the object directly
}

export { clearDivWrapper, clearState, addResourceElementState, loadResourceVersionList, getHistoryVersionOfResource, getVersionDifference, retrieveIterativeElement, updateIterativeJson, editIterativeState, loadReferencedResource, loadGroupCharacteristics, getCodeDisplayOrText, getStringFromFHIR, DisplayFromFHIR, checkCharacteristicCombination, DeleteRowButton, AssociatedResourcesDisplay, SimpleResourceFieldViewer, codesToCodeableConcept, NoteDisplay, getNotes, ElementNote, changeSimpleState, changeSimpleStateWithCheck, changeResourceElementState, getIdentifier, identifierTextViewChangesToJson, generateIdentifierTable, generateIdentifierSystemCell, generateIdentifierValueCell, getResourceComments, loadUsageView, createInviteLink, revokeInviteLink, jsonErrorCheck, jsonErrorCheck2, convertBooleanToString, votePermissionCheck, getCodeableConceptsDisplay, getContainedResources, submitResource, submitBulkResources, FHIRtoCTgovConverter, getRobatValueSetResourcesData, builderUpdateJson, submitUpdatedFhirResource, joinGroup, sendFli, addToProject, getValueSetCodesAsOptions, recursiveChange, formatDate, ReorganizeSectionsModal, deleteEmptyElementsInObjectRecursive, deleteEmptyElementsInObjectNonRecursive };