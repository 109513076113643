import React, { useEffect, useContext, useState, useImperativeHandle } from 'react';
import { emptyReasonValueSet, emptyReasonCodeableConceptSet } from './CodeSystemLookup';
import { autoEditEmptyReason, DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import {
  EditIntroduction, EditDiscussion, EditMethods, EditReferences, EditCompetingInterests,
  EditAcknowledgements, EditAppendices
} from './EvidenceReportPackageAuthoringTool';
import { generateRecommendationJustificationNarrative } from './RecommendationJustificationEdit';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { generateGroupSummary } from './GenerateNarrativeSummaryFunctions';
import FevirContext from './FevirContext';
import { DataEntry } from './DataEntryFormFunctions';
import { NarrativeEntry } from './NarrativeEntry';
import { getFoiFromReference } from './ResourceDictionaryFunctions';
import { DisplayFromFHIR } from './ResourceFunctions';
import { Button } from 'semantic-ui-react';

const reorderSections = (sectionArray) => {
  let emptySummary = {
    "title": "Summary Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "summary",
        display: "Summary"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyIntroduction = {
    "title": "Introduction Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "introduction",
        display: "Introduction"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyDiscussion = {
    "title": "Discussion Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "discussion",
        display: "Discussion"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyMethods = {
    "title": "Methods Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "methods",
        display: "Methods"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyReferences = {
    "title": "References Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "references",
        display: "References"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyCompetingInterests = {
    "title": "Competing Interests Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "competing-interests",
        display: "Competing Interests"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAcknowledgements = {
    "title": "Acknowledgements Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "acknowledgements",
        display: "Acknowledgements"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAppendices = {
    "title": "Appendices Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "appendices",
        display: "Appendices"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyRecommendation = {
    "title": "Recommendation Specification",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "recommendation-specification",
        display: "Recommendation Specification"
      }]
    },
    "section": [
      {
        "title": "Recommendation Statement",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "recommendation-statement",
            display: "Recommendation Statement"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Recommendation Ratings",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "ratings",
            display: "Ratings"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Population",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "population",
            display: "Population"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Action",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "action",
            display: "Action"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Opposite Action",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "opposite-action",
            display: "Opposite Action"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      }
    ]
  };
  let emptyEvidence = {
    "title": "Evidence",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "evidence",
        display: "Evidence"
      }]
    },
    "emptyReason": {
      "coding": [{
        "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
        "code": "notstarted",
        "display": "Not Started"
      }]
    }
  };
  let emptyJustification = {
    "title": "Justification",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "justification",
        display: "Justification"
      }]
    },
    "emptyReason": {
      "coding": [{
        "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
        "code": "notstarted",
        "display": "Not Started"
      }]
    }
  };
  let emptyConsiderations = {
    "title": "Considerations",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "considerations",
        display: "Considerations"
      }]
    },
    "section": [
      {
        "title": "Overall Considerations Summary",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "summary",
            display: "Summary"
          }],
          "text": "Considerations Summary"
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Subgroup Considerations",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "subgroup-considerations",
            display: "Subgroup Considerations"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Implementation Considerations",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "implementation-considerations",
            display: "Implementation Considerations"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Monitoring Considerations",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "monitoring-considerations",
            display: "Monitoring Considerations"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Research Considerations",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "research-considerations",
            display: "Research Considerations"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      }
    ]
  };
  let sectionDictionary = {
    summary: emptySummary,
    introduction: emptyIntroduction,
    discussion: emptyDiscussion,
    methods: emptyMethods,
    references: emptyReferences,
    competingInterests: emptyCompetingInterests,
    acknowledgements: emptyAcknowledgements,
    appendices: emptyAppendices,
    recommendation: emptyRecommendation,
    evidence: emptyEvidence,
    justification: emptyJustification,
    considerations: emptyConsiderations,
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
    if (sectionCode === "introduction" || sectionCode === "Introduction") {
      sectionDictionary.introduction = section;
    } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
      sectionDictionary.discussion = section;
    } else if (sectionCode === "methods" || sectionCode === "Methods") {
      sectionDictionary.methods = section;
    } else if (sectionCode === "summary" || sectionCode === "Summary") {
      sectionDictionary.summary = section;
    } else if (sectionCode === "references" || sectionCode === "References") {
      sectionDictionary.references = section;
    } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
      sectionDictionary.competingInterests = section;
    } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
      sectionDictionary.acknowledgements = section;
    } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
      sectionDictionary.appendices = section;
    } else if (sectionCode === "recommendation-specification" || sectionCode === "Recommendation Specification") {
      sectionDictionary.recommendation = section;
    } else if (sectionCode === "evidence" || sectionCode === "Evidence") {
      sectionDictionary.evidence = section;
    } else if (sectionCode === "justification" || sectionCode === "Justification" || sectionCode === "judgments" || sectionCode === "Judgments") {
      sectionDictionary.justification = section;
    } else if (sectionCode === "considerations" || sectionCode === "Considerations") {
      sectionDictionary.considerations = section;
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  let reorderedSectionArray = [
    sectionDictionary.summary,
    sectionDictionary.introduction,
    sectionDictionary.recommendation,
    sectionDictionary.justification,
    sectionDictionary.considerations,
    sectionDictionary.methods,
    sectionDictionary.evidence,
    sectionDictionary.discussion,
    sectionDictionary.references,
    sectionDictionary.competingInterests,
    sectionDictionary.acknowledgements,
    sectionDictionary.appendices
  ].concat(sectionDictionary.additionalSections);
  return reorderedSectionArray;
};

const RecommendationAuthor = ({ resourceState, setResourceState, globalContext, sourceJsonState,
  setSourceJsonState, previousVersionLoaded,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  let fhirJson = resourceState.resourceJson;
  let defaultType = {
    coding: [{
      system: "https://fevir.net/resources/CodeSystem/179423",
      code: "Recommendation",
      display: "Recommendation"
    }]
  }

  useEffect(() => {
    if (resourceState) {
      setResourceState(prevState => {
        return {
          ...prevState,
          type: prevState.type || defaultType,
          section: reorderSections(prevState.section)
        };
      });
    }
  }, []);

  const loadAdaptationStateFunction = () => {
    if (!adaptationReportState.adaptationDictionary && fhirJson.section?.length) {
      let startingAdaptationDictionary = {};
      let startingPathList = [];
      for (const section of fhirJson?.section) {
        if (section.code) {
          let sectionCode;
          if (section.code.text) {
            sectionCode = "section:" + section.code.text;
          } else if (section.code.coding?.[0]) {
            sectionCode = "section:" + section.code.coding[0].system + "#" + section.code.coding[0].code;
          } else {
            sectionCode = "section:" + JSON.stringify(section.code);
          }
          if (startingPathList.includes(sectionCode)) {
            alert("Duplicate sections wtih the same code: " + sectionCode + " at top section level");
          }
          startingPathList.push(sectionCode);
          startingPathList.push(sectionCode + ".title");
          startingPathList.push(sectionCode + ".author");
          startingPathList.push(sectionCode + ".orderedBy");
          startingPathList.push(sectionCode + ".entry");
          startingPathList.push(sectionCode + ".emptyReason");
          startingAdaptationDictionary[sectionCode] = {
            "initialValue": section.text?.div || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".title"] = {
            "initialValue": section.title || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".author"] = {
            "initialValue": section.author || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".orderedBy"] = {
            "initialValue": section.orderedBy || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".entry"] = {
            "initialValue": section.entry || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".emptyReason"] = {
            "initialValue": section.emptyReason || null,
            "itemChanged": false
          };
        }
        if (section.section?.length) {
          for (const subsection of section.section) {
            if (subsection.code) {
              let sectionCode;
              if (subsection.code.text) {
                sectionCode = "section:" + subsection.code.text;
              } else if (subsection.code.coding?.[0]) {
                sectionCode = "section:" + subsection.code.coding[0].system + "#" + subsection.code.coding[0].code;
              } else {
                sectionCode = "section:" + JSON.stringify(subsection.code);
              }
              if (startingPathList.includes(sectionCode)) {
                alert("Duplicate sections wtih the same code: " + sectionCode + " at section.section level");
              }
              startingPathList.push(sectionCode);
              startingPathList.push(sectionCode + ".title");
              startingPathList.push(sectionCode + ".author");
              startingPathList.push(sectionCode + ".orderedBy");
              startingPathList.push(sectionCode + ".entry");
              startingPathList.push(sectionCode + ".emptyReason");
              startingAdaptationDictionary[sectionCode] = {
                "initialValue": subsection.text?.div || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".title"] = {
                "initialValue": subsection.title || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".author"] = {
                "initialValue": subsection.author || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".orderedBy"] = {
                "initialValue": subsection.orderedBy || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".entry"] = {
                "initialValue": subsection.entry || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".emptyReason"] = {
                "initialValue": subsection.emptyReason || null,
                "itemChanged": false
              };
            }
            if (subsection.section?.length) {
              for (const subsubsection of subsection.section) {
                if (subsubsection.code) {
                  let sectionCode;
                  if (subsubsection.code.text) {
                    sectionCode = "section:" + subsubsection.code.text;
                  } else if (subsubsection.code.coding?.[0]) {
                    sectionCode = "section:" + subsubsection.code.coding[0].system + "#" + subsubsection.code.coding[0].code;
                  } else {
                    sectionCode = "section:" + JSON.stringify(subsubsection.code);
                  }
                  if (startingPathList.includes(sectionCode)) {
                    alert("Duplicate sections wtih the same code: " + sectionCode + " at section.section.section level");
                  }
                  startingPathList.push(sectionCode);
                  startingPathList.push(sectionCode + ".title");
                  startingPathList.push(sectionCode + ".author");
                  startingPathList.push(sectionCode + ".orderedBy");
                  startingPathList.push(sectionCode + ".entry");
                  startingPathList.push(sectionCode + ".emptyReason");
                  startingAdaptationDictionary[sectionCode] = {
                    "initialValue": subsubsection.text?.div || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".title"] = {
                    "initialValue": subsubsection.title || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".author"] = {
                    "initialValue": subsubsection.author || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".orderedBy"] = {
                    "initialValue": subsubsection.orderedBy || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".entry"] = {
                    "initialValue": subsubsection.entry || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".emptyReason"] = {
                    "initialValue": subsubsection.emptyReason || null,
                    "itemChanged": false
                  };
                }
              }
            }
          }
        }
      }
      setAdaptationReportState(prevState => {
        return {
          ...prevState,
          "adaptationDictionary": startingAdaptationDictionary,
          "pathList": startingPathList
        }
      })
    }
  }

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
      loadAdaptationStateFunction();
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  return <>{sourceJsonState.loaded ?
    <div style={{ marginTop: "12px" }}>
      {resourceState.section ? <>
        <h3 id="summary">Summary</h3>
        <div style={{ marginLeft: "24px" }}>
          <DocumentSectionEntry sectionArrayIndex={0}
            fieldLabel="Summary" sectionCode="section:https://fevir.net/resources/CodeSystem/179423#summary"
            startingValue={resourceState.section[0] || null}
            fixedTitle={"Summary"} fixedCode={{
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "summary",
                  "display": "Summary"
                }
              ]
            }} startCollapsed editTextDiv={true} textDeletable={true}
            emptyTextDivValue="[No data.]"
            editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
            noEntry={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonStartCollapsed={true}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            editSection={true}
            editableSectionCodes={true} addSectionAllowed={true}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </div>
        <EditIntroduction sectionIndex={1} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <h3 id="recommendation">Recommendation</h3>
        <div style={{ marginLeft: "24px" }}>
          <DocumentSectionEntry sectionArrayIndex={2}
            startingValue={resourceState.section[2] || null}
            fieldLabel="Recommendation"
            fixedTitle={"Recommendation Specification"} fixedCode={{
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "recommendation-specification",
                "display": "Recommendation Specification"
              }]
            }}
            startCollapsed editTextDiv={false} editAuthor={true}
            noEntry={true} editEmptyReason={true}
            emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            emptyReasonStartCollapsed={true}
            editSection={true}
            editableSectionCodes={false} addSectionAllowed={false}
            allowedSectionCodes={[
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "recommendation-statement",
                  "display": "Recommendation Statement"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "ratings",
                  "display": "Ratings"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "population",
                  "display": "Population"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "action",
                  "display": "Action"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "opposite-action",
                  "display": "Opposite Action"
                }]
              }
            ]}
            sectionDictionary={{
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"recommendation-statement","display":"Recommendation Statement"}]}': {
                'startCollapsed': false,
                'fixedTitle': "Recommendation Statement",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'noSection': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"ratings","display":"Ratings"}]}': {
                'startCollapsed': false,
                'fixedTitle': "Ratings (Strength/Level) of Recommendation",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                'entryReferencedResourceTypes': ["ArtifactAssessment"], 'entryStartCollapsed': true,
                'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "ArtifactAssessment",
                'entrySetProfile': "RecommendationJustification",
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'noSection': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"population","display":"Population"}]}': {
                'startCollapsed': false,
                'fixedTitle': "Population",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'generateTextDivFunction': generateGroupSummary,
                'emptyTextDivValue': "[No data.]",
                'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                'entryReferencedResourceTypes': ["Group"], 'entryStartCollapsed': true,
                'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "Group",
                'entrySetProfile': "RecommendationEligibilityCriteria",
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'noSection': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"action","display":"Action"}]}': {
                'startCollapsed': false,
                'fixedTitle': "Action",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                'entryReferencedResourceTypes': ["ActivityDefinition", "EvidenceVariable"], 'entryStartCollapsed': true,
                'entryStartEmptyArrayClosed': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'noSection': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"opposite-action","display":"Opposite Action"}]}': {
                'startCollapsed': false,
                'fixedTitle': "Opposite Action",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                'entryReferencedResourceTypes': ["ActivityDefinition", "EvidenceVariable"], 'entryStartCollapsed': true,
                'entryStartEmptyArrayClosed': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'noSection': true
              }
            }}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState}
            globalContext={globalContext} resourceState={resourceState} setSourceJsonState={setSourceJsonState} />
        </div>
        <h3 id="justification">Justification</h3>
        <div style={{ marginLeft: "24px" }}>
          <DocumentSectionEntry sectionArrayIndex={3}
            startingValue={resourceState.section[3] || null}
            fieldLabel="Justification"
            fixedTitle={"Justification"} fixedCode={{
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "justification",
                "display": "Justification"
              }]
            }}
            startCollapsed editTextDiv={true} textDeletable={true} editAuthor={true}
            generateNarrativeFromSingleEntryFunction={generateRecommendationJustificationNarrative}
            resourceDictionary={sourceJsonState.resourceDictionary}
            emptyTextDivValue="[No data.]"
            entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
            entryReferencedResourceTypes={["ArtifactAssessment"]} entryStartCollapsed
            entryStartEmptyArrayClosed entryStartingResourceType="ArtifactAssessment"
            entrySetProfile="RecommendationJustification"
            editEmptyReason={true}
            emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            emptyReasonStartCollapsed={true}
            editSection={true}
            editableSectionCodes={true} addSectionAllowed={true}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </div>
        <h3 id="considerations">Considerations</h3>
        <div style={{ marginLeft: "24px" }}>
          <DocumentSectionEntry sectionArrayIndex={4}
            startingValue={resourceState.section[4] || null}
            fieldLabel="Considerations"
            fixedTitle={"Considerations"} fixedCode={{
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "considerations",
                "display": "Considerations"
              }]
            }}
            startCollapsed editTextDiv={false} editAuthor={true}
            noEntry={true} editEmptyReason={true}
            emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            emptyReasonStartCollapsed={true}
            editSection={true}
            editableSectionCodes={true} addSectionAllowed={true}
            allowedSectionCodes={[
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "summary",
                  "display": "Summary"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "subgroup-considerations",
                  "display": "Subgroup Considerations"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "implementation-considerations",
                  "display": "Implementation Considerations"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "monitoring-considerations",
                  "display": "Monitoring Considerations"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "competing-interests",
                  "display": "Competing Interests"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "research-considerations",
                  "display": "Research Considerations"
                }]
              }
            ]}
            sectionDictionary={{
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"summary","display":"Summary"}]}': {
                'startCollapsed': false,
                'fixedTitle': "Overall Considerations Summary",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"subgroup-considerations","display":"Subgroup Considerations"}]}': {
                'startCollapsed': false,
                'fixedTitle': "Subgroup Considerations",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"implementation-considerations","display":"Implementation Considerations"}]}': {
                'startCollapsed': false,
                'fixedTitle': "Implementation Considerations",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"monitoring-considerations","display":"Monitoring Considerations"}]}': {
                'startCollapsed': false,
                'fixedTitle': "Monitoring Considerations",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"competing-interests","display":"Competing Interests"}]}': {
                'startCollapsed': false,
                'fixedTitle': "Competing Interests",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                'entryReferencedResourceTypes': ["ArtifactAssessment", "Composition"], 'entryStartCollapsed': true,
                'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "ArtifactAssessment",
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"research-considerations","display":"Research Considerations"}]}': {
                'startCollapsed': false,
                'fixedTitle': "Research Considerations",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              }
            }}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </div>
        <EditMethods sectionIndex={5} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <h3 id="evidence">Evidence</h3>
        <div style={{ marginLeft: "24px" }}>
          <DocumentSectionEntry sectionArrayIndex={6}
            startingValue={resourceState.section[6] || null}
            fieldLabel="Evidence"
            fixedTitle={"Evidence"} fixedCode={{
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "evidence",
                "display": "Evidence"
              }]
            }}
            editTextDiv={true} textDeletable={true} editAuthor={true}
            emptyTextDivValue="[No data.]"
            entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
            entryReferencedResourceTypes={["ArtifactAssessment", "Composition", "Evidence"]}
            entryStartCollapsed={true} entryStartEmptyArrayClosed={true}
            editEmptyReason={true}
            emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            emptyReasonStartCollapsed={true}
            editSection={true}
            editableSectionCodes={true} addSectionAllowed={true}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </div>
        <EditDiscussion sectionIndex={7} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <EditReferences sectionIndex={8} previousVersionLoaded={previousVersionLoaded}
          resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <EditCompetingInterests sectionIndex={9} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <EditAcknowledgements sectionIndex={10} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <EditAppendices sectionIndex={11} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
      </>
        :
        <>Section element is missing in the JSON.</>
      }
    </div>
    :
    <div><p>Loading ...</p></div>}</>
};


const RecommendationSpecificationEdit = ({ section, formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState,
  resourceState, setResourceState, sourceJsonState, setSourceJsonState }) => {
  const globalContext = useContext(FevirContext);
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let sectionPathIndexes = fhirEntryState.sectionPathIndexes;

  let startingSection = {
    title: "Recommendation Specification", code: {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/179423",
          code: "recommendation-specification",
          display: "Recommendation Specification"
        }
      ]
    }, author: [], section: [], sectionPathIndexes: sectionPathIndexes
  }
  if (!section) {
    section = "";
  } else {
    if (section.extension) { startingSection.extension = section.extension; }
    if (section.title) { startingSection.title = section.title; }
    if (section.code) { startingSection.code = section.code; }
    if (section.author) { startingSection.author = section.author; }
    if (section.focus) { startingSection.focus = section.focus; }
    if (section.text) { startingSection.text = section.text; }
    if (section.orderedBy) { startingSection.orderedBy = section.orderedBy; }
    if (section.entry) { startingSection.entry = section.entry; }
    if (section.section) { startingSection.section = section.section; }
  }

  let startingRecommendationStatementSection = {
    "title": "Recommendation Statement",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "recommendation-statement",
          "display": "Recommendation Statement"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingRecommendationRatingsSection = {
    "title": "Recommendation Ratings",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "ratings",
          "display": "Ratings"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "entry": [],
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingPopulationSection = {
    "title": "Population",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "population",
          "display": "Population"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "entry": [],
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingActionSection = {
    "title": "Action",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "action",
          "display": "Action"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "entry": [],
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingOppositeActionSection = {
    "title": "Opposite Action",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "opposite-action",
        display: "Opposite Action"
      }]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "entry": [],
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };

  if (startingSection.section) {
    for (let subsection of startingSection.section) {
      let subsectionCode = subsection.code?.coding?.[0]?.code;
      if (subsectionCode === "population") {
        startingPopulationSection = subsection;
      } else if (subsectionCode === "recommendation-statement") {
        startingRecommendationStatementSection = subsection;
      } else if (subsectionCode === "ratings") {
        startingRecommendationRatingsSection = subsection;
      } else if (subsectionCode === "action") {
        startingActionSection = subsection;
      } else if (subsectionCode === "opposite-action") {
        startingOppositeActionSection = subsection;
      }
    }
  }

  let startingSubsections = {
    "recommendationStatement": startingRecommendationStatementSection,
    "recommendationRatings": startingRecommendationRatingsSection,
    "population": startingPopulationSection,
    "action": startingActionSection,
    "oppositeAction": startingOppositeActionSection
  }

  const [sectionState, setSectionState] = useState(startingSection);
  const [subsectionState, setSubsectionState] = useState(startingSubsections);
  const [populationState, setPopulationState] = useState(startingPopulationSection);
  const [recommendationStatementState, setRecommendationStatementState] = useState(startingRecommendationStatementSection);
  const [recommendationRatingsState, setRecommendationRatingsState] = useState(startingRecommendationRatingsSection);
  const [actionState, setActionState] = useState(startingActionSection);
  const [oppositeActionState, setOppositeActionState] = useState(startingOppositeActionSection);
  const [editAuthorCollapsedState, setEditAuthorCollapsedState] = useState(true);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(populationState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "population": newSection };
    });
  }, [populationState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(recommendationStatementState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "recommendationStatement": newSection };
    });
  }, [recommendationStatementState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(recommendationRatingsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "recommendationRatings": newSection };
    });
  }, [recommendationRatingsState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(actionState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "action": newSection };
    });
  }, [actionState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(oppositeActionState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "oppositeAction": newSection };
    });
  }, [oppositeActionState]);

  useEffect(() => {
    setSectionState(prevState => {
      let newSection = [subsectionState.recommendationStatement, subsectionState.recommendationRatings,
      subsectionState.population, subsectionState.action, subsectionState.oppositeAction
      ];
      return { ...prevState, "section": newSection };
    });
  }, [subsectionState]);

  useEffect((() => {
    if (Object.keys(sectionState).length > 0) {
      if (JSON.stringify(sectionState.text) !== JSON.stringify(startingSection.text) ||
        sectionState.title !== startingSection.title ||
        JSON.stringify(sectionState.section) !== JSON.stringify(startingSection.section) ||
        JSON.stringify(sectionState.code) !== JSON.stringify(startingSection.code)) {
        setFhirEntryState(prevState => {
          return { ...prevState, sectionChanged: true }
        });
      }
    }
  }), [sectionState]);

  useImperativeHandle(formInputsStateRef, () => ({
    sectionState,
  }), [sectionState]);

  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];


  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 style={{ margin: "0px" }}>Recommendation Specification</h3>
      <div style={{ marginLeft: "24px" }}>
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setEditAuthorCollapsedState(!editAuthorCollapsedState) }}>
          {editAuthorCollapsedState ? <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </> : <>
            <b>Collapse Section Author Data Entry ▼</b>
          </>}
        </span>
        <br />
        {!editAuthorCollapsedState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author'
            fieldLabel='Section Author'
            startingValue={sectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            startCollapsed startEmptyArrayClosed
            setResourceState={setSectionState} />
        </>
        }
      </div>
      <h3 style={{ margin: "0px" }}>Recommendation Statement</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Recommendation Statement Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={recommendationStatementState.text} setResourceState={setRecommendationStatementState}
        />
        {((!recommendationStatementState.text || recommendationStatementState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={recommendationStatementState.emptyReason}
              startCollapsed={true}
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setRecommendationStatementState} />
          </div>
        </>}
      </div>
      <h3 style={{ margin: "0px" }}>Recommendation Ratings</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Recommendation Ratings Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={recommendationRatingsState.text} setResourceState={setRecommendationRatingsState}
        />
        <h3>Create or identify the ArtifactAssessment Resource(s) for the Recommendation Ratings.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'ArtifactAssessment Resource'}
            startingValue={recommendationRatingsState.entry} referencedResourceTypes={['ArtifactAssessment']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            startingResourceType={"ArtifactAssessment"}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            setResourceState={setRecommendationRatingsState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!recommendationRatingsState.entry || recommendationRatingsState.entry.length === 0) &&
          (!recommendationRatingsState.text || recommendationRatingsState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={recommendationRatingsState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setRecommendationRatingsState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Population</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Population Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          generateSummaryFunction={generateGroupSummary}
          entryFoi={getFoiFromReference(populationState.entry)}
          sectionEntry={populationState.entry}
          resourceType="Group"
          resourceDictionary={resourceDictionary} globalContext={globalContext}
          startingValue={populationState.text} setResourceState={setPopulationState}
        />
        <h3>Create or identify the Group Resource for the Population.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'Group Resource'}
            startingValue={populationState.entry} referencedResourceTypes={['Group']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            startingResourceType={"Group"}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            setResourceState={setPopulationState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!populationState.section || populationState.section.length === 0) &&
          (!populationState.entry || populationState.entry.length === 0) &&
          (!populationState.text || populationState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={populationState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setPopulationState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Action</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Action Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={actionState.text} setResourceState={setActionState}
        />
        <h3>Create or identify the ActivityDefinition or EvidenceVariable Resource.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'ActivityDefinition or EvidenceVariable Resource'}
            startingValue={actionState.entry} referencedResourceTypes={['ActivityDefinition', 'EvidenceVariable']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            setResourceState={setActionState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!actionState.entry || actionState.entry.length === 0) &&
          (!actionState.text || actionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={actionState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setActionState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Opposite Action</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Opposite Action Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={oppositeActionState.text} setResourceState={setOppositeActionState}
        />
        <h3>Create or identify the ActivityDefinition or EvidenceVariable Resource.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'ActivityDefinition or EvidenceVariable Resource'}
            startingValue={oppositeActionState.entry} referencedResourceTypes={['ActivityDefinition', 'EvidenceVariable']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            setResourceState={setOppositeActionState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!oppositeActionState.entry || oppositeActionState.entry.length === 0) &&
          (!oppositeActionState.text || oppositeActionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={oppositeActionState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setOppositeActionState} />
            </div>
          </>}
      </div>
    </div>
  </div>
}

const RecommendationConsiderationsEdit = ({ section, formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState,
  resourceState, setResourceState, sourceJsonState, setSourceJsonState }) => {
  const globalContext = useContext(FevirContext);
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let sectionPathIndexes = fhirEntryState.sectionPathIndexes;

  let startingSection = {
    title: "Considerations", code: {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/179423",
          code: "considerations",
          display: "Considerations"
        }
      ]
    }, author: [], section: [], sectionPathIndexes: sectionPathIndexes
  }
  if (!section) {
    section = "";
  } else {
    if (section.extension) { startingSection.extension = section.extension; }
    if (section.title) { startingSection.title = section.title; }
    if (section.code) { startingSection.code = section.code; }
    if (section.author) { startingSection.author = section.author; }
    if (section.focus) { startingSection.focus = section.focus; }
    if (section.text) { startingSection.text = section.text; }
    if (section.orderedBy) { startingSection.orderedBy = section.orderedBy; }
    if (section.entry) { startingSection.entry = section.entry; }
    if (section.section) { startingSection.section = section.section; }
  }

  let startingSummarySection = {
    "title": "Overall Considerations Summary",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "summary",
          "display": "Summary"
        }
      ],
      "text": "Considerations Summary"
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingSubgroupConsiderationsSection = {
    "title": "Subgroup Considerations",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "subgroup-considerations",
          "display": "Subgroup Considerations"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingImplementationConsiderationsSection = {
    "title": "Implementation Considerations",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "implementation-considerations",
          "display": "Implementation Considerations"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingMonitoringConsiderationsSection = {
    "title": "Monitoring Considerations",
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/17942",
          "code": "monitoring-considerations",
          "display": "Monitoring Considerations"
        }
      ]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingResearchConsiderationsSection = {
    "title": "Research Considerations",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "research-considerations",
        display: "Research Considerations"
      }]
    },
    "text": {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let startingAdditionalSections = [];

  if (startingSection.section) {
    for (let subsection of startingSection.section) {
      let subsectionCode = subsection.code?.coding?.[0]?.code;
      if (subsectionCode === "summary") {
        startingSummarySection = subsection;
      } else if (subsectionCode === "subgroup-considerations") {
        startingSubgroupConsiderationsSection = subsection;
      } else if (subsectionCode === "implementation-considerations") {
        startingImplementationConsiderationsSection = subsection;
      } else if (subsectionCode === "monitoring-considerations") {
        startingMonitoringConsiderationsSection = subsection;
      } else if (subsectionCode === "research-considerations") {
        startingResearchConsiderationsSection = subsection;
      } else {
        startingAdditionalSections.push(subsection);
      }
    }
  }

  let startingSubsections = {
    "summaryConsiderations": startingSummarySection,
    "subgroupConsiderations": startingSubgroupConsiderationsSection,
    "implementationConsiderations": startingImplementationConsiderationsSection,
    "monitoringConsiderations": startingMonitoringConsiderationsSection,
    "researchConsiderations": startingResearchConsiderationsSection,
    "additionalSections": startingAdditionalSections
  }

  const [sectionState, setSectionState] = useState(startingSection);
  const [subsectionState, setSubsectionState] = useState(startingSubsections);
  const [summaryConsiderationsState, setSummaryConsiderationsState] = useState(startingSummarySection);
  const [subgroupConsiderationsState, setSubgroupConsiderationsState] = useState(startingSubgroupConsiderationsSection);
  const [implementationConsiderationsState, setImplementationConsiderationsState] = useState(startingImplementationConsiderationsSection);
  const [monitoringConsiderationsState, setMonitoringConsiderationsState] = useState(startingMonitoringConsiderationsSection);
  const [researchConsiderationsState, setResearchConsiderationsState] = useState(startingResearchConsiderationsSection);
  const [editAuthorCollapsedState, setEditAuthorCollapsedState] = useState(true);
  const [addedSectionState, setAddedSectionState] = useState({ "title": "", "textDiv": "" });

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(summaryConsiderationsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "summaryConsiderations": newSection };
    });
  }, [summaryConsiderationsState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(subgroupConsiderationsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "subgroupConsiderations": newSection };
    });
  }, [subgroupConsiderationsState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(implementationConsiderationsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "implementationConsiderations": newSection };
    });
  }, [implementationConsiderationsState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(monitoringConsiderationsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "monitoringConsiderations": newSection };
    });
  }, [monitoringConsiderationsState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(researchConsiderationsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "researchConsiderations": newSection };
    });
  }, [researchConsiderationsState]);

  useEffect(() => {
    if (addedSectionState.addSection && addedSectionState.title) {
      let sectionToBeAdded = { "title": addedSectionState.title };
      if (addedSectionState.textDiv) {
        sectionToBeAdded.text = {
          "status": "additional",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + addedSectionState.textDiv + "</div>"
        }
      }
      setSubsectionState(prevState => {
        let newSection = prevState.additionalSections || [];
        newSection.push(sectionToBeAdded);
        return { ...prevState, "section": newSection };
      });
      setAddedSectionState({ "title": "", "textDiv": "" });
    }
  }, [addedSectionState]);

  useEffect(() => {
    setSectionState(prevState => {
      let newSection = [subsectionState.summaryConsiderations, subsectionState.subgroupConsiderations,
      subsectionState.implementationConsiderations, subsectionState.monitoringConsiderations,
      subsectionState.researchConsiderations
      ].concat(subsectionState.additionalSections);
      return { ...prevState, "section": newSection };
    });
  }, [subsectionState]);

  useEffect((() => {
    if (Object.keys(sectionState).length > 0) {
      if (JSON.stringify(sectionState.text) !== JSON.stringify(startingSection.text) ||
        sectionState.title !== startingSection.title ||
        JSON.stringify(sectionState.section) !== JSON.stringify(startingSection.section) ||
        JSON.stringify(sectionState.code) !== JSON.stringify(startingSection.code)) {
        setFhirEntryState(prevState => {
          return { ...prevState, sectionChanged: true }
        });
      }
    }
  }), [sectionState]);

  useImperativeHandle(formInputsStateRef, () => ({
    sectionState,
  }), [sectionState]);

  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 style={{ margin: "0px" }}>Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setEditAuthorCollapsedState(!editAuthorCollapsedState) }}>
          {editAuthorCollapsedState ? <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </> : <>
            <b>Collapse Section Author Data Entry ▼</b>
          </>}
        </span>
        <br />
        {!editAuthorCollapsedState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author'
            fieldLabel='Section Author'
            startingValue={sectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            startCollapsed startEmptyArrayClosed
            setResourceState={setSectionState} />
        </>
        }
      </div>
      <h3 style={{ margin: "0px" }}>Overall Considerations Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Overall Considerations Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={summaryConsiderationsState.text} setResourceState={setSummaryConsiderationsState}
        />
        {((!summaryConsiderationsState.section || summaryConsiderationsState.section.length === 0) && 
        (!summaryConsiderationsState.text || summaryConsiderationsState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={summaryConsiderationsState.emptyReason}
              startCollapsed={true}
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setSummaryConsiderationsState} />
          </div>
        </>}
        <div style={{ marginLeft: "24px" }}>
        <p><b>Contained Sections (for text with subheadings):</b></p>
        {(Array.isArray(summaryConsiderationsState.section) && summaryConsiderationsState.section.length > 0) &&
          summaryConsiderationsState.section.map((subsection, subsectionIndex) => {
            let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
            let sectionTextDiv = subsection.text?.div;
            let sectionTextStatus = subsection.text?.status;
            return <span key={subsectionIndex}>
              <p><b>Section: </b>{sectionName}</p>
              {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                <p>Narrative status: {sectionTextStatus}</p>
                <p>Narrative summary:</p>
                <DisplayFromFHIR xhtml={sectionTextDiv} />
              </div>}
            </span>;
          })}
        <div>
          <p>Use Section Detail for this section to add additional subsections.</p>
        </div>
      </div>
      </div>
      <h3 style={{ margin: "0px" }}>Subgroup Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Subgroup Considerations Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={subgroupConsiderationsState.text} setResourceState={setSubgroupConsiderationsState}
        />
        {((!subgroupConsiderationsState.section || subgroupConsiderationsState.section.length === 0) && 
        (!subgroupConsiderationsState.text || subgroupConsiderationsState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={subgroupConsiderationsState.emptyReason}
              startCollapsed={true}
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setSubgroupConsiderationsState} />
          </div>
        </>}
        <div style={{ marginLeft: "24px" }}>
        <p><b>Contained Sections (for text with subheadings):</b></p>
        {(Array.isArray(subgroupConsiderationsState.section) && subgroupConsiderationsState.section.length > 0) &&
          subgroupConsiderationsState.section.map((subsection, subsectionIndex) => {
            let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
            let sectionTextDiv = subsection.text?.div;
            let sectionTextStatus = subsection.text?.status;
            return <span key={subsectionIndex}>
              <p><b>Section: </b>{sectionName}</p>
              {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                <p>Narrative status: {sectionTextStatus}</p>
                <p>Narrative summary:</p>
                <DisplayFromFHIR xhtml={sectionTextDiv} />
              </div>}
            </span>;
          })}
        <div>
          <p>Use Section Detail for this section to add additional subsections.</p>
        </div>
      </div>
      </div>
      <h3 style={{ margin: "0px" }}>Implementation Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Implementation Considerations Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={implementationConsiderationsState.text} setResourceState={setImplementationConsiderationsState}
        />
        {((!implementationConsiderationsState.section || implementationConsiderationsState.section.length === 0) && 
        (!implementationConsiderationsState.text || implementationConsiderationsState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={implementationConsiderationsState.emptyReason}
              startCollapsed={true}
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setImplementationConsiderationsState} />
          </div>
        </>}
        <div style={{ marginLeft: "24px" }}>
        <p><b>Contained Sections (for text with subheadings):</b></p>
        {(Array.isArray(implementationConsiderationsState.section) && implementationConsiderationsState.section.length > 0) &&
          implementationConsiderationsState.section.map((subsection, subsectionIndex) => {
            let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
            let sectionTextDiv = subsection.text?.div;
            let sectionTextStatus = subsection.text?.status;
            return <span key={subsectionIndex}>
              <p><b>Section: </b>{sectionName}</p>
              {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                <p>Narrative status: {sectionTextStatus}</p>
                <p>Narrative summary:</p>
                <DisplayFromFHIR xhtml={sectionTextDiv} />
              </div>}
            </span>;
          })}
        <div>
          <p>Use Section Detail for this section to add additional subsections.</p>
        </div>
      </div>
      </div>
      <h3 style={{ margin: "0px" }}>Monitoring Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Monitoring Considerations Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={monitoringConsiderationsState.text} setResourceState={setMonitoringConsiderationsState}
        />
        {((!monitoringConsiderationsState.section || monitoringConsiderationsState.section.length === 0) && 
        (!monitoringConsiderationsState.text || monitoringConsiderationsState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={monitoringConsiderationsState.emptyReason}
              startCollapsed={true}
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setMonitoringConsiderationsState} />
          </div>
        </>}
        <div style={{ marginLeft: "24px" }}>
        <p><b>Contained Sections (for text with subheadings):</b></p>
        {(Array.isArray(monitoringConsiderationsState.section) && monitoringConsiderationsState.section.length > 0) &&
          monitoringConsiderationsState.section.map((subsection, subsectionIndex) => {
            let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
            let sectionTextDiv = subsection.text?.div;
            let sectionTextStatus = subsection.text?.status;
            return <span key={subsectionIndex}>
              <p><b>Section: </b>{sectionName}</p>
              {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                <p>Narrative status: {sectionTextStatus}</p>
                <p>Narrative summary:</p>
                <DisplayFromFHIR xhtml={sectionTextDiv} />
              </div>}
            </span>;
          })}
        <div>
          <p>Use Section Detail for this section to add additional subsections.</p>
        </div>
      </div>
      </div>
      <h3 style={{ margin: "0px" }}>Research Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Research Considerations Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={researchConsiderationsState.text} setResourceState={setResearchConsiderationsState}
        />
        {((!researchConsiderationsState.section || researchConsiderationsState.section.length === 0) && 
        (!researchConsiderationsState.text || researchConsiderationsState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={researchConsiderationsState.emptyReason}
              startCollapsed={true}
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setResearchConsiderationsState} />
          </div>
        </>}
        <div style={{ marginLeft: "24px" }}>
        <p><b>Contained Sections (for text with subheadings):</b></p>
        {(Array.isArray(researchConsiderationsState.section) && researchConsiderationsState.section.length > 0) &&
          researchConsiderationsState.section.map((subsection, subsectionIndex) => {
            let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
            let sectionTextDiv = subsection.text?.div;
            let sectionTextStatus = subsection.text?.status;
            return <span key={subsectionIndex}>
              <p><b>Section: </b>{sectionName}</p>
              {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                <p>Narrative status: {sectionTextStatus}</p>
                <p>Narrative summary:</p>
                <DisplayFromFHIR xhtml={sectionTextDiv} />
              </div>}
            </span>;
          })}
        <div>
          <p>Use Section Detail for this section to add additional subsections.</p>
        </div>
      </div>
      </div>
      <h3 style={{ margin: "0px" }}>Additional Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Contained Sections (for text with subheadings):</b></p>
        {(Array.isArray(subsectionState.additionalSections) && subsectionState.additionalSections.length > 0) &&
          subsectionState.additionalSections.map((subsection, subsectionIndex) => {
            let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
            let sectionTextDiv = subsection.text?.div;
            let sectionTextStatus = subsection.text?.status;
            return <span key={subsectionIndex}>
              <p><b>Section: </b>{sectionName}</p>
              {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                <p>Narrative status: {sectionTextStatus}</p>
                <p>Narrative summary:</p>
                <DisplayFromFHIR xhtml={sectionTextDiv} />
              </div>}
            </span>;
          })}
        <div>
          <p>Enter a title then click Add Section.</p>
          <DataEntry datatype='string' elementName='title' fieldLabel='Title'
            startingValue={addedSectionState.title}
            setResourceState={setAddedSectionState} />
          <DataEntry datatype='xhtml' elementName='textDiv' fieldLabel='Narrative Summary'
            startingValue={addedSectionState.textDiv}
            setResourceState={setAddedSectionState} />
          <Button className="formButton" style={{ color: "#000000", width: "100%" }}
            content={"Add Section"}
            onClick={() => {
              setAddedSectionState(prevState => { return { ...prevState, addSection: true }; });
            }}
            disabled={!addedSectionState.title} />
        </div>
      </div>
    </div>
  </div>
}

const changeSectionDetailEditSettingsForRecommendation = (sectionDetailEditSettings, section) => {

  if (section.code?.coding?.[0]?.code === "recommendation-specification") {
    sectionDetailEditSettings.RecommendationSpecificationEdit = RecommendationSpecificationEdit;
  }
  if (section.code?.coding?.[0]?.code === "recommendation-statement") {
    sectionDetailEditSettings.fixedTitle = "Recommendation Statement";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "ratings") {
    sectionDetailEditSettings.fixedTitle = "Ratings (Strength/Level) of Recommendation";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add ArtifactAssessment Resource (RecommendationJustification Profile)";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ArtifactAssessment"];
    sectionDetailEditSettings.entryStartingResourceType = "ArtifactAssessment";
    sectionDetailEditSettings.entrySetProfile = "RecommendationJustification";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "population") {
    sectionDetailEditSettings.fixedTitle = "Population";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.entryFoi = getFoiFromReference(section.entry);
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add Group Resource (RecommendationEligibilityCriteria Profile)";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySetProfile = "RecommendationEligibilityCriteria";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "action") {
    sectionDetailEditSettings.fixedTitle = "Action";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add ActivityDefinition or EvidenceVariable Resource";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ActivityDefinition", "EvidenceVariable"];
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "opposite-action") {
    sectionDetailEditSettings.fixedTitle = "Opposite Action";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add ActivityDefinition or EvidenceVariable Resource";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ActivityDefinition", "EvidenceVariable"];
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "justification") {
    sectionDetailEditSettings.fixedTitle = "Justification";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateNarrativeFromSingleEntryFunction = generateRecommendationJustificationNarrative;
    sectionDetailEditSettings.entryFoi = getFoiFromReference(section.entry);
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add ArtifactAssessment Resource (RecommendationJustification Profile)";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ArtifactAssessment"];
    sectionDetailEditSettings.entryStartingResourceType = "ArtifactAssessment";
    sectionDetailEditSettings.entrySetProfile = "RecommendationJustification";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.editableSectionCodes = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "considerations") {
    sectionDetailEditSettings.RecommendationConsiderationsEdit = RecommendationConsiderationsEdit;
  }
  if (section.code?.coding?.[0]?.code === "summary" && section.code.text === "Considerations Summary") {
    sectionDetailEditSettings.fixedTitle = "Overall Considerations Summary";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "subgroup-considerations") {
    sectionDetailEditSettings.fixedTitle = "Subgroup Considerations";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "implementation-considerations") {
    sectionDetailEditSettings.fixedTitle = "Implementation Considerations";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "monitoring-considerations") {
    sectionDetailEditSettings.fixedTitle = "Monitoring Considerations";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "research-considerations") {
    sectionDetailEditSettings.fixedTitle = "Research Considerations";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "evidence") {
    sectionDetailEditSettings.fixedTitle = "Evidence";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add ArtifactAssessment, Composition, or Evidence Resource";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ArtifactAssessment", "Composition", "Evidence"];
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.editableSectionCodes = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }

  return sectionDetailEditSettings;
}

export { RecommendationAuthor, changeSectionDetailEditSettingsForRecommendation };