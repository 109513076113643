import React, { useState, useContext, useEffect, useImperativeHandle } from 'react';
import { getStringFromFHIR, DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import { NarrativeEntry } from './NarrativeEntry';
import './App.css';
import { changeSectionDetailEditSettingsForEvidenceReportPackage } from './EvidenceReportPackageAuthoringTool';
import { changeSectionDetailEditSettingsForM11Report } from './M11ReportAuthoringTool';
import { changeSectionDetailEditSettingsForGuideline } from './GuidelineAuthoringTool';
import { changeSectionDetailEditSettingsForRecommendation } from './RecommendationAuthoringTool';
import { changeSectionDetailEditSettingsForComparativeEvidenceReport } from './ComparativeEvidenceReportAuthor';
import { changeSectionDetailEditSettingsForComparativeEvidenceSynthesisReport } from './ComparativeEvidenceSynthesisReportAuthor';
import FevirContext from './FevirContext';
import { useToasts } from 'react-toast-notifications';
import { Button, Modal } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import submitToFevirServer from './SubmitToFevirServer';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';

const SectionDetail = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState, resourceState, setResourceState,
  sourceJsonState, setSourceJsonState }) => {
  if (fhirEntryState.editMode) {
    return <SectionDetailEdit formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      resourceState={resourceState} setResourceState={setResourceState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} />
  } else {
    return <SectionDetailDisplay fhirEntryState={fhirEntryState} />
  }
}

const SectionDetailDisplay = ({ fhirEntryState }) => {

  let section;
  let sectionWasSelected = fhirEntryState.sectionPathIndexes?.length > 0;
  if (sectionWasSelected) {
    section = fhirEntryState.sectionSelected;
  } else {
    section = JSON.parse(fhirEntryState.fhirEntryString).section; //this is the highest-level section array for the Resource
  }

  const [sectionJsonExpandedState, setSectionJsonExpandedState] = useState(false);

  return <div>
    <div style={{ marginTop: "12px" }}>
      {sectionWasSelected ?
        <>
          <h2 style={{ margin: "0px" }}>{fhirEntryState.sectionName || section.title || section.code?.text ||
            section.code?.coding?.[0]?.display || section.code?.coding?.[0]?.code}</h2>
          <br />
          <span style={{ whiteSpace: "pre-wrap" }}>
            <div style={{ marginTop: "12px" }}>
              <h3 id="identification">Identification</h3>
              <div style={{ marginLeft: "24px" }}>
                {section.title && <div><b>Title: </b>{section.title}<br /><br /></div>}
                {section.code && <div><b>Section Code: </b><DisplayFromFHIR codeableConcept={section.code} /><br /><br /></div>}
                {section.focus && <div><b>Section Focus: </b><DisplayFromFHIR reference={section.focus} /><br /><br /></div>}
              </div>
              <h3 id="narrative">Narrative</h3>
              {section.text && <div>
                <p><b>Narrative Status: </b>{section.text.status}</p>
                <div>
                  <b>Narrative Summary: </b>
                  <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
                    <DisplayFromFHIR xhtml={section.text.div} />
                  </div>
                </div>
                <br />
              </div>}
              {(Array.isArray(section.author) && section.author.length > 0) &&
                section.author.map((author, authorIndex) => {
                  return <div key={authorIndex}>
                    <b>Author: </b><DisplayFromFHIR reference={author} />
                  </div>;
                })}
              {((Array.isArray(section.entry) && section.entry.length > 0) ||
                (Array.isArray(section.section) && section.section.length > 0) ||
                section.emptyReason) && <div>
                  <h3 id="section-content">Section Content</h3>
                  {(Array.isArray(section.entry) && section.entry.length > 0) &&
                    section.entry.map((entry, entryIndex) => {
                      return <div key={entryIndex}>
                        <b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} />
                      </div>;
                    })}
                  {(Array.isArray(section.section) && section.section.length > 0) &&
                    section.section.map((subsection, subsectionIndex) => {
                      let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code
                      return <span key={subsectionIndex}>
                        <p><b>Section: </b>{sectionName}</p>
                      </span>;
                    })}
                  {section.orderedBy && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={section.orderedBy} /><br /></span>}
                  {(section.emptyReason && (!section.section || section.section.length === 0) &&
                    (!section.entry || section.entry.length === 0) &&
                    (!section.text || section.text.status === "empty")) && <div>
                      <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={section.emptyReason} /><br /></span>
                    </div>}
                </div>}
            </div>
            <br />
          </span>
          <h3 id="json-outline">JSON Outline</h3>
          <SimpleResourceFieldViewer resource={section} parentElement={""} />
          <br /><br />
        </>
        :
        <>
          <span>Please select a section</span>
          <br />
        </>
      }
      <br />
      <div>
        <span>
          <b>JSON</b> <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => {
              setSectionJsonExpandedState(prevState => {
                return !prevState;
              })
            }}>{sectionJsonExpandedState ? <>▼</> : <>►</>}</span>
        </span>
        <br />
        {sectionJsonExpandedState &&
          <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>{JSON.stringify(section, null, 2)}</div>}
      </div>
    </div>
  </div>
}

const submitCreateCitation = async (addToast, globalContext, compositionFOI, compositionTitle,
  createCitationModalState, setCreateCitationModalState, addCitationToEntry) => {
  if (createCitationModalState.title) {
    const body = {
      'functionid': 'createcitationforreferencelist', //modeled from submitaddcitationtoproject
      'idToken': "",
      'title': createCitationModalState.title,
      'pmid': createCitationModalState.pmid,
      'description': createCitationModalState.description,
      'compositionFOI': compositionFOI,
      'cmpositionTitle': compositionTitle,
      'username': globalContext.userState.name
    };
    try {
      let response = await submitToFevirServer(globalContext, 10000, body, true, false);
      if (response?.success) {
        if (response.citationFOI) {
          addCitationToEntry(response.citationFOI, createCitationModalState.title);
        }
        addToast('Your Citation for this reference list has been submitted.', { appearance: 'success' });
        setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: false, "title": "", "pmid": "", "description": "" }; });
        window.open("/resources/Citation/" + response.citationFOI, "_blank"); //opens newly created Citation resource in a new tab
      } else {
        alert("There was a problem with your submission. Perhaps the PMID you provided is non-existant");
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    alert("A title is required.");
  }
};

const CreateCitationModal = ({ addToast, globalContext, compositionFOI, compositionTitle,
  createCitationModalState, setCreateCitationModalState, addCitationToEntry }) => {
  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />
    <h3>Create and Add Citation to Reference List</h3>
    <br />
    <TextField style={{ width: "580px" }} className="inputField" type='text' label={'Citation Title'}
      size="small" variant='outlined' value={createCitationModalState.title}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, title: e.target.value }; });
      }} />
    <br /><br />
    <TextField style={{ width: "280px" }} className="inputField" type='number' label={'PMID (optional)'}
      size="small" variant='outlined' value={createCitationModalState.pmid}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, pmid: e.target.value }; });
      }} />
    <br /><br />
    <TextField style={{ width: "580px" }} multiline className="inputField" type='text'
      label={'Citation Description (optional)'} size="small" variant='outlined'
      value={createCitationModalState.description}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, description: e.target.value }; });
      }} />
    <br /><br />
    <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
      content="Submit Citation" positive
      onClick={() => {
        submitCreateCitation(addToast, globalContext, compositionFOI, compositionTitle,
          createCitationModalState, setCreateCitationModalState, addCitationToEntry);
      }}
    />
    <br />
  </div>;
  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={createCitationModalState?.modalOpen}
      centered={false}
      content={modalContent}
    />
  )
}

const SectionDetailEdit = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState, resourceState, setResourceState,
  sourceJsonState, setSourceJsonState }) => {
  const globalContext = useContext(FevirContext);
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let sectionDetailEditSettings = {
    editCode: false, editCodeCodeableConceptLevelValueSet: "",
    editTextStatus: true, editTextDiv: true, textDeletable: true, emptyTextDivValue: "", editTextFunction: "",
    generateTextDivFunction: "", generateNarrativeFromSingleEntryFunction: "", generateNarrativeFromEntryArrayFunction: "",
    entryFoiList: [],
    editAuthor: true, editFocus: true, editFocusAddElementValues: "",
    entryEnableCreation: true,
    noEntry: false, entryHeader: "", entryStartingResourceType: "", entryReferencedResourceTypes: [],
    entrySetProfile: "", entrySelectProfile: null, entrySelectAnyProfile: false, entryAddElementValues: "",
    editOrderedBy: true, editEmptyReason: true, noSection: false, addSectionAllowed: true,
    adaptationReportState: "", setAdaptationReportState: "", setChangeAvailableToSaveState: "",
    createCitation: "", fixedTitle: "", compositionId: resourceId, compositionTitle: "",
    usesLoadSourceJson: false
  };

  let section;
  let sectionWasSelected = fhirEntryState.sectionPathIndexes?.length > 0;
  if (sectionWasSelected) {
    section = fhirEntryState.sectionSelected;
    sectionDetailEditSettings = changeSectionDetailEditSettingsForEvidenceReportPackage(sectionDetailEditSettings, section);
    if (fhirEntryState.compositionProfiles?.length) {
      if (fhirEntryState.compositionProfiles.includes("guidelineProfile")) {
        sectionDetailEditSettings = changeSectionDetailEditSettingsForGuideline(sectionDetailEditSettings, section);
      }
      if (fhirEntryState.compositionProfiles.includes("recommendationProfile")) {
        sectionDetailEditSettings = changeSectionDetailEditSettingsForRecommendation(sectionDetailEditSettings, section);
      }
      if (fhirEntryState.compositionProfiles.includes("comparativeEvidenceReportProfile")) {
        sectionDetailEditSettings = changeSectionDetailEditSettingsForComparativeEvidenceReport(sectionDetailEditSettings, section);
      }
      if (fhirEntryState.compositionProfiles.includes("comparativeEvidenceSynthesisReportProfile")) {
        sectionDetailEditSettings = changeSectionDetailEditSettingsForComparativeEvidenceSynthesisReport(sectionDetailEditSettings, section, resourceDictionary);
      }
      if (fhirEntryState.compositionProfiles.includes("m11reportProfile")) {
        sectionDetailEditSettings = changeSectionDetailEditSettingsForM11Report(sectionDetailEditSettings, section);
      }
    }
  } else {
    section = JSON.parse(fhirEntryState.fhirEntryString).section; //this is the highest-level section array for the Resource
  }

  let editCode = sectionDetailEditSettings.editCode;
  let editCodeCodeableConceptLevelValueSet = sectionDetailEditSettings.editCodeCodeableConceptLevelValueSet;
  let editTextStatus = sectionDetailEditSettings.editTextStatus;
  let editTextDiv = sectionDetailEditSettings.editTextDiv;
  let textDeletable = sectionDetailEditSettings.textDeletable;
  let emptyTextDivValue = sectionDetailEditSettings.emptyTextDivValue;
  let editTextFunction = sectionDetailEditSettings.editTextFunction;
  let generateTextDivFunction = sectionDetailEditSettings.generateTextDivFunction;
  let generateNarrativeFromSingleEntryFunction = sectionDetailEditSettings.generateNarrativeFromSingleEntryFunction;
  let generateNarrativeFromEntryArrayFunction = sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction;
  let entryFoiList = sectionDetailEditSettings.entryFoiList;
  let editAuthor = sectionDetailEditSettings.editAuthor;
  let editFocus = sectionDetailEditSettings.editFocus;
  let editFocusAddElementValues = sectionDetailEditSettings.editFocusAddElementValues;
  let noEntry = sectionDetailEditSettings.noEntry;
  let entryDeletable = sectionDetailEditSettings.entryDeletable;
  let entryInstanceDeletable = sectionDetailEditSettings.entryInstanceDeletable;
  let entryEnableCreation = sectionDetailEditSettings.entryEnableCreation;
  let entryHeader = sectionDetailEditSettings.entryHeader;
  let entryStartingResourceType = sectionDetailEditSettings.entryStartingResourceType;
  let entryReferencedResourceTypes = sectionDetailEditSettings.entryReferencedResourceTypes;
  let entrySetProfile = sectionDetailEditSettings.entrySetProfile;
  let entrySelectProfile = sectionDetailEditSettings.entrySelectProfile;
  let entrySelectAnyProfile = sectionDetailEditSettings.entrySelectAnyProfile;
  let entryAddElementValues = sectionDetailEditSettings.entryAddElementValues;
  let editOrderedBy = sectionDetailEditSettings.editOrderedBy;
  let noSection = sectionDetailEditSettings.noSection;
  let editEmptyReason = sectionDetailEditSettings.editEmptyReason;
  let adaptationReportState = sectionDetailEditSettings.adaptationReportState;
  let setAdaptationReportState = sectionDetailEditSettings.setAdaptationReportState;
  let setChangeAvailableToSaveState = sectionDetailEditSettings.setChangeAvailableToSaveState;
  let createCitation = sectionDetailEditSettings.createCitation;
  let fixedTitle = sectionDetailEditSettings.fixedTitle;
  let compositionId = sectionDetailEditSettings.compositionId;
  let compositionTitle = sectionDetailEditSettings.compositionTitle;
  let starterSection = sectionDetailEditSettings.starterSection;
  let addSectionAllowed = sectionDetailEditSettings.addSectionAllowed;
  let addSectionFixedCodeNoText = sectionDetailEditSettings.addSectionFixedCodeNoText;
  let addNewOutcomeSectionsFunction = sectionDetailEditSettings.addNewOutcomeSectionsFunction;
  let editableSectionCodes = sectionDetailEditSettings.editableSectionCodes;
  let fixedSectionCode = sectionDetailEditSettings.fixedSectionCode;
  let createRecommendation = sectionDetailEditSettings.createRecommendation;
  let SummaryOfFindingsEdit = sectionDetailEditSettings.SummaryOfFindingsEdit;
  let RecommendationSpecificationEdit = sectionDetailEditSettings.RecommendationSpecificationEdit;
  let RecommendationConsiderationsEdit = sectionDetailEditSettings.RecommendationConsiderationsEdit;
  let usesLoadSourceJson = sectionDetailEditSettings.usesLoadSourceJson;

  let sectionPathIndexes = fhirEntryState.sectionPathIndexes;
  let sectionCode = getStringFromFHIR.CodeableConcept(section.code);
  let sectionName = section.title || section.code?.text || section.code?.coding?.[0]?.display || section.code?.coding?.[0]?.code;

  if (entryAddElementValues) {
    if (!entryAddElementValues.relatedArtifact && !entryAddElementValues.relatesTo) {
      entryAddElementValues.relatedArtifact = [
        {
          "type": "part-of",
          "resourceReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ];
    }
  } else {
    entryAddElementValues = {
      relatedArtifact: [
        {
          "type": "part-of",
          "resourceReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ]
    };
  }
  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];
  let orderedByValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
  ];
  let orderedByCodeableConceptLevelValueSet = [
    {
      "coding": [{ "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
      ]
    }
  ];
  let emptyReasonCodeableConceptLevelValueSet = [
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }]
    },
    {
      "coding":
        [{ "system": "https://fevir.net/resources/CodeSystem/179423", "code": "not-relevant", "display": "Not Relevant" }]
    }
  ];
  let timestamp = new Date();

  let startingSection = {
    title: "", code: { "text": "section created " + timestamp }, author: [], focus: "", text: "", orderedBy: "",
    entry: [], emptyReason: "", section: [], sectionPathIndexes: sectionPathIndexes
  }
  if (!section) {
    section = "";
  } else {
    if (section.extension) { startingSection.extension = section.extension; }
    if (section.title) { startingSection.title = section.title; }
    if (section.code) { startingSection.code = section.code; }
    if (section.author) { startingSection.author = section.author; }
    if (section.focus) { startingSection.focus = section.focus; }
    if (section.text) { startingSection.text = section.text; }
    if (section.orderedBy) { startingSection.orderedBy = section.orderedBy; }
    if (section.entry) { startingSection.entry = section.entry; }
    if (section.emptyReason) { startingSection.emptyReason = section.emptyReason; }
    if (section.section) { startingSection.section = section.section; } else if (starterSection) {
      startingSection.section = starterSection;
    }
  }

  const [sectionState, setSectionState] = useState(startingSection);
  const [editAuthorCollapsedState, setEditAuthorCollapsedState] = useState(true);
  const { addToast } = useToasts();
  const [createCitationModalState, setCreateCitationModalState] = useState({
    "modalOpen": false,
    "title": "", "pmid": "", "description": ""
  });
  const [citationEntryRevisedState, setCitationEntryRevisedState] = useState(false);
  const [addedSectionState, setAddedSectionState] = useState({ "title": "", "textDiv": "" });

  const addCitationToEntry = (citationFOI, title) => {
    setSectionState(prevState => {
      let newEntry = prevState.entry || [];
      newEntry.push({
        "reference": "Citation/" + citationFOI,
        "type": "Citation", "display": title
      });
      return { ...prevState, "entry": newEntry };
    });
    setCitationEntryRevisedState(true);
  };

  useEffect(() => {
    if (citationEntryRevisedState) {
      alert("Citation added."); //This alert appears at the right time but does not re-render the component
      setCitationEntryRevisedState(false);
    }
  }, [citationEntryRevisedState]);

  useEffect(() => {
    if (addedSectionState.addSection && addedSectionState.title) {
      let sectionToBeAdded = { "title": addedSectionState.title };
      if (addedSectionState.textDiv) {
        sectionToBeAdded.text = {
          "status": "additional",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + addedSectionState.textDiv + "</div>"
        }
      }
      if (createRecommendation) {
        sectionToBeAdded.code = { "text": "recommendations" };
      } else if (createCitation) {
        sectionToBeAdded.code = { "text": "references-section" };
      }
      if (addedSectionState.code) {
        sectionToBeAdded.code = addedSectionState.code;
      }
      setSectionState(prevState => {
        let newSection = prevState.section || [];
        newSection.push(sectionToBeAdded);
        return { ...prevState, "section": newSection };
      });
      setAddedSectionState({ "title": "", "textDiv": "" });
    }
  }, [addedSectionState]);

  useEffect((() => {
    if (sectionWasSelected && Object.keys(sectionState).length > 0) {
      let newEmptyReason = false;
      let deleteEmptyReason = false;
      if (sectionState.section?.length || sectionState.entry?.length ||
        (sectionState.text && sectionState.text.status !== "empty")) {
        if (sectionState.emptyReason) {
          deleteEmptyReason = true;
        }
      } else if (!sectionState.emptyReason) {
        newEmptyReason = {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted", "display": "Not Started"
          }]
        }
      }
      if (deleteEmptyReason) {
        setSectionState(prevState => {
          let newState = prevState;
          delete newState.emptyReason;
          return newState;
        });
      } else if (newEmptyReason) {
        setSectionState(prevState => {
          return { ...prevState, emptyReason: newEmptyReason };
        });
      }
      if (JSON.stringify(sectionState.text) !== JSON.stringify(startingSection.text) ||
        sectionState.title !== startingSection.title ||
        JSON.stringify(sectionState.section) !== JSON.stringify(startingSection.section) ||
        JSON.stringify(sectionState.code) !== JSON.stringify(startingSection.code) ||
        JSON.stringify(sectionState.emptyReason) !== JSON.stringify(startingSection.emptyReason) ||
        (sectionState.entry?.length &&
          ((sectionState.entry[0]?.reference &&
            JSON.stringify(sectionState.entry[0].reference) !== JSON.stringify(startingSection.entry?.[0]?.reference)) ||
            (sectionState.entry[1] && sectionState.entry[1]?.reference &&
              JSON.stringify(sectionState.entry[1].reference) !== JSON.stringify(startingSection.entry?.[1]?.reference))))) {
        setFhirEntryState(prevState => {
          return { ...prevState, sectionChanged: true }
        });
      }
    }
  }), [sectionState]);

  useImperativeHandle(formInputsStateRef, () => ({
    sectionState,
  }), [sectionState]);


  const loadSourceJson = () => {
    if (usesLoadSourceJson) {
      if (!sourceJsonState.loaded || !sourceJsonState.loaded2) {
        loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState, setResourceState);
      }
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState, resourceState, fhirEntryState.sectionChanged]);

  if (SummaryOfFindingsEdit) {
    return <SummaryOfFindingsEdit section={section}
      formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      resourceState={resourceState} setResourceState={setResourceState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} />
  }

  if (RecommendationSpecificationEdit) {
    return <RecommendationSpecificationEdit section={section}
      formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      resourceState={resourceState} setResourceState={setResourceState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} />
  }

  if (RecommendationConsiderationsEdit) {
    return <RecommendationConsiderationsEdit section={section}
      formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      resourceState={resourceState} setResourceState={setResourceState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} />
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      {sectionWasSelected ?
        <>
          <h3 style={{ margin: "0px" }}>{fhirEntryState.sectionName}</h3>
          {createCitation && <>
            <CreateCitationModal addToast={addToast} globalContext={globalContext}
              compositionFOI={resourceState.id} compositionTitle={resourceState.title}
              createCitationModalState={createCitationModalState}
              setCreateCitationModalState={setCreateCitationModalState}
              addCitationToEntry={addCitationToEntry} />
            <Button className="formButton" style={{ color: "#000000", width: "100%" }}
              content={"Create Citation"}
              onClick={async () => {
                setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: true }; });
              }}
              disabled={fhirEntryState.previousVersionLoaded}
            />
            <br /></>}
          <>
            {sectionName && <p><b>{fixedTitle || sectionState.title || sectionName}: </b></p>}
            <div style={{ marginLeft: "24px" }}>
              {fixedTitle
                ?
                <p><b>Title: </b>{fixedTitle}</p>
                :
                <>
                  <DataEntry datatype='string' elementName='title' fieldLabel='Section Title'
                    startingValue={sectionState.title} setResourceState={setSectionState}
                    path={sectionCode + '.title'}
                    adaptationReportState={adaptationReportState}
                    setAdaptationReportState={setAdaptationReportState}
                    setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                </>}
              {editCode && <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Section Code'
                startingValue={sectionState.code}
                systemChoicesOpen={true} startCollapsed={true}
                codeableConceptLevelValueSet={editCodeCodeableConceptLevelValueSet}
                setResourceState={setSectionState} />}
              <NarrativeEntry elementName='text' fieldLabel='Section Summary'
                editTextStatus={editTextStatus} editTextDiv={editTextDiv} emptyTextDivValue={emptyTextDivValue}
                editTextFunction={editTextFunction}
                generateSummaryFunction={generateTextDivFunction}
                generateNarrativeFromSingleEntryFunction={generateNarrativeFromSingleEntryFunction}
                generateNarrativeFromEntryArrayFunction={generateNarrativeFromEntryArrayFunction}
                entryFoi={sectionState.entry?.[0]?.reference?.split("/")[1]}
                entryFoiList={entryFoiList}
                resourceDictionary={resourceDictionary} globalContext={globalContext}
                startingValue={sectionState.text} setResourceState={setSectionState}
                path={sectionCode}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
              {editAuthor && <>
                <span className={"unselectable"} style={{ cursor: "pointer" }}
                  onClick={() => { setEditAuthorCollapsedState(!editAuthorCollapsedState) }}>
                  {editAuthorCollapsedState ? <>
                    <b>Add/Edit Author(s) for the Section ►</b>
                  </> : <>
                    <b>Collapse Section Author Data Entry ▼</b>
                  </>}
                </span>
                <br />
                {!editAuthorCollapsedState && <>
                  <DataEntry asArray={true} datatype='Reference' elementName='author'
                    fieldLabel='Section Author'
                    startingValue={sectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
                    startCollapsed startEmptyArrayClosed
                    setResourceState={setSectionState}
                    path={sectionCode + '.author'}
                    adaptationReportState={adaptationReportState}
                    setAdaptationReportState={setAdaptationReportState}
                    setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                </>
                }
              </>}
              {editFocus && <>
                <h3>Create or identify the Resource defining the focus for this section.</h3>
                <div style={{ marginLeft: "24px" }}>
                  <DataEntry datatype='Reference' elementName='focus'
                    fieldLabel={'EvidenceVariable Resource'}
                    startingValue={sectionState.focus} referencedResourceTypes={["EvidenceVariable"]}
                    startingResourceType={"EvidenceVariable"}
                    startCollapsed={true} enableCreation={true}
                    setResourceState={setSectionState} addElementValues={editFocusAddElementValues}
                    fullResourceState={resourceState}
                    globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
                </div>
              </>}
              {editOrderedBy && <>
                <h3>Ordering of Section Content</h3>
                <div style={{ marginLeft: "24px" }}>
                  <DataEntry datatype='CodeableConcept' elementName='orderedBy' fieldLabel='Ordered by'
                    startingValue={sectionState.orderedBy} valueSet={orderedByValueSet}
                    startCollapsed={true}
                    codeableConceptLevelValueSet={orderedByCodeableConceptLevelValueSet}
                    setResourceState={setSectionState}
                    path={sectionCode + '.orderedBy'}
                    adaptationReportState={adaptationReportState}
                    setAdaptationReportState={setAdaptationReportState}
                    setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                </div>
              </>}
              {!noEntry && <>
                {entryHeader ?
                  <h3>{entryHeader}</h3>
                  :
                  <h3>{sectionName} Section Content (as Resource Entries)</h3>
                }
                <div style={{ marginLeft: "24px" }}>
                  <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel={sectionName + ' Content Entry'}
                    startingValue={sectionState.entry} referencedResourceTypes={entryReferencedResourceTypes}
                    startCollapsed={true}
                    startEmptyArrayClosed={true}
                    deletableArray={entryDeletable ?? true}
                    deletable={entryInstanceDeletable ?? true}
                    enableCreation={entryEnableCreation ?? true}
                    startingResourceType={entryStartingResourceType}
                    setProfile={entrySetProfile}
                    selectProfile={entrySelectProfile} selectAnyProfile={entrySelectAnyProfile}
                    addElementValues={entryAddElementValues}
                    setResourceState={setSectionState} fullResourceState={resourceState}
                    globalContext={globalContext} setSourceJsonState={setSourceJsonState}
                    path={sectionCode + '.entry'}
                    adaptationReportState={adaptationReportState}
                    setAdaptationReportState={setAdaptationReportState}
                    setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                </div>
              </>}
              {!noSection && <p><b>Contained Sections (for text with subheadings):</b></p>}
              {(Array.isArray(sectionState.section) && sectionState.section.length > 0) &&
                sectionState.section.map((subsection, subsectionIndex) => {
                  let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
                  let sectionTextDiv = subsection.text?.div;
                  let sectionTextStatus = subsection.text?.status;
                  return <span key={subsectionIndex}>
                    <p><b>Section: </b>{sectionName}</p>
                    {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                      <p>Narrative status: {sectionTextStatus}</p>
                      <p>Narrative summary:</p>
                      <DisplayFromFHIR xhtml={sectionTextDiv} />
                    </div>}
                  </span>;
                })}
              {(addSectionAllowed && !noSection) && <>
                {addSectionFixedCodeNoText
                  ?
                  <div>
                    <p>Enter a title then click Add Section.</p>
                    <DataEntry datatype='string' elementName='title' fieldLabel='Title'
                      startingValue={addedSectionState.title}
                      setResourceState={setAddedSectionState} />
                    <Button className="formButton" style={{ color: "#000000", width: "100%" }}
                      content={"Add Section"}
                      onClick={() => {
                        setAddedSectionState(prevState => {
                          return {
                            ...prevState,
                            code: addSectionFixedCodeNoText,
                            addSection: true
                          };
                        });
                      }}
                      disabled={!addedSectionState.title} />
                  </div>
                  :
                  <>{addNewOutcomeSectionsFunction
                    ?
                    <>
                      {sectionState.section?.length ?
                        <div>
                          <p>Current section content includes sections for:</p>
                          {sectionState.section.map((sect, sectIndex) => {
                            let sectionDisplay = sect.title || sect.focus?.display || sect.focus?.reference || "[Missing variable identifier.]";
                            return <div key={sectIndex} style={{ marginLeft: "24px" }}>
                              <p>{sectionDisplay}</p>
                            </div>
                          })}
                        </div>
                        :
                        <></>}
                      {sectionState.entry?.length ?
                        <div>
                          <br />
                          <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
                            content="Add new Outcome (Result) sections" positive
                            onClick={() => {
                              addNewOutcomeSectionsFunction(setSectionState);
                            }}
                          />
                        </div>
                        :
                        <></>}
                    </>
                    :
                    <div>
                      <p>Enter a title then click Add Section.</p>
                      <DataEntry datatype='string' elementName='title' fieldLabel='Title'
                        startingValue={addedSectionState.title}
                        setResourceState={setAddedSectionState} />
                      <DataEntry datatype='xhtml' elementName='textDiv' fieldLabel='Narrative Summary'
                        startingValue={addedSectionState.textDiv}
                        setResourceState={setAddedSectionState} />
                      <Button className="formButton" style={{ color: "#000000", width: "100%" }}
                        content={"Add Section"}
                        onClick={() => {
                          setAddedSectionState(prevState => { return { ...prevState, addSection: true }; });
                        }}
                        disabled={!addedSectionState.title} />
                    </div>
                  }
                  </>
                }</>}
              {(editEmptyReason && (!sectionState.section || sectionState.section.length === 0) &&
                (!sectionState.entry || sectionState.entry.length === 0) &&
                (!sectionState.text || sectionState.text.status === "empty")) && <>
                  <h3>Explain why empty</h3>
                  <div style={{ marginLeft: "24px" }}>
                    <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                      startingValue={sectionState.emptyReason}
                      startCollapsed={true}
                      codeableConceptLevelValueSet={emptyReasonCodeableConceptLevelValueSet}
                      setResourceState={setSectionState}
                      path={sectionCode + '.emptyReason'}
                      adaptationReportState={adaptationReportState}
                      setAdaptationReportState={setAdaptationReportState}
                      setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                  </div>
                </>}
            </div>
          </>
          <br /><br />
        </>
        :
        <>
          <span>Please select a section</span>
          <br />
          <br />
        </>
      }
    </div>
  </div>
}

//function copied below to check for concepts to include
/** 
const DocumentSectionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  startCollapsed, sectionArrayIndex,
  fixedTitle, editTitle, fixedCode, editCode, editCodeAllowedValues, inTableCell,
  editCodeValueSet, editCodeInTableCell, editCodeSystemChoices, editCodeSystemChoicesOpen, editCodeStartCollapsed,
  editCodeCodeableConceptLevelValueSet, editAuthor, editFocus, editFocusEnableCreation, editFocusReferencedResourceTypes,
  editFocusStartCollapsed, editFocusStartingResourceType,
  editFocusSetProfile, editFocusSelectProfile, editFocusSelectAnyProfile, editFocusAddElementValues,
  textStatusAutomatic, editTextStatus, editTextDiv, textDeletable, generateTextDivFunction,
  autogenerateTextDiv, emptyTextDivValue, editTextFunction, generateNarrativeFromSingleEntryFunction,
  generateNarrativeFromEntryArrayFunction, entryFoiList,
  fixedOrderedBy, editOrderedBy, editOrderedByAllowedValues, editOrderedByValueSet, editOrderedByInTableCell,
  editOrderedBySystemChoices, editOrderedBySystemChoicesOpen, editOrderedByStartCollapsed,
  editOrderedByCodeableConceptLevelValueSet, entryLimitToOne, entryLimitToTwo, noEntry, entryHeader,
  entryDeletable, entryInstanceDeletable, entryEnableCreation, entryReferencedResourceTypes,
  statisticType, statisticUnit, knownDataCount, createCitation,
  entryStartCollapsed, entryStartEmptyArrayClosed, entryStartingResourceType, entrySetProfile, entrySelectProfile, entrySelectAnyProfile,
  entryAddElementValues, fixedEmptyReason, editEmptyReason, emptyReasonAllowedValues, emptyReasonValueSet,
  emptyReasonInTableCell, emptyReasonSystemChoices, emptyReasonSystemChoicesOpen, emptyReasonStartCollapsed,
  emptyReasonCodeableConceptLevelValueSet, noSection, editSection, containedSectionsHeader,
  addSectionAllowed, sectionEntryRecommendationOnly,
  allowedSectionCodes, requiredSectionCodes, editableSectionCodes, entryResourceEntry,
  topSection, sectionDictionary, resourceDictionary, setSourceJsonState, globalContext,
  resourceState, previousVersionLoaded,
  compositionId, compositionTitle, sectionCode,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  if (adaptationReportState?.pathList && !sectionCode && startingValue?.code) {
    if (startingValue.code.text) {
      sectionCode = "section:" + startingValue.code.text;
    } else if (startingValue.code.coding?.[0]) {
      sectionCode = "section:" + startingValue.code.coding[0].system + "#" + startingValue.code.coding[0].code;
    } else {
      sectionCode = "section:" + JSON.stringify(startingValue.code);
    }
  }

  if (entryAddElementValues) {
    if (!entryAddElementValues.relatedArtifact && !entryAddElementValues.relatesTo) {
      entryAddElementValues.relatedArtifact = [
        {
          "type": "part-of",
          "resourceReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ];
    }
  } else {
    entryAddElementValues = {
      relatedArtifact: [
        {
          "type": "part-of",
          "resourceReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ]
    };
  }

  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];
  let orderedByValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
  ];
  let orderedByCodeableConceptLevelValueSet = [
    {
      "coding": [{ "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
      ]
    }
  ];
  let timestamp = new Date();
  let startingSection = {
    title: "", code: { "text": "section created " + timestamp }, author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }
  if (fixedTitle) {
    startingSection.title = fixedTitle;
  }
  if (fixedCode) {
    startingSection.code = fixedCode;
  }
  if (fixedOrderedBy) {
    startingSection.orderedBy = fixedOrderedBy;
  }

  const [sectionState, setSectionState] = useState(startingSection);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);
  const [editAuthorCollapsedState, setEditAuthorCollapsedState] = useState(true);
  const { addToast } = useToasts();
  const [createCitationModalState, setCreateCitationModalState] = useState({
    "modalOpen": false,
    "title": "", "pmid": "", "description": ""
  });
  const [citationEntryRevisedState, setCitationEntryRevisedState] = useState(false);

  const addCitationToEntry = (citationFOI, title) => {
    setSectionState(prevState => {
      let newEntry = prevState.entry || [];
      newEntry.push({
        "reference": "Citation/" + citationFOI,
        "type": "Citation", "display": title
      });
      return { ...prevState, "entry": newEntry };
    });
    setCitationEntryRevisedState(true);
  };

  useEffect(() => {
    if (citationEntryRevisedState) {
      alert("Citation added."); //This alert appears at the right time but does not re-render the component
      setCitationEntryRevisedState(false);
    }
  }, [citationEntryRevisedState]);

  useEffect((() => {
    if (Object.keys(sectionState).length > 0) {
      let newSection = {};
      if (sectionState.extension) { newSection.extension = sectionState.extension; }
      if (sectionState.title) { newSection.title = sectionState.title; }
      if (sectionState.code) { newSection.code = sectionState.code; }
      if (Array.isArray(sectionState.author) && sectionState.author.length > 0) {
        newSection.author = sectionState.author;
      }
      if (sectionState.focus) { newSection.focus = sectionState.focus; }
      if (sectionState.text) { newSection.text = sectionState.text; }
      if (sectionState.orderedBy) { newSection.orderedBy = sectionState.orderedBy; }
      if (Array.isArray(sectionState.entry) && sectionState.entry.length > 0) {
        newSection.entry = sectionState.entry;
      }
      if (sectionState.emptyReason) { newSection.emptyReason = sectionState.emptyReason; }
      if (Array.isArray(sectionState.section) && sectionState.section.length > 0) {
        newSection.section = sectionState.section;
      }
      if (newSection.section?.length || newSection.entry?.length ||
        (newSection.text && newSection.text.status !== "empty")) {
        delete newSection.emptyReason;
      } else if (!newSection.emptyReason) {
        newSection.emptyReason = {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted", "display": "Not Started"
          }]
        }
      }
      if (Object.keys(newSection).length === 0) {
        newSection = null;
      }
      if (sectionArrayIndex || sectionArrayIndex === 0 || sectionArrayIndex === "0") {
        setResourceState(prevState => {
          let newSectionArray = prevState.section || [];
          newSectionArray[sectionArrayIndex] = newSection;
          return { ...prevState, "section": newSectionArray };
        });
      } else if (elementName || elementName === 0 || elementName === "0") {
        handleChange(elementName, newSection, setResourceState);
      }
    }
  }), [sectionState]);

  if (startCollapsedState) {
    return <>
      <div>
        {fieldLabel && <b>{fixedTitle || sectionState.title || fieldLabel}: </b>}
        {startingValue && <div style={{ marginLeft: "24px" }}>
          {(startingSection.title && !inTableCell) && <p><b>Title: </b>{startingSection.title}</p>}
          {startingSection.text?.div &&
            <div><b>Summary: </b><DisplayFromFHIR xhtml={startingSection.text.div} /></div>}
          {startingSection.entry?.length > 0 && <>
            {startingSection.entry.length > 1 ? <><br />Contains {startingSection.entry.length} entries</>
              :
              <><br /><DisplayFromFHIR reference={startingSection.entry[0]} /></>}
          </>}
          {startingSection.section?.length > 0 && <>
            {inTableCell ? <><br />Contains {startingSection.section.length} subsections</> : <>
              <div>
                <p><b>Subsections:</b></p>
                {startingSection.section.map((section, sectionIndex) => {
                  return <div key={sectionIndex} style={{ marginLeft: "24px" }}>
                    {section.title && <span><b>Title: </b>{section.title}</span>}
                    {section.text?.div && <div><br /><b>Summary: </b><DisplayFromFHIR xhtml={section.text.div} /></div>}
                  </div>
                })}
              </div>
            </>}</>}
          <br />
        </div>}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
        &nbsp;&nbsp;{!inTableCell && (fixedTitle || sectionState.title || fieldLabel)}
      </div>
    </>
  } else {
    return <>
      {createCitation && <>
        <CreateCitationModal addToast={addToast} globalContext={globalContext}
          compositionFOI={resourceState.id} compositionTitle={resourceState.title}
          createCitationModalState={createCitationModalState}
          setCreateCitationModalState={setCreateCitationModalState}
          addCitationToEntry={addCitationToEntry} />
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={"Create Citation"}
          onClick={async () => {
            setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: true }; });
          }}
          disabled={previousVersionLoaded}
        />
        <br /></>}
      {fieldLabel && <p><b>{fixedTitle || sectionState.title || fieldLabel}: </b></p>}
      <div style={{ marginLeft: "24px" }}>
        {fixedTitle && <p><b>Title: </b>{fixedTitle}</p>}
        {editTitle && <>
          <DataEntry datatype='string' elementName='title' fieldLabel='Section Title'
            startingValue={sectionState.title} setResourceState={setSectionState}
            path={sectionCode + '.title'}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </>}
        {editCode && <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Section Code'
          startingValue={sectionState.code} allowedValues={editCodeAllowedValues}
          valueSet={editCodeValueSet} inTableCell={editCodeInTableCell} systemChoices={editCodeSystemChoices}
          systemChoicesOpen={editCodeSystemChoicesOpen} startCollapsed={editCodeStartCollapsed ?? true}
          codeableConceptLevelValueSet={editCodeCodeableConceptLevelValueSet}
          setResourceState={setSectionState} />}
        {(textStatusAutomatic || editTextStatus || editTextDiv || textDeletable ||
          generateTextDivFunction || autogenerateTextDiv || editTextFunction) &&
          <NarrativeEntry elementName='text' fieldLabel='Section Summary'
            editTextStatus={editTextStatus} editTextDiv={editTextDiv} emptyTextDivValue={emptyTextDivValue}
            editTextFunction={editTextFunction}
            generateSummaryFunction={generateTextDivFunction}
            generateNarrativeFromSingleEntryFunction={generateNarrativeFromSingleEntryFunction}
            generateNarrativeFromEntryArrayFunction={generateNarrativeFromEntryArrayFunction}
            entryFoi={sectionState.entry?.[0]?.reference?.split("/")[1]}
            entryFoiList={entryFoiList}
            resourceDictionary={resourceDictionary} globalContext={globalContext}
            startingValue={sectionState.text} setResourceState={setSectionState}
            path={sectionCode}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />}
        {editAuthor && <>
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setEditAuthorCollapsedState(!editAuthorCollapsedState) }}>
            {editAuthorCollapsedState ? <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </> : <>
              <b>Collapse Section Author Data Entry ▼</b>
            </>}
          </span>
          <br />
          {!editAuthorCollapsedState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author'
              fieldLabel='Section Author'
              startingValue={sectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              startCollapsed startEmptyArrayClosed
              setResourceState={setSectionState}
              path={sectionCode + '.author'}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </>
          }
        </>}
        {editFocus && <>
          {editFocusEnableCreation ?
            <h3>Create or identify the Resource defining the focus for this section.</h3>
            :
            <h3>Identify the Resource defining the focus for this section.</h3>}
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='Reference' elementName='focus'
              fieldLabel={editFocusStartingResourceType + ' Resource'}
              startingValue={sectionState.focus} referencedResourceTypes={editFocusReferencedResourceTypes}
              startingResourceType={editFocusStartingResourceType} setProfile={editFocusSetProfile}
              selectProfile={editFocusSelectProfile} selectAnyProfile={editFocusSelectAnyProfile}
              startCollapsed={editFocusStartCollapsed ?? true} enableCreation={editFocusEnableCreation}
              setResourceState={setSectionState} addElementValues={editFocusAddElementValues}
              fullResourceState={resourceState}
              globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
          </div>
        </>}
        {editOrderedBy && <>
          <h3>Ordering of Section Content</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='orderedBy' fieldLabel='Ordered by'
              startingValue={sectionState.orderedBy} valueSet={editOrderedByValueSet || orderedByValueSet}
              startCollapsed={editOrderedByStartCollapsed ?? true}
              codeableConceptLevelValueSet={editOrderedByCodeableConceptLevelValueSet || orderedByCodeableConceptLevelValueSet}
              allowedValues={editOrderedByAllowedValues} inTableCell={editOrderedByInTableCell}
              systemChoices={editOrderedBySystemChoices} systemChoicesOpen={editOrderedBySystemChoicesOpen}
              setResourceState={setSectionState}
              path={sectionCode + '.orderedBy'}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
        </>}
        {!noEntry && <>
          {entryHeader ?
            <h3>{entryHeader}</h3>
            :
            <h3>{fieldLabel} Section Content (as Resource Entries)</h3>
          }
          <div style={{ marginLeft: "24px" }}>
            {entryResourceEntry === "SingleEvidenceEntry" ?
              <SingleEvidenceEntry startingValue={sectionState.entry}
                statisticType={statisticType} statisticUnit={statisticUnit}
                knownDataCount={knownDataCount}
                addElementValues={entryAddElementValues}
                resourceDictionary={resourceDictionary} globalContext={globalContext}
                setResourceState={setSectionState} resourceState={resourceState}
                setSourceJsonState={setSourceJsonState} />
              :
              <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel={fieldLabel + ' Content Entry'}
                startingValue={sectionState.entry} referencedResourceTypes={entryReferencedResourceTypes}
                startCollapsed={entryStartCollapsed || sectionEntryRecommendationOnly}
                startEmptyArrayClosed={entryStartEmptyArrayClosed || sectionEntryRecommendationOnly}
                inTableCell={inTableCell}
                deletableArray={entryDeletable || sectionEntryRecommendationOnly}
                deletable={entryInstanceDeletable || sectionEntryRecommendationOnly}
                enableCreation={entryEnableCreation || sectionEntryRecommendationOnly}
                startingResourceType={entryStartingResourceType ? entryStartingResourceType : sectionEntryRecommendationOnly && "Composition"}
                setProfile={entrySetProfile ? entrySetProfile : sectionEntryRecommendationOnly && "Recommendation"}
                selectProfile={entrySelectProfile} selectAnyProfile={entrySelectAnyProfile}
                addElementValues={entryAddElementValues}
                setResourceState={setSectionState} fullResourceState={resourceState}
                globalContext={globalContext} setSourceJsonState={setSourceJsonState}
                path={sectionCode + '.entry'}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            }
          </div>
        </>}
        {editSection && <>
          {containedSectionsHeader ?
            <p><b>{containedSectionsHeader}</b></p>
            :
            <p><b>Contained Sections (for text with subheadings):</b></p>
          }
          <SectionArrayDataEntry startingValue={sectionState.section} setResourceState={setSectionState}
            addSectionAllowed={addSectionAllowed}
            allowedSectionCodes={allowedSectionCodes}
            requiredSectionCodes={requiredSectionCodes}
            editableSectionCodes={editableSectionCodes}
            sectionDictionary={sectionDictionary}
            topSection={sectionState.title || topSection + " subsection"}
            compositionId={compositionId} compositionTitle={compositionTitle}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState}
            globalContext={globalContext}
            sectionEntryRecommendationOnly={sectionEntryRecommendationOnly}
            entryReferencedResourceTypes={entryReferencedResourceTypes}
            entryResourceEntry={entryResourceEntry}
            resourceState={resourceState} setSourceJsonState={setSourceJsonState}
          />
        </>}
        {(editEmptyReason && (!sectionState.section || sectionState.section.length === 0) &&
          (!sectionState.entry || sectionState.entry.length === 0) &&
          (!sectionState.text || sectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={sectionState.emptyReason} valueSet={emptyReasonValueSet}
                startCollapsed={emptyReasonStartCollapsed || inTableCell}
                allowedValues={emptyReasonAllowedValues} inTableCell={emptyReasonInTableCell || inTableCell} systemChoices={emptyReasonSystemChoices}
                systemChoicesOpen={emptyReasonSystemChoicesOpen} codeableConceptLevelValueSet={emptyReasonCodeableConceptLevelValueSet}
                setResourceState={setSectionState}
                path={sectionCode + '.emptyReason'}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            </div>
          </>}
      </div>
    </>
  }
})
  */

export default SectionDetail;