import React, { useEffect, useImperativeHandle, useState } from 'react';
import { AssociatedResourcesDisplay, ReorganizeSectionsModal, SimpleResourceFieldViewer } from './ResourceFunctions';
import { DataEntry, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import { EvidenceReportMetadataPatternEdit } from './EvidenceReportMetadataDataEntry';
import { Button } from 'semantic-ui-react';
import ActivityDefinitionBuilder from './ActivityDefinitionBuilder';
import BundleBuilder from './BundleBuilder';
import CodeSystemBuilder from './CodeSystemBuilder';
import EvidenceBuilder from './EvidenceBuilder';
import EvidenceVariableBuilder from './EvidenceVariableBuilder';
import GroupBuilder from './GroupBuilder';
import LibraryBuilder from './LibraryBuilder';
import ListBuilder from './ListBuilder';
import MeasureBuilder from './MeasureBuilder';
import PlanDefinitionBuilder from './PlanDefinitionBuilder';
import ResearchStudyBuilder from './ResearchStudyBuilder';
import ValueSetBuilder from './ValueSetBuilder';
import resourceElementNames from './resourceElementNames';
import CompositionAuthor from './CompositionAuthor';
import CompositionMeasureReportAuthor from './CompositionMeasureReportAuthor';
import { ComparativeEvidenceSynthesisReportAuthor } from './ComparativeEvidenceSynthesisReportAuthor';
import { ComparativeEvidenceReportAuthor } from './ComparativeEvidenceReportAuthor';
import { SummaryOfFindingsAuthor } from './SummaryOfFindingsAuthoringTool';
import { EvidenceReportPackageAuthor } from './EvidenceReportPackageAuthoringTool';
import { M11ReportAuthor } from './M11ReportAuthoringTool';
import { GuidelineAuthor } from './GuidelineAuthoringTool';
import { RecommendationAuthor } from './RecommendationAuthoringTool';


const loadResourceStateByType = (fhirJson) => {
  if (fhirJson) {
    let resourceType = fhirJson.resourceType;
    if (resourceType === "Evidence") {
      if (!fhirJson.studyDesign && fhirJson.studyType) {
        if (Array.isArray(fhirJson.studyType)) {
          fhirJson.studyDesign = fhirJson.studyType;
        } else {
          fhirJson.studyDesign = [fhirJson.studyType];
        }
      }
    }

    let starterResourceStateValues = { "resourceJson": fhirJson, "newClassifications": null };
    let resourceElements = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension"];

    if (resourceType && resourceElementNames[resourceType]) {
      resourceElements = resourceElementNames[resourceType];
    }

    for (let element of resourceElements) {
      starterResourceStateValues[element] = fhirJson[element];
    }

    return starterResourceStateValues;
  }
};

const SimpleResourceEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson,
  resourceState, setResourceState, sourceJsonState, setSourceJsonState,
  classificationsArrayState, classificationsLoadedState, globalContext }) => {
  let resourceType = fhirJson.resourceType;
  if (resourceType && !resourceState.resourceJson) {
    let starterResourceStateValues = loadResourceStateByType(fhirJson);
    if (starterResourceStateValues) {
      setResourceState(starterResourceStateValues);
    }
  }

  useImperativeHandle(formInputsStateRef, () => ({
    activityDefinitionState: resourceType === "ActivityDefinition" ? resourceState : null,
    bundleState: resourceType === "Bundle" ? resourceState : null,
    codeSystemState: resourceType === "CodeSystem" ? resourceState : null,
    evidenceState: resourceType === "Evidence" ? resourceState : null,
    evidenceVariableState: resourceType === "EvidenceVariable" ? resourceState : null,
    groupState: resourceType === "Group" ? resourceState : null,
    libraryState: resourceType === "Library" ? resourceState : null,
    listState: resourceType === "List" ? resourceState : null,
    measureState: resourceType === "Measure" ? resourceState : null,
    planDefinitionState: resourceType === "PlanDefinition" ? resourceState : null,
    researchStudyState: resourceType === "ResearchStudy" ? resourceState : null,
    valueSetState: resourceType === "ValueSet" ? resourceState : null
  }), [resourceState]);

  return <div>
    {resourceState.resourceJson ?
      <div style={{ marginTop: "12px" }}>
        {resourceType === "ActivityDefinition" && <ActivityDefinitionBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "Bundle" && <BundleBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "CodeSystem" && <CodeSystemBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "Evidence" && <EvidenceBuilder resourceState={resourceState} setResourceState={setResourceState}
          globalContext={globalContext} setSourceJsonState={setSourceJsonState} />}
        {resourceType === "EvidenceVariable" && <EvidenceVariableBuilder resourceState={resourceState} setResourceState={setResourceState}
          setSourceJsonState={setSourceJsonState} />}
        {resourceType === "Group" && <GroupBuilder resourceState={resourceState} setResourceState={setResourceState}
          globalContext={globalContext} setSourceJsonState={setSourceJsonState} />}
        {resourceType === "Library" && <LibraryBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "List" && <ListBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "Measure" && <MeasureBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "PlanDefinition" && <PlanDefinitionBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "ResearchStudy" && <ResearchStudyBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "ValueSet" && <ValueSetBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType !== "Bundle" && <div>
          <h3 id="how-to-cite">How to Cite</h3>
          <div style={{ marginLeft: "24px" }}>
            <DisplayHowToCite citationSummary={citationSummary}
              citationJson={citationJson} />
          </div>
        </div>}
        <h3 id="metadata">Metadata</h3>
        <div style={{ marginLeft: "24px" }}>
          <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
        </div>
        <h3 id="associated-resources">Associated Resources</h3>
        <div style={{ marginLeft: "24px" }}>
          <AssociatedResourcesDisplay fhirJson={fhirJson} />
        </div>
        <h3 id="classifiers">Classifiers</h3>
        <div style={{ marginLeft: "24px" }}>
          <div>
            <p>Add Classifiers:</p>
            <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
              fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
          </div>
          {(classificationsArrayState) && <div>
            <p>Existing Classifiers:</p>
            {classificationsLoadedState ?
              <DisplayClassifiers classificationsArray={classificationsArrayState} />
              :
              <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
            }
          </div>}
        </div>
        <h3 id="json-outline">JSON Outline</h3>
        <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
        <br /><br />
      </div>
      :
      <>Loading...</>}
  </div>
}

const CompositionResourceEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson,
  resourceState, setResourceState, sourceJsonState, setSourceJsonState,
  classificationsArrayState, classificationsLoadedState, globalContext, profile, update, previousVersionLoaded,
  fhirEntryState, setFhirEntryState, history, changeFormState,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  if (Array.isArray(fhirJson.author) && fhirJson.author.length === 1 && fhirJson.author[0].name) {
    fhirJson.author[0].display = fhirJson.author[0].name;
    delete fhirJson.author[0].name;
  }
  let resourceType = fhirJson.resourceType;

  const [reorganizeSectionsModalState, setReorganizeSectionsModalState] = useState({});

  useEffect(() => {
    if (resourceType && !resourceState.resourceJson) {
      let starterResourceStateValues = loadResourceStateByType(fhirJson);
      if (starterResourceStateValues) {
        setResourceState(starterResourceStateValues);
      }
    }
  }, []);

  useImperativeHandle(formInputsStateRef, () => ({
    compositionDocumentState: resourceState
  }), [resourceState]);

  //TODO - address loadSourceJson functions but they are currently different variations across authoring tools

  return <div>
    {resourceState.resourceJson ?
      <div style={{ marginTop: "12px" }}>
        {reorganizeSectionsModalState.modalOpen && <ReorganizeSectionsModal resourceState={resourceState}
          setResourceState={setResourceState} reorganizeSectionsModalState={reorganizeSectionsModalState}
          setReorganizeSectionsModalState={setReorganizeSectionsModalState} update={update} elementName={"section"} />}
        <Button style={{ color: "#000000", marginTop: "18px", marginLeft: "6px", fontSize: "18px", padding: "4px" }}
          className="formButton sectionReorganizeButton"
          content="Reorganize Sections"
          onClick={() => { setReorganizeSectionsModalState(prevState => { return { ...prevState, modalOpen: true } }); }} />
        <br />
        {profile === "none" && <CompositionAuthor resourceState={resourceState} setResourceState={setResourceState} />}
        {(profile === "OutcomeMeasureReport" || profile === "BaselineMeasureReport" || profile === "ParticipantFlowReport") &&
          <CompositionMeasureReportAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState} profile={profile} />}
        {profile === "ComparativeEvidenceSynthesisReport" &&
          <ComparativeEvidenceSynthesisReportAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            previousVersionLoaded={previousVersionLoaded} />}
        {profile === "ComparativeEvidenceReport" &&
          <ComparativeEvidenceReportAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            previousVersionLoaded={previousVersionLoaded} />}
        {profile === "SummaryOfFindings" &&
          <SummaryOfFindingsAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            formInputsStateRef={formInputsStateRef} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
            history={history} changeFormState={changeFormState}
          />}
        {profile === "EvidenceReportPackage" &&
          <EvidenceReportPackageAuthor resourceState={resourceState} setResourceState={setResourceState}
            previousVersionLoaded={previousVersionLoaded} />}
        {profile === "M11Report" &&
          <M11ReportAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} setFhirEntryState={setFhirEntryState} history={history} />}
        {profile === "Guideline" &&
          <GuidelineAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState} />}
        {profile === "Recommendation" &&
          <RecommendationAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />}
        <div>
          <h3 id="how-to-cite">How to Cite</h3>
          <div style={{ marginLeft: "24px" }}>
            <DisplayHowToCite citationSummary={citationSummary}
              citationJson={citationJson} />
          </div>
          <h3 id="metadata">Metadata</h3>
          <div style={{ marginLeft: "24px" }}>
            <EvidenceReportMetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
          </div>
          <h3 id="associated-resources">Associated Resources</h3>
          <div style={{ marginLeft: "24px" }}>
            <AssociatedResourcesDisplay fhirJson={fhirJson} />
          </div>
          <h3 id="classifiers">Classifiers</h3>
          <div style={{ marginLeft: "24px" }}>
            <div>
              <p>Add Classifiers:</p>
              <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
                fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
            </div>
            {(classificationsArrayState) && <div>
              <p>Existing Classifiers:</p>
              {classificationsLoadedState ?
                <DisplayClassifiers classificationsArray={classificationsArrayState} />
                :
                <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
              }
            </div>}
          </div>
          <h3 id="json-outline">JSON Outline</h3>
          <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
          <br /><br />
        </div>
      </div>
      :
      <>Loading...</>}
  </div>
}

export { SimpleResourceEdit, CompositionResourceEdit, loadResourceStateByType };
