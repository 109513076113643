import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import scrollTo from './ScrollTo';
import { DisplayFromFHIR } from './ResourceFunctions';
import { navigateToAnotherTerm, getAlternativeTerms, getPropertyCodes, findConceptProperties } from './CodeSystemFunctions';

let separatorCharacter = ": ";

const ChildConceptsTable = ({ resourceId, fhirEntryState, setFhirEntryState, history }) => {

  let header = "Child concepts:";
  let concept = fhirEntryState.conceptSelected;
  let conceptPath = fhirEntryState.conceptPath;
  let json;
  if (fhirEntryState.fhirEntryString) {
    json = JSON.parse(fhirEntryState.fhirEntryString);
  }

  if (conceptPath === undefined || conceptPath.length === 0) {
    concept = json;
    header = "Top-level concepts:"
  }
  return <>
    {(concept?.concept?.length > 0) &&
      <>
        <b>{header}</b>
        {(concept?.concept?.length > 0) &&
          <div>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Code</Table.HeaderCell>
                  <Table.HeaderCell>Display</Table.HeaderCell>
                  <Table.HeaderCell>Definition</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {concept?.concept?.map((subConcept, subConceptIndex) => {
                  let subConceptPath = [];
                  let subConceptPathString = fhirEntryState.conceptPathString + ": " + subConcept.display;
                  if (conceptPath) {
                    subConceptPath = [...conceptPath, subConcept.display];
                  } else {
                    subConceptPath = [subConcept.display];
                  }
                  let subConceptPathIndexes = [];
                  if (fhirEntryState.conceptPathIndexes) {
                    subConceptPathIndexes = [...fhirEntryState.conceptPathIndexes, subConceptIndex];
                  } else {
                    subConceptPathIndexes = [subConceptIndex];
                  }
                  return <Table.Row key={subConceptIndex}>
                    <Table.Cell>{subConcept.code}</Table.Cell>
                    <Table.Cell>
                      <a onClick={() => {
                        navigateToAnotherTerm("term", history, fhirEntryState, setFhirEntryState, 'CodeSystem', resourceId, json,
                          subConcept, subConcept.display, subConceptPath, subConceptPathIndexes, subConceptPathString);
                      }}>
                        {subConcept.display}
                      </a>
                    </Table.Cell>
                    <Table.Cell>{subConcept.definition}</Table.Cell>
                  </Table.Row>
                })}
              </Table.Body>
            </Table>
          </div>
        }
      </>
    }
  </>
}

const CodeSystemTermDisplay = ({ resourceId, fhirEntryState, setFhirEntryState, history }) => {

  let json;
  let showAsDraft = false;
  if (fhirEntryState.fhirEntryString) {
    json = JSON.parse(fhirEntryState.fhirEntryString);
  }
  let hasApprovalProperty = fhirEntryState.hasApprovalProperty;
  let concept = fhirEntryState.conceptSelected;
  let conceptPath = fhirEntryState.conceptPath;
  let conceptPathIndexes = fhirEntryState.conceptPathIndexes;
  let conceptPathString = fhirEntryState.conceptPathString;
  let conceptPathLength = 0;
  if (conceptPath) {
    conceptPathLength = conceptPath.length;
  }

  let separator = "";
  if (conceptPathString) {
    separator = separatorCharacter;
  }

  let alternativeTerms = [];
  if (concept?.designation?.length > 0) {
    for (const designation of concept.designation) {
      if (designation.use.code === "900000000000013009" || designation.use.display === "Alternative term") {
        if (designation.language) {
          alternativeTerms.push(designation.value + " [" + designation.language + "]");
        } else {
          alternativeTerms.push(designation.value);
        }
      }
      if (designation.use.code === "900000000000003001" && designation.language) {
        alternativeTerms.push(designation.value + " [Language: " + designation.language + "]");
      }
    }
  }

  if (hasApprovalProperty) {
    if (!fhirEntryState["conceptProperties"] || !fhirEntryState["conceptProperties"]["approval"]) {
      showAsDraft = true;
    }
  }




  const [votesCountDisplayState, setVotesCountDisplayState] = useState("");
  const [voterSummaryState, setVoterSummaryState] = useState("");
  const [finalVotesState, setFinalVotesState] = useState([]);
  const [currentVotesState, setCurrentVotesState] = useState([]);
  const [openForVotingDateState, setOpenForVotingDateState] = useState();
  //const [duplicateVotesState, setDuplicateVotesState] = useState([]);
  //const [oldVotesState, setOldVotesState] = useState([]);

  useEffect((() => {
    let voterSummary = "";
    let votesCountDisplay;
    let voutesCount = 0;
    //let usersLatestVotes = [];
    let finalVotes = [];
    let currentVotes = [];
    let notCountedVotes = [];
    let oldVotes = [];
    let duplicateVoterVotes = [];
    let currentPositiveVotes = [];
    let currentNegativeVotes = [];
    let usersVoted = [];
    let voteAuthorNames = [];

    let openForVotingDate;
    let openForVotingDateString = fhirEntryState?.["conceptProperties"]?.["open-for-voting"];
    if (openForVotingDateString) {
      openForVotingDate = new Date(openForVotingDateString);
      setOpenForVotingDateState(openForVotingDate);
    }

    if (fhirEntryState.editpermission && fhirEntryState.votesdict?.[fhirEntryState.conceptCode]) {
      voutesCount = fhirEntryState.votesdict[fhirEntryState.conceptCode].length;
      votesCountDisplay = voutesCount.toString();
      if (openForVotingDate) {
        for (let vote of fhirEntryState.votesdict[fhirEntryState.conceptCode]) {
          let voteJsonString = JSON.stringify(vote);
          let voteTime = new Date(vote.time);
          if (voteTime >= openForVotingDate && vote.status !== "retired") {
            if (!vote.userid || !usersVoted.includes(vote.userid)) {
              voteAuthorNames.push(vote.author);
              usersVoted.push(vote.userid);
              currentVotes.push(voteJsonString);
              //usersLatestVotes.push(voteJsonString);
              if (vote.vote !== undefined) {
                if (vote.vote) {
                  currentPositiveVotes.push(voteJsonString);
                } else {
                  currentNegativeVotes.push(voteJsonString);
                }
              }
            } else {
              duplicateVoterVotes.push(voteJsonString);
              notCountedVotes.push(voteJsonString);
            }
          } else {
            oldVotes.push(voteJsonString);
            notCountedVotes.push(voteJsonString);
          }
        }
        if (currentVotes.length > 0 || notCountedVotes.length > 0) {
          votesCountDisplay += ": ";
        }
        let addingOn = "";
        if (currentVotes.length > 0) {
          let yesVotesCount = currentPositiveVotes.length.toString();
          let noVotesCount = currentNegativeVotes.length.toString();
          addingOn += yesVotesCount + " yes votes";
          if (currentNegativeVotes) {
            addingOn += ", " + noVotesCount + " no votes";
          }
          voterSummary = "vote " + yesVotesCount + "-" + noVotesCount + " by " + voteAuthorNames.join(", ");
        }
        if (duplicateVoterVotes.length > 0) {
          if (addingOn) {
            addingOn += ", ";
          }
          addingOn += duplicateVoterVotes.length.toString() + " multiple votes";
        }
        if (oldVotes.length > 0) {
          if (addingOn) {
            addingOn += ", ";
          }
          addingOn += oldVotes.length.toString() + " old votes";
        }
        votesCountDisplay += addingOn;
      } else {
        for (let vote of fhirEntryState.votesdict[fhirEntryState.conceptCode]) {
          //usersLatestVotes.push();
          if (!vote.userid || !usersVoted.includes(vote.userid)) {
            let voteJsonString = JSON.stringify(vote);
            usersVoted.push(vote.userid);
            finalVotes.push(voteJsonString);
          }
        }
        setFinalVotesState(finalVotes);
      }
    }
    setCurrentVotesState(currentVotes);
    //setDuplicateVotesState(duplicateVoterVotes);
    //setOldVotesState(oldVotes);
    setVotesCountDisplayState(votesCountDisplay || "0");
    setVoterSummaryState(voterSummary);
  }), [fhirEntryState.votesdict, fhirEntryState.conceptCode, fhirEntryState.conceptProperties, fhirEntryState.editpermission]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      {conceptPath?.length > 0 ?
        <>
          <h2 style={{ margin: "0px" }}>
            {fhirEntryState.conceptName}
            {showAsDraft && <span style={{ fontWeight: "normal", fontSize: "18px" }}> (draft)</span>}
          </h2>
          <br />
          <span style={{ whiteSpace: "pre-wrap" }}>
            {concept.code && <><b>Code:</b> {concept.code}<br /><br /></>}
            {concept.display && <><b>Preferred term:</b> {concept.display}<br /><br /></>}
            {concept.definition && <><b>Definition:</b> {concept.definition}<br /><br /></>}
            {alternativeTerms.length > 0 && <>
              <b>Alternative term{alternativeTerms.length > 1 && <>s</>}: </b>
              <ul style={{ margin: "0px" }}>
                {alternativeTerms.map((alternativeTerm, alternativeTermIndex) => {
                  return <li key={alternativeTermIndex}>{alternativeTerm}</li>
                })}
              </ul>
              <br />
            </>
            }
            {fhirEntryState.conceptPropertyComment && <>
              <b>Comment for application: </b>
              <DisplayFromFHIR markdown={fhirEntryState.conceptPropertyComment} />
              <br /><br />
            </>}
            <>
              {fhirEntryState.conceptCode ?
                <span onClick={() => {
                  setFhirEntryState(prevState => {
                    return {
                      ...prevState, conceptName: "", conceptCode: "", conceptDefinition: undefined,
                      conceptPath: undefined, conceptPathIndexes: undefined, conceptPathString: undefined,
                      conceptSelected: undefined, newChildConcepts: [], conceptCommentsExpanded: false,
                      comments: [], votes: [], myvotes: []
                    }
                  });
                  scrollTo("navigationTermDetailSegment", "", true);
                  history.push(`/resources/CodeSystem/${resourceId}`);
                }}>
                  <a>TOP</a>
                </span>
                :
                <b>TOP</b>
              }
              <br />
              <span style={{ paddingLeft: "8px" }}>
                {conceptPath?.map((parentConceptDisplay, parentConceptIndex) => {
                  let parentConceptPath = conceptPath.slice(0, parentConceptIndex + 1);
                  let parentConceptPathIndexes = conceptPathIndexes.slice(0, parentConceptIndex + 1);
                  let parentConcept = json;
                  for (let parentIndexIndex in parentConceptPathIndexes) {
                    let parentIndex = parentConceptPathIndexes[parentIndexIndex];
                    parentConcept = parentConcept.concept[parentIndex];
                  }
                  let parentConceptPathString = conceptPath.join(separator);
                  return <span key={parentConceptIndex} style={{ paddingLeft: parentConceptIndex * 24 + "px" }}><span>
                    {parentConceptIndex + 1 === conceptPath.length ?
                      <b>{parentConceptDisplay}</b>
                      :
                      <a onClick={() => {
                        //history.push(`/resources/CodeSystem/${resourceId}#${parentConcept.code},${parentConceptPathIndexes.toString()}`);    //INCLUDES PATH SO IT CAN HANDLE DUPLICATE CODES, COMMENTED OUT FOR NOW
                        history.push(`/resources/CodeSystem/${resourceId}#${parentConcept.code}`);
                        let propertyProperties = getPropertyCodes(json);
                        let propertyCodes = propertyProperties["propertyCodes"];
                        let propertyDescriptions = propertyProperties["propertyDescriptions"];
                        if (fhirEntryState.termChanged !== true) {
                          let scrollToId = "termdetail-navigation-code-" + parentConcept.code;
                          if (parentConceptPathIndexes.length === 1 && parseInt(parentConceptPathIndexes[0]) == 0) {
                            scrollToId = "";
                          }
                          scrollTo("navigationTermDetailSegment", scrollToId, true);
                        }
                        let conceptProperties = findConceptProperties(parentConcept, propertyCodes);
                        let conceptOpenForVoting = false;
                        let conceptPropertyComment = "";
                        if (conceptProperties["open-for-voting"] && conceptProperties["open-for-voting"] !== "") {
                          conceptOpenForVoting = true;
                        }
                        if (conceptProperties["comment"] && conceptProperties["comment"] !== "") {
                          conceptPropertyComment = conceptProperties["comment"];
                        }
                        setFhirEntryState(prevState => { return { ...prevState, conceptName: parentConceptDisplay, conceptPath: parentConceptPath, conceptPathIndexes: parentConceptPathIndexes, conceptPathString: parentConceptPathString, conceptSelected: parentConcept, conceptDefinition: parentConcept.definition, conceptCode: parentConcept.code, alternativeTerms: getAlternativeTerms(parentConcept), propertyCodes: propertyCodes, propertyDescriptions: propertyDescriptions, conceptProperties: conceptProperties, conceptOpenForVoting: conceptOpenForVoting, conceptPropertyComment: conceptPropertyComment, newChildConcepts: [], conceptCommentsExpanded: false, comments: [], votes: [], myvotes: [] }; });
                      }} >
                        {parentConceptDisplay}</a>
                    }
                  </span><br /></span>
                })
                }</span>
            </>
            {concept.concept?.length > 0 &&
              <div>{concept.concept.map((subConcept, subConceptIndex) => {
                let subConceptDisplay = subConcept.display;
                let subConceptPath = [];
                if (conceptPath) {
                  subConceptPath = [...conceptPath, subConceptDisplay];
                }
                let subConceptPathIndexes = [];
                if (conceptPathIndexes) {
                  subConceptPathIndexes = [...conceptPathIndexes, subConceptIndex];
                }
                let subConceptPathString = conceptPathString + separator + subConceptDisplay;
                return <span key={subConceptIndex} style={{ paddingLeft: conceptPathLength * 24 + "px" }}>
                  <a onClick={() => {
                    //history.push(`/resources/CodeSystem/${resourceId}#${subConcept.code},${subConceptPathIndexes.toString()}`);    //INCLUDES PATH SO IT CAN HANDLE DUPLICATE CODES, COMMENTED OUT FOR NOW
                    history.push(`/resources/CodeSystem/${resourceId}#${subConcept.code}`);
                    let propertyProperties = getPropertyCodes(json);
                    let propertyCodes = propertyProperties["propertyCodes"];
                    let propertyDescriptions = propertyProperties["propertyDescriptions"];

                    if (fhirEntryState.termChanged !== true) {
                      let scrollToId = "termdetail-navigation-code-" + subConcept.code;
                      if (subConceptPathIndexes.length === 1 && parseInt(subConceptPathIndexes[0]) == 0) {
                        scrollToId = "";
                      }
                      scrollTo("navigationTermDetailSegment", scrollToId, true);
                    }

                    let conceptProperties = findConceptProperties(subConcept, propertyCodes);
                    let conceptOpenForVoting = false;
                    let conceptPropertyComment = "";
                    if (conceptProperties["open-for-voting"] && conceptProperties["open-for-voting"] !== "") {
                      conceptOpenForVoting = true;
                    }
                    if (conceptProperties["comment"] && conceptProperties["comment"] !== "") {
                      conceptPropertyComment = conceptProperties["comment"];
                    }
                    setFhirEntryState(prevState => {
                      return {
                        ...prevState, conceptName: subConceptDisplay,
                        conceptPath: subConceptPath, conceptPathIndexes: subConceptPathIndexes,
                        conceptPathString: subConceptPathString, conceptSelected: subConcept,
                        conceptDefinition: subConcept.definition, conceptCode: subConcept.code,
                        alternativeTerms: getAlternativeTerms(subConcept), propertyCodes: propertyCodes,
                        propertyDescriptions: propertyDescriptions, conceptProperties: conceptProperties,
                        conceptOpenForVoting: conceptOpenForVoting, conceptPropertyComment: conceptPropertyComment,
                        newChildConcepts: [], conceptCommentsExpanded: false, comments: [], votes: [], myvotes: []
                      };
                    })
                  }} >
                    {subConcept.display}
                  </a>
                  <br />
                </span>
              })
              }
              </div>
            }
            <br />
            {//Object.keys(fhirEntryState["conceptProperties"]).map((propertyKey, propertyIndex) => {
              fhirEntryState.propertyCodes.map((propertyKey, propertyIndex) => {
                let property = fhirEntryState["conceptProperties"][propertyKey];
                let propertyBlock = <span key={propertyIndex}>{property && <><b>{fhirEntryState.propertyDescriptions[propertyKey]}:</b> {property}<br /><br /></>}</span>;
                if (propertyKey === "comment") {
                  propertyBlock = <span key={propertyIndex}></span>
                }
                return propertyBlock;
              })}
            <br />
          </span>
          <ChildConceptsTable resourceId={resourceId} fhirEntryState={fhirEntryState}
            setFhirEntryState={setFhirEntryState} history={history} />
          <br />
          <span>
            <b>Comments ({(fhirEntryState.commentsdict && fhirEntryState.commentsdict[fhirEntryState.conceptCode])
              ?
              fhirEntryState.commentsdict[fhirEntryState.conceptCode].length : 0})</b>
            <span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setFhirEntryState(prevState => {
                  return {
                    ...prevState,
                    conceptCommentsExpanded: !fhirEntryState.conceptCommentsExpanded
                  };
                })
              }}>
              {fhirEntryState.conceptCommentsExpanded ? <>▼</> : <>►</>}
            </span>
          </span>
          {(fhirEntryState.conceptCommentsExpanded && fhirEntryState.commentsdict[fhirEntryState.conceptCode] !== undefined) &&
            <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>
              {fhirEntryState.comments.length !== fhirEntryState.commentsdict[fhirEntryState.conceptCode].length
                ?
                <i>Loading...</i>
                :
                fhirEntryState.comments.map((comment, commentIndex) => {
                  return <div key={commentIndex}><div style={{ padding: "12px", paddingLeft: "0px" }}>
                    {comment &&
                      <div style={{ border: "1px solid", padding: "10px", backgroundColor: "#FFFFFF" }}>
                        {comment.status !== "retired" ?
                          <><b>{comment.author}: </b>{comment.summary}<br /><i>{comment.time}</i></>
                          :
                          <b>RETIRED COMMENT</b>
                        }
                      </div>
                    }
                  </div></div>
                })}
            </div>}
          <br /><br />
          {fhirEntryState.editpermission && <>
            <span><b>Votes ({votesCountDisplayState})</b>
              <span className={"unselectable"} style={{ cursor: "pointer" }}
                onClick={() => {
                  setFhirEntryState(prevState => {
                    return {
                      ...prevState,
                      conceptVotesExpanded: !fhirEntryState.conceptVotesExpanded
                    };
                  })
                }}>
                {fhirEntryState.conceptVotesExpanded ? <>▼</> : <>►</>}
              </span>
            </span>
            {(fhirEntryState.conceptVotesExpanded && fhirEntryState.votesdict[fhirEntryState.conceptCode] !== undefined) &&
              <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>
                {voterSummaryState && <div style={{ "paddingTop": "6px" }}>
                  {new Date().toISOString().substring(0, 10)}
                  {/* this will remove the time part, from what I can tell it will be UTC time which is the same as the database */}
                  {console.log(new Date().toISOString())} {/* We could use this if we want exact times */}
                  {voterSummaryState}
                </div>}
                {fhirEntryState.votesdict[fhirEntryState.conceptCode].map((vote, voteIndex) => {
                  let backgroundColor = "#EAEAEA";
                  let stringifiedVote = JSON.stringify(vote);
                  if (currentVotesState.includes(stringifiedVote)) {
                    backgroundColor = "#FFEDB2";
                  } else if (finalVotesState.includes(stringifiedVote)) {
                    //backgroundColor = "#EDB2FF";
                  }
                  return <div key={voteIndex}><div style={{ padding: "12px", paddingLeft: "0px" }}>
                    {vote &&
                      <div style={{ border: "1px solid", padding: "10px", backgroundColor: backgroundColor }}>
                        {vote.status !== "retired" ?
                          <>
                            {vote.vote !== undefined && <span><b>{vote.vote ? "Yes" : "No"}</b><br /></span>}
                            {vote.summary && <span>{vote.summary}<br /></span>}
                            <span>re: "{vote.termconcept?.display}" = "{vote.termconcept?.definition}"<br /></span>
                            <span>By: {vote.author} (<i>{vote.time}</i>)<br /></span>
                          </>
                          :
                          <b>RETIRED VOTE</b>
                        }
                      </div>
                    }
                  </div></div>
                })}</div>}
            <br /><br />
          </>}
          {(fhirEntryState.myvotesdict?.[fhirEntryState.conceptCode]?.length > 0) && <>
            <span><b>My Votes ({(fhirEntryState.myvotesdict[fhirEntryState.conceptCode]) ? fhirEntryState.myvotesdict[fhirEntryState.conceptCode].length : 0})</b> <span className={"unselectable"} style={{ cursor: "pointer" }} onClick={() => { setFhirEntryState(prevState => { return { ...prevState, conceptMyVotesExpanded: !fhirEntryState.conceptMyVotesExpanded }; }) }}>{fhirEntryState.conceptMyVotesExpanded ? <>▼</> : <>►</>}</span></span>
            {(fhirEntryState.conceptMyVotesExpanded && fhirEntryState.myvotesdict[fhirEntryState.conceptCode] !== undefined) &&
              <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>
                {fhirEntryState.myvotesdict[fhirEntryState.conceptCode]?.map((vote, voteIndex) => {
                  let backgroundColor = "#EAEAEA";
                  if (voteIndex == 0 && openForVotingDateState && new Date(vote.time) >= openForVotingDateState) {
                    backgroundColor = "#FFEDB2";
                  }
                  return <div key={voteIndex}><div style={{ padding: "12px", paddingLeft: "0px" }}>
                    {vote &&
                      <div style={{ border: "1px solid", padding: "10px", backgroundColor: backgroundColor }}>
                        {vote.status !== "retired" ?
                          <>
                            {/*vote.classifierTexts?.length > 0 && <span><b>{vote.classifierTexts[0]}</b><br /></span>*/}
                            {typeof (vote.vote) === "boolean" && <span><b>{vote.vote ? "Yes" : "No"}</b><br /></span>}
                            {vote.summary && <span>{vote.summary}<br /></span>}
                            {/*vote.componentDict["term voted upon"] && <span>re: {vote.componentDict["term voted upon"]}<br /></span>*/}
                            {vote.termconcept && <span>re: {`"${vote.termconcept.display}" = "${vote.termconcept.definition}"`}<br /></span>}
                            <span>By: {vote.author} (<i>{vote.time}</i>)<br /></span>
                          </>
                          :
                          <b>RETIRED VOTE</b>
                        }
                      </div>
                    }
                  </div></div>
                })}</div>}
            <br /><br />
          </>}
          <span><b>JSON</b> <span className={"unselectable"} style={{ cursor: "pointer" }} onClick={() => { setFhirEntryState(prevState => { return { ...prevState, conceptJsonExpanded: !prevState.conceptJsonExpanded }; }) }}>{fhirEntryState.conceptJsonExpanded ? <>▼</> : <>►</>}</span></span>
          <br />
          {fhirEntryState.conceptJsonExpanded && <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>{JSON.stringify(concept, null, 2)}</div>}
        </>
        :
        <>
          <span>Please select a term</span>
          <br />
          <br />
          <ChildConceptsTable resourceId={resourceId} fhirEntryState={fhirEntryState}
            setFhirEntryState={setFhirEntryState} history={history} />
        </>
      }
    </div>
  </div>
}

export default CodeSystemTermDisplay;