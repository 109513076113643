import React, { useState, useEffect, memo } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import { emptyReasonValueSet } from './CodeSystemLookup';
import { DisplayFromFHIR, getStringFromFHIR, jsonErrorCheck2 } from './ResourceFunctions';
import submitToFevirServer from './SubmitToFevirServer';
import { DataEntry } from './DataEntryFormFunctions';
import { generateEvidenceSummary } from './GenerateNarrativeSummaryFunctions';
import SEVCO from './SEVCO';
import { useToasts } from 'react-toast-notifications';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { NarrativeEntry } from './NarrativeEntry';

const EvidenceTableDisplay = ({ relatesTo, section, reportType }) => {
  let interventionGroupReference = null;
  let interventionGroupDisplay = "";
  let comparatorGroupReference = null;
  let comparatorGroupDisplay = "";
  let totalGroupReference = null;
  let totalGroupDisplay = "";
  let groupAssignmentReference = null;
  let groupAssignmentDisplay = "";
  let variableReferencesList = [];
  if (relatesTo) {
    for (let item of relatesTo) {
      if (item.type === "depends-on") {
        if (item.label === "Intervention Group") {
          interventionGroupReference = item.resourceReference?.reference;
          interventionGroupDisplay = item.display || item.resourceReference?.display || "";
        } else if (item.label === "Comparator Group") {
          comparatorGroupReference = item.resourceReference?.reference;
          comparatorGroupDisplay = item.display || item.resourceReference?.display || "";
        } else if (item.label === "Total Group") {
          totalGroupReference = item.resourceReference?.reference;
          totalGroupDisplay = item.display || item.resourceReference?.display || "";
        } else if (item.label === reportType) {
          let variableReferenceDisplay = item.display || item.resourceReference?.display;
          if (variableReferenceDisplay) {
            variableReferencesList.push(variableReferenceDisplay);
          }
        }
      } else if (item.type === "composed-of") {
        if (item.label === "Group Assignment" || item.classifier?.[0]?.coding?.[0]?.code === "GroupAssignment") {
          groupAssignmentReference = item.resourceReference?.reference;
          groupAssignmentDisplay = item.display || item.resourceReference?.display || "";
        }
      }
    }
  }
  let usableSection = section.filter((row) => {
    let rowFocus = row.title || row.focus?.display;
    let match = false;
    if (rowFocus && variableReferencesList.includes(rowFocus)) {
      match = true;
    }
    return match;
  });

  return <>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable Name</Table.HeaderCell>
          {totalGroupReference && <Table.HeaderCell>Total Group</Table.HeaderCell>}
          {interventionGroupReference && <Table.HeaderCell>Intervention Group</Table.HeaderCell>}
          {comparatorGroupReference && <Table.HeaderCell>Comparator Group</Table.HeaderCell>}
          {groupAssignmentReference && <Table.HeaderCell>Comparative Evidence</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row key={"topline"}>
          <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
          {totalGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>{totalGroupDisplay || totalGroupReference}</Table.Cell>}
          {interventionGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>{interventionGroupDisplay || interventionGroupReference}</Table.Cell>}
          {comparatorGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>{comparatorGroupDisplay || comparatorGroupReference}</Table.Cell>}
          {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>{groupAssignmentDisplay || groupAssignmentReference}</Table.Cell>}
        </Table.Row>
        {usableSection.map((section, index) => {
          let comparatorSection = null;
          let interventionSection = null;
          let totalSection = null;
          let comparativeSection = null;
          for (let subsection of section.section) {
            let subsectionCode = subsection.code?.coding?.[0]?.code;
            if (subsectionCode === "evidence-with-comparator-alone") {
              comparatorSection = subsection;
            } else if (subsectionCode === "evidence-with-intervention-alone") {
              interventionSection = subsection;
            } else if (subsectionCode === "evidence-with-total-group") {
              totalSection = subsection;
            } else if (subsectionCode === "evidence-with-intervention-vs-comparator") {
              comparativeSection = subsection;
            }
          }
          return <Table.Row key={index}>
            <Table.Cell style={{ verticalAlign: "top" }}>{section.title || section.focus?.display}</Table.Cell>
            {totalGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {totalSection && <span>
                <DisplayFromFHIR xhtml={totalSection.text?.div} />
                <br />
                <DisplayFromFHIR reference={totalSection.entry?.[0]} />
              </span>}
            </Table.Cell>}
            {interventionGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {interventionSection && <span>
                <DisplayFromFHIR xhtml={interventionSection.text?.div} />
                <br />
                <DisplayFromFHIR reference={interventionSection.entry?.[0]} />
              </span>}
            </Table.Cell>}
            {comparatorGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {comparatorSection && <span>
                <DisplayFromFHIR xhtml={comparatorSection.text?.div} />
                <br />
                <DisplayFromFHIR reference={comparatorSection.entry?.[0]} />
              </span>}
            </Table.Cell>}
            {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {comparativeSection && <span>
                <DisplayFromFHIR xhtml={comparativeSection.text?.div} />
                <br />
                <DisplayFromFHIR reference={comparativeSection.entry?.[0]} />
              </span>}
            </Table.Cell>}
          </Table.Row>
        })}
      </Table.Body>
    </Table>
  </>
}

const SingleEvidenceEntry = memo(({ startingValue, addElementValues, globalContext,
  statisticType, statisticUnit, setResourceState, resourceDictionary, knownDataCount,
  resourceState, setSourceJsonState }) => {
  let startingEntryValues = { "statisticType": "", "quantity": "", "knownDataCount": "", "entryInstance": "" };
  let startingEvidenceValues = { "foi": "", "statisticType": "", "quantity": "", "knownDataCount": "", "json": "" };
  if (statisticType) {
    startingEntryValues.statisticType = statisticType;
    startingEvidenceValues.statisticType = statisticType;
  }
  if (statisticUnit) {
    startingEntryValues.quantity = { "unit": statisticUnit };
    startingEvidenceValues.quantity = { "unit": statisticUnit };
  }
  if (knownDataCount || knownDataCount === 0) {
    startingEntryValues.knownDataCount = knownDataCount;
    startingEvidenceValues.knownDataCount = knownDataCount;
  }
  if (startingValue && startingValue[0]) {
    startingEntryValues.entryInstance = startingValue[0];
    let foi = "";
    if (startingValue[0].reference) {
      if (startingValue[0].reference.split("/")[1] && !isNaN(startingValue[0].reference.split("/")[1])) {
        foi = startingValue[0].reference.split("/")[1];
      }
    } else if (startingValue[0].identifier?.system === "https://fevir.net/FLI" &&
      startingValue[0].identifier.value) {
      let fli = startingValue[0].identifier.value;
      if (resourceDictionary?.fliToFoi) {
        foi = resourceDictionary["fliToFoi"][fli];
      }
    }
    if (foi && resourceDictionary[foi]) {
      let EvidenceJson = resourceDictionary[foi];
      startingEvidenceValues.foi = EvidenceJson.id;
      startingEvidenceValues.json = EvidenceJson;
      if (EvidenceJson.statistic?.[0]) {
        if (EvidenceJson.statistic[0].statisticType) {
          startingEvidenceValues.statisticType = EvidenceJson.statistic[0].statisticType;
          startingEntryValues.statisticType = EvidenceJson.statistic[0].statisticType;
        }
        if (EvidenceJson.statistic[0].quantity) {
          startingEvidenceValues.quantity = EvidenceJson.statistic[0].quantity;
          startingEntryValues.quantity = EvidenceJson.statistic[0].quantity;
        }
        if (EvidenceJson.statistic[0].sampleSize?.knownDataCount ||
          EvidenceJson.statistic[0].sampleSize?.knownDataCount === 0) {
          startingEvidenceValues.knownDataCount = EvidenceJson.statistic[0].sampleSize.knownDataCount;
          startingEntryValues.knownDataCount = EvidenceJson.statistic[0].sampleSize.knownDataCount;
        }
      }
    }
  }

  const [entryState, setEntryState] = useState(startingEntryValues);
  const [evidenceJsonState, setEvidenceJsonState] = useState(startingEvidenceValues);
  const [changesAvailableToSaveState, setChangesAvailableToSaveState] = useState(false);
  const [saveJsonChangesState, setSaveJsonChangesState] = useState(false);

  const createNewReferencedResource = async (title, statisticType, quantity, knownDataCount, globalContext, addElementValues) => {

    let newResource = {
      "resourceType": "Evidence",
      "meta": {},
      "title": title || "[Untitled Evidence]",
      "status": "active",
      "statistic": [{}]
    };

    if (addElementValues) {
      for (const key in addElementValues) {
        if (addElementValues[key]) {
          newResource[key] = addElementValues[key];
          if (!title && key === "title") {
            title = newResource.title;
          }
        }
      }
    }

    if (newResource.statistic) {
      newResource.statistic[0].statisticType = statisticType;
      newResource.statistic[0].quantity = quantity;
      if (newResource.statistic[0].sampleSize) {
        newResource.statistic[0].sampleSize.knownDataCount = knownDataCount;
      } else {
        newResource.statistic[0].sampleSize = { "knownDataCount": knownDataCount };
      }
    } else {
      newResource.statistic = [{
        "statisticType": statisticType, "quantity": quantity,
        "sampleSize": { "knownDataCount": knownDataCount }
      }]
    }

    const body = {
      'functionid': "submitfhirresource",
      'idToken': "",
      'fhirEntry': JSON.stringify(newResource),
      'title': title,
      'status': 'active',
    };
    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
    let resourceFOI;
    let evidenceResourceValues;
    if (response.success) {
      resourceFOI = response.formstateid;
      evidenceResourceValues = {
        foi: resourceFOI, statisticType: statisticType, quantity: quantity,
        knownDataCount: knownDataCount, json: newResource
      }
    }
    return evidenceResourceValues;
  };

  const createEvidenceResource = async () => {
    let title = entryState?.entryInstance?.display || "[Untitled evidence]";
    let statisticType = evidenceJsonState.statisticType;
    let quantity = evidenceJsonState.quantity;
    let knownDataCount = evidenceJsonState.knownDataCount;
    let evidenceResourceValues = await createNewReferencedResource(title,
      statisticType, quantity, knownDataCount, globalContext, addElementValues);
    if (evidenceResourceValues?.foi) {
      setEvidenceJsonState(evidenceResourceValues);
      setEntryState({
        "statisticType": statisticType, "quantity": quantity, "knownDataCount": knownDataCount,
        "entryInstance": {
          "reference": "Evidence/" + evidenceResourceValues.foi,
          "type": "Evidence",
          "display": title
        }
      });
      setChangesAvailableToSaveState(false);
    } else {
      alert("A problem occurred when creating this Evidence Resource.");
    }
  };

  const saveReferencedResourceJsonChanges = async () => {
    setSaveJsonChangesState(true);
    if (evidenceJsonState?.foi) {
      let workingJson = JSON.parse(JSON.stringify(evidenceJsonState.json));
      if (typeof workingJson === "object") {
        if (!workingJson.statistic) {
          workingJson.statistic = [{}]
        }
        workingJson.statistic[0].statisticType = evidenceJsonState.statisticType;
        workingJson.statistic[0].quantity = evidenceJsonState.quantity;
        workingJson.statistic[0].sampleSize.knownDataCount = evidenceJsonState.knownDataCount;
        let body = {
          'functionid': 'updatefhirresource',
          'idToken': '',
          'fhirEntry': JSON.stringify(workingJson, null, 2),
          'resourcetype': "Evidence",
          'resourceid': evidenceJsonState.foi,
          'title': workingJson.title || workingJson.name,
          'status': "active",
          'bypasswarnings': true
        };
        let response = await submitToFevirServer(globalContext, 9000, body, false, false);
        if (!response?.success) {
          if (response.warningMessage) {
            alert(response.warningMessage);
          } else {
            alert("The Resource updating did not occur. You might not have editing rights to the referenced Resource.")
          }
        }
        loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState, null, null, null);
        setChangesAvailableToSaveState(false);
      } else {
        alert("ERROR: workingJson is not an object. Contact support@computablepublishing.com");
        console.log(evidenceJsonState);
      }
    }
    setSaveJsonChangesState(false);
    setChangesAvailableToSaveState(false);
  }

  useEffect(() => {
    if (entryState.entryInstance && startingEntryValues.entryInstance !== entryState.entryInstance) {
      setResourceState(prevState => { return { ...prevState, "entry": [entryState.entryInstance] }; })
    }
  }, [entryState]);

  useEffect(() => {
    if (evidenceJsonState && JSON.stringify(evidenceJsonState) !== JSON.stringify(startingEvidenceValues)) {
      setChangesAvailableToSaveState(true);
    }
  }, [evidenceJsonState]);

  return <>
    {evidenceJsonState.foi ?
      <Button className="formButton" positive={changesAvailableToSaveState ? true : false}
        style={{ color: changesAvailableToSaveState ? "#FFFFFF" : "#000000" }}
        content={"Save changes to Evidence Resource"}
        onClick={saveReferencedResourceJsonChanges}
        disabled={(saveJsonChangesState || !changesAvailableToSaveState || (!evidenceJsonState || (!evidenceJsonState.foi && !evidenceJsonState.quantity)))} />
      :
      <Button className="formButton" style={{ color: "#000000" }}
        content={`Create this Evidence Resource`}
        onClick={createEvidenceResource} />}
    <DataEntry datatype='CodeableConcept' elementName='statisticType' fieldLabel='Statistic Type'
      startingValue={evidenceJsonState.statisticType}
      valueSet={SEVCO.statisticType} inTableCell={true} startCollapsed={true}
      setResourceState={setEvidenceJsonState} />
    <DataEntry datatype='Quantity' elementName='quantity' fieldLabel='Quantity' inTableCell={true}
      startingValue={evidenceJsonState.quantity} setResourceState={setEvidenceJsonState} />
    <DataEntry datatype='unsignedInt' elementName='knownDataCount' fieldLabel='Known Data Count'
      startingValue={evidenceJsonState.knownDataCount} setResourceState={setEvidenceJsonState} />
    <DataEntry datatype='Reference' elementName='entryInstance' fieldLabel='Evidence Resource'
      startingValue={entryState.entryInstance} referencedResourceTypes={["Evidence"]}
      startCollapsed={true} enableCreation={true} inTableCell={true}
      startingResourceType={"Evidence"}
      addElementValues={addElementValues}
      setResourceState={setEntryState}
      fullResourceState={resourceState} setSourceJsonState={setSourceJsonState}
    />
  </>
});

const InnerSectionDataEntry = memo(({ startingValue, elementName, setResourceState,
  entryHeader, entrySetProfile, variableDefinition, resourceDictionary, setSourceJsonState,
  researchStudyRelatedArtfifact, resourceState, suggestedEvidenceTitle,
  globalContext, statisticType, statisticUnit, knownDataCount }) => {

  return <DocumentSectionEntry elementName={elementName}
    inTableCell={true} entryResourceEntry="SingleEvidenceEntry"
    startingValue={startingValue} setResourceState={setResourceState}
    editTextStatus={false} editTextDiv={true}
    generateTextDivFunction={generateEvidenceSummary}
    entryHeader={"Evidence Resource for " + entryHeader} entryLimitToOne={true}
    entryEnableCreation={true} entryReferencedResourceTypes={["Evidence"]}
    entryStartCollapsed={true} entryStartingResourceType={"Evidence"}
    entrySetProfile={entrySetProfile}
    statisticType={statisticType} statisticUnit={statisticUnit}
    knownDataCount={knownDataCount}
    entryAddElementValues={{
      title: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.title || suggestedEvidenceTitle,
      relatedArtifact: researchStudyRelatedArtfifact || "",
      description: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.description || "",
      variableDefinition: variableDefinition || "",
      statistic: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.statistic || ""
    }}
    editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
    startCollapsed={true} noSection={true} setSourceJsonState={setSourceJsonState}
    resourceDictionary={resourceDictionary} globalContext={globalContext}
    resourceState={resourceState}
    compositionId={resourceState.id} compositionTitle={resourceState.title || resourceState.name} />
});

const InnerSectionArrayDataEntry = memo(({ startingValue, setResourceState,
  totalGroupReference, interventionGroupReference, setSourceJsonState, sampleSizeDefaultState,
  comparatorGroupReference, groupAssignmentReference, comparatorCategory, resourceDictionary,
  researchStudyRelatedArtfifact, resourceState, globalContext }) => {

  let comparatorSection = null;
  let interventionSection = null;
  let totalSection = null;
  let comparativeSection = null;
  let startingSectionStateValues = {};
  let defaultStatisticType = "";
  let defaultStatisticUnits = "";
  if (startingValue) {
    for (let itemIndex in startingValue.section) {
      let subsection = startingValue.section[itemIndex];
      let subsectionCode = subsection.code?.coding?.[0]?.code;
      if (subsectionCode === "evidence-with-comparator-alone") {
        comparatorSection = subsection;
        startingSectionStateValues["comparatorSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-with-intervention-alone") {
        interventionSection = subsection;
        startingSectionStateValues["interventionSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-with-total-group") {
        totalSection = subsection;
        startingSectionStateValues["totalSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-with-intervention-vs-comparator") {
        comparativeSection = subsection;
        startingSectionStateValues["comparativeSection"] = subsection;
      }
    }
  }
  let comparatorVariableDefinition = [
    {
      "variableRole": "population",
      "observed": { "reference": comparatorGroupReference, "type": "Group" }
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedComparatorEvidenceTitle = startingValue.focus.display + " with comparator";
  let interventionVariableDefinition = [
    {
      "variableRole": "population",
      "observed": { "reference": interventionGroupReference, "type": "Group" }
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedInterventionEvidenceTitle = startingValue.focus.display + " with intervention";
  let totalVariableDefinition = [
    {
      "variableRole": "population",
      "observed": { "reference": totalGroupReference, "type": "Group" }
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedTotalEvidenceTitle = startingValue.focus.display + " in total group";
  let comparativeVariableDefinition = [
    {
      "variableRole": "population",
      "observed": { "reference": totalGroupReference, "type": "Group" }
    },
    {
      "variableRole": "exposure",
      "comparatorCategory": comparatorCategory || "",
      "observed": { "reference": groupAssignmentReference, "type": "Group" }
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedComparativeEvidenceTitle = startingValue.focus.display + " comparing intervention vs. comparator";

  const [sectionState, setSectionState] = useState(startingSectionStateValues);
  const [statisticDefaultsState, setStatisticDefaultsState] = useState({
    "statisticType": "", "units": ""
  });

  useEffect(() => {
    let newSection = [];
    for (const key in sectionState) {
      newSection.push(sectionState[key]);
    }
    if (newSection && Object.keys(newSection).length) {
      setResourceState(prevState => { return { ...prevState, "section": newSection } });
    }
  }, [sectionState]);

  return <>
    <Table.Cell style={{ verticalAlign: "top" }}>
      {startingValue.title || startingValue.focus?.display}
      <p><span className={"unselectable"} style={{ cursor: "pointer" }}
        onClick={() => {
          setStatisticDefaultsState({
            "statisticType": defaultStatisticType || "",
            "units": defaultStatisticUnits || ""
          })
        }}>
        {(defaultStatisticType && !statisticDefaultsState.statisticType) &&
          <>➕ Add Default Statistic Type {getStringFromFHIR.CodeableConcept(defaultStatisticType)} and Units {defaultStatisticUnits || "none"}</>}
      </span></p>
    </Table.Cell>
    {totalGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {totalSection && <InnerSectionDataEntry startingValue={totalSection}
        elementName={"totalSection"} setResourceState={setSectionState}
        entryHeader="total group" entrySetProfile={"NoncomparativeEvidence"}
        variableDefinition={totalVariableDefinition}
        suggestedEvidenceTitle={suggestedTotalEvidenceTitle}
        resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        knownDataCount={sampleSizeDefaultState.totalKnownDataCount}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState} />}
    </Table.Cell>}
    {interventionGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {interventionSection && <InnerSectionDataEntry startingValue={interventionSection}
        elementName={"interventionSection"} setResourceState={setSectionState}
        entryHeader="intervention group" entrySetProfile={"NoncomparativeEvidence"}
        variableDefinition={interventionVariableDefinition}
        suggestedEvidenceTitle={suggestedInterventionEvidenceTitle} resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        knownDataCount={sampleSizeDefaultState.interventionKnownDataCount}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState} />}
    </Table.Cell>}
    {comparatorGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {comparatorSection && <InnerSectionDataEntry startingValue={comparatorSection}
        elementName={"comparatorSection"} setResourceState={setSectionState}
        entryHeader="comparator group" entrySetProfile={"NoncomparativeEvidence"}
        variableDefinition={comparatorVariableDefinition}
        suggestedEvidenceTitle={suggestedComparatorEvidenceTitle} resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        knownDataCount={sampleSizeDefaultState.comparatorKnownDataCount} resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState} />}
    </Table.Cell>}
    {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {comparativeSection && <InnerSectionDataEntry startingValue={comparativeSection}
        elementName={"comparativeSection"} setResourceState={setSectionState}
        entryHeader="comparison (intevention vs. comparator)" entrySetProfile={"ComparativeEvidence"}
        variableDefinition={comparativeVariableDefinition}
        suggestedEvidenceTitle={suggestedComparativeEvidenceTitle} resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        knownDataCount={sampleSizeDefaultState.comparativeKnownDataCount} resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState} />}
    </Table.Cell>}
  </>
});

const TableRowEdit = memo(({ startingValue, setResourceState, sectionArrayIndex,
  elementName, setSourceJsonState, sampleSizeDefaultState,
  totalGroupReference, interventionGroupReference, comparatorGroupReference, groupAssignmentReference,
  comparatorGroupDisplay, resourceDictionary, globalContext, researchStudyRelatedArtfifact,
  resourceState }) => {

  let startingSection = {
    title: "", code: "", author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
  }
  if (startingValue) {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }

  const [tableRowSectionState, setTableRowSectionState] = useState(JSON.parse(JSON.stringify(startingSection)));
  /*
    useEffect(() => {
      if (startingValue.title !== tableRowSectionState.title) {
        let startingSection = {
          title: "", code: "", author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
        }
        if (startingValue) {
          if (startingValue.extension) { startingSection.extension = startingValue.extension; }
          if (startingValue.title) { startingSection.title = startingValue.title; }
          if (startingValue.code) { startingSection.code = startingValue.code; }
          if (startingValue.author) { startingSection.author = startingValue.author; }
          if (startingValue.focus) { startingSection.focus = startingValue.focus; }
          if (startingValue.text) { startingSection.text = startingValue.text; }
          if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
          if (startingValue.entry) { startingSection.entry = startingValue.entry; }
          if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
          if (startingValue.section) { startingSection.section = startingValue.section; }
        }
        setTableRowSectionState(JSON.parse(JSON.stringify(startingSection)));
      }
    }, [startingValue]);
  */
  useEffect((() => {
    if (Object.keys(tableRowSectionState).length > 0) {
      let newSection = {};
      if (tableRowSectionState.extension) { newSection.extension = tableRowSectionState.extension; }
      if (tableRowSectionState.title) { newSection.title = tableRowSectionState.title; }
      if (tableRowSectionState.code) { newSection.code = tableRowSectionState.code; }
      if (Array.isArray(tableRowSectionState.author) && tableRowSectionState.author.length > 0) {
        newSection.author = tableRowSectionState.author;
      }
      if (tableRowSectionState.focus) { newSection.focus = tableRowSectionState.focus; }
      if (tableRowSectionState.text) { newSection.text = tableRowSectionState.text; }
      if (tableRowSectionState.orderedBy) { newSection.orderedBy = tableRowSectionState.orderedBy; }
      if (Array.isArray(tableRowSectionState.entry) && tableRowSectionState.entry.length > 0) {
        newSection.entry = tableRowSectionState.entry;
      }
      if (tableRowSectionState.emptyReason) { newSection.emptyReason = tableRowSectionState.emptyReason; }
      if (Array.isArray(tableRowSectionState.section) && tableRowSectionState.section.length > 0) {
        newSection.section = tableRowSectionState.section;
      }
      newSection = autoEditEmptyReason(newSection);
      if (Object.keys(newSection).length === 0) {
        newSection = null;
      }
      newSection = JSON.parse(JSON.stringify(newSection));
      if (sectionArrayIndex || sectionArrayIndex === 0 || sectionArrayIndex === "0") {
        setResourceState(prevState => {
          return { ...prevState, [sectionArrayIndex]: newSection };
        });
      } else if (elementName || elementName === 0 || elementName === "0") {
        handleChange(elementName, newSection, setResourceState);
      }
    }
    //}
  }), [tableRowSectionState]);

  return <InnerSectionArrayDataEntry startingValue={startingSection}
    setResourceState={setTableRowSectionState}
    totalGroupReference={totalGroupReference}
    interventionGroupReference={interventionGroupReference}
    comparatorGroupReference={comparatorGroupReference}
    groupAssignmentReference={groupAssignmentReference}
    comparatorCategory={comparatorGroupDisplay}
    resourceDictionary={resourceDictionary}
    sampleSizeDefaultState={sampleSizeDefaultState}
    researchStudyRelatedArtfifact={researchStudyRelatedArtfifact} resourceState={resourceState}
    globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
});

const createStartingArrayAsObject = (relatesTo, section, reportType) => {
  let variableReferencesDictionary = {};
  let variableReferencesList = [];
  if (Array.isArray(relatesTo)) {
    for (let item of relatesTo) {
      if (item.type === "depends-on") {
        if (item.label === reportType) {
          let variableName = item.display || item.resourceReference?.display;
          let variableReference = item.resourceReference || "";
          if (variableName && variableName !== "[Not yet identified.]") {
            variableReferencesDictionary[variableName] = variableReference;
            variableReferencesList.push(variableName);
          }
        }
      }
    }
  }
  let includedRowFocusList = [];
  let usableSection = section.filter((row) => {
    let rowFocus = row.title || row.focus?.display;
    let match = false;
    if (rowFocus && variableReferencesList.includes(rowFocus)) {
      match = true;
      includedRowFocusList.push(rowFocus);
    }
    return match;
  });
  for (const rowFocus of variableReferencesList) {
    if (!includedRowFocusList.includes(rowFocus)) {
      let starterSection = {
        "title": rowFocus,
        "text": {
          "status": "empty",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
        },
        "focus": variableReferencesDictionary[rowFocus],
        "section": [
          {
            "code": {
              coding: [{
                system: "https://fevir.net/resources/CodeSystem/179423",
                code: "evidence-with-comparator-alone",
                display: "Evidence with comparator alone"
              }]
            },
            "emptyReason": {
              "coding": [
                {
                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                  "code": "notstarted",
                  "display": "Not Started"
                }
              ]
            }
          },
          {
            "code": {
              coding: [{
                system: "https://fevir.net/resources/CodeSystem/179423",
                code: "evidence-with-intervention-alone",
                display: "Evidence with intervention alone"
              }]
            },
            "emptyReason": {
              "coding": [
                {
                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                  "code": "notstarted",
                  "display": "Not Started"
                }
              ]
            }
          },
          {
            "code": {
              coding: [{
                system: "https://fevir.net/resources/CodeSystem/179423",
                code: "evidence-with-total-group",
                display: "Evidence with total group"
              }]
            },
            "emptyReason": {
              "coding": [
                {
                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                  "code": "notstarted",
                  "display": "Not Started"
                }
              ]
            }
          },
          {
            "code": {
              coding: [{
                system: "https://fevir.net/resources/CodeSystem/179423",
                code: "evidence-with-intervention-vs-comparator",
                display: "Evidence with intervention vs. comparator"
              }]
            },
            "emptyReason": {
              "coding": [
                {
                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                  "code": "notstarted",
                  "display": "Not Started"
                }
              ]
            }
          }
        ]
      };
      usableSection.push(starterSection);
    }
  }
  let startingArrayAsObject = {};
  if (usableSection) {
    for (let itemIndex in usableSection) {
      startingArrayAsObject[itemIndex] = usableSection[itemIndex];
    }
  }
  return startingArrayAsObject;
}

const EvidenceTableEdit = ({ resourceState, setResourceState, setSourceJsonState,
  resourceDictionary, globalContext, reportType }) => {
  let interventionGroupReference = null;
  let interventionGroupDisplay = "";
  let comparatorGroupReference = null;
  let comparatorGroupDisplay = "";
  let totalGroupReference = null;
  let totalGroupDisplay = "";
  let groupAssignmentReference = null;
  let groupAssignmentDisplay = "";
  let researchStudyRelatedArtfifact;
  let totalKnownDataCount = "";
  let interventionKnownDataCount = "";
  let comparatorKnownDataCount = "";
  let comparativeKnownDataCount = "";
  if (Array.isArray(resourceState.relatesTo)) {
    for (let item of resourceState.relatesTo) {
      if (item.type === "depends-on") {
        if (item.label === "Intervention Group") {
          interventionGroupReference = item.resourceReference?.reference;
          if (interventionGroupReference?.split("/")[1] && resourceDictionary && resourceDictionary[interventionGroupReference.split("/")[1]]) {
            let groupJson = resourceDictionary[interventionGroupReference.split("/")[1]];
            if (groupJson.quantity) {
              interventionKnownDataCount = groupJson.quantity;
            }
          }
          interventionGroupDisplay = item.display || item.resourceReference?.display || "";
        } else if (item.label === "Comparator Group") {
          comparatorGroupReference = item.resourceReference?.reference;
          if (comparatorGroupReference?.split("/")[1] && resourceDictionary && resourceDictionary[comparatorGroupReference.split("/")[1]]) {
            let groupJson = resourceDictionary[comparatorGroupReference.split("/")[1]];
            if (groupJson.quantity) {
              comparatorKnownDataCount = groupJson.quantity;
            }
          }
          comparatorGroupDisplay = item.display || item.resourceReference?.display || "";
        } else if (item.label === "Total Group") {
          totalGroupReference = item.resourceReference?.reference;
          if (totalGroupReference?.split("/")[1] && resourceDictionary && resourceDictionary[totalGroupReference.split("/")[1]]) {
            let groupJson = resourceDictionary[totalGroupReference.split("/")[1]];
            if (groupJson.quantity) {
              totalKnownDataCount = groupJson.quantity;
            }
          }
          totalGroupDisplay = item.display || item.resourceReference?.display || "";
        }
      } else if (item.type === "composed-of") {
        if (item.label === "Group Assignment" || item.classifier?.[0]?.coding?.[0]?.code === "GroupAssignment") {
          groupAssignmentReference = item.resourceReference?.reference;
          groupAssignmentDisplay = item.display || item.resourceReference?.display || "";
        }
      } else if (item.type === "derived-from") {
        if (item.classifier?.[0]?.coding?.[0]?.code === "protocol") {
          researchStudyRelatedArtfifact = item;
        }
      }
    }
  }
  if (interventionKnownDataCount && comparatorKnownDataCount) {
    comparativeKnownDataCount = interventionKnownDataCount + comparatorKnownDataCount;
  } else if (totalKnownDataCount) {
    comparativeKnownDataCount = totalKnownDataCount;
  }

  const [arrayState, setArrayState] = useState(null);
  const [startingArrayState, setStartingArrayState] = useState(null);

  const [sampleSizeDefaultState, setSampleSizeDefaultState] = useState({});

  const notStartedSection = [
    {
      "code": {
        coding: [{
          system: "https://fevir.net/resources/CodeSystem/179423",
          code: "evidence-with-comparator-alone",
          display: "Evidence with comparator alone"
        }]
      },
      "emptyReason": {
        "coding": [
          {
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }
        ]
      }
    },
    {
      "code": {
        coding: [{
          system: "https://fevir.net/resources/CodeSystem/179423",
          code: "evidence-with-intervention-alone",
          display: "Evidence with intervention alone"
        }]
      },
      "emptyReason": {
        "coding": [
          {
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }
        ]
      }
    },
    {
      "code": {
        coding: [{
          system: "https://fevir.net/resources/CodeSystem/179423",
          code: "evidence-with-total-group",
          display: "Evidence with total group"
        }]
      },
      "emptyReason": {
        "coding": [
          {
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }
        ]
      }
    },
    {
      "code": {
        coding: [{
          system: "https://fevir.net/resources/CodeSystem/179423",
          code: "evidence-with-intervention-vs-comparator",
          display: "Evidence with intervention vs. comparator"
        }]
      },
      "emptyReason": {
        "coding": [
          {
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }
        ]
      }
    }
  ];

  useEffect(() => {
    let resourceStateTemp = JSON.parse(JSON.stringify(resourceState));
    let startingArrayAsObject = createStartingArrayAsObject(resourceStateTemp.relatesTo, resourceStateTemp.section, reportType);
    setArrayState(startingArrayAsObject);
    setStartingArrayState(startingArrayAsObject);
  }, [resourceState.relatesTo]);

  useEffect((() => {
    if (arrayState && Object.keys(arrayState).length > 0 && startingArrayState &&
      JSON.stringify(arrayState) !== JSON.stringify(startingArrayState)) {
      let relatesToStarterDictionary = {};
      let resourceStateTemp = JSON.parse(JSON.stringify(resourceState));
      for (let item of resourceStateTemp.relatesTo) {
        if (item.type === "depends-on") {
          if (item.label === reportType && item.display) {
            relatesToStarterDictionary[item.display] = {
              "title": item.display,
              "text": {
                "status": "empty",
                "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
              },
              "focus": item.resourceReference || {
                "reference": "",
                "type": "EvidenceVariable",
                "display": item.display
              },
              "section": notStartedSection
            };
          }
        }
      }
      let oldArrayStateDictionary = {};
      for (const index in arrayState) {
        let focus = arrayState[index].title || arrayState[index].focus?.display;
        oldArrayStateDictionary[focus] = JSON.parse(JSON.stringify(arrayState[index]));
      }
      let newArrayStateDictionary = {};
      for (const focus of Object.keys(relatesToStarterDictionary)) {
        if (oldArrayStateDictionary[focus]) {
          newArrayStateDictionary[focus] = oldArrayStateDictionary[focus];
        } else {
          newArrayStateDictionary[focus] = relatesToStarterDictionary[focus];
        }
      }
      if (Object.keys(newArrayStateDictionary).length > 0) {
        let newArray = [];
        for (let key of Object.keys(newArrayStateDictionary)) {
          if (typeof newArrayStateDictionary[key] === "object" && Object.keys(newArrayStateDictionary[key]).length > 0) {
            newArray.push(newArrayStateDictionary[key]);
          }
        }
        if (newArray.length > 0) {
          setResourceState(prevState => {
            return { ...prevState, "section": JSON.parse(JSON.stringify(newArray)) }
          });
        }
      }

    }
  }), [arrayState]);

  return <>{arrayState && <>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable Name</Table.HeaderCell>
          {totalGroupReference && <Table.HeaderCell>Total Group</Table.HeaderCell>}
          {interventionGroupReference && <Table.HeaderCell>Intervention Group</Table.HeaderCell>}
          {comparatorGroupReference && <Table.HeaderCell>Comparator Group</Table.HeaderCell>}
          {groupAssignmentReference && <Table.HeaderCell>Comparative Evidence</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row key={"topline"}>
          <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
          {totalGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
            {totalGroupDisplay || totalGroupReference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "totalKnownDataCount": totalKnownDataCount,
                    "totalKnownDataCountSelected": true
                  }
                })
              }}>
              {(totalKnownDataCount && !sampleSizeDefaultState.totalKnownDataCountSelected) &&
                <>➕ Add {totalKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
          {interventionGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
            {interventionGroupDisplay || interventionGroupReference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "interventionKnownDataCount": interventionKnownDataCount,
                    "interventionKnownDataCountSelected": true
                  }
                })
              }}>
              {(interventionKnownDataCount && !sampleSizeDefaultState.interventionKnownDataCountSelected) &&
                <>➕ Add {interventionKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
          {comparatorGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
            {comparatorGroupDisplay || comparatorGroupReference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "comparatorKnownDataCount": comparatorKnownDataCount,
                    "comparatorKnownDataCountSelected": true
                  }
                })
              }}>
              {(comparatorKnownDataCount && !sampleSizeDefaultState.comparatorKnownDataCountSelected) &&
                <>➕ Add {comparatorKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
          {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>
            {groupAssignmentDisplay || groupAssignmentReference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "comparativeKnownDataCount": comparativeKnownDataCount,
                    "comparativeKnownDataCountSelected": true
                  }
                })
              }}>
              {(comparativeKnownDataCount && !sampleSizeDefaultState.comparativeKnownDataCountSelected) &&
                <>➕ Add {comparativeKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
        </Table.Row>
        {(arrayState && Object.keys(arrayState).length > 0) &&
          Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
            return <Table.Row key={keyValuePairIndex}>
              <TableRowEdit startingValue={keyValuePair[1]}
                setResourceState={setArrayState} sectionArrayIndex={keyValuePair[0]}
                totalGroupReference={totalGroupReference}
                interventionGroupReference={interventionGroupReference}
                comparatorGroupReference={comparatorGroupReference}
                groupAssignmentReference={groupAssignmentReference}
                comparatorGroupDisplay={comparatorGroupDisplay}
                resourceDictionary={resourceDictionary}
                researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
                sampleSizeDefaultState={sampleSizeDefaultState} resourceState={resourceState}
                globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
            </Table.Row>
          })}
      </Table.Body>
    </Table>
  </>}
  </>
};

// What it does. (All done on the server)
//1. Composition Resource is primary URL for human use and target for classifications on FEvIR Platform
//2. Bundle Resource (type = document) is the 'computable resource' for the document -- apply to Computable Resource portion of citation summary
//3. When Composition Resource is updated, map all referenced Resources to the corresponding Bundle and Bundle is automatically updated and stored in formstatetable
//3a. The FLI for the Bundle Resource can be 'DocumentFromComposition' + compositionFoi
//4. TO DO: On Client-Side, if the Bundle Builder/Viewer is put into edit mode for a Bundle.type=document with a Composition on the FEvIR Platform, move the user to the corresponding Authoring Tool around the Composition Resource

const getResourcesJsonArray = async (globalContext, resourceFoiArray) => {
  const body = {
    'functionid': 'getmultiplefhirresources',
    'resourceids': resourceFoiArray,
    'idToken': ""
  };
  let response = await submitToFevirServer(globalContext, 20000, body, true, false);
  if (response?.success && response.resourceentries) {
    let resourcesJsonArray = response.resourceentries.map(entry => {
      return JSON.parse(entry.toolstatestring);
    });
    return resourcesJsonArray;
  } else {
    return null;
  }
}

const builderUpdateCompositionDocumentJson = async (resourceId, fhirEntryState, formInputsStateRef, globalContext) => {

  let compositionState = formInputsStateRef.current.compositionDocumentState;

  const body = {
    'functionid': "builderupdatecompositiondocumentjson",
    'idToken': "",
    'resourceId': resourceId,
    'fhirEntryState': fhirEntryState,
    'compositionState': compositionState
  };

  let response = await submitToFevirServer(globalContext, 20000, body, true, false);

  if (response?.success) {
    let fhirJson = response.fhirJson;
    let newClassificationJson = response.newClassificationJson;
    return [fhirJson, newClassificationJson];
  }

}

const sectionDetailViewChangesToJson = (sectionDetailState) => {
  let sectionDetailJson = {};
  sectionDetailJson.title = sectionDetailState.title || "DELETEME";
  sectionDetailJson.code = sectionDetailState.code || "DELETEME";
  sectionDetailJson.focus = sectionDetailState.focus || "DELETEME";
  sectionDetailJson.text = sectionDetailState.text || "DELETEME";
  sectionDetailJson.author = sectionDetailState.author || "DELETEME";
  sectionDetailJson.orderedBy = sectionDetailState.orderedBy || "DELETEME";
  sectionDetailJson.entry = sectionDetailState.entry || "DELETEME";
  sectionDetailJson.section = sectionDetailState.section || "DELETEME";
  sectionDetailJson.emptyReason = sectionDetailState.emptyReason || "DELETEME";
  sectionDetailJson.extension = sectionDetailState.extension || "DELETEME";
  for (const key in sectionDetailJson) {
    if (sectionDetailJson[key] === "DELETEME" ||
      (typeof sectionDetailJson[key] === "object" && Object.keys(sectionDetailJson[key]).length === 0) ||
      sectionDetailJson[key] === "" || sectionDetailJson[key] === undefined || sectionDetailJson[key] === null) {
      delete sectionDetailJson[key];
    }
  }
  return sectionDetailJson;
}

const builderUpdateCompositionSectionDetailJson = (resourceId, fhirEntryState, formInputsStateRef) => {
  let fhirJson = jsonErrorCheck2("Composition", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }

  let sectionDetailState = formInputsStateRef.current.sectionState;
  let newSectionDetailJson = sectionDetailViewChangesToJson(sectionDetailState);
  let sectionPathIndexes = sectionDetailState.sectionPathIndexes;

  let section = fhirJson;
  for (let i in sectionPathIndexes) {
    let sectionIndex = sectionPathIndexes[i];
    if (parseInt(i) + 1 === sectionPathIndexes.length) {
      section.section[sectionIndex] = newSectionDetailJson;
    } else {
      section = section.section[sectionIndex];
    }
  }

  fhirJson = jsonErrorCheck2("Composition", resourceId, fhirEntryState.startingVersionId, fhirJson);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }

  return fhirJson;
}

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } })
}

const ExpandToAddOrEdit = ({ startingValue, setStartCollapsedState }) => {
  return <span className={"unselectable"} style={{ cursor: "pointer" }}
    onClick={() => { setStartCollapsedState(false) }}>
    {startingValue ? <>✎ Edit</> : <>➕ Add</>}
  </span>
}
//copied to SectionDetail (so can be deleted from here when all section editing moved to SectionDetail)
const submitCreateCitation = async (addToast, globalContext, compositionFOI, compositionTitle,
  createCitationModalState, setCreateCitationModalState, addCitationToEntry) => {
  if (createCitationModalState.title) {
    const body = {
      'functionid': 'createcitationforreferencelist', //modeled from submitaddcitationtoproject
      'idToken': "",
      'title': createCitationModalState.title,
      'pmid': createCitationModalState.pmid,
      'description': createCitationModalState.description,
      'compositionFOI': compositionFOI,
      'cmpositionTitle': compositionTitle,
      'username': globalContext.userState.name
    };
    try {
      let response = await submitToFevirServer(globalContext, 10000, body, true, false);
      if (response?.success) {
        if (response.citationFOI) {
          addCitationToEntry(response.citationFOI, createCitationModalState.title);
        }
        addToast('Your Citation for this reference list has been submitted.', { appearance: 'success' });
        setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: false, "title": "", "pmid": "", "description": "" }; });
        window.open("/resources/Citation/" + response.citationFOI, "_blank"); //opens newly created Citation resource in a new tab
      } else {
        alert("There was a problem with your submission. Perhaps the PMID you provided is non-existant");
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    alert("A title is required.");
  }
};
//copied to SectionDetail (so can be deleted from here when all section editing moved to SectionDetail)
const CreateCitationModal = ({ addToast, globalContext, compositionFOI, compositionTitle,
  createCitationModalState, setCreateCitationModalState, addCitationToEntry }) => {
  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />
    <h3>Create and Add Citation to Reference List</h3>
    <br />
    <TextField style={{ width: "580px" }} className="inputField" type='text' label={'Citation Title'}
      size="small" variant='outlined' value={createCitationModalState.title}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, title: e.target.value }; });
      }} />
    <br /><br />
    <TextField style={{ width: "280px" }} className="inputField" type='number' label={'PMID (optional)'}
      size="small" variant='outlined' value={createCitationModalState.pmid}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, pmid: e.target.value }; });
      }} />
    <br /><br />
    <TextField style={{ width: "580px" }} multiline className="inputField" type='text'
      label={'Citation Description (optional)'} size="small" variant='outlined'
      value={createCitationModalState.description}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, description: e.target.value }; });
      }} />
    <br /><br />
    <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
      content="Submit Citation" positive
      onClick={() => {
        submitCreateCitation(addToast, globalContext, compositionFOI, compositionTitle,
          createCitationModalState, setCreateCitationModalState, addCitationToEntry);
      }}
    />
    <br />
  </div>;
  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={createCitationModalState?.modalOpen}
      centered={false}
      content={modalContent}
    />
  )
}

const autoEditEmptyReason = (newSection) => {
  if (newSection.section?.length || newSection.entry?.length ||
    (newSection.text && newSection.text.status !== "empty")) {
      if (newSection.emptyReason) {
        delete newSection.emptyReason;
      }
  } else if (!newSection.emptyReason) {
    newSection.emptyReason = {
      "coding": [{
        "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
        "code": "notstarted", "display": "Not Started"
      }]
    }
  }
  return newSection;
}
// TO DO
//map the use of xxxState across Builder/Viewer Tools and determine if Authoring/Viewing Tools should be handled the same way or consolidated into documentBundleState
//recommendationTextViewChangesToJson is async/await because it creates/updates PlanDefinition and RecommendationJustification Resources
//Does this async/await appraoch apply across the Authoring/Viewing Tools? Perhaps yes if the real role is editing Composition PLUS structured data

// entryResourceEntry is (the interface to enter changes to the referenced Resource JSON)
const DocumentSectionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  startCollapsed, sectionArrayIndex,
  fixedTitle, editTitle, fixedCode, editCode, editCodeAllowedValues, inTableCell,
  editCodeValueSet, editCodeInTableCell, editCodeSystemChoices, editCodeSystemChoicesOpen, editCodeStartCollapsed,
  editCodeCodeableConceptLevelValueSet, editAuthor, editFocus, editFocusEnableCreation, editFocusReferencedResourceTypes,
  editFocusStartCollapsed, editFocusStartingResourceType,
  editFocusSetProfile, editFocusSelectProfile, editFocusSelectAnyProfile, editFocusAddElementValues,
  textStatusAutomatic, editTextStatus, editTextDiv, textDeletable, generateTextDivFunction,
  autogenerateTextDiv, emptyTextDivValue, editTextFunction, generateNarrativeFromSingleEntryFunction,
  generateNarrativeFromEntryArrayFunction, entryFoiList,
  fixedOrderedBy, editOrderedBy, editOrderedByAllowedValues, editOrderedByValueSet, editOrderedByInTableCell,
  editOrderedBySystemChoices, editOrderedBySystemChoicesOpen, editOrderedByStartCollapsed,
  editOrderedByCodeableConceptLevelValueSet, entryLimitToOne, entryLimitToTwo, noEntry, entryHeader,
  entryDeletable, entryInstanceDeletable, entryEnableCreation, entryReferencedResourceTypes,
  statisticType, statisticUnit, knownDataCount, createCitation,
  entryStartCollapsed, entryStartEmptyArrayClosed, entryStartingResourceType, entrySetProfile, entrySelectProfile, entrySelectAnyProfile,
  entryAddElementValues, fixedEmptyReason, editEmptyReason, emptyReasonAllowedValues, emptyReasonValueSet,
  emptyReasonInTableCell, emptyReasonSystemChoices, emptyReasonSystemChoicesOpen, emptyReasonStartCollapsed,
  emptyReasonCodeableConceptLevelValueSet, noSection, editSection, containedSectionsHeader,
  addSectionAllowed, sectionEntryRecommendationOnly,
  allowedSectionCodes, requiredSectionCodes, editableSectionCodes, entryResourceEntry,
  topSection, sectionDictionary, resourceDictionary, setSourceJsonState, globalContext,
  resourceState, previousVersionLoaded,
  compositionId, compositionTitle, sectionCode,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  if (adaptationReportState?.pathList && !sectionCode && startingValue?.code) {
    if (startingValue.code.text) {
      sectionCode = "section:" + startingValue.code.text;
    } else if (startingValue.code.coding?.[0]) {
      sectionCode = "section:" + startingValue.code.coding[0].system + "#" + startingValue.code.coding[0].code;
    } else {
      sectionCode = "section:" + JSON.stringify(startingValue.code);
    }
  }

  if (entryAddElementValues) {
    if (!entryAddElementValues.relatedArtifact && !entryAddElementValues.relatesTo) {
      entryAddElementValues.relatedArtifact = [
        {
          "type": "part-of",
          "resourceReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ];
    }
  } else {
    entryAddElementValues = {
      relatedArtifact: [
        {
          "type": "part-of",
          "resourceReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ]
    };
  }

  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];
  let orderedByValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
  ];
  let orderedByCodeableConceptLevelValueSet = [
    {
      "coding": [{ "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
      ]
    }
  ];
  let timestamp = new Date();
  let startingSection = {
    title: "", code: { "text": "section created " + timestamp }, author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }
  if (fixedTitle) {
    startingSection.title = fixedTitle;
  }
  if (fixedCode) {
    startingSection.code = fixedCode;
  }
  if (fixedOrderedBy) {
    startingSection.orderedBy = fixedOrderedBy;
  }

  const [sectionState, setSectionState] = useState(startingSection);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);
  const [editAuthorCollapsedState, setEditAuthorCollapsedState] = useState(true);
  const { addToast } = useToasts();
  const [createCitationModalState, setCreateCitationModalState] = useState({
    "modalOpen": false,
    "title": "", "pmid": "", "description": ""
  });
  const [citationEntryRevisedState, setCitationEntryRevisedState] = useState(false);

  const addCitationToEntry = (citationFOI, title) => {
    setSectionState(prevState => {
      let newEntry = prevState.entry || [];
      newEntry.push({
        "reference": "Citation/" + citationFOI,
        "type": "Citation", "display": title
      });
      return { ...prevState, "entry": newEntry };
    });
    setCitationEntryRevisedState(true);
  };

  useEffect(() => {
    if (citationEntryRevisedState) {
      alert("Citation added."); //This alert appears at the right time but does not re-render the component
      setCitationEntryRevisedState(false);
    }
  }, [citationEntryRevisedState]);

  useEffect((() => {
    if (Object.keys(sectionState).length > 0) {
      let newSection = {};
      if (sectionState.extension) { newSection.extension = sectionState.extension; }
      if (sectionState.title) { newSection.title = sectionState.title; }
      if (sectionState.code) { newSection.code = sectionState.code; }
      if (Array.isArray(sectionState.author) && sectionState.author.length > 0) {
        newSection.author = sectionState.author;
      }
      if (sectionState.focus) { newSection.focus = sectionState.focus; }
      if (sectionState.text) { newSection.text = sectionState.text; }
      if (sectionState.orderedBy) { newSection.orderedBy = sectionState.orderedBy; }
      if (Array.isArray(sectionState.entry) && sectionState.entry.length > 0) {
        newSection.entry = sectionState.entry;
      }
      if (sectionState.emptyReason) { newSection.emptyReason = sectionState.emptyReason; }
      if (Array.isArray(sectionState.section) && sectionState.section.length > 0) {
        newSection.section = sectionState.section;
      }
      newSection = autoEditEmptyReason(newSection);
      if (Object.keys(newSection).length === 0) {
        newSection = null;
      }
      if (sectionArrayIndex || sectionArrayIndex === 0 || sectionArrayIndex === "0") {
        setResourceState(prevState => {
          let newSectionArray = prevState.section || [];
          newSectionArray[sectionArrayIndex] = newSection;
          return { ...prevState, "section": newSectionArray };
        });
      } else if (elementName || elementName === 0 || elementName === "0") {
        handleChange(elementName, newSection, setResourceState);
      }
    }
  }), [sectionState]);

  if (startCollapsedState) {
    return <>
      <div>
        {fieldLabel && <b>{fixedTitle || sectionState.title || fieldLabel}: </b>}
        {startingValue && <div style={{ marginLeft: "24px" }}>
          {(startingSection.title && !inTableCell) && <p><b>Title: </b>{startingSection.title}</p>}
          {startingSection.text?.div &&
            <div><b>Summary: </b><DisplayFromFHIR xhtml={startingSection.text.div} /></div>}
          {startingSection.entry?.length > 0 && <>
            {startingSection.entry.length > 1 ? <><br />Contains {startingSection.entry.length} entries</>
              :
              <><br /><DisplayFromFHIR reference={startingSection.entry[0]} /></>}
          </>}
          {startingSection.section?.length > 0 && <>
            {inTableCell ? <><br />Contains {startingSection.section.length} subsections</> : <>
              <div>
                <p><b>Subsections:</b></p>
                {startingSection.section.map((section, sectionIndex) => {
                  return <div key={sectionIndex} style={{ marginLeft: "24px" }}>
                    {section.title && <span><b>Title: </b>{section.title}</span>}
                    {section.text?.div && <div><br /><b>Summary: </b><DisplayFromFHIR xhtml={section.text.div} /></div>}
                  </div>
                })}
              </div>
            </>}</>}
          <br />
        </div>}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
        &nbsp;&nbsp;{!inTableCell && (fixedTitle || sectionState.title || fieldLabel)}
      </div>
    </>
  } else {
    return <>
      {createCitation && <>
        <CreateCitationModal addToast={addToast} globalContext={globalContext}
          compositionFOI={resourceState.id} compositionTitle={resourceState.title}
          createCitationModalState={createCitationModalState}
          setCreateCitationModalState={setCreateCitationModalState}
          addCitationToEntry={addCitationToEntry} />
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={"Create Citation"}
          onClick={async () => {
            setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: true }; });
          }}
          disabled={previousVersionLoaded}
        />
        <br /></>}
      {fieldLabel && <p><b>{fixedTitle || sectionState.title || fieldLabel}: </b></p>}
      <div style={{ marginLeft: "24px" }}>
        {fixedTitle && <p><b>Title: </b>{fixedTitle}</p>}
        {editTitle && <>
          <DataEntry datatype='string' elementName='title' fieldLabel='Section Title'
            startingValue={sectionState.title} setResourceState={setSectionState}
            path={sectionCode + '.title'}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </>}
        {editCode && <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Section Code'
          startingValue={sectionState.code} allowedValues={editCodeAllowedValues}
          valueSet={editCodeValueSet} inTableCell={editCodeInTableCell} systemChoices={editCodeSystemChoices}
          systemChoicesOpen={editCodeSystemChoicesOpen} startCollapsed={editCodeStartCollapsed ?? true}
          codeableConceptLevelValueSet={editCodeCodeableConceptLevelValueSet}
          setResourceState={setSectionState} />}
        {(textStatusAutomatic || editTextStatus || editTextDiv || textDeletable ||
          generateTextDivFunction || autogenerateTextDiv || editTextFunction) &&
          <NarrativeEntry elementName='text' fieldLabel='Section Summary'
            editTextStatus={editTextStatus} editTextDiv={editTextDiv} emptyTextDivValue={emptyTextDivValue}
            editTextFunction={editTextFunction}
            generateSummaryFunction={generateTextDivFunction}
            generateNarrativeFromSingleEntryFunction={generateNarrativeFromSingleEntryFunction}
            generateNarrativeFromEntryArrayFunction={generateNarrativeFromEntryArrayFunction}
            entryFoi={sectionState.entry?.[0]?.reference?.split("/")[1]}
            entryFoiList={entryFoiList}
            resourceDictionary={resourceDictionary} globalContext={globalContext}
            startingValue={sectionState.text} setResourceState={setSectionState}
            path={sectionCode}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />}
        {editAuthor && <>
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setEditAuthorCollapsedState(!editAuthorCollapsedState) }}>
            {editAuthorCollapsedState ? <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </> : <>
              <b>Collapse Section Author Data Entry ▼</b>
            </>}
          </span>
          <br />
          {!editAuthorCollapsedState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author'
              fieldLabel='Section Author'
              startingValue={sectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              startCollapsed startEmptyArrayClosed
              setResourceState={setSectionState}
              path={sectionCode + '.author'}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </>
          }
        </>}
        {editFocus && <>
          {editFocusEnableCreation ?
            <h3>Create or identify the Resource defining the focus for this section.</h3>
            :
            <h3>Identify the Resource defining the focus for this section.</h3>}
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='Reference' elementName='focus'
              fieldLabel={editFocusStartingResourceType + ' Resource'}
              startingValue={sectionState.focus} referencedResourceTypes={editFocusReferencedResourceTypes}
              startingResourceType={editFocusStartingResourceType} setProfile={editFocusSetProfile}
              selectProfile={editFocusSelectProfile} selectAnyProfile={editFocusSelectAnyProfile}
              startCollapsed={editFocusStartCollapsed ?? true} enableCreation={editFocusEnableCreation}
              setResourceState={setSectionState} addElementValues={editFocusAddElementValues}
              fullResourceState={resourceState}
              globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
          </div>
        </>}
        {editOrderedBy && <>
          <h3>Ordering of Section Content</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='orderedBy' fieldLabel='Ordered by'
              startingValue={sectionState.orderedBy} valueSet={editOrderedByValueSet || orderedByValueSet}
              startCollapsed={editOrderedByStartCollapsed ?? true}
              codeableConceptLevelValueSet={editOrderedByCodeableConceptLevelValueSet || orderedByCodeableConceptLevelValueSet}
              allowedValues={editOrderedByAllowedValues} inTableCell={editOrderedByInTableCell}
              systemChoices={editOrderedBySystemChoices} systemChoicesOpen={editOrderedBySystemChoicesOpen}
              setResourceState={setSectionState}
              path={sectionCode + '.orderedBy'}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
        </>}
        {!noEntry && <>
          {entryHeader ?
            <h3>{entryHeader}</h3>
            :
            <h3>{fieldLabel} Section Content (as Resource Entries)</h3>
          }
          <div style={{ marginLeft: "24px" }}>
            {entryResourceEntry === "SingleEvidenceEntry" ?
              <SingleEvidenceEntry startingValue={sectionState.entry}
                statisticType={statisticType} statisticUnit={statisticUnit}
                knownDataCount={knownDataCount}
                addElementValues={entryAddElementValues}
                resourceDictionary={resourceDictionary} globalContext={globalContext}
                setResourceState={setSectionState} resourceState={resourceState}
                setSourceJsonState={setSourceJsonState} />
              :
              <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel={fieldLabel + ' Content Entry'}
                startingValue={sectionState.entry} referencedResourceTypes={entryReferencedResourceTypes}
                startCollapsed={entryStartCollapsed || sectionEntryRecommendationOnly}
                startEmptyArrayClosed={entryStartEmptyArrayClosed || sectionEntryRecommendationOnly}
                inTableCell={inTableCell}
                deletableArray={entryDeletable || sectionEntryRecommendationOnly}
                deletable={entryInstanceDeletable || sectionEntryRecommendationOnly}
                enableCreation={entryEnableCreation || sectionEntryRecommendationOnly}
                startingResourceType={entryStartingResourceType ? entryStartingResourceType : sectionEntryRecommendationOnly && "Composition"}
                setProfile={entrySetProfile ? entrySetProfile : sectionEntryRecommendationOnly && "Recommendation"}
                selectProfile={entrySelectProfile} selectAnyProfile={entrySelectAnyProfile}
                addElementValues={entryAddElementValues}
                setResourceState={setSectionState} fullResourceState={resourceState}
                globalContext={globalContext} setSourceJsonState={setSourceJsonState}
                path={sectionCode + '.entry'}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            }
          </div>
        </>}
        {editSection && <>
          {containedSectionsHeader ?
            <p><b>{containedSectionsHeader}</b></p>
            :
            <p><b>Contained Sections (for text with subheadings):</b></p>
          }
          <SectionArrayDataEntry startingValue={sectionState.section} setResourceState={setSectionState}
            addSectionAllowed={addSectionAllowed}
            allowedSectionCodes={allowedSectionCodes}
            requiredSectionCodes={requiredSectionCodes}
            editableSectionCodes={editableSectionCodes}
            sectionDictionary={sectionDictionary}
            topSection={sectionState.title || topSection + " subsection"}
            compositionId={compositionId} compositionTitle={compositionTitle}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState}
            globalContext={globalContext}
            sectionEntryRecommendationOnly={sectionEntryRecommendationOnly}
            entryReferencedResourceTypes={entryReferencedResourceTypes}
            entryResourceEntry={entryResourceEntry}
            resourceState={resourceState} setSourceJsonState={setSourceJsonState}
          />
        </>}
        {(editEmptyReason && (!sectionState.section || sectionState.section.length === 0) &&
          (!sectionState.entry || sectionState.entry.length === 0) &&
          (!sectionState.text || sectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={sectionState.emptyReason} valueSet={emptyReasonValueSet}
                startCollapsed={emptyReasonStartCollapsed || inTableCell}
                allowedValues={emptyReasonAllowedValues} inTableCell={emptyReasonInTableCell || inTableCell} systemChoices={emptyReasonSystemChoices}
                systemChoicesOpen={emptyReasonSystemChoicesOpen} codeableConceptLevelValueSet={emptyReasonCodeableConceptLevelValueSet}
                setResourceState={setSectionState}
                path={sectionCode + '.emptyReason'}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            </div>
          </>}
      </div>
    </>
  }
})

const SectionArrayDataEntry = memo(({ startingValue, setResourceState, topSection,
  addSectionAllowed,
  allowedSectionCodes, requiredSectionCodes, editableSectionCodes, sectionDictionary,
  compositionId, compositionTitle,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState, globalContext,
  sectionEntryRecommendationOnly,
  entryReferencedResourceTypes, entryResourceEntry, resourceState, setSourceJsonState }) => {
  let showDataEntry = true;
  let startingArrayAsObject = {};
  if (startingValue) {
    for (let itemIndex in startingValue) {
      startingArrayAsObject[itemIndex] = startingValue[itemIndex];
    }
  } else {
    //startingArrayAsObject['0'] = null;
    showDataEntry = false;
  }

  const [arrayState, setArrayState] = useState(startingArrayAsObject);
  const [showDataEntryState, setShowDataEntryState] = useState(showDataEntry);

  useEffect((() => {
    if (Object.keys(arrayState).length) {
      let newArray = [];
      for (let key of Object.keys(arrayState)) {
        if (arrayState[key] !== null && !(typeof arrayState[key] === "object" && Object.keys(arrayState[key]).length === 0) && !(Array.isArray(arrayState[key]) && arrayState[key].length === 0) && arrayState[key] !== "" &&
          arrayState[key] !== undefined && arrayState[key] !== "DELETEME") {
          newArray.push(arrayState[key]);
        }
      }
      if (newArray.length > 0) {
        handleChange("section", newArray, setResourceState);
      } else {
        handleChange("section", null, setResourceState);
      }
    }
  }), [arrayState]);

  return <div>
    <div style={{ marginLeft: "24px" }}>
      {(showDataEntryState === true) &&
        Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
          return <div key={keyValuePairIndex}>
            {keyValuePair[1] === "DELETEME" ?
              <>
                <p><b>{topSection + ' Section entry ' + (keyValuePairIndex + 1)}: </b></p>
                <div style={{ marginLeft: "24px" }}>WILL BE DELETED.</div>
              </>
              :
              <>{(sectionDictionary && keyValuePair[1]?.code &&
                Object.keys(sectionDictionary).includes(JSON.stringify(keyValuePair[1].code))) ?
                <DocumentSectionEntry elementName={keyValuePair[0]} topSection={topSection}
                  globalContext={globalContext}
                  fieldLabel={topSection + ' Section entry ' + (keyValuePairIndex + 1)}
                  startingValue={keyValuePair[1]} setResourceState={setArrayState}
                  startCollapsed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].startCollapsed}
                  fixedTitle={sectionDictionary[JSON.stringify(keyValuePair[1].code)].fixedTitle}
                  editTitle={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editTitle}
                  editTextStatus={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editTextStatus}
                  editTextDiv={typeof (sectionDictionary[JSON.stringify(keyValuePair[1].code)].editTextDiv) === "boolean" ?
                    sectionDictionary[JSON.stringify(keyValuePair[1].code)].editTextDiv : true}
                  textDeletable={sectionDictionary[JSON.stringify(keyValuePair[1].code)].textDeletable}
                  emptyTextDivValue={sectionDictionary[JSON.stringify(keyValuePair[1].code)].emptyTextDivValue}
                  editTextFunction={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editTextFunction}
                  generateTextDivFunction={sectionDictionary[JSON.stringify(keyValuePair[1].code)].generateTextDivFunction}
                  editFocus={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocus}
                  editFocusEnableCreation={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusEnableCreation}
                  editFocusReferencedResourceTypes={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusReferencedResourceTypes}
                  editFocusStartCollapsed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusStartCollapsed}
                  editFocusStartingResourceType={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusStartingResourceType}
                  editFocusSetProfile={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusSetProfile}
                  editFocusSelectProfile={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusSelectProfile}
                  editFocusSelectAnyProfile={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusSelectAnyProfile}
                  noEntry={sectionDictionary[JSON.stringify(keyValuePair[1].code)].noEntry}
                  entryHeader={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryHeader}
                  entryDeletable={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryDeletable}
                  entryInstanceDeletable={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryInstanceDeletable}
                  entryEnableCreation={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryEnableCreation}
                  entryReferencedResourceTypes={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryReferencedResourceTypes}
                  entryStartCollapsed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryStartCollapsed}
                  entryStartEmptyArrayClosed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryStartEmptyArrayClosed}
                  entryStartingResourceType={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryStartingResourceType}
                  entrySetProfile={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entrySetProfile}
                  editEmptyReason={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editEmptyReason}
                  emptyReasonValueSet={sectionDictionary[JSON.stringify(keyValuePair[1].code)].emptyReasonValueSet}
                  emptyReasonCodeableConceptLevelValueSet={sectionDictionary[JSON.stringify(keyValuePair[1].code)].emptyReasonCodeableConceptLevelValueSet}
                  emptyReasonStartCollapsed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].emptyReasonStartCollapsed}
                  noSection={sectionDictionary[JSON.stringify(keyValuePair[1].code)].noSection}
                  editSection={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editSection}
                  addSectionAllowed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].addSectionAllowed}
                  containedSectionsHeader={sectionDictionary[JSON.stringify(keyValuePair[1].code)].containedSectionsHeader}
                  sectionDictionary={sectionDictionary[JSON.stringify(keyValuePair[1].code)].sectionDictionary}
                  compositionId={compositionId} compositionTitle={compositionTitle}
                  adaptationReportState={adaptationReportState}
                  setAdaptationReportState={setAdaptationReportState}
                  setChangeAvailableToSaveState={setChangeAvailableToSaveState}
                  resourceState={resourceState} setSourceJsonState={setSourceJsonState}
                />
                :
                <DocumentSectionEntry elementName={keyValuePair[0]} topSection={topSection}
                  fieldLabel={topSection + ' Section entry ' + (keyValuePairIndex + 1)}
                  editTitle={true} editCode={true}
                  startingValue={keyValuePair[1]} setResourceState={setArrayState}
                  sectionDictionary={sectionDictionary} editTextDiv={true}
                  startCollapsed={keyValuePair[1] && true}
                  addSectionAllowed={addSectionAllowed}
                  editSection={addSectionAllowed}
                  compositionId={compositionId} compositionTitle={compositionTitle}
                  adaptationReportState={adaptationReportState}
                  setAdaptationReportState={setAdaptationReportState}
                  setChangeAvailableToSaveState={setChangeAvailableToSaveState}
                  sectionEntryRecommendationOnly={sectionEntryRecommendationOnly}
                  entryReferencedResourceTypes={entryReferencedResourceTypes}
                  entryResourceEntry={entryResourceEntry}
                  resourceState={resourceState} setSourceJsonState={setSourceJsonState}
                />
              }</>
            }
          </div>
        })}
      <><br />
        {addSectionAllowed &&
          <Button className="formButton" style={{ color: "#000000" }} content={"+ Add Section"}
            onClick={() => {
              setArrayState(prevState => {
                let arrayLength = Object.keys(prevState).length;
                let arrayIndexNumber = arrayLength.toString();
                if (prevState['0'] === null) {
                  arrayIndexNumber = '1';
                }
                if (allowedSectionCodes?.length === 1) {
                  if (sectionDictionary[JSON.stringify(allowedSectionCodes[0])]?.sectionDictionary) {
                    let subsection = [];
                    for (let key in sectionDictionary[JSON.stringify(allowedSectionCodes[0])].sectionDictionary) {
                      subsection.push({ "code": JSON.parse(key) });
                    }
                    return {
                      ...prevState,
                      [arrayIndexNumber]: { "code": allowedSectionCodes[0], "section": subsection }
                    };
                  } else {
                    return {
                      ...prevState,
                      [arrayIndexNumber]: { "code": allowedSectionCodes[0] }
                    };
                  }
                } else {
                  return {
                    ...prevState,
                    //['0']: prevState['0'] || "",
                    [arrayIndexNumber]: ""
                  };
                }
              });
              if (!showDataEntryState) {
                setShowDataEntryState(true);
              }
            }} />}
      </>
    </div>
  </div>
});

//note no author at the "Bundle Resource" level -- the metadata needs to be in Composition in the Bundle

export {
  autoEditEmptyReason, builderUpdateCompositionDocumentJson, builderUpdateCompositionSectionDetailJson, 
  DocumentSectionEntry, EvidenceTableDisplay, EvidenceTableEdit, getResourcesJsonArray
};