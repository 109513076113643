import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const getDefinitionByTypeAndValueValuesFromFhirJson = (definitionByTypeAndValue) => {

    let valueType = "";
    let typeCodeableCodingJson;
    let valueCodeableCoding;

    let valueComparator = "";
    let valueValue = "";
    let valueUnit = "";

    let valueLow = "";
    let valueLowUnit = "";
    let valueHigh = "";
    let valueHighUnit = "";

    let valueValueText = "";
    let valueText = "";
    let valueBooleanRadioValue = "";
    let valueReference = "";
    let valueId = "";
    let method = "";
    let device = "";
    let offset = "";

    let typeCodeableConceptJson;
    if (definitionByTypeAndValue.type) {
        typeCodeableConceptJson = definitionByTypeAndValue.type;
        if (typeCodeableConceptJson) {
            if (typeCodeableConceptJson.coding?.length > 0) {
                if (typeCodeableConceptJson.coding[0].system || typeCodeableConceptJson.coding[0].code || typeCodeableConceptJson.coding[0].display) {
                    typeCodeableCodingJson = typeCodeableConceptJson.coding[0];
                }
            }
            if (typeCodeableConceptJson.text) {
                valueText = typeCodeableConceptJson.text;
            }
        }
    } else if (definitionByTypeAndValue.typeCodeableConcept) { /* July 19, 2022, remove this else if and its contents */
        typeCodeableConceptJson = definitionByTypeAndValue.typeCodeableConcept;
        if (typeCodeableConceptJson) {
            if (typeCodeableConceptJson.coding?.length > 0) {
                if (typeCodeableConceptJson.coding[0].system || typeCodeableConceptJson.coding[0].code || typeCodeableConceptJson.coding[0].display) {
                    typeCodeableCodingJson = typeCodeableConceptJson.coding[0];
                }
            }
            if (typeCodeableConceptJson.text) {
                valueText = typeCodeableConceptJson.text;
            }
        }
    }

    if (definitionByTypeAndValue.valueQuantity) {
        valueType = "Define by quantity";
        let valueQuantity = definitionByTypeAndValue.valueQuantity;
        if (valueQuantity.value !== undefined) {
            valueValue = parseFloat(valueQuantity.value);
        }
        if (valueQuantity.comparator) {
            valueComparator = valueQuantity.comparator;
        }
        if (valueQuantity.unit) {
            valueUnit = valueQuantity.unit;
        }
    } else if (definitionByTypeAndValue.valueRange) {
        valueType = "Define by range";
        let valueRange = definitionByTypeAndValue.valueRange;
        if (valueRange.low) {
            if (valueRange.low.value !== undefined) {
                valueLow = parseFloat(valueRange.low.value);
            }
            if (valueRange.low.unit) {
                valueLowUnit = valueRange.low.unit;
            }
        }
        if (valueRange.high) {
            if (valueRange.high.value !== undefined) {
                valueHigh = parseFloat(valueRange.high.value);
            }
            if (valueRange.high.unit) {
                valueHighUnit = valueRange.high.unit;
            }
        }
    } else if (definitionByTypeAndValue.valueBoolean !== undefined) {
        valueType = "Define by Boolean";
        valueBooleanRadioValue = definitionByTypeAndValue.valueBoolean;
    } else if (definitionByTypeAndValue.valueReference) {
        valueType = "Define by Identifier";
        valueReference = definitionByTypeAndValue.valueReference;
    } else if (definitionByTypeAndValue.valueId) {
        valueType = "Define by Id";
        valueId = definitionByTypeAndValue.valueId;
    } else if (definitionByTypeAndValue.valueCodeableConcept) {
        let valueCodeableConceptJson = definitionByTypeAndValue.valueCodeableConcept;
        if (valueCodeableConceptJson.coding?.length > 0 && (valueCodeableConceptJson.coding[0].system || valueCodeableConceptJson.coding[0].code || valueCodeableConceptJson.coding[0].display)) {
            valueType = "Define by code";
            valueCodeableCoding = valueCodeableConceptJson.coding[0];
        } else if (valueCodeableConceptJson.text) {
            valueType = "Simple text";
            valueValueText = valueCodeableConceptJson.text;
        }
    }
    if (definitionByTypeAndValue.method) {
        method = definitionByTypeAndValue.method;
    }
    if (definitionByTypeAndValue.device) {
        device = definitionByTypeAndValue.device;
    }
    if (definitionByTypeAndValue.offset) {
        offset = definitionByTypeAndValue.offset;
    }
    let values = {
        typeCodeableCodingJson: typeCodeableCodingJson,
        method: method,
        device: device,
        valueCodeableCoding: valueCodeableCoding,
        valueText: valueText,
        valueValueText: valueValueText,
        valueType: valueType,
        valueValue: valueValue,
        valueComparator: valueComparator,
        valueUnit: valueUnit,
        valueLow: valueLow,
        valueLowUnit: valueLowUnit,
        valueHigh: valueHigh,
        valueHighUnit: valueHighUnit,
        valueBooleanRadioValue: valueBooleanRadioValue,
        valueReference: valueReference,
        valueId: valueId,
        offset: offset
    };
    return values;
}

const getCharacteristicValuesFromFhirJson = (fhirEntryState, characteristicState) => {
    //For the EvidenceVariable Viewer/Builder in the Characteristic Detail tab
    //Gets the values from the FHIR JSON for a single characteristic and returns the values to be used for the characteristicState for display/editing in the Characteristic Detail tab. It doesn't set the state in this function.

    let update = false; //Maybe make a better variable name
    let linkId;
    let characteristicJson = fhirEntryState.characteristicSelected; //Maybe call it characteristicJson, because it's the original FHIR JSON
    let characteristicValues = characteristicState || {};

    if (characteristicValues.loaded === false || characteristicValues.loaded === undefined) {
        update = true;
    }

    let definitionDetailTypeInformation = false;

    let exclude;
    let includeExcludeRadioValue = "";

    let characteristicDefineType = "";
    let valueDefineType = "";

    let definitionCanonical = "";
    let definitionId = "";
    let definitionReference = {};
    let definitionCodeableConcept = {};
    let simpleText = characteristicState.simpleText || "";
    let expressionLanguage = "";
    let expressionExpression = "";
    let expressionDescription = "";
    let expressionName = "";
    let expressionUrl = "";

    let valueText = "";

    let valueTypeInformation = false;

    let valueType = "";

    let typeCodeableCodingJson = {};
    let valueCodeableCoding = {};

    let valueComparator = "";
    let valueValue = "";
    let valueUnit = "";

    let valueLow = "";
    let valueLowUnit = "";
    let valueHigh = "";
    let valueHighUnit = "";

    let valueValueText = "";

    //let valueIdentifier;
    let valueReference = "";
    let valueId = "";

    let valueBooleanRadioValue = "";
    //true or false

    let combinationType; //Dropdownvalue
    let combinationThreshold = "";

    let description = "";
    let note = [];
    let method;
    let device;
    let offset;

    let subCharacteristics = [];

    if (JSON.stringify(characteristicState.pathIndexes) !== JSON.stringify(fhirEntryState.conceptPathIndexes)) {
        //startingCharacteristic

        //Timing array
        //Mean/Median
        update = true;
        if (characteristicJson) {
            if (characteristicJson.linkId) {
                linkId = characteristicJson.linkId;
            }
            if (characteristicJson.description) {
                description = characteristicJson.description;
            }
            if (characteristicJson.note) {
                note = characteristicJson.note;
                if (!Array.isArray(note)) {
                    note = [note];
                }
                for (let i in note) {
                    if (typeof (note[i]) === 'string') {
                        note[i] = { "text": note[i] };
                    }
                }
            }
            if (characteristicJson.definitionReference || characteristicJson.definitionCanonical || characteristicJson.definitionCodeableConcept || characteristicJson.definitionExpression
                || characteristicJson.definitionId || characteristicJson.definitionId || characteristicJson.definitionByTypeAndValue || characteristicJson.definitionByCombination) {
                //If one of these exist in the JSON then the characteristic was already previously defined
                //If it alreadt exists then it will default as "Include"
                exclude = false;
                includeExcludeRadioValue = "Include";
            }
            if (characteristicJson.exclude) {
                exclude = true;
                includeExcludeRadioValue = "Exclude";
            }

            if (characteristicJson.definitionCanonical || characteristicJson.definitionId || characteristicJson.definitionReference) {

                definitionCanonical = characteristicJson.definitionCanonical || "";
                definitionId = characteristicJson.definitionId || "";
                definitionReference = characteristicJson.definitionReference || "";
                //Select radio
                characteristicDefineType = "Direct Link";
            } else if (characteristicJson.definitionCodeableConcept) {
                definitionCodeableConcept = characteristicJson.definitionCodeableConcept;
                if (definitionCodeableConcept.coding?.length > 0) {
                    if (definitionCodeableConcept.coding[0].system || definitionCodeableConcept.coding[0].code || definitionCodeableConcept.coding[0].display) {
                        characteristicDefineType = "Define by code";
                    }
                } else if (definitionCodeableConcept.text) {
                    simpleText = characteristicJson.definitionCodeableConcept.text;
                    characteristicDefineType = "Simple text";
                }
                //
                //} else if (characteristic.definitionString) {
            } else if (characteristicJson.definitionByTypeAndValue) {
                let definitionByTypeAndValue = characteristicJson.definitionByTypeAndValue;
                characteristicDefineType = "Define by type and value";

                let definitionByTypeAndValueValues = getDefinitionByTypeAndValueValuesFromFhirJson(definitionByTypeAndValue);
                typeCodeableCodingJson = definitionByTypeAndValueValues["typeCodeableCodingJson"];
                valueCodeableCoding = definitionByTypeAndValueValues["valueCodeableCoding"];
                valueText = definitionByTypeAndValueValues["valueText"];
                valueValueText = definitionByTypeAndValueValues["valueValueText"];
                valueType = definitionByTypeAndValueValues["valueType"];
                valueValue = definitionByTypeAndValueValues["valueValue"];
                valueComparator = definitionByTypeAndValueValues["valueComparator"];
                valueUnit = definitionByTypeAndValueValues["valueUnit"];
                valueLow = definitionByTypeAndValueValues["valueLow"];
                valueLowUnit = definitionByTypeAndValueValues["valueLowUnit"];
                valueHigh = definitionByTypeAndValueValues["valueHigh"];
                valueHighUnit = definitionByTypeAndValueValues["valueHighUnit"];
                valueBooleanRadioValue = definitionByTypeAndValueValues["valueBooleanRadioValue"];
                valueReference = definitionByTypeAndValueValues["valueReference"];
                valueId = definitionByTypeAndValueValues["valueId"];
            } else if (characteristicJson.definitionByCombination) {
                characteristicDefineType = "Define by combining characteristics";
            } else if (characteristicJson.definitionExpression) {
                characteristicDefineType = "Expression";
            }
            //Put other characteristic data loading into here
            if (characteristicJson.definitionExpression) {
                expressionLanguage = characteristicJson.definitionExpression.language || "";
                expressionExpression = characteristicJson.definitionExpression.expression || "";
                expressionDescription = characteristicJson.definitionExpression.description || "";
                expressionName = characteristicJson.definitionExpression.name || "";
                expressionUrl = characteristicJson.definitionExpression.reference || "";
            }

            if (characteristicJson.characteristic?.length > 0) {
                subCharacteristics = characteristicJson.characteristic;
            } else if (characteristicJson.definitionByCombination?.characteristic?.length > 0) {
                subCharacteristics = characteristicJson.definitionByCombination.characteristic;
            }
        }
    }
    if (characteristicJson === undefined && fhirEntryState.fhirEntryString) {
        let json = JSON.parse(fhirEntryState.fhirEntryString);
        if (json.characteristic?.length > 0) {
            subCharacteristics = json.characteristic;
        }
    }

    if (characteristicDefineType) {
        definitionDetailTypeInformation = true;
    }

    if (valueType) {
        valueTypeInformation = true;
    }

    if (update) { //Then update the characteristicValues
        characteristicValues = {
            loaded: true,
            linkId: linkId,
            definitionDetailTypeInformation: definitionDetailTypeInformation,
            exclude: exclude,
            includeExcludeRadioValue: includeExcludeRadioValue,
            characteristicDefineType: characteristicDefineType,
            valueDefineType: valueDefineType,
            definitionCanonical: definitionCanonical,
            definitionId: definitionId,
            definitionReference: definitionReference,
            simpleText: simpleText,
            expressionLanguage: expressionLanguage,
            expressionExpression: expressionExpression,
            expressionDescription: expressionDescription,
            expressionName: expressionName,
            expressionUrl: expressionUrl,
            definitionCodeableConcept: definitionCodeableConcept,
            valueTypeInformation: valueTypeInformation,
            valueText: valueText,
            valueType: valueType,
            typeCodeableCodingJson: typeCodeableCodingJson,
            valueCodeableCoding: valueCodeableCoding,
            valueValueText: valueValueText,
            valueComparator: valueComparator,
            valueValue: valueValue,
            valueUnit: valueUnit,
            valueLow: valueLow,
            valueLowUnit: valueLowUnit,
            valueHigh: valueHigh,
            valueHighUnit: valueHighUnit,
            valueBooleanRadioValue: valueBooleanRadioValue,
            valueReference: valueReference,
            valueId: valueId,
            combinationType: combinationType,
            combinationThreshold: combinationThreshold,
            description: description,
            note: note,
            method: method,
            device: device,
            offset: offset,
            subCharacteristics: subCharacteristics,
            pathIndexes: fhirEntryState.conceptPathIndexes,
            termChanged: false
        };
    }

    return characteristicValues;
}

const evidenceVariableTextViewChangesToJson = (fhirJson, evidenceVariableState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = evidenceVariableState.meta;
    fhirJson.language = evidenceVariableState.language;
    fhirJson.text = evidenceVariableState.text;
    fhirJson.contained = evidenceVariableState.contained || "DELETEME";
    fhirJson.extension = evidenceVariableState.extension || "DELETEME";
    fhirJson.modifierExtension = evidenceVariableState.modifierExtension || "DELETEME";
    fhirJson.url = evidenceVariableState.url || "DELETEME";
    fhirJson.identifier = evidenceVariableState.identifier || "DELETEME";
    fhirJson.version = evidenceVariableState.version || "DELETEME";
    fhirJson.versionAlgorithmString = evidenceVariableState.versionAlgorithmString || "DELETEME";
    fhirJson.versionAlgorithmCoding = evidenceVariableState.versionAlgorithmCoding || "DELETEME";
    fhirJson.name = evidenceVariableState.name || "DELETEME";
    fhirJson.title = evidenceVariableState.title || "DELETEME";
    fhirJson.status = evidenceVariableState.status || "unknown";
    fhirJson.experimental = evidenceVariableState.experimental || "DELETEME";
    fhirJson.date = evidenceVariableState.date || "DELETEME";
    fhirJson.publisher = evidenceVariableState.publisher || "DELETEME";
    fhirJson.contact = evidenceVariableState.contact || "DELETEME";
    fhirJson.description = evidenceVariableState.description || "DELETEME";
    fhirJson.useContext = evidenceVariableState.useContext || "DELETEME";
    fhirJson.jurisdiction = evidenceVariableState.jurisdiction || "DELETEME";
    fhirJson.purpose = evidenceVariableState.purpose || "DELETEME";
    fhirJson.usage = evidenceVariableState.usage || "DELETEME";
    fhirJson.copyright = evidenceVariableState.copyright || "DELETEME";
    fhirJson.copyrightLabel = evidenceVariableState.copyrightLabel || "DELETEME";
    fhirJson.approvalDate = evidenceVariableState.approvalDate || "DELETEME";
    fhirJson.lastReviewDate = evidenceVariableState.lastReviewDate || "DELETEME";
    fhirJson.effectivePeriod = evidenceVariableState.effectivePeriod || "DELETEME";
    fhirJson.topic = evidenceVariableState.topic || "DELETEME";
    fhirJson.author = evidenceVariableState.author || "DELETEME";
    fhirJson.editor = evidenceVariableState.editor || "DELETEME";
    fhirJson.reviewer = evidenceVariableState.reviewer || "DELETEME"
    fhirJson.endorser = evidenceVariableState.endorser || "DELETEME";
    fhirJson.relatedArtifact = evidenceVariableState.relatedArtifact || "DELETEME";
    fhirJson.shortTitle = evidenceVariableState.shortTitle || "DELETEME";
    fhirJson.actual = "DELETEME";
    if (typeof evidenceVariableState.actual === "boolean") {
        fhirJson.actual = evidenceVariableState.actual;
    }
    fhirJson.definition = evidenceVariableState.definition || "DELETEME";
    fhirJson.characteristicDefinition = evidenceVariableState.characteristicDefinition || "DELETEME";
    fhirJson.characteristic = evidenceVariableState.characteristic || "DELETEME";
    fhirJson.handling = evidenceVariableState.handling || "DELETEME";
    fhirJson.category = evidenceVariableState.category || "DELETEME";

    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const characteristicDetailViewChangesToJson = (characteristicDetailState) => {
    let definitionExpression = {};
    if (characteristicDetailState.expressionDescription) {
        definitionExpression.description = characteristicDetailState.expressionDescription;
    }
    if (characteristicDetailState.expressionName) {
        definitionExpression.name = characteristicDetailState.expressionName;
    }
    if (characteristicDetailState.expressionLanguage) {
        definitionExpression.language = characteristicDetailState.expressionLanguage;
    }
    if (characteristicDetailState.expressionExpression) {
        definitionExpression.expression = characteristicDetailState.expressionExpression;
    }
    if (characteristicDetailState.expressionUrl) {
        definitionExpression.reference = characteristicDetailState.expressionUrl;
    }
    let definitionByTypeAndValue = {};
    if (characteristicDetailState.typeCodeableCodingJson &&
        (characteristicDetailState.typeCodeableCodingJson.system ||
            characteristicDetailState.typeCodeableCodingJson.code ||
            characteristicDetailState.typeCodeableCodingJson.display) &&
        characteristicDetailState.valueText) {
        definitionByTypeAndValue.type = {
            "coding": [characteristicDetailState.typeCodeableCodingJson],
            "text": characteristicDetailState.valueText
        };
    } else if (characteristicDetailState.typeCodeableCodingJson &&
        (characteristicDetailState.typeCodeableCodingJson.system ||
            characteristicDetailState.typeCodeableCodingJson.code ||
            characteristicDetailState.typeCodeableCodingJson.display)) {
        definitionByTypeAndValue.type = {
            "coding": [characteristicDetailState.typeCodeableCodingJson]
        };
    } else if (characteristicDetailState.valueText) {
        definitionByTypeAndValue.type = {
            "text": characteristicDetailState.valueText
        };
    }
    if (characteristicDetailState.method) {
        definitionByTypeAndValue.method = characteristicDetailState.method;
    }
    if (characteristicDetailState.device) {
        definitionByTypeAndValue.device = characteristicDetailState.device;
    }
    if (characteristicDetailState.valueCodeableCoding &&
        (characteristicDetailState.valueCodeableCoding.system ||
            characteristicDetailState.valueCodeableCoding.code ||
            characteristicDetailState.valueCodeableCoding.display) &&
        characteristicDetailState.valueValueText) {
        definitionByTypeAndValue.valueCodeableConcept = {
            "coding": [characteristicDetailState.valueCodeableCoding],
            "text": characteristicDetailState.valueValueText
        };
    } else if (characteristicDetailState.valueCodeableCoding &&
        (characteristicDetailState.valueCodeableCoding.system ||
            characteristicDetailState.valueCodeableCoding.code ||
            characteristicDetailState.valueCodeableCoding.display)) {
        definitionByTypeAndValue.valueCodeableConcept = {
            "coding": [characteristicDetailState.valueCodeableCoding]
        };
    } else if (characteristicDetailState.valueValueText) {
        definitionByTypeAndValue.valueCodeableConcept = {
            "text": characteristicDetailState.valueValueText
        };
    }
    if (characteristicDetailState.valueBooleanRadioValue !== undefined && characteristicDetailState.valueBooleanRadioValue !== null &&
        characteristicDetailState.valueBooleanRadioValue !== "") {
        definitionByTypeAndValue.valueBoolean = characteristicDetailState.valueBooleanRadioValue;
    }
    if (characteristicDetailState.valueComparator || characteristicDetailState.valueValue ||
        characteristicDetailState.valueUnit) {
        definitionByTypeAndValue.valueQuantity = {};
        if (characteristicDetailState.valueComparator) {
            definitionByTypeAndValue.valueQuantity.comparator = characteristicDetailState.valueComparator;
        }
        if (characteristicDetailState.valueValue) {
            definitionByTypeAndValue.valueQuantity.value = characteristicDetailState.valueValue;
        }
        if (characteristicDetailState.valueUnit) {
            definitionByTypeAndValue.valueQuantity.unit = characteristicDetailState.valueUnit;
        }
    }
    if (characteristicDetailState.valueLow || characteristicDetailState.valueLowUnit ||
        characteristicDetailState.valueHigh || characteristicDetailState.valueHighUnit) {
        definitionByTypeAndValue.valueRange = {};
        if (characteristicDetailState.valueLow || characteristicDetailState.valueLowUnit) {
            definitionByTypeAndValue.valueRange.low = {};
            if (characteristicDetailState.valueLow) {
                definitionByTypeAndValue.valueRange.low.value = characteristicDetailState.valueLow;
            }
            if (characteristicDetailState.valueLowUnit) {
                definitionByTypeAndValue.valueRange.low.unit = characteristicDetailState.valueLowUnit;
            }
        }
        if (characteristicDetailState.valueHigh || characteristicDetailState.valueHighUnit) {
            definitionByTypeAndValue.valueRange.high = {};
            if (characteristicDetailState.valueHigh) {
                definitionByTypeAndValue.valueRange.high.value = characteristicDetailState.valueHigh;
            }
            if (characteristicDetailState.valueHighUnit) {
                definitionByTypeAndValue.valueRange.high.unit = characteristicDetailState.valueHighUnit;
            }
        }
    }
    if (characteristicDetailState.valueReference) {
        definitionByTypeAndValue.valueReference = characteristicDetailState.valueReference;
    }
    if (characteristicDetailState.valueId) {
        definitionByTypeAndValue.valueId = characteristicDetailState.valueId;
    }
    if (characteristicDetailState.offset) {
        definitionByTypeAndValue.offset = characteristicDetailState.offset;
    }
    let definitionByCombination = {};
    if (characteristicDetailState.combinationType) {
        definitionByCombination.code = characteristicDetailState.combinationType;
    }
    if (characteristicDetailState.combinationThreshold) {
        definitionByCombination.threshold = characteristicDetailState.combinationThreshold;
    }
    if (characteristicDetailState.subCharacteristics) {
        definitionByCombination.characteristic = characteristicDetailState.subCharacteristics;
    }
    let characteristicDetailJson = {};
    characteristicDetailJson.extension = characteristicDetailState.extension || "DELETEME";
    characteristicDetailJson.linkId = characteristicDetailState.linkId || "DELETEME";
    characteristicDetailJson.description = characteristicDetailState.description || "DELETEME";
    characteristicDetailJson.note = characteristicDetailState.note || "DELETEME";
    characteristicDetailJson.exclude = characteristicDetailState.exclude ?? "DELETEME";
    if (characteristicDetailState.characteristicDefineType === "Direct Link") {
        characteristicDetailJson.definitionReference = characteristicDetailState.definitionReference || "DELETEME";
        characteristicDetailJson.definitionCanonical = characteristicDetailState.definitionCanonical || "DELETEME";
        characteristicDetailJson.definitionId = characteristicDetailState.definitionId || "DELETEME";
    }
    if (characteristicDetailState.characteristicDefineType === "Define by code" ||
        characteristicDetailState.characteristicDefineType === "Simple text") {
        characteristicDetailJson.definitionCodeableConcept = characteristicDetailState.definitionCodeableConcept || "DELETEME";
    }
    if (characteristicDetailState.characteristicDefineType === "Define by type and value") {
        characteristicDetailJson.definitionByTypeAndValue = definitionByTypeAndValue || "DELETEME";
    }
    if (characteristicDetailState.characteristicDefineType === "Define by combining characteristics") {
        characteristicDetailJson.definitionByCombination = definitionByCombination || "DELETEME";
    }
    characteristicDetailJson.definitionExpression = definitionExpression || "DELETEME";
    characteristicDetailJson.instancesQuantity = characteristicDetailState.instancesQuantity || "DELETEME";
    characteristicDetailJson.instancesRange = characteristicDetailState.instancesRange || "DELETEME";
    characteristicDetailJson.durationQuantity = characteristicDetailState.durationQuantity || "DELETEME";
    characteristicDetailJson.durationRange = characteristicDetailState.durationRange || "DELETEME";
    characteristicDetailJson.timeFromEvent = characteristicDetailState.timeFromEvent || "DELETEME";
    for (const key in characteristicDetailJson) {
        if (characteristicDetailJson[key] === "DELETEME" ||
            (typeof characteristicDetailJson[key] === "object" && Object.keys(characteristicDetailJson[key]).length === 0) ||
            characteristicDetailJson[key] === "" || characteristicDetailJson[key] === undefined || characteristicDetailJson[key] === null) {
            delete characteristicDetailJson[key];
        }
    }
    return characteristicDetailJson;
}
/*
const createCharacteristicIndex = (characteristic, characteristicIndex) => {
    if (!characteristicIndex) {
        characteristicIndex = {};
    }
    for (const item of characteristic) {
        if (item.linkId) {
            if (!(item.linkId in characteristicIndex)) {
                characteristicIndex[item.linkId] = item;
            }
            if (Array.isArray(item.definitionByCombination.characteristic)) {
                createCharacteristicIndex(item.definitionByCombination.characteristic, characteristicIndex);
            }
        }
    }
    return characteristicIndex;
}
*/
const createNewClassificationJson = (evidenceVariableState) => {
    let contentArray = evidenceVariableState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "Evidence";
    let classifiedFoi = evidenceVariableState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = evidenceVariableState.title || evidenceVariableState.name || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta": {
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateEvidenceVariableCharacteristicDetailJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let characteristicDetailState = formInputsStateRef.current.characteristicState;
    let newCharacteristicDetailJson = characteristicDetailViewChangesToJson(characteristicDetailState);
    let fhirJson = jsonErrorCheck2("EvidenceVariable", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (!newCharacteristicDetailJson.linkId) {
        alert('Re-index Characteristics on Text View of EvidenceVariable Builder first.')
    }
    let newDetailLinkId = newCharacteristicDetailJson.linkId.replace("item", "");
    let newDetailLinkIdSplit = newDetailLinkId.split('..');
    //TO DO, make this infinitely recursive
    switch (newDetailLinkIdSplit.length) {
        case 1:
            fhirJson.characteristic[parseInt(newDetailLinkId)] = newCharacteristicDetailJson;
            break;
        case 2:
            fhirJson.characteristic[parseInt(newDetailLinkIdSplit[0])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[1])] = newCharacteristicDetailJson;
            break;
        case 3:
            fhirJson.characteristic[parseInt(newDetailLinkIdSplit[0])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[1])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[2])] = newCharacteristicDetailJson;
            break;
        case 4:
            fhirJson.characteristic[parseInt(newDetailLinkIdSplit[0])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[1])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[2])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[3])] = newCharacteristicDetailJson;
            break;
        case 5:
            fhirJson.characteristic[parseInt(newDetailLinkIdSplit[0])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[1])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[2])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[3])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[4])] = newCharacteristicDetailJson;
            break;
        case 6:
            fhirJson.characteristic[parseInt(newDetailLinkIdSplit[0])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[1])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[2])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[3])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[4])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[5])] = newCharacteristicDetailJson;
            break;
        case 7:
            fhirJson.characteristic[parseInt(newDetailLinkIdSplit[0])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[1])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[2])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[3])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[4])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[5])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[6])] = newCharacteristicDetailJson;
            break;
        case 8:
            fhirJson.characteristic[parseInt(newDetailLinkIdSplit[0])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[1])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[2])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[3])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[4])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[5])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[6])].definitionByCombination
                .characteristic[parseInt(newDetailLinkIdSplit[7])] = newCharacteristicDetailJson;
            break;
        default:
            alert('in too deep')
    }
    fhirJson = jsonErrorCheck2("EvidenceVariable", resourceId, fhirEntryState.startingVersionId, fhirJson);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    return fhirJson;
}

const builderUpdateEvidenceVariableJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let evidenceVariableState = formInputsStateRef.current.evidenceVariableState;

    let fhirJson = jsonErrorCheck2("EvidenceVariable", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = evidenceVariableTextViewChangesToJson(fhirJson, evidenceVariableState);
        fhirJson = jsonErrorCheck2("EvidenceVariable", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(evidenceVariableState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateEvidenceVariableJson, builderUpdateEvidenceVariableCharacteristicDetailJson, getCharacteristicValuesFromFhirJson };