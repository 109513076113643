import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Segment } from 'semantic-ui-react';
import scrollTo from './ScrollTo';

const bulletPoints = ['◉', '⦾', '■', '○', '♦', '◘', '•'];

let separatorCharacter = ": ";

const getPropertyCodes = (json) => {
    let propertyCodes = [];
    let propertyDescriptions = {};
    for (let propertyIndex in json.property) {
        let property = json.property[propertyIndex];
        let propertyCode = property.code;
        if (propertyCode && !propertyCodes.includes(propertyCode)) {
            propertyCodes.push(propertyCode);
            propertyDescriptions[propertyCode] = property.description;
        }
    }
    return { propertyCodes: propertyCodes, propertyDescriptions: propertyDescriptions }
}

const navigateToAnotherSection = (scrollName, history, fhirEntryState, setFhirEntryState, resourceType, resourceId, json, section, display, path, pathIndexes, pathString) => {
    if (fhirEntryState.sectionDelete) {
        alert("Please click the 'Update' button before navigating away. Or refresh browser to discard changes.")
    } else {
        //history.push(`/resources/${resourceType}/${resourceId}#${section.code},${pathIndexes.toString()}`);    //INCLUDES PATH SO IT CAN HANDLE DUPLICATE CODES, COMMENTED OUT FOR NOW
        let navigateAway = true;
        if (fhirEntryState.sectionChanged || fhirEntryState.newChildSections?.length > 0) {
            if ((fhirEntryState.sectionCode && fhirEntryState.sectionName) || (fhirEntryState.sectionPath === undefined || fhirEntryState.sectionPath?.length === 0)) {
                let alertMessage = "You made changes to the section, would you like to save your changes?";
                if (fhirEntryState.sectionPath === undefined || fhirEntryState.sectionPath?.length === 0) {
                    if (fhirEntryState.newChildSections.length === 1) {
                        alertMessage = "You added a section, would you like to save your changes?";
                    } else if (fhirEntryState.newChildSections.length > 1) {
                        alertMessage = "You added sections, would you like to save your changes?";
                    }
                }
                if (window.confirm(alertMessage)) {
                    //fhirJson = builderUpdateCodeSystemJson(resourceId, fhirEntryState, formInputsStateRef);
                    navigateAway = false;
                    setFhirEntryState(prevState => { return { ...prevState, submittingToServer: true, sectionToNavigateTo: section.code } });
                } else {
                    navigateAway = false;
                }
            } else {
                navigateAway = false;
                alert("Section is missing ... something?");
            }
        }
        if (navigateAway) {
            let scrollToId = scrollName.toLowerCase() + "detail-navigation-path-" + pathIndexes.toString();
            history.push(`/resources/${resourceType}/${resourceId}#${pathIndexes.toString()}`);

            let propertyProperties = getPropertyCodes(json);
            let propertyCodes = propertyProperties["propertyCodes"];
            let propertyDescriptions = propertyProperties["propertyDescriptions"];

            let classifierCodes = [];
            let notes = [];

            setFhirEntryState(prevState => { 
                return { ...prevState, sectionName: display, sectionPath: path, sectionPathIndexes: pathIndexes, 
                    sectionPathString: pathString, sectionSelected: section, sectionDefinition: section.definition, 
                    sectionSummary: section.summary, sectionCode: section.code, classifierCodes: classifierCodes, 
                    notes: notes, propertyCodes: propertyCodes, propertyDescriptions: propertyDescriptions, 
                    editMode: false,
                    sectionChanged: false, newChildSections: [], sectionCommentsExpanded: false, comments: [], votes: [], myvotes: [] }; });

            scrollTo("navigation" + scrollName.toLowerCase() + "DetailSegment", scrollToId, true);
            scrollTo("resourceTextViewSegment", "", true);
        }
    };
}

const NavigationCompositionSectionSegment = ({ resourceId, fhirEntryState, setFhirEntryState, formInputsStateRef }) => {

    useEffect(async () => {
        if (fhirEntryState.initialScrolled !== true && fhirEntryState.editMode !== true) {
            let sectionPathIndexesString = fhirEntryState.sectionPathIndexes?.toString() || "";
            let scrollToId = "sectiondetail-navigation-path-" + sectionPathIndexesString;
            if (fhirEntryState.sectionPathIndexes?.length === 1 && parseInt(fhirEntryState.sectionPathIndexes[0]) === 0) {
                scrollToId = ""; //So it doesn't scroll for the top-most element
            }
            scrollTo("navigationSectionDetailSegment", scrollToId, true);
            setFhirEntryState(prevState => { return { ...prevState, initialScrolled: true } })
        }
    }, [fhirEntryState]);


    const history = useHistory();

    let json;
    if (fhirEntryState.fhirEntryString) {
        json = JSON.parse(fhirEntryState.fhirEntryString);
    }
    const generatePathsLookup = (parentJson, parentPathString, dictionary) => {
        if (parentJson) {
            let separator = "";
            if (parentPathString) {
                separator = separatorCharacter;
            }

            for (let sectionIndex in parentJson.section) {
                let section = parentJson.section[sectionIndex];
                if (section?.title) {
                    let pathString = parentPathString + separator + section.title;
                    if (section.section) {
                        dictionary[pathString] = { expanded: true };
                        generatePathsLookup(section, pathString, dictionary);
                    }
                }
            }
        }
        return dictionary
    }

    const [navigationCollapsedState, setNavigationCollapsedState] = useState(generatePathsLookup(json, "", {}));
    const [collapseAllState, setCollapseAllState] = useState(false);

    const NavigationSections = ({ parentJson, level, parentPathString, parentPath, parentPathIndexes, setFhirEntryState, formInputsStateRef }) => {
        let hasApprovalProperty = fhirEntryState.hasApprovalProperty;
        let style = { paddingLeft: (level * 10).toString() + "px" };
        let separator = "";
        if (parentPathString) {
            separator = separatorCharacter;
        }
        return <>
            {parentJson?.section?.map((section, sectionIndex) => {
                let title;
                if (section.title) {
                    title = section.title;
                } else if (section.code) {
                    if (section.code.text) {
                        title = section.code.text;
                    } else {
                        for (let coding of section.code.coding) {
                            title = coding.display || coding.code;
                            if (title) {
                                break;
                            }
                        }
                    }
                }
                if (!title) {
                    title = "[MISSING TITLE OR CODE]";
                }
                let pathString = parentPathString + separator + title;
                let path = [...parentPath, title];
                let pathIndexes = [...parentPathIndexes, sectionIndex];

                let linkStyle = {};
                let linkOnClick;
                if (section && fhirEntryState.sectionPathIndexes?.toString() === pathIndexes.toString()) { // || (fhirEntryState.sectionChanged && fhirEntryState.sectionName === section.title)
                    linkStyle = { cursor: "default", color: "#000000", fontWeight: "bold" };
                } else {
                    linkOnClick = () => {
                        navigateToAnotherSection("Section", history, fhirEntryState, setFhirEntryState, 'Composition', resourceId, json, section, title, path, pathIndexes, pathString);
                    }
                }
                return <span key={sectionIndex} style={style}
                    id={section && "sectiondetail-navigation-path-" + pathIndexes.toString()}>
                    {bulletPoints[level % bulletPoints.length]}
                    &nbsp;<a style={linkStyle} onClick={linkOnClick}>{title}</a>
                    {(navigationCollapsedState[pathString] && navigationCollapsedState[pathString]["expanded"] !== undefined) &&
                        <span className={"unselectable"} style={{ cursor: "pointer" }} onClick={() => { setNavigationCollapsedState(prevState => { let prevObj = prevState[pathString]; prevObj["expanded"] = !prevObj["expanded"]; return { ...prevState, [pathString]: prevObj }; }) }}>&nbsp;{navigationCollapsedState[pathString]["expanded"] ? <>▼</> : <>►</>}</span>
                    }
                    <br />
                    {(section && navigationCollapsedState[pathString] && (navigationCollapsedState[pathString]["expanded"] === undefined || navigationCollapsedState[pathString]["expanded"] === true)) &&
                        <NavigationSections parentJson={section} level={level + 1} parentPathString={pathString} setFhirEntryState={setFhirEntryState} parentPath={path} parentPathIndexes={pathIndexes} formInputsStateRef={formInputsStateRef} />
                    }
                </span>

            })}
        </>
    }

    const CollapseAllButton = ({ }) => {
        return <>{collapseAllState === false && <span
            className={"unselectable"}
            style={{ cursor: "pointer" }}
            onClick={() => {
                setNavigationCollapsedState(prevState => {
                    for (let key in prevState) {
                        prevState[key]["expanded"] = false;
                    }
                    return prevState;
                });
                setCollapseAllState(true);
            }}
        >
            ➖
        </span>}</>
    }

    return <Segment id={`navigationSectionDetailSegment`} className={`containerSegment maxNavigationCodeSystemSegment`} style={{ marginTop: "8px", padding: "2px 4px 6px 4px" }} raised>
        <h2 className={"unselectable"} style={{ fontSize: "22px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }}>Section Navigation</h2>
        <div className={`navigationCodeSystemSectionDetailSection`} style={{ paddingTop: "4px" }}>
            <span>
                {fhirEntryState.sectionPath?.length > 0 ?
                    <span onClick={() => {
                        /*
                        setFhirEntryState(prevState  => {
                            return { ...prevState, sectionName: "", sectionCode: "", sectionDefinition: undefined, sectionPath: undefined, sectionPathIndexes: undefined, sectionPathString: undefined, sectionSelected: undefined, newChildSections: [], sectionCommentsExpanded: false, comments: [], votes: [], myvotes: [] } }
                        );
                        */
                        navigateToAnotherSection("Section", history, fhirEntryState, setFhirEntryState, 'Composition', resourceId, json, {}, "", [], [], []);
                    }}>
                        <a><CollapseAllButton /> TOP</a>
                    </span>
                    :
                    <b><CollapseAllButton /> TOP</b>
                }
                <br />

            </span>
            {json && <NavigationSections parentJson={json} level={0} parentPathString={""} parentPath={[]} parentPathIndexes={[]} setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} />}
        </div>
    </Segment>
}
export default NavigationCompositionSectionSegment;