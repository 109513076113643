import React, { useState, useEffect } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { DataEntry } from './DataEntryFormFunctions';
import { Checkbox } from '@mui/material';
import { EvidenceTableEdit } from './CompositionDocumentBundleFunctions';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';

const RelatedGroupResourceEntry = ({ groupType, startingValue, setResourceState,
  resourceState, globalContext, setSourceJsonState }) => {
  let setProfile;
  if (groupType === "Total") {
    setProfile = "StudyGroup";
  } else if (groupType === "Intervention") {
    setProfile = "ExposureGroup";
  } else if (groupType === "Comparator") {
    setProfile = "ComparatorGroup";
  }
  let startingRelatedArtifact = {
    "type": "depends-on",
    "label": groupType + " Group",
    "resourceReference": { "display": "[Not yet identified.]" }
  };
  if (startingValue) {
    if (startingValue.type) { startingRelatedArtifact.type = startingValue.type; }
    if (startingValue.label) { startingRelatedArtifact.label = startingValue.label; }
    if (startingValue.resourceReference) { startingRelatedArtifact.resourceReference = startingValue.resourceReference; }
  }
  const [relatedArtifactState, setRelatedArtifactState] = useState(startingRelatedArtifact);

  useEffect(() => {
    if (startingValue && startingValue.label !== relatedArtifactState.label) {
      let startingRelatedArtifact = {
        "type": "depends-on",
        "label": groupType + " Group",
        "resourceReference": { "display": "[Not yet identified.]" }
      };
      if (startingValue) {
        if (startingValue.type) { startingRelatedArtifact.type = startingValue.type; }
        if (startingValue.label) { startingRelatedArtifact.label = startingValue.label; }
        if (startingValue.resourceReference) { startingRelatedArtifact.resourceReference = startingValue.resourceReference; }
      }
      setRelatedArtifactState(JSON.parse(JSON.stringify(startingRelatedArtifact)));
    }
  }, [startingValue]);

  useEffect(() => {
    if (resourceState.section) {
      setResourceState(prevState => {
        let prevRelatesTo = [];
        if (prevState.relatesTo) {
          prevRelatesTo = JSON.parse(JSON.stringify(prevState.relatesTo));
        }
        let newRelatedArtifact = JSON.parse(JSON.stringify(relatedArtifactState));
        let relatesToEntryFound = false;
        let newRelatesTo = prevRelatesTo.map(relatedItem => {
          if (relatedItem.type === "depends-on" && relatedItem.label === groupType + " Group") {
            relatesToEntryFound = true;
            return newRelatedArtifact;
          } else {
            return relatedItem;
          }
        })
        if (!relatesToEntryFound) {
          newRelatesTo.push(newRelatedArtifact);
        }
        let newState = { ...prevState, relatesTo: newRelatesTo };
        loadSourceJsonFunction(newState, globalContext, setSourceJsonState);
        return newState;
      });
    }
  }, [relatedArtifactState]);

  return <div>
    <h4>Create or identify the Group Resource for the {groupType} Group.</h4>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='Reference' elementName='resourceReference'
        fieldLabel={'Group Resource (for the ' + groupType + ' Group)'}
        startingValue={relatedArtifactState.resourceReference} referencedResourceTypes={['Group']}
        startingResourceType="Group" setProfile={setProfile}
        startCollapsed startEmptyArrayClosed enableCreation={true}
        fullResourceState={resourceState}
        setSourceJsonState={setSourceJsonState}
        setResourceState={setRelatedArtifactState} />
    </div>
    <br />
  </div>
}

const RelatesToEvidenceVariableResourceEntry = ({ element, startingValue, setResourceState, reportType,
  resourceState, setSourceJsonState }) => {

  const [relatedArtifactState, setRelatedArtifactState] = useState(null);
  const [startingRelatedArtifactState, setStartingRelatedArtifactState] = useState(null);

  useEffect(() => {
    let startingRelatedArtifact = {
      "type": "depends-on",
      "label": reportType,
      "display": "",
      "resourceReference": { "display": "[Not yet identified.]" }
    };
    if (startingValue?.type) {
      startingRelatedArtifact.type = startingValue.type;
    }
    if (startingValue?.label) {
      startingRelatedArtifact.label = startingValue.label;
    }
    if (startingValue?.display) {
      startingRelatedArtifact.display = startingValue.display;
    }
    if (startingValue?.resourceReference) {
      startingRelatedArtifact.resourceReference = startingValue.resourceReference;
    }
    setRelatedArtifactState(startingRelatedArtifact);
    setStartingRelatedArtifactState(startingRelatedArtifact);
  }, [])

  useEffect(() => {
    if (relatedArtifactState && startingRelatedArtifactState &&
      JSON.stringify(relatedArtifactState) !== JSON.stringify(startingRelatedArtifactState)) {
      let newRelatedArtifact = JSON.parse(JSON.stringify(relatedArtifactState));
      setResourceState(prevState => {
        return {
          ...prevState,
          [element]: newRelatedArtifact,
        }
      });
    }
  }, [relatedArtifactState]);

  return <>{relatedArtifactState && <>
    <Table.Cell>
      <DataEntry datatype='string' elementName='display' fieldLabel='Enter Variable Name'
        startingValue={relatedArtifactState.display} setResourceState={setRelatedArtifactState} />
    </Table.Cell>
    <Table.Cell>
      <DataEntry datatype='Reference' elementName='resourceReference'
        fieldLabel={'EvidenceVariable Resource (for the ' + reportType + " " + relatedArtifactState.display + ')'}
        startingValue={relatedArtifactState.resourceReference} referencedResourceTypes={['EvidenceVariable']}
        startingResourceType="EvidenceVariable"
        setProfile={reportType === "Participant Flow" ? "ParticipantFlowEvidenceVariable" : "VariableDefinition"}
        startCollapsed startEmptyArrayClosed enableCreation={true}
        fullResourceState={resourceState}
        setSourceJsonState={setSourceJsonState}
        setResourceState={setRelatedArtifactState} />
    </Table.Cell>
  </>}
  </>
}

const CompositionMeasuresTableEntry = ({ relatesTo, setResourceState, reportType, resourceState,
  setSourceJsonState }) => {

  const [relevantMeasureRelatesToArrayState, setRelevantMeasureRelatesToArrayState] = useState(null);
  const [startingRelevantMeasureRelatesToArrayState, setStartingRelevantMeasureRelatesToArrayState] = useState(null);
  const [nonRelevantMeasureRelatesToArrayState, setNonRelevantMeasureRelatesToArrayState] = useState(null);

  useEffect(() => {
    let nonRelevantMeasureRelatesTo = [];
    let relevantMeasureRelatesTo = [];
    let startingRelevantMeasuresArrayAsObject = {};
    if (relatesTo) {
      for (let item of relatesTo) {
        if (item.type === "depends-on" && item.label === reportType) {
          relevantMeasureRelatesTo.push(item);
        } else {
          nonRelevantMeasureRelatesTo.push(item);
        }
      }
    }
    for (let itemIndex in relevantMeasureRelatesTo) {
      startingRelevantMeasuresArrayAsObject[itemIndex] = relevantMeasureRelatesTo[itemIndex];
    }
    setRelevantMeasureRelatesToArrayState(startingRelevantMeasuresArrayAsObject);
    setStartingRelevantMeasureRelatesToArrayState(startingRelevantMeasuresArrayAsObject);
    setNonRelevantMeasureRelatesToArrayState(nonRelevantMeasureRelatesTo);
  }, [])

  useEffect((() => {
    if (startingRelevantMeasureRelatesToArrayState && relevantMeasureRelatesToArrayState &&
      JSON.stringify(startingRelevantMeasureRelatesToArrayState) !== JSON.stringify(relevantMeasureRelatesToArrayState) &&
      Object.keys(relevantMeasureRelatesToArrayState).length > 0) {
      let newArray = [];
      for (let key of Object.keys(relevantMeasureRelatesToArrayState)) {
        if (relevantMeasureRelatesToArrayState[key] !== null &&
          !(typeof relevantMeasureRelatesToArrayState[key] === "object" && Object.keys(relevantMeasureRelatesToArrayState[key]).length === 0) &&
          !(Array.isArray(relevantMeasureRelatesToArrayState[key]) && relevantMeasureRelatesToArrayState[key].length === 0) &&
          relevantMeasureRelatesToArrayState[key] !== "" &&
          relevantMeasureRelatesToArrayState[key] !== undefined && relevantMeasureRelatesToArrayState[key] !== "DELETEME") {
          newArray.push(relevantMeasureRelatesToArrayState[key]);
        }
      }
      if (newArray.length > 0) {
        let newRelatesTo = nonRelevantMeasureRelatesToArrayState.concat(newArray);

        setResourceState(prevState => { return { ...prevState, "relatesTo": newRelatesTo } });
      }
    }
  }), [relevantMeasureRelatesToArrayState]);

  return <>{relevantMeasureRelatesToArrayState && <div>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable Name</Table.HeaderCell>
          <Table.HeaderCell>EvidenceVariable Resource</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(relevantMeasureRelatesToArrayState).map((row, rowIndex) => {
          return <Table.Row key={rowIndex}>
            <RelatesToEvidenceVariableResourceEntry element={row} reportType={reportType}
              startingValue={relevantMeasureRelatesToArrayState[row]}
              resourceState={resourceState}
              setSourceJsonState={setSourceJsonState}
              setResourceState={setRelevantMeasureRelatesToArrayState} />
          </Table.Row>
        })}
      </Table.Body>
    </Table>
    <div style={{ marginBottom: "12px" }}>
      {<>
        <Button className="formButton" style={{ color: "#000000" }}
          content={reportType === "Participant Flow" ? "+ Add Participant Flow Measure" : "+ Add " + reportType}
          onClick={() => {
            setRelevantMeasureRelatesToArrayState(prevState => {
              let arrayLength = Object.keys(prevState).length;
              let arrayIndexNumber = arrayLength.toString();
              if (prevState['0'] === null) {
                arrayIndexNumber = '1';
              }
              return {
                ...prevState,
                //['0']: prevState['0'] || "",
                [arrayIndexNumber]: ""
              };
            });
          }} />
      </>}
    </div>
  </div>}</>
}

const CompositionMeasureReportAuthor = ({ resourceState, setResourceState, globalContext, sourceJsonState, setSourceJsonState,
  profile }) => {

  let fhirJson = resourceState.resourceJson;
  let reportType;
  if (profile === "OutcomeMeasureReport") {
    reportType = "Outcome Measure"
  } else if (profile === "BaselineMeasureReport") {
    reportType = "Baseline Measure"
  } else if (profile === "ParticipantFlowReport") {
    reportType = "Participant Flow"
  }

  let startingAddComparativeEvidence = false;
  let totalGroupRelatedArtifact;
  let interventionGroupRelatedArtifact;
  let comparatorGroupRelatedArtifact;
  let groupAssignmentRelatedArtifact;
  let evidenceVariableRelatedArtifactArray = [];
  if (Array.isArray(fhirJson?.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "depends-on") {
        if (relatedArtifactEntry.label === "Intervention Group") {
          interventionGroupRelatedArtifact = relatedArtifactEntry;
        } else if (relatedArtifactEntry.label === "Comparator Group") {
          comparatorGroupRelatedArtifact = relatedArtifactEntry;
        } else if (relatedArtifactEntry.label === "Total Group") {
          totalGroupRelatedArtifact = relatedArtifactEntry;
        } else if (relatedArtifactEntry.label === reportType) {
          evidenceVariableRelatedArtifactArray.push(relatedArtifactEntry);
        }
      } else if (relatedArtifactEntry.type === "composed-of") {
        if (relatedArtifactEntry.classifier?.[0]?.coding?.[0]?.code === "GroupAssignment") {
          groupAssignmentRelatedArtifact = relatedArtifactEntry;
          startingAddComparativeEvidence = true;
        }
      }
    }
  }

  let defaultType = {
    coding: [{
      system: "https://fevir.net/resources/CodeSystem/179423",
      code: "OutcomeMeasureReport",
      display: "OutcomeMeasureReport"
    }],
    text: 'Outcome Measure Report'
  };
  if (reportType === "Baseline Measure") {
    defaultType = {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "BaselineMeasureReport",
        display: "BaselineMeasureReport"
      }],
      text: 'Baseline Measure Report'
    };
  } else if (reportType === "Participant Flow") {
    defaultType = {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "ParticipantFlowReport",
        display: "ParticipantFlowReport"
      }],
      text: 'Participant Flow Report'
    };
  }

  const [addComparativeEvidenceState, setAddComparativeEvidenceState] = useState(startingAddComparativeEvidence);

  useEffect(() => {
    if (resourceState && !resourceState.type) {
      setResourceState(prevState => {return { ...prevState, type: defaultType}; });
    }
  }, []);
  
  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  useEffect(() => {
    if (addComparativeEvidenceState) {
      let interventionDescription = {
        "display": sourceJsonState?.groupReferences?.interventionGroup?.display ||
          sourceJsonState?.groupReferences?.interventionGroup?.reference || "Untitled Intervention"
      };
      let comparatorDescription = {
        "display": sourceJsonState?.groupReferences?.comparatorGroup?.display ||
          sourceJsonState?.groupReferences?.comparatorGroup?.reference || "Untitled Comparator"
      };
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState,
        setResourceState, interventionDescription, comparatorDescription);
    }
  }, [addComparativeEvidenceState]);

  return <>{sourceJsonState.loaded ?
    <div style={{ marginTop: "12px" }}>
      <div>
        <div>
          <h3 id="groups">Groups</h3>
          {reportType === "Baseline Measure" && <div>
            <p>The Baseline Measure Report is used to report the findings for baseline measures in a research study, as commonly reported in "Table 1" with many research reports.
            </p>
            <ul>
              <li>To report baseline measures for a single group, create a Total Group.</li>
              <li>To report baseline measures for 2 groups (with or without comparing them), create an Intervention Group and a Comparator Group.</li>
              <li>You can also create a Total Group in addition to an Intervention Group and Comparator Group.</li>
            </ul>
          </div>}
          {reportType === "Participant Flow" && <div>
            <p>The Participant Flow Report is used to report the findings for participant flow measures in a research study, as commonly reported in "Figure 1" or the CONSORT flow diagram with many research reports.
            </p>
            <ul>
              <li>To report participant flow measures for a single group, create a Total Group.</li>
              <li>To report participant flow measures for 2 groups (with or without comparing them), create an Intervention Group and a Comparator Group.</li>
              <li>You can also create a Total Group in addition to an Intervention Group and Comparator Group.</li>
            </ul>
          </div>}
          {reportType === "Outcome Measure" && <div>
            <p>The Outcome Measure Report is used to report the findings for outcome measures in a research study.
            </p>
            <ul>
              <li>To report outcome measures for a single group, create a Total Group.</li>
              <li>To report outcome measures for 2 groups (with or without comparing them), create an Intervention Group and a Comparator Group.</li>
              <li>You can also create a Total Group in addition to an Intervention Group and Comparator Group.</li>
            </ul>
          </div>}
          <RelatedGroupResourceEntry groupType='Total'
            startingValue={totalGroupRelatedArtifact}
            setResourceState={setResourceState}
            resourceState={resourceState} globalContext={globalContext}
            setSourceJsonState={setSourceJsonState}
          />
          <RelatedGroupResourceEntry groupType='Intervention'
            startingValue={interventionGroupRelatedArtifact}
            setResourceState={setResourceState}
            resourceState={resourceState} globalContext={globalContext}
            setSourceJsonState={setSourceJsonState}
          />
          <RelatedGroupResourceEntry groupType='Comparator'
            startingValue={comparatorGroupRelatedArtifact}
            setResourceState={setResourceState}
            resourceState={resourceState} globalContext={globalContext}
            setSourceJsonState={setSourceJsonState}
          />
          <span><b>Check if adding comparative (intervention vs. comparator) evidence: </b>
            <Checkbox checked={addComparativeEvidenceState}
              onChange={(e) => {
                setAddComparativeEvidenceState(!addComparativeEvidenceState);
              }}
              color="primary"
              style={{ paddingLeft: "4px", paddingRight: "4px" }}
            /></span>
        </div>
        {reportType === "Baseline Measure" && <h3 id="baseline-measures">Baseline Measures</h3>}
        {reportType === "Participant Flow" && <h3 id="participant-flow-measures">Participant Flow Measures</h3>}
        {reportType === "Outcome Measure" && <h3 id="outcome-measures">Outcome Measures</h3>}
        <CompositionMeasuresTableEntry relatesTo={resourceState.relatesTo}
          resourceState={resourceState}
          setSourceJsonState={setSourceJsonState}
          setResourceState={setResourceState} reportType={reportType} />
        {resourceState.section ? <>
          <h3 id="evidence">Evidence</h3>
          <div style={{ marginLeft: "24px" }}>
            <EvidenceTableEdit resourceState={resourceState}
              resourceDictionary={sourceJsonState.resourceDictionary}
              reportType={reportType}
              setSourceJsonState={setSourceJsonState}
              setResourceState={setResourceState} globalContext={globalContext} />
          </div>
          <br />
        </>
          :
          <>Section element is missing in the JSON.</>
        }
      </div>
    </div>
    :
    <div><p>Loading ...</p></div>}</>
};

export default CompositionMeasureReportAuthor;