import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment, TextArea, } from 'semantic-ui-react';
import { RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { submitResource } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import FevirContext from './FevirContext';
import ProfilesByResourceType from './ProfilesByResourceType';

const NewResourceEntry = ({ newResourceState, setNewResourceState, resourceTypeList, titleOnly }) => {
  if (!resourceTypeList) {
    resourceTypeList = ['ActivityDefinition', 'ArtifactAssessment', 'Citation',
      'CodeSystem', 'Composition', 'Evidence', 'EvidenceVariable', 'Group', 'Library', 'List', 'Measure',
      'PlanDefinition', 'Project', 'ResearchStudy', 'ValueSet']
  }

  const [profileListState, setProfileListState] = useState([]);

  useEffect(() => {
    if (newResourceState.resourceType) {
      const resourceType = newResourceState.resourceType;
      const profileDictionary = ProfilesByResourceType[resourceType];
      if (profileDictionary) {
        const profileNames = profileDictionary?.profileNames;
        if (profileNames) {
          setProfileListState(profileNames);
        } else {
          setProfileListState([]);
        }
      }
    }
  }, [newResourceState.resourceType]);

  if (titleOnly) {
    let itemTypeDisplay = newResourceState.resourceType + " Resource";
    if (newResourceState.meta?.profile) {
      for (const profile of newResourceState.meta.profile) {
        const profileDictionary = ProfilesByResourceType[newResourceState.resourceType];
        for (const key in profileDictionary) {
          if (profileDictionary[key].url === profile) {
            itemTypeDisplay = key;
            break;
          }
        }
      }
    }
    return <div>
      <div style={{ marginLeft: "24px" }}>
        <p>Enter a title here and click Submit to create a new blank {itemTypeDisplay}.</p>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={newResourceState.title} setResourceState={setNewResourceState} />
      </div>
    </div>
  }

  return <div>
    <div style={{ marginLeft: "24px" }}>
      <p><b>Enter a title here, select a Resource type, optionally select a Profile, and click Submit to create a new blank Resource.</b></p>
      <p>Alternatively, you can paste and/or edit complete JSON below. Use of data entry fields above will replace the JSON.</p>
      <DataEntry datatype='string' elementName='title' fieldLabel='Title'
        startingValue={newResourceState.title} setResourceState={setNewResourceState} />
      <br /><br />
      <DataEntry datatype='code' elementName='resourceType' fieldLabel='Resource Type'
        allowedValues={resourceTypeList}
        startingValue={newResourceState.resourceType} setResourceState={setNewResourceState} />
      {newResourceState.resourceType && <div>
        {newResourceState.resourceType === 'ActivityDefinition' && <p>
          The ActivityDefinition Resource allows for the definition of some activity to be performed, independent of a particular patient, practitioner, or other performance context.
        </p>}
        {newResourceState.resourceType === 'ArtifactAssessment' && <div>
          <p>The ArtifactAssessment Resource provides one or more comments, classifiers or ratings about a Resource and supports attribution and rights management metadata for the added content.</p>
          <div style={{ marginLeft: "24px" }}>
            {Object.keys(ProfilesByResourceType.ArtifactAssessment).map((key, keyIndex) => {
              const description = ProfilesByResourceType.ArtifactAssessment[key].description;
              if (description) {
                return <p key={keyIndex} >{description}</p>
              } else {
                return <p key={keyIndex} >Select a Profile</p>
              }
            })}
          </div>
        </div>}
        {newResourceState.resourceType === 'Citation' && <div><p>
          The Citation Resource enables reference to any knowledge artifact for purposes of identification and attribution.
        </p>
        <div style={{ marginLeft: "24px" }}>
            {Object.keys(ProfilesByResourceType.Citation).map((key, keyIndex) => {
              const description = ProfilesByResourceType.Citation[key].description;
              if (description) {
                return <p key={keyIndex} >{description}</p>
              } else {
                return <p key={keyIndex} >Select a Profile</p>
              }
            })}
          </div>
        </div>}
        {newResourceState.resourceType === 'CodeSystem' && <p>
          The CodeSystem Resource is used to declare the existence of and describe a code system (structured terminology) or code system supplement and its key properties, and optionally define a part or all of its content.
        </p>}
        {newResourceState.resourceType === 'Composition' && <div>
          <p>
          The Composition Resource provides a package of content and defines the structure and narrative content necessary for a document.
        </p>
        <div style={{ marginLeft: "24px" }}>
            {Object.keys(ProfilesByResourceType.Composition).map((key, keyIndex) => {
              const description = ProfilesByResourceType.Composition[key].description;
              if (description) {
                return <p key={keyIndex} >{description}</p>
              } else {
                return <p key={keyIndex} >Select a Profile</p>
              }
            })}
          </div>
        </div>}
        {newResourceState.resourceType === 'Evidence' && <div>
          <p>
          The Evidence Resource provides a machine-interpretable expression of an evidence concept including the evidence variables (e.g., population, exposures/interventions, comparators, outcomes, measured variables, confounding variables), the statistics, and the certainty of this evidence.
        </p>
        <div style={{ marginLeft: "24px" }}>
            {Object.keys(ProfilesByResourceType.Evidence).map((key, keyIndex) => {
              const description = ProfilesByResourceType.Evidence[key].description;
              if (description) {
                return <p key={keyIndex} >{description}</p>
              } else {
                return <p key={keyIndex} >Select a Profile</p>
              }
            })}
          </div>
        </div>}
        {newResourceState.resourceType === 'EvidenceVariable' && <div>
          <p>The EvidenceVariable Resource describes an element that knowledge (Evidence) is about.</p>
          <div style={{ marginLeft: "24px" }}>
            {Object.keys(ProfilesByResourceType.EvidenceVariable).map((key, keyIndex) => {
              const description = ProfilesByResourceType.EvidenceVariable[key].description;
              if (description) {
                return <p key={keyIndex} >{description}</p>
              } else {
                return <p key={keyIndex} >Select a Profile</p>
              }
            })}
          </div>
        </div>}
        {newResourceState.resourceType === 'Group' && <div>
          <p>
          The Group Resource represents a defined collection of entities that may be discussed or acted upon collectively but which are not expected to act collectively, and are not formally or legally recognized; i.e. a collection of entities that isn't an Organization.
        </p>
        <div style={{ marginLeft: "24px" }}>
            {Object.keys(ProfilesByResourceType.Group).map((key, keyIndex) => {
              const description = ProfilesByResourceType.Group[key].description;
              if (description) {
                return <p key={keyIndex} >{description}</p>
              } else {
                return <p key={keyIndex} >Select a Profile</p>
              }
            })}
          </div>
        </div>}
        {newResourceState.resourceType === 'Library' && <div>
          <p>
            The Library Resource is a container for a collection of knowledge assets.
          </p>
          <div style={{ marginLeft: "24px" }}>
            {Object.keys(ProfilesByResourceType.Library).map((key, keyIndex) => {
              const description = ProfilesByResourceType.Library[key].description;
              if (description) {
                return <p key={keyIndex} >{description}</p>
              } else {
                return <p key={keyIndex} >Select a Profile</p>
              }
            })}
          </div>
        </div>}
        {newResourceState.resourceType === 'List' && <div>
          <p>
            The List Resource represents a curated collection of resources.
          </p>
          <div style={{ marginLeft: "24px" }}>
            {Object.keys(ProfilesByResourceType.List).map((key, keyIndex) => {
              const description = ProfilesByResourceType.List[key].description;
              if (description) {
                return <p key={keyIndex} >{description}</p>
              } else {
                return <p key={keyIndex} >Select a Profile</p>
              }
            })}
          </div>
        </div>}
        {newResourceState.resourceType === 'Measure' && <p>
          The Measure Resource allows for the definition of a quality measure or performance measure.
        </p>}
        {newResourceState.resourceType === 'PlanDefinition' && <div>
          <p>
          The PlanDefinition Resource allows for the definition of various types of plans as a sharable, consumable, and executable artifact. The resource is general enough to support the description of a broad range of clinical and non-clinical artifacts such as clinical decision support rules, order sets, protocols, and drug quality specifications.
        </p>
        <div style={{ marginLeft: "24px" }}>
            {Object.keys(ProfilesByResourceType.PlanDefinition).map((key, keyIndex) => {
              const description = ProfilesByResourceType.PlanDefinition[key].description;
              if (description) {
                return <p key={keyIndex} >{description}</p>
              } else {
                return <p key={keyIndex} >Select a Profile</p>
              }
            })}
          </div>
        </div>}
        {newResourceState.resourceType === 'Project' && <p>
          The Project Resource is not a FHIR Resource. The Project Resource is used to store the data for Projects on the FEvIR Platform.
        </p>}
        {newResourceState.resourceType === 'ResearchStudy' && <div>
          <p>
            The ResearchStudy Resource allows for the definition of a scientific study.
          </p>
        </div>}
        {newResourceState.resourceType === 'ValueSet' && <p>
          The ValueSet Resource instance specifies a set of codes (terms) drawn from one or more code systems (structured terminologies), intended for use in a particular context.
        </p>}
      </div>}
      {profileListState.length > 0 && <div style={{ marginLeft: "24px" }}>
        <br /><br />
        <p><b>Select a Profile</b></p>
        <RadioGroup row aria-label='profile-selection' name='profile-selection' >
          {profileListState.map((profile, profileIndex) => {
            return <FormControlLabel
              key={profileIndex} value={profile}
              control={<Radio color="primary"
                onClick={(e) => {
                  let meta;
                  let section;
                  let profileUrl = "";
                  if (e.target.value) {
                    const profileDictionary = ProfilesByResourceType[newResourceState.resourceType];
                    let profileName = e.target.value;
                    if (profileDictionary) {
                      for (const key in profileDictionary) {
                        if (key === profileName) {
                          profileUrl = profileDictionary[key].url;
                        }
                      }
                    }
                    if (profileUrl) {
                      meta = { "profile": [profileUrl] };
                      if (profileName === "Guideline") {
                        section = [
                          {
                            "title": "Introduction Section",
                            "code": {
                              coding: [{
                                system: "https://fevir.net/resources/CodeSystem/179423",
                                code: "introduction",
                                display: "Introduction"
                              }]
                            },
                            "emptyReason": {
                              "coding": [
                                {
                                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                  "code": "notstarted",
                                  "display": "Not Started"
                                }
                              ]
                            }
                          },
                          {
                            "title": "Recommendations Section",
                            "code": {
                              coding: [{
                                system: "https://fevir.net/resources/CodeSystem/179423",
                                code: "recommendations",
                                display: "Recommendations"
                              }]
                            },
                            "emptyReason": {
                              "coding": [
                                {
                                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                  "code": "notstarted",
                                  "display": "Not Started"
                                }
                              ]
                            }
                          },
                          {
                            "title": "Discussion Section",
                            "code": {
                              coding: [{
                                system: "https://fevir.net/resources/CodeSystem/179423",
                                code: "discussion",
                                display: "Discussion"
                              }]
                            },
                            "emptyReason": {
                              "coding": [
                                {
                                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                  "code": "notstarted",
                                  "display": "Not Started"
                                }
                              ]
                            }
                          },
                          {
                            "title": "Methods Section",
                            "code": {
                              coding: [{
                                system: "https://fevir.net/resources/CodeSystem/179423",
                                code: "methods",
                                display: "Methods"
                              }]
                            },
                            "emptyReason": {
                              "coding": [
                                {
                                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                  "code": "notstarted",
                                  "display": "Not Started"
                                }
                              ]
                            }
                          },
                          {
                            "title": "Acknowledgements Section",
                            "code": {
                              coding: [{
                                system: "https://fevir.net/resources/CodeSystem/179423",
                                code: "acknowledgements",
                                display: "Acknowledgements"
                              }]
                            },
                            "emptyReason": {
                              "coding": [
                                {
                                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                  "code": "notstarted",
                                  "display": "Not Started"
                                }
                              ]
                            }
                          },
                          {
                            "title": "Appendices Section",
                            "code": {
                              coding: [{
                                system: "https://fevir.net/resources/CodeSystem/179423",
                                code: "appendices",
                                display: "Appendices"
                              }]
                            },
                            "emptyReason": {
                              "coding": [
                                {
                                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                  "code": "notstarted",
                                  "display": "Not Started"
                                }
                              ]
                            }
                          }
                        ];
                      }
                      if (profileName === "Recommendation") {
                        section = [
                          {
                            "title": "Recommendation Specification",
                            "code": {
                              coding: [{
                                system: "https://fevir.net/resources/CodeSystem/179423",
                                code: "recommendation-specification",
                                display: "Recommendation Specification"
                              }]
                            },
                            "section": [
                              {
                                "title": "Recommendation Statement",
                                "code": {
                                  coding: [{
                                    system: "https://fevir.net/resources/CodeSystem/179423",
                                    code: "recommendation-statement",
                                    display: "Recommendation Statement"
                                  }]
                                },
                                "emptyReason": {
                                  "coding": [{
                                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                    "code": "notstarted",
                                    "display": "Not Started"
                                  }]
                                }
                              },
                              {
                                "title": "Recommendation Ratings",
                                "code": {
                                  coding: [{
                                    system: "https://fevir.net/resources/CodeSystem/179423",
                                    code: "ratings",
                                    display: "Ratings"
                                  }]
                                },
                                "emptyReason": {
                                  "coding": [{
                                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                    "code": "notstarted",
                                    "display": "Not Started"
                                  }]
                                }
                              },
                              {
                                "title": "Population",
                                "code": {
                                  coding: [{
                                    system: "https://fevir.net/resources/CodeSystem/179423",
                                    code: "population",
                                    display: "Population"
                                  }]
                                },
                                "emptyReason": {
                                  "coding": [{
                                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                    "code": "notstarted",
                                    "display": "Not Started"
                                  }]
                                }
                              },
                              {
                                "title": "Action",
                                "code": {
                                  coding: [{
                                    system: "https://fevir.net/resources/CodeSystem/179423",
                                    code: "action",
                                    display: "Action"
                                  }]
                                },
                                "emptyReason": {
                                  "coding": [{
                                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                    "code": "notstarted",
                                    "display": "Not Started"
                                  }]
                                }
                              },
                              {
                                "title": "Opposite Action",
                                "code": {
                                  coding: [{
                                    system: "https://fevir.net/resources/CodeSystem/179423",
                                    code: "opposite-action",
                                    display: "Opposite Action"
                                  }]
                                },
                                "emptyReason": {
                                  "coding": [{
                                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                    "code": "notstarted",
                                    "display": "Not Started"
                                  }]
                                }
                              }
                            ]
                          },
                          {
                            "title": "Discussion",
                            "code": {
                              coding: [{
                                system: "https://fevir.net/resources/CodeSystem/179423",
                                code: "discussion",
                                display: "Discussion"
                              }]
                            },
                            "emptyReason": {
                              "coding": [{
                                "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                "code": "notstarted",
                                "display": "Not Started"
                              }]
                            }
                          },
                          {
                            "title": "Evidence",
                            "code": {
                              coding: [{
                                system: "https://fevir.net/resources/CodeSystem/179423",
                                code: "evidence",
                                display: "Evidence"
                              }]
                            },
                            "emptyReason": {
                              "coding": [{
                                "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                "code": "notstarted",
                                "display": "Not Started"
                              }]
                            }
                          },
                          {
                            "title": "Justification",
                            "code": {
                              coding: [{
                                system: "https://fevir.net/resources/CodeSystem/179423",
                                code: "justification",
                                display: "Justification"
                              }]
                            },
                            "emptyReason": {
                              "coding": [{
                                "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                "code": "notstarted",
                                "display": "Not Started"
                              }]
                            }
                          },
                          {
                            "title": "Considerations",
                            "code": {
                              coding: [{
                                system: "https://fevir.net/resources/CodeSystem/179423",
                                code: "considerations",
                                display: "Considerations"
                              }]
                            },
                            "section": [
                              {
                                "title": "Overall Considerations Summary",
                                "code": {
                                  coding: [{
                                    system: "https://fevir.net/resources/CodeSystem/179423",
                                    code: "summary",
                                    display: "Summary"
                                  }]
                                },
                                "emptyReason": {
                                  "coding": [{
                                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                    "code": "notstarted",
                                    "display": "Not Started"
                                  }]
                                }
                              },
                              {
                                "title": "Subgroup Considerations",
                                "code": {
                                  coding: [{
                                    system: "https://fevir.net/resources/CodeSystem/179423",
                                    code: "subgroup-considerations",
                                    display: "Subgroup Considerations"
                                  }]
                                },
                                "emptyReason": {
                                  "coding": [{
                                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                    "code": "notstarted",
                                    "display": "Not Started"
                                  }]
                                }
                              },
                              {
                                "title": "Implementation Considerations",
                                "code": {
                                  coding: [{
                                    system: "https://fevir.net/resources/CodeSystem/179423",
                                    code: "implementation-considerations",
                                    display: "Implementation Considerations"
                                  }]
                                },
                                "emptyReason": {
                                  "coding": [{
                                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                    "code": "notstarted",
                                    "display": "Not Started"
                                  }]
                                }
                              },
                              {
                                "title": "Monitoring Considerations",
                                "code": {
                                  coding: [{
                                    system: "https://fevir.net/resources/CodeSystem/179423",
                                    code: "monitoring-considerations",
                                    display: "Monitoring Considerations"
                                  }]
                                },
                                "emptyReason": {
                                  "coding": [{
                                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                    "code": "notstarted",
                                    "display": "Not Started"
                                  }]
                                }
                              },
                              {
                                "title": "Competing Interests",
                                "code": {
                                  coding: [{
                                    system: "https://fevir.net/resources/CodeSystem/179423",
                                    code: "competing-interests",
                                    display: "Competing Interests"
                                  }]
                                },
                                "emptyReason": {
                                  "coding": [{
                                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                    "code": "notstarted",
                                    "display": "Not Started"
                                  }]
                                }
                              },
                              {
                                "title": "Research Considerations",
                                "code": {
                                  coding: [{
                                    system: "https://fevir.net/resources/CodeSystem/179423",
                                    code: "research-considerations",
                                    display: "Research Considerations"
                                  }]
                                },
                                "emptyReason": {
                                  "coding": [{
                                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                    "code": "notstarted",
                                    "display": "Not Started"
                                  }]
                                }
                              }
                            ]
                          }
                        ];
                      }
                      if (profileName === "SummaryOfFindings") {
                        section = [
                          {
                            "title": "Column Headers",
                            "code": {
                              "coding": [
                                {
                                  "system": "https://fevir.net/resources/CodeSystem/179423",
                                  "code": "column-headers",
                                  "display": "Column Headers"
                                }
                              ]
                            },
                            "section": [
                              {
                                "title": "Outcome",
                                "code": {
                                  "coding": [
                                    {
                                      "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "outcome-measure",
                                      "display": "Outcome Measure"
                                    }
                                  ]
                                },
                                "text": {
                                  "status": "generated",
                                  "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Outcome</div>"
                                }
                              },
                              {
                                "title": "Sample Size",
                                "code": {
                                  "coding": [
                                    {
                                      "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "sample-size",
                                      "display": "Sample Size"
                                    }
                                  ]
                                },
                                "text": {
                                  "status": "generated",
                                  "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Sample size: # studies, # participants, # counted, # events</div>"
                                }
                              },
                              {
                                "title": "Result Without Treatment",
                                "code": {
                                  "coding": [
                                    {
                                      "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "result-with-comparator-alone",
                                      "display": "Result with comparator alone"
                                    }
                                  ]
                                },
                                "text": {
                                  "status": "generated",
                                  "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result Without Treatment</div>"
                                }
                              },
                              {
                                "title": "Result With Treatment--Observed",
                                "code": {
                                  "coding": [
                                    {
                                      "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "result-with-intervention-alone",
                                      "display": "Result with intervention alone"
                                    }
                                  ]
                                },
                                "text": {
                                  "status": "generated",
                                  "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result With Treatment (Observed)</div>"
                                }
                              },
                              {
                                "title": "Result With Treatment--Derived",
                                "code": {
                                  "coding": [
                                    {
                                      "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "result-with-intervention-alone-calculated",
                                      "display": "Result with intervention alone (calculated)"
                                    }
                                  ],
                                  "text": "result with intervention with calculated value derived from the control group and effect estimate"
                                },
                                "text": {
                                  "status": "generated",
                                  "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result With Treatment (Calculated)</div>"
                                }
                              },
                              {
                                "title": "Effect Estimate",
                                "code": {
                                  "coding": [
                                    {
                                      "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "result-with-intervention-vs-comparator",
                                      "display": "Result with intervention vs. comparator"
                                    }
                                  ]
                                },
                                "text": {
                                  "status": "generated",
                                  "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Effect Estimate (Relative effect)</div>"
                                }
                              },
                              {
                                "title": "Certainty of Evidence",
                                "code": {
                                  "coding": [
                                    {
                                      "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "certainty-of-evidence",
                                      "display": "Certainty of Evidence"
                                    }
                                  ]
                                },
                                "text": {
                                  "status": "generated",
                                  "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Certainty of finding (Quality of evidence)</div>"
                                }
                              },
                              {
                                "title": "Assertion",
                                "code": {
                                  "coding": [
                                    {
                                      "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "assertion",
                                      "display": "Assertion"
                                    }
                                  ]
                                },
                                "text": {
                                  "status": "generated",
                                  "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">What this means</div>"
                                }
                              }
                            ]
                          }
                          /*
                          ,
                          {
                            "title": "Outcome Section 1",
                            "code": {
                             "coding": [
                                    {
                                      "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "summary-of-findings-entry-for-a-single-outcome",
                                      "display": "Summary of findings entry for a single outcome"
                                    }
                                  ]
                            },
                            "section": [
                              {
                                "title": "Outcome",
                                "code": {
                                  "coding": [
                                    {
                                       "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "outcome-measure",
                                      "display": "Outcome Measure"
                                  }
                                  ]
                                },
                                
                                "emptyReason": {
                                  "coding": [
                                    {
                                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                      "code": "notstarted",
                                      "display": "Not Started"
                                    }
                                  ]
                                }
                              },
                              {
                                "title": "Sample Size",
                                "code": {
                                  "coding": [
                                    {
                                         "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "sample-size",
                                      "display": "Sample Size"
                                   }
                                  ]
                                },
                                
                                "emptyReason": {
                                  "coding": [
                                    {
                                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                      "code": "notstarted",
                                      "display": "Not Started"
                                    }
                                  ]
                                }
                              },
                              {
                                "title": "Result Without Treatment",
                                "code": {
                                  "coding": [
                                    {
                                  "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "result-with-comparator-alone",
                                      "display": "Result with comparator alone"
                                     }
                                  ]
                                },
                                "emptyReason": {
                                  "coding": [
                                    {
                                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                      "code": "notstarted",
                                      "display": "Not Started"
                                    }
                                  ]
                                }
                              },
                              {
                                "title": "Result With Treatment--Observed",
                                "code": {
                                  "coding": [
                                    {
                                  "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "result-with-intervention-alone",
                                      "display": "Result with intervention alone"
                                     }
                                  ]
                                },
                                
                                "emptyReason": {
                                  "coding": [
                                    {
                                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                      "code": "notstarted",
                                      "display": "Not Started"
                                    }
                                  ]
                                }
                              },
                              {
                                "title": "Result With Treatment--Derived",
                                "code": {
                                           "coding": [
                                  {
                                    "system": "https://fevir.net/resources/CodeSystem/179423",
                                    "code": "result-with-intervention-alone-calculated",
                                    "display": "Result with intervention alone (calculated)"
                                  }
                                ],
                              "text": "result with intervention with calculated value derived from the control group and effect estimate"
                                },
                                
                                "emptyReason": {
                                  "coding": [
                                    {
                                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                      "code": "notstarted",
                                      "display": "Not Started"
                                    }
                                  ]
                                }
                              },
                              {
                                "title": "Effect Estimate",
                                "code": {
                                  "coding": [
                                    {
                                   "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "result-with-intervention-vs-comparator",
                                      "display": "Result with intervention vs. comparator"
                                    }
                                  ]
                                },
                                
                                "emptyReason": {
                                  "coding": [
                                    {
                                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                      "code": "notstarted",
                                      "display": "Not Started"
                                    }
                                  ]
                                }
                              },
                              {
                                "title": "Certainty of Evidence",
                                "code": {
                                  "coding": [
                                    {
                                     "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "certainty-of-evidence",
                                      "display": "Certainty of Evidence"
                                    }
                                  ]
                                },
                                
                                "emptyReason": {
                                  "coding": [
                                    {
                                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                      "code": "notstarted",
                                      "display": "Not Started"
                                    }
                                  ]
                                }
                              },
                              {
                                "title": "Assertion",
                                "code": {
                                  "coding": [
                                    {
                                   "system": "https://fevir.net/resources/CodeSystem/179423",
                                      "code": "assertion",
                                      "display": "Assertion"
                                    }
                                  ]
                                },
                                
                                "emptyReason": {
                                  "coding": [
                                    {
                                      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                      "code": "notstarted",
                                      "display": "Not Started"
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                          */
                        ];
                      }
                      if (section) {
                        setNewResourceState(prevState => { return { ...prevState, 'meta': meta, 'section': section } });
                      } else {
                        setNewResourceState(prevState => { return { ...prevState, 'meta': meta } });
                      }
                    }
                  }
                }} />}
              name="profile-selection-radio-button-control"
              color="default"
              inputprops={{ 'aria-label': profile }}
              labelPlacement="top"
              label={<p style={{ textAlign: 'center' }}>{profile}</p>}
            />
          })}
        </RadioGroup>
      </div>}
    </div>
  </div>
}

const JsonEntryPage = ({ useTitle, resourceType, resourceId, getResource, resourceStarter }) => {

  let titleOnly = true;
  let pageTitle = "FEvIR Resource Entry";
  if (!resourceStarter) {
    titleOnly = false;
    pageTitle = "FEvIR Resource JSON Entry";
    resourceStarter = { "resourceType": "", "title": "" };
  }

  useTitle(pageTitle);
  const globalContext = useContext(FevirContext);
  const [fhirEntryState, setFhirEntryState] = useState({ "fhirEntry": "", "status": "active" });

  const [newResourceState, setNewResourceState] = useState(resourceStarter);

  const changeFhirEntryState = (newValue, field) => {
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        [field]: newValue
      };
    });
  };

  useEffect(async () => {
    if (!globalContext.userState.loading) {
      if (resourceId) {
        let resourceEntry = await getResource(resourceId, resourceType, globalContext.userState.idToken);
        let fhirJsonString = resourceEntry["fhirResource"];
        let fhirJson = JSON.parse(fhirJsonString);
        if (globalContext.userState.id) {
          if (fhirJson.title && typeof fhirJson.title === "string" && fhirJson.title.trim()) {
            fhirJson.title = fhirJson.title + " - " + globalContext.userState.name;
          } else if (fhirJson.name) {
            if (typeof fhirJson.name === "string" && fhirJson.name.trim()) {
              fhirJson.name = fhirJson.name + " - " + globalContext.userState.name;
            } else if (Array.isArray(fhirJson.name) && fhirJson.name.length > 0 && fhirJson.name[0].text && fhirJson.name[0].text.trim() !== "") {
              fhirJson.name.append = { "text": fhirJson.name[0].text + " - " + globalContext.userState.name };
            }
          }
          if (fhirJson.author) {
            delete fhirJson.author;
          }
          let identifiersToKeep = [];
          if (fhirJson.identifier) {
            for (let identifierIndex in fhirJson.identifier) {
              if (fhirJson.identifier[identifierIndex].system !== "https://fevir.net") {
                identifiersToKeep.push(fhirJson.identifier[identifierIndex]);
              }
            }
            delete fhirJson.identifier;
            if (identifiersToKeep.length > 0) {
              fhirJson.identifier = identifiersToKeep;
            }
          }
          fhirJsonString = JSON.stringify(fhirJson, null, 2);
          changeFhirEntryState(fhirJsonString, "fhirEntry");
          window.history.replaceState(null, "", "/jsonentry")
        }
      }
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(newResourceState) !== JSON.stringify({ "resourceType": "", "title": "" })) {
      let newJson = {
        "resourceType": newResourceState.resourceType,
        "meta": newResourceState.meta,
        "title": newResourceState.title
      };
      if (newResourceState.resourceType === "CodeSystem") {
        newJson.concept = [];
      }
      if (newResourceState.section) {
        newJson.section = newResourceState.section;
      } else if (newResourceState.resourceType === "Composition") {
        newJson.section = [];
      }
      changeFhirEntryState(JSON.stringify(newJson), "fhirEntry");
    }
  }, [newResourceState])

  const history = useHistory();

  return <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <br /><br />
      {globalContext.userState.firebaseuid ?
        <>
          <NewResourceEntry newResourceState={newResourceState} setNewResourceState={setNewResourceState}
            titleOnly={titleOnly} />
          <br />
               {titleOnly === false && <><br /><p>You can paste and/or edit complete JSON below. <b>Use of data entry fields above will replace the JSON.</b></p>
            <TextArea placeholder='Paste your JSON here' style={{ minHeight: 100, width: "60%" }}
              value={fhirEntryState.fhirEntry}
              onChange={(e) => { changeFhirEntryState(e.target.value, "fhirEntry"); }} />
            <br /><br />
            {/* Move this into a module because it's used for both JsonEntryPage and ResourceDisplayPage */}
            <RadioGroup row aria-label="status" name="status"
              value={fhirEntryState.status} onChange={(e) => { changeFhirEntryState(e.target.value, "status"); }}>
              <FormControlLabel
                value="active"
                control={<Radio color="primary" />}
                name="radio-button-control"
                color="default"
                inputprops={{ 'aria-label': 'Publish' }}
                labelPlacement="top"
                label={<p style={{ textAlign: 'center' }}>Publish<br />(anyone can view)</p>}
              />
              <FormControlLabel
                value="draft"
                control={<Radio color="primary" />}
                color="default"
                name="radio-button-control"
                inputprops={{ 'aria-label': 'Draft' }}
                labelPlacement="top"
                label={<p style={{ textAlign: 'center' }}>Draft<br />(only editors can view)</p>}
              />
            </RadioGroup>
            <br />
          </>}
          <Button className="formButton positive" content="Submit" compact
            onClick={() => { submitResource(globalContext, history, fhirEntryState, true); }} />
          {titleOnly === false && <>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button className="formButton" style={{ color: "#000000" }}
              content="Use JSON Tree Writer for Evidence Resource" compact
              onClick={() => { window.open('https://khalid-shahin.github.io/JSON-Tree-Writer/EBMonFHIR-Evidence.html'); }} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button className="formButton" style={{ color: "#000000" }}
              content="Use JSON Tree Writer for EvidenceVariable Resource" compact
              onClick={() => { window.open('https://khalid-shahin.github.io/JSON-Tree-Writer/EBMonFHIR-EvidenceVariable.html'); }} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button className="formButton" style={{ color: "#000000" }}
              content="JSON Bulk Entry" compact
              onClick={() => { history.push(`/jsonbulkentry`); }} />
          </>}
        </>
        :
        <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
      }
    </Segment>
  </div>
};

export default JsonEntryPage;